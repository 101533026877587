import { useState, useRef } from "react";
// CSS
import "./LoginForm.css";

// Components
import Header from "../Layout/Header";
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "../../redux/actions";
import { useNavigate } from "react-router-dom";
import Loader from "../Users/globalLoader";
import { notify } from "../util/notify";
import axios from "axios";
import { Alert } from "bootstrap";
import { BASE_URL } from "../../assets/constants";

const LoginForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const passwordRef = useRef();
  const emailRef = useRef();
  const loginData = useSelector((state) => state?.userReducer?.loginData);
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const validateForm = () => {
    const newErrors = {};

    if (!emailRef.current.value) {
      newErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(emailRef.current.value)) {
      newErrors.email = "Email address is invalid";
    }

    if (!passwordRef.current.value) {
      newErrors.password = "Password is required";
    } else if (passwordRef.current.value.length < 6) {
      newErrors.password = "Password must be at least 6 characters";
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const onLoginSubmit = async (e) => {
    e.preventDefault();
    if (!emailRef.current.value && !passwordRef.current.value) {
      notify(true, "Email and Password are required");
      return;
    } else if (!emailRef.current.value) {
      notify(true, "Email is required");
      return;
    } else if (!passwordRef.current.value) {
      notify(true, "Password is required");
      return;
    }

    setLoading(true);

    const formData = {
      email: emailRef.current.value,
      password: passwordRef.current.value,
    };

    try {
      const res = await axios.post(`${BASE_URL}login`, {
        email: emailRef.current.value,
        password: passwordRef.current.value,
      });

      if (res?.status === 200) {
        notify("", "Accesso effettuato con successo");
        console.log("res?.data?.role", res?.data?.role);
        if (res?.data?.role === "coach") {
          navigate("/coach/home");
          localStorage.setItem("adminData", JSON.stringify(res?.data));
        } else if (res?.data?.role === "athlete") {
          localStorage.setItem("adminData", JSON.stringify(res?.data));
          let response = await axios.get(`${BASE_URL}athlete/step-form`, {
            headers: {
              Authorization: `Bearer ${res?.data?.token}`,
            },
          });
          let steps = response?.data?.forms_completed;
          localStorage.setItem("onboardingProgress", JSON.stringify(steps));
          if (!steps.has_created_step_1) {
            navigate("/onboardingstep1");
          } else if (!steps.has_created_step_2) {
            navigate("/onboardingstep3");
          } else if (!steps.has_created_step_3) {
            navigate("/onboardingstep4");
          } else if (!steps.has_created_step_4) {
            navigate("/onboardingstep5");
          } else if (!steps.has_created_step_5) {
            navigate("/onboardingstep6");
          } else {
            navigate("/user/home");
          }
          setLoading(false);
          return;

          navigate("/onboardingstep1");
        } else if (res?.data?.role === "admin") {
          navigate("/admin/coach");
          localStorage.setItem("adminData", JSON.stringify(res));
        }
        localStorage.setItem("adminData", JSON.stringify(res));
      }
    } catch (error) {
      notify(true, error?.response?.data?.message);
      console.log(error, "ERROR");

      // if (error.response) {
      //   notify(true, `Error: ${error.response.data.message}`, 3000);
      // } else if (error.request) {
      //   console.log("Request data:", error.request);
      //   notify("No response received from server", 3000);
      // } else {
      //   console.log("Error message:", error.message);
      //   notify(true, error.message, 3000);
      // }
    }

    setLoading(false);
    emailRef.current.value = "";
    passwordRef.current.value = "";
  };

  return (
    <>
      <Header />
      {loading && <Loader />}
      <div className="main-container centered">
        <div className="login-content">
          <div className="headline">accedi al tuo account</div>
          <div className="social-login-buttons">
            <a
              href="services/users/loginUser.php?provider=Google"
              className="button-default-no-icon"
            >
              continua con google
            </a>
            <a
              href="services/users/loginUser.php?provider=Facebook"
              className="button-default-no-icon"
            >
              continua con facebook
            </a>
          </div>
          <div className="oppure">oppure</div>
          <form name="login" id="userLogin" onSubmit={onLoginSubmit}>
            <div className="user-info">
              <div className="text-input">
                <div className="title">Indirizzo email</div>
                <div className="input input-control">
                  <input
                    type="text"
                    name="email"
                    id="userEmail"
                    placeholder="something@domain.com"
                    ref={emailRef}
                  />
                  {errors.email && <div className="error">{errors.email}</div>}
                </div>
              </div>
              <div className="text-input margin-top">
                <div className="title">Password</div>
                <div className="input input-control">
                  <input
                    type={showPassword ? "text" : "password"}
                    name="password"
                    id="userPassword"
                    placeholder="MyP@assword"
                    ref={passwordRef}
                  />
                  {errors.password && (
                    <div className="error">{errors.password}</div>
                  )}
                </div>
              </div>
              <div
                className="input-control show-password"
                style={{ marginBottom: "1em" }}
              >
                <input
                  type="checkbox"
                  className="round-checkbox"
                  checked={showPassword}
                  onChange={toggleShowPassword}
                />
                Show password
              </div>
            </div>
            <div className="login-register">
              <button
                type="submit"
                id="submitLogin"
                className="button-default-no-icon"
                disabled={loading}
              >
                {loading ? "Logging in..." : "Login"}
              </button>
              <a
                href="/forgot-password"
                className="button-default-no-icon outline"
              >
                password dimenticata?
              </a>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default LoginForm;
