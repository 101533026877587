import React from "react";
import { useDrop } from "react-dnd";
import RearrangeableItem from "./RearrangeableItem";
import { useLocation } from "react-router-dom";

let dummy = { Breakfast: [], lunch: [], dinner: [], Fiber: [] };
// let foods = [
//   {
//     id: 18,
//     nutrition_meal_id: 9,
//     diet_id: 1,
//     quantity: 1,
//     created_at: "2024-10-05T12:40:01.000000Z",
//     updated_at: "2024-10-05T12:40:01.000000Z",
//   },
//   {
//     id: 19,
//     nutrition_meal_id: 9,
//     diet_id: 16,
//     quantity: 1,
//     created_at: "2024-10-05T12:40:01.000000Z",
//     updated_at: "2024-10-05T12:40:01.000000Z",
//   },
// ];
// let foods2 = [
//   {
//     id: 18,
//     nutrition_meal_id: 9,
//     diet_id: 1,
//     quantity: 1,
//     created_at: "2024-10-05T12:40:01.000000Z",
//     updated_at: "2024-10-05T12:40:01.000000Z",
//   },
//   {
//     id: 19,
//     nutrition_meal_id: 9,
//     diet_id: 16,
//     quantity: 1,
//     created_at: "2024-10-05T12:40:01.000000Z",
//     updated_at: "2024-10-05T12:40:01.000000Z",
//   },
//   {
//     id: 19,
//     nutrition_meal_id: 9,
//     diet_id: 17,
//     quantity: 1,
//     created_at: "2024-10-05T12:40:01.000000Z",
//     updated_at: "2024-10-05T12:40:01.000000Z",
//   },
// ];

const ItemTypes = {
  ITEM: "item",
};

function DropContainer({
  droppedItems,
  setDroppedItems,
  dietType,
  setSelectedItems,
  selectedItems,
}) {
  const { state } = useLocation();
  const [, drop] = useDrop({
    accept: ItemTypes.ITEM,
    drop: (item) => handleDrop(item),
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  });

  const handleDrop = (item) => {
    // Check if the item is not already in the droppedItems.Breakfast list
    if (!droppedItems[dietType].some((i) => i.id === item.id)) {
      setDroppedItems((prev) => ({
        ...prev,
        [dietType]: [...prev[dietType], item], // Add item to Breakfast array
      }));
    }
  };

  const moveItem = (dragIndex, hoverIndex) => {
    const updatedItems = [...droppedItems[dietType]];
    const [draggedItem] = updatedItems.splice(dragIndex, 1);
    updatedItems.splice(hoverIndex, 0, draggedItem);
    setDroppedItems((prev) => ({
      ...prev,
      [dietType]: updatedItems,
    }));
  };
  const handleCancel = (meal) => {
    const dietTypeFromState = state?.dietTypes;

    setDroppedItems((prev) => ({
      ...prev,
      [dietType]: prev[dietType]?.filter((item) => item.id !== meal.id),
    }));

    setSelectedItems((prev) => ({
      ...prev,
      [dietTypeFromState]: [...(prev[dietTypeFromState] || []), meal],
    }));
  };

  return (
    <div
      ref={drop}
      className="drop-container"
      style={{
        padding: "20px",
        minHeight: "300px",
        backgroundColor: "transparent",
      }}
    >
      {droppedItems?.[dietType]?.map((item, index) => (
        <RearrangeableItem
          key={item.id}
          index={index}
          data={item}
          icon={item?.icon}
          moveItem={moveItem}
          handleCancel={handleCancel}
        />
      ))}
    </div>
  );
}

export default DropContainer;
