import React, { useEffect, useState } from "react";
import plusIcon from "../../assets/img/icons/plus-black.svg";
import AtletiHeader from "../Reuseable/AtletiHeader";
import CoachHeader from "../Reuseable/CoachHeader";
import AdminTranining from "../Admin/AdminTranining";
import NoteCard from "../Admin/NotesCard";
import editIcon from "../../assets/img/icons/edit.svg";
import deleteIcon from "../../assets/img/icons/delete.svg";
import calendarIcon from "../../assets/img/icons/calendar.svg";
import axios from "axios";
import { BASE_URL } from "../../assets/constants";
import { notify } from "../util/notify";
import CreateNoteModal from "../CreateNoteModal";
import { useLocation } from "react-router-dom";
import DeleteModal from "../DeleteModal";

const TaskEditor = ({
  title,
  date,
  description,
  handleEditClick,
  value,
  handleDelete,
}) => {
  const formatDate = (dateString) => {
    // Create a new Date object
    const date = new Date(dateString);

    // Format the date to "Saturday, 05 Oct"
    const options = { weekday: "long", day: "2-digit", month: "short" };
    return date.toLocaleDateString("en-US", options);
  };

  return (
    <div className="task-editor">
      <div className="title">
        {title}
        <div>
          <img
            src={editIcon}
            alt="edit icon"
            onClick={() => handleEditClick(value)}
          />
          <img
            src={deleteIcon}
            alt="delete icon"
            onClick={() => handleDelete(value)}
            style={{
              marginLeft: "10px",
              cursor: "pointer",
              width: "20px",
              height: "20px",
              filter: "invert(100%) brightness(100%)",
            }}
          />
        </div>
      </div>
      <div className="task-body">
        <img
          src={calendarIcon}
          alt="calendar icon"
          style={{ marginRight: "10px" }}
        />{" "}
        <label>{formatDate(date)}</label>
      </div>
      <div className="description">{description}</div>
    </div>
  );
};

const CoachAtheletStepFive = ({ onClick, onButtonClick }) => {
  const [openModal, setOpenModal] = useState(false);
  const [selectedNote, setSelectedNote] = useState(null);
  const [notes, setNotes] = useState([]);
  const { state } = useLocation();
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const adminData = JSON.parse(localStorage.getItem("adminData"));
  const token = adminData?.data?.token;

  const handleEditClick = (note) => {
    setOpenModal(true);
    setSelectedNote(note);
  };

  const handleDelete = async (value) => {
    setIsLoading(true);
    try {
      await axios.delete(`${BASE_URL}coach/note-delete/${confirmationModal}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      fetchNotes();
      notify("", "Deleted Successfully..!");
    } catch (error) {}
    setIsLoading(false);
    setConfirmationModal(false);
  };

  const fetchNotes = async () => {
    try {
      const response = await axios.get(`${BASE_URL}coach/note-all`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setNotes(response?.data?.data || []);
    } catch (error) {
      console.error("Error fetching athlete notes:", error);
    } finally {
    }
  };

  const handleNoteSave = () => {
    fetchNotes();
    setOpenModal(false);
  };

  useEffect(() => {
    fetchNotes();
  }, []);

  return (
    <div>
      <CoachHeader />
      <div className="admin-atletet-step-five-container">
        <div className="admin-atletet-step-five-card-one">
          <AdminTranining />
        </div>
        <div className="admin-atletet-step-five-card-two">
          <div className="admin-notes-width">
            <div className="notes-group">
              {/* <div className="notes width">
                <div className=" title-para">
                  <p className=" ">NOTE</p>
                </div>
                {notes.map((note, index) => (
                  <NoteCard
                    key={index}
                    title={note.title}
                    description={note.description}
                    tags={note.tags}
                  />
                ))}
                <a href="#" className="button-default-no-icon noteButton">
                  <img
                    src={plusIcon}
                    alt="plus icon"
                    style={{ width: "25px", height: "25px" }}
                  />
                  <p>aggiungi nuova</p>
                </a>
              </div> */}
              <DeleteModal
                isOpen={confirmationModal}
                onCancel={() => setConfirmationModal(false)}
                onConfirm={handleDelete}
                message="Sei sicuro di voler eliminare questa nota?"
                isLoading={isLoading}
              />
              <div className="notes width">
                <div className="right-sidebar">
                  <div className="sub---title">Note</div>
                  {notes?.length > 0
                    ? notes?.map((activity, index) => (
                        <TaskEditor
                          key={index}
                          title={activity.title}
                          date={activity.date}
                          description={activity.description}
                          handleEditClick={handleEditClick}
                          value={activity}
                          handleDelete={() =>
                            setConfirmationModal(activity?.uuid)
                          }
                        />
                      ))
                    : "essuna nota trovat"}
                  <a
                    onClick={(e) => {
                      e.preventDefault();
                      setOpenModal(true);
                      setSelectedNote(null);
                    }}
                    className="button-default-no-icon"
                  >
                    <img src={plusIcon} alt="plus icon" />
                    <p>aggiungi nuova</p>
                  </a>
                </div>{" "}
                <CreateNoteModal
                  isOpen={openModal}
                  onClose={() => setOpenModal(false)}
                  token={token}
                  selectedNote={selectedNote}
                  onSave={handleNoteSave}
                  coachAthelete
                  userId={state?.id}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CoachAtheletStepFive;
