// src/components/Dashboard.js
import React, { useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Header from "../Layout/Header";
import SideBar from "./SideBar";
import MainContent from "./MainContent";
import Footer from "./Footer";
import "./Dashboard.css";
import CoachContent from "./CoachContent";
import CoachSidebar from "../sidebar";
import AdminContent from "./AdminContent";
import AdminSideBar from "./AdminSideBar";

const UsersDashboard = ({ content, coachContent, adminContent }) => {
  const [bol, setBol] = useState(false);
  const location = useLocation();

  const getActiveOption = () => {
    const path = location.pathname;
    if (path.includes("/user/home")) return "Home";
    if (path.includes("/user/training/step-one")) return "Allenamento";
    if (path.includes("/user/nutrizione")) return "Nutrizione";
    if (path.includes("/user/profilo")) return "Profilo";
    if (path.includes("/user/aggiorn-le-misurazioni")) return "newui";

    if (path.includes("/coach/home")) return "Atleti";
    if (path.includes("/coach/calendario")) return "Calendario";
    if (path.includes("/coach/note")) return "Note";
    if (path.includes("/coach/profilo")) return "Profilo";

    if (path.includes("/admin/atlet")) return "Atleti";
    if (path.includes("/admin/coach")) return "Coach";
    if (path.includes("/admin/calender")) return "Calendario";
    if (path.includes("/admin/note")) return "Note";
    if (path.includes("/admin/profile")) return "Profilo";

    return "Home";
  };

  return (
    <div className="dashboard">
      <Header />
      <SideBar activeOption={getActiveOption()} />
      <div className="content">
        <Outlet adminContent={adminContent} />
      </div>
      <Footer />
    </div>
  );
};

export default UsersDashboard;
