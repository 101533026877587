import React, { useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";

function FirstScreen({}) {
  const [countdown, setCountdown] = useState(3);
  const [showVideo, setShowVideo] = useState(false);
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate("/onboardingstep2");
  };

  return (
    <div>
      <div className="timer" id="scheda-allenamento">
        <div className="title" style={{ marginTop: -300 }}>
          Benvenuto!
        </div>
        <div style={{ textAlign: "center", width: "20em" }}>
          Ti farò una serie di domande per conoscerci meglio e per elaborare il
          tuo giusto piano di allenamento e nutrizione in base ai dati che ci
          fornirai.
        </div>
        <button
          type="button"
          className="button-default-no-icon"
          onClick={() => {
            handleBackClick();
          }}
          style={{ width: 300, position: "absolute", bottom: 30 }}
        >
          iniziamo
        </button>
      </div>
    </div>
  );
}

export default FirstScreen;
