import React from "react";
import GridTableMeasurement from "./GridTableMeasurement";
import Dropdown from "../Reuseable/Dropdown";
import { useState } from "react";

const MeasurementTables = ({ atheleteData }) => {
  const [selectedOption, setSelectedOption] = useState("visualizza per");

  const options = ["settimana 1", "nuova settimana", "duplica", "elimina"];

  const handleSelect = (option) => {
    setSelectedOption(option);
  };

  return (
    <div className="measurementTablesContanier">
      <div>
        <p className="measurement-table-heading">Aggiornamenti</p>

        <GridTableMeasurement atheleteData={atheleteData?.updates} />
      </div>
      <div>
        <p className="measurement-table-heading">misurazioni</p>
        <GridTableMeasurement
          type="measurementData"
          atheleteData={atheleteData?.measurements}
        />
      </div>
      <div>
        <p className="measurement-table-heading">foto</p>

        {/* <Dropdown
          btn="visualizza per"
          options={options}
          bgList="true"
          onSelect={handleSelect}
          content={selectedOption}
        /> */}
        <div className="foto-flex">
          <div className="foto-card">
            {atheleteData?.photo[0]?.front_photo && (
              <img
                src={atheleteData?.photo[0]?.front_photo || ""}
                width={"100%"}
                height={"215px"}
              />
            )}
          </div>
          <div className="foto-card">
            {atheleteData?.photo[0]?.side_photo && (
              <img
                src={atheleteData?.photo[0]?.side_photo}
                width={"100%"}
                height={"215px"}
              />
            )}
          </div>
          <div className="foto-card">
            {atheleteData?.photo[0]?.back_photo && (
              <img
                src={atheleteData?.photo[0]?.back_photo}
                width={"100%"}
                height={"215px"}
              />
            )}
          </div>
        </div>
      </div>
      <div>
        {/* table-----------3 */}
        <div className="mt-2">
          <p className="measurement-table-heading">onboarding</p>

          <div className="">
            <div className="table-one my-3">
              <GridTableMeasurement
                type="onBoarding"
                atheleteData={atheleteData?.onBoarding}
              />
            </div>

            <div className="my-3">
              <GridTableMeasurement
                type="wellBeing"
                atheleteData={atheleteData?.onBoarding}
              />
            </div>
          </div>
        </div>

        {/* table-----------4 */}
        <div className="">
          <div className="">
            <div className="table-one my-3">
              <GridTableMeasurement
                type="measurementsss"
                atheleteData={atheleteData?.onBoarding}
              />
            </div>
            <div className="table-two my-3">
              <GridTableMeasurement
                type="nutritions"
                atheleteData={atheleteData?.onBoarding}
              />
            </div>
          </div>
        </div>
      </div>
      {/* table----------------5 */}
      <div>
        <GridTableMeasurement
          type="Training"
          atheleteData={atheleteData?.onBoarding}
        />
      </div>
    </div>
  );
};

export default MeasurementTables;
