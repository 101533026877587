import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { LeftArrowIcon, ClipBoard, UserActive } from "../Reuseable/Icons";
import logoutIcon from "../../assets/img/icons/log-out-red.svg";

import "./ProfileContent.css";
import Loader from "./globalLoader";

const Profile = () => {
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const [loading, setLoading] = useState(false);

  const handleBackClick = () => {
    navigate(-1);
  };

  // useEffect(() => {
  //   if (!token) {
  //     navigate("/login");
  //   }
  // }, []);

  const handleLogout = () => {
    setLoading(true);
    setTimeout(() => {
      localStorage.removeItem("token");
      localStorage.removeItem("adminData");
      localStorage.removeItem("onboardingProgress");
      navigate("/login");
    }, 500);
  };

  return (
    <div className="profile-content">
      {/* Top bar */}
      {loading && <Loader />}
      <div className="top-bar">
        <button onClick={handleBackClick} className="icon">
          <LeftArrowIcon />
        </button>
        <div className="title">Profilo</div>
      </div>
      {/* Main profile link group */}
      <div className="main-profile-link-group">
        <Link className="main-profile-link" to="/profilo-modifica">
          {/* <img src={userIcon} alt="" /> */}
          <UserActive />
          Modifica profilo
        </Link>
        <Link to={"/privacy"} target="_blank">
          <a
            className="main-profile-link"
            to="/privacy"
            // href="https://www.appilgiusto.com/privacy.php"
            target="_blank"
          >
            {/* <img src={clipboardIcon} alt="" /> */}
            <ClipBoard />
            Privacy
          </a>
        </Link>

        <button
          className="main-profile-link"
          onClick={handleLogout}
          style={{ background: "none", border: "none" }}
        >
          <img src={logoutIcon} alt="Logout" />
          <p
            style={{ marginLeft: "-5px", marginTop: "0px", cursor: "pointer" }}
          >
            Logout
          </p>
        </button>
      </div>
    </div>
  );
};

export default Profile;
