import React from "react";
import AdminAtheletStepSeven from "../../components/Admin/AdminAtheletStepSeven";

const AdminMisurazione = () => {
  return (
    <div>
      <AdminAtheletStepSeven />
    </div>
  );
};

export default AdminMisurazione;
