import React, { useState, useEffect } from "react";
import axios from "axios";
import { BASE_URL } from "../assets/constants";
import { notify } from "./util/notify";
import DatePicker from "react-datepicker";
import Dropdown from "./Reuseable/Dropdown";

const NewActivityModal = ({
  closedHandler,
  selectedAthlete,
  setSelectedAthlete,
  onButtonClick,
  date,
  setDate,
}) => {
  const [athletes, setAthletes] = useState([]);
  const selectedAthleteData = athletes?.find(
    (athlete) => athlete.id === parseInt(selectedAthlete)
  );

  useEffect(() => {
    const fetchData = async () => {
      const adminData = JSON.parse(localStorage.getItem("adminData"));
      const token = adminData?.data?.token;

      try {
        const [athleteResponse] = await Promise.all([
          axios.get(`${BASE_URL}admin/athlete-all`, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }),
        ]);

        let filterAthlete = athleteResponse?.data?.athlete?.map((val) => ({
          name: val?.name,
          id: val?.id,
        }));
        setAthletes(filterAthlete);
      } catch (error) {
        console.error(
          "Failed to fetch data:",
          error.response ? error.response.data : error.message
        );
      }
    };

    fetchData();
  }, []);

  return (
    <div className="modal-overlayy" sty>
      <div className="modal-content">
        <p className="modal-title">Assign Meal</p>
        <form onSubmit={onButtonClick}>
          <div
            className="form-div custom-select-container"
            style={{ width: "100%" }}
          >
            <label>Choose Athlete</label>

            <Dropdown
              options={athletes && athletes}
              onSelect={(e) => setSelectedAthlete(e)}
              btn="Select account type"
              bgList="true"
              className="custom-select-container-drop"
              content={selectedAthlete}
            />
          </div>
          {selectedAthlete?.id !== 0 && (
            <div className="form-div picker">
              <label>date</label>
              <DatePicker
                selected={date}
                onChange={(date) => setDate(date)}
                dateFormat="dd-MMM-yyyy"
                placeholderText="dd-mmm-yyyy"
                minDate={new Date()}
                isClearable
              />
            </div>
          )}

          <button type="submit" className="modal-btn">
            Assign
          </button>
        </form>
        <div onClick={closedHandler} className="modal-close">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="15"
            height="15"
            viewBox="0 0 16 16"
          >
            <path
              fillRule="evenodd"
              fill="#fff"
              d="M0 14.545L1.455 16 8 9.455 14.545 16 16 14.545 9.455 8 16 1.455 14.545 0 8 6.545 1.455 0 0 1.455 6.545 8z"
            ></path>
          </svg>
        </div>
      </div>
    </div>
  );
};

export default NewActivityModal;
