import React, { useState, useEffect } from "react";
import "./onboardingCss.css";
import "../../components/Users/Measurements.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "../../assets/constants";
import { notify } from "../../components/util/notify";

function SecondStep({}) {
  const adminData = JSON.parse(localStorage.getItem("adminData"));
  const token = adminData?.token;
  const [isLoading, setIsLoading] = useState(false);
  const [atheleteData, setAtheleteData] = useState({
    name_or_surname: "",
    height: "",
    weight: "",
    biological_sex: "",
    age: "",
    profession: "",
  });
  const navigate = useNavigate();

  const handleBackClick = async () => {
    setIsLoading(true);
    const errors = [];

    if (!atheleteData.name_or_surname.trim()) {
      errors.push("Name or Surname");
    }
    if (!atheleteData.height || atheleteData.height <= 0) {
      errors.push("Height");
    }
    if (!atheleteData.weight || atheleteData.weight <= 0) {
      errors.push("Weight");
    }
    if (!atheleteData.biological_sex.trim()) {
      errors.push("Biological Sex");
    }
    if (!atheleteData.age || atheleteData.age <= 0) {
      errors.push("Age");
    }
    if (!atheleteData.profession.trim()) {
      errors.push("Profession");
    }

    if (errors.length > 0) {
      setIsLoading(false);
      notify(true, `Required Fields Are Missing: ${errors.join(", ")}!`);
      return;
    }
    try {
      let response = await axios.post(
        `${BASE_URL}athlete/step-1`,
        atheleteData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response?.data?.status) {
        let step = await axios.get(`${BASE_URL}athlete/step-form`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        let steps = step?.data?.forms_completed;
        localStorage.setItem("onboardingProgress", JSON.stringify(steps));
        notify(false, "Passaggio 1 completato");

        navigate("/onboardingstep3");
      }
    } catch (error) {
      const errorMessages = error?.response?.data?.message;

      if (errorMessages) {
        const combinedErrors = Object.values(errorMessages).flat().join("\n");

        notify(true, combinedErrors);
      } else {
        notify(true, "Something went wrong!");
      }
    }
    setIsLoading(false);
  };

  const handleInputChange = (event) => {
    let { value, name } = event.target;
    setAtheleteData({
      ...atheleteData,
      [name]: value,
    });
  };

  const spinnerStyle = {
    border: "3px solid #f3f3f3",
    borderTop: "3px solid #a0fc6e",
    borderRadius: "50%",
    width: "30px",
    height: "30px",
    animation: "spin 1s linear infinite",
    margin: "0 auto",
  };

  const containerStyle = {
    display: "flex",
    justifyContent: "center",
    marginTop: "10px",
  };

  return (
    <div className="mainContainer">
      <div>
        <div
          className="hunger-level-container1"
          style={{
            backgroundColor: "#080808",
            marginTop: 20,
            padding: 20,
            width: "25em",
          }}
        >
          <div className="title" style={{ fontSize: "2em" }}>
            Profilo personale
          </div>

          <div className="sprateColorsStep">
            <div className="colorBar" style={{ height: 8 }}></div>
            <div
              className="colorBar"
              style={{ height: 6, marginLeft: 10, backgroundColor: "#fff" }}
            ></div>
            <div
              className="colorBar"
              style={{ height: 6, marginLeft: 10, backgroundColor: "#fff" }}
            ></div>
            <div
              className="colorBar"
              style={{ height: 6, marginLeft: 10, backgroundColor: "#fff" }}
            ></div>
            <div
              className="colorBar"
              style={{ height: 6, marginLeft: 10, backgroundColor: "#fff" }}
            ></div>
          </div>

          <div className="range-labels" style={{ marginTop: -15 }}>
            <span className="label-left" style={{ color: "#7A7A7A" }}>
              Step 1 di 5
            </span>
          </div>

          {[
            { name: "Nome e Cognome", value: "name_or_surname" },
            { name: "Altezza", value: "height", type: "number" },
            { name: "PESO", value: "weight", type: "number" },
            { name: "Sesso", value: "biological_sex" },
            { name: "ETA", value: "age", type: "number" },
            { name: "Professione", value: "profession" },
          ].map((inp, ind) => {
            return (
              <div key={ind}>
                {ind == 3 ? (
                  <div className="text-input">
                    <div className="title" style={{ marginTop: 15 }}>
                      {"Sesso biologico"}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        className="input input-control"
                        style={{
                          marginLeft: 5,
                          border: "1px solid lightgray",
                        }}
                      >
                        <input
                          type={inp.type || "text"}
                          name={inp?.value}
                          value={"UOMO"}
                          id={"i"}
                          placeholder={"UOMO"}
                          onClick={() =>
                            setAtheleteData({
                              ...atheleteData,
                              biological_sex: "male",
                            })
                          }
                          style={{
                            color:
                              atheleteData?.biological_sex == "male" && "green",
                            cursor: "pointer",
                            caretColor: "transparent",
                          }}
                        />
                      </div>
                      <div
                        className="input input-control"
                        style={{
                          marginLeft: 5,
                          border: "1px solid lightgray",
                        }}
                      >
                        <input
                          type={inp.type || "text"}
                          name={"i"}
                          id={"i"}
                          value={"Danna"}
                          placeholder={"Danna"}
                          onClick={() =>
                            setAtheleteData({
                              ...atheleteData,
                              biological_sex: "female",
                            })
                          }
                          style={{
                            color:
                              atheleteData?.biological_sex == "female" &&
                              "green",
                            cursor: "pointer",
                            caretColor: "transparent",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="text-input">
                    <div className="title" style={{ marginTop: 15 }}>
                      {inp?.name}
                    </div>
                    <div className="input input-control">
                      <input
                        type={inp.type || "text"}
                        name={inp?.value}
                        id={inp?.value}
                        onChange={handleInputChange}
                        placeholder={`Entra ${inp?.name} ...`}
                      />
                    </div>
                    {ind == 4 && (
                      <p className="help-text" style={{ fontSize: "0.8em" }}>
                        In base al lavoro che svogli capiremo quanta energia
                        spendi durante la tua giornata.{" "}
                      </p>
                    )}
                  </div>
                )}
              </div>
            );
          })}
          {isLoading ? (
            <div style={containerStyle}>
              <div style={spinnerStyle}></div>
              <style>
                {`
              @keyframes spin {
                0% { transform: rotate(0deg); }
                100% { transform: rotate(360deg); }
              }
            `}
              </style>
            </div>
          ) : (
            <button
              type="button"
              className="button-default-no-icon"
              onClick={() => {
                handleBackClick();
              }}
              style={{ width: "100%", marginTop: 20 }}
            >
              continua
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default SecondStep;
