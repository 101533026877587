import React from "react";
import { Bars } from "react-loader-spinner";
import "../../css/index.css";

const Loader = () => {
  return (
    <div className="loader-overlay">
      <div className="loader-container">
        <Bars
          height="80"
          width="80"
          color="#4fa94d"
          ariaLabel="bars-loading"
          visible={true}
        />
        <p>Loading...</p>
      </div>
    </div>
  );
};

export default Loader;
