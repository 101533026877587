import React from "react";

import AdminCoach from "../../pages/Admin/AdminCoach";
import AdminAthleti from "../../pages/Admin/AdminAthleti";
import AdminCalender from "../../pages/Admin/AdminCalender";
import AdminNotes from "../../pages/Admin/AdminNotes";
import AdminProfile from "../../pages/Admin/AdminProfile";
import AdminActivity from "../../pages/Admin/AdminActivity";
import AdminNutrizione from "../../pages/Admin/AdminNutrizione";
import AdminAllenamento from "../../pages/Admin/AdminAllenamento";
import AdminMisurazione from "../../pages/Admin/AdminMisurazione";
import AdminCoachScheda from "../../pages/Admin/AdminCoachScheda";

const AdminContent = ({ adminContent }) => {
  const renderContent = () => {
    switch (adminContent) {
      case "adminHome":
        return <AdminCoach />;
      case "adminCoach":
        return <AdminCoach />;
      case "adminCalender":
        return <AdminCalender />;
      case "adminNote":
        return <AdminNotes />;
      case "adminProfile":
        return <AdminProfile />;
      case "adminAtleti":
        return <AdminAthleti />;

      case "atheleteActivity":
        return <AdminActivity />;

      case "atheleteAllenamento":
        return <AdminAllenamento />;
      case "atheleteNutrizione":
        return <AdminNutrizione />;
      case "atheleteMisurazioni":
        return <AdminMisurazione />;
      case "adminSchedaCoach":
        return <AdminCoachScheda />;

      default:
        return <AdminCoach />;
    }
  };

  return <div className="content">{renderContent()}</div>;
};

export default AdminContent;
