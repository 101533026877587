import React from "react";

const SmallInput = ({
  type,
  val,
  onChange,
  name,
  inputType = "text",
  width,
  disabled = false,
}) => {
  return (
    <div>
      {type === "xs" ? (
        <input
          value={val}
          onChange={onChange}
          name={name}
          className="xs-input"
          type={inputType}
          style={{ width: width && width }}
          disabled={disabled}
        />
      ) : (
        <input
          value={val}
          onChange={onChange}
          name={name}
          className="small-input"
          style={{ width: width && width }}
          disabled={disabled}
          type={inputType}
        />
      )}
    </div>
  );
};

export default SmallInput;
