import React, { useState } from "react";
import "../../css/index.css";
import atletiIcon from "../../assets/images/home.png";
import calendarioIcon from "../../assets/img/icons/calendar.svg";
import noteIcon from "../../assets/img/icons/clipboard.svg";
import profiloIcon from "../../assets/images/profile.png";
import { NavLink, useNavigate } from "react-router-dom";
// import Loader from "./Users/globalLoader";
import { CoachIcon, AtletiIcon } from "../Reuseable/Icons";

export default function AdminSideBar({ activeOption }) {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleNavigation = (option) => {
    setLoading(true);
    setTimeout(() => {
      switch (option) {
        case "Coach":
          navigate("/admin/coach");
          break;
        case "Atleti":
          navigate("/admin/atleti");
          break;
        case "Calendario":
          navigate("/admin/calender");
          break;
        case "Note":
          navigate("/admin/note");
          break;
        case "Profilo":
          navigate("/admin/profile");
          break;
        case "/admin/atleti/attivita":
          navigate("Atleti");
          break;
        case "/admin/atleti/nutrizione":
          navigate("Atleti");
          break;
        case "/admin/atleti/misurazioni":
          navigate("Atleti");
          break;
        case "/admin/atleti/allenamento":
          navigate("Atleti");
          break;
        default:
          navigate("/admin/coach");
      }

      setLoading(false);
    }, 500);
  };

  return (
    <div className="sidebar">
      {/* {loading && <Loader />} */}

      <div
        className={`sidebar-item ${activeOption === "Coach" ? "active" : ""}`}
        onClick={() => handleNavigation("Coach")}
      >
        <CoachIcon />
        <span>Coach</span>
      </div>
      <div
        className={`sidebar-item ${activeOption === "Atleti" ? "active" : ""}`}
        onClick={() => navigate(`/admin/atlet`)}
      >
        <AtletiIcon />
        <span>Atleti</span>
      </div>
      <div
        className={`sidebar-item ${
          activeOption === "Calendario" ? "active" : ""
        }`}
        onClick={() => handleNavigation("Calendario")}
      >
        <img className="sidebarIcons" src={calendarioIcon} alt="Calendario" />
        <span>Calendario</span>
      </div>
      <div
        className={`sidebar-item ${activeOption === "Note" ? "active" : ""}`}
        onClick={() => handleNavigation("Note")}
      >
        <img className="sidebarIcons" src={noteIcon} alt="Note" />
        <span>Note</span>
      </div>
      <div
        className={`sidebar-item ${activeOption === "Profilo" ? "active" : ""}`}
        onClick={() => handleNavigation("Profilo")}
      >
        <img className="sidebarIcons" src={profiloIcon} alt="Profilo" />
        <span>Profilo</span>
      </div>
    </div>
  );
}
