import React from "react";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import editIcon from "../../assets/img/icons/edit.svg";
import AdminCoachModal from "../AdminCoachModal";

const NoteCard = ({
  title,
  description,
  tags,
  date,
  setIsAddModal,
  setSelectedNote,
  adminCoach,
  id,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [originalDescription, setOriginalDescription] = useState(description);
  const [editedDescription, setEditedDescription] = useState(description);

  const [allenamentoModal, setAllenamentoModal] = useState(false);
  const [noteModal, setNoteModal] = useState(false);
  const navigate = useNavigate();
  const token = localStorage.getItem("token");

  const handleEditClick = () => {
    setIsEditing(true);
    setOriginalDescription(editedDescription);
  };

  const handleCancelClick = () => {
    setEditedDescription(originalDescription);
    setIsEditing(false);
  };

  const handleSaveClick = () => {
    setIsEditing(false);
  };

  const handleModalFn = (content) => {
    if (content === "Allenamento") {
      setAllenamentoModal(true);
    } else if (content === "Nome Atleta") {
      setNoteModal(true);
    }
  };

  return (
    <div className="card" style={{ marginTop: "20px" }}>
      <div className="title">
        {title}{" "}
        <img
          src={editIcon}
          alt="edit icon"
          onClick={() => {
            if (adminCoach) {
              setIsAddModal(true);
              setSelectedNote({
                title,
                description,
                date,
                id,
              });
            } else {
              handleEditClick();
            }
          }}
        />
      </div>
      <div className="description">
        {isEditing ? (
          <>
            <input
              className="inputText"
              type="text"
              value={editedDescription}
              onChange={(e) => setEditedDescription(e.target.value)}
            />
            <button onClick={handleSaveClick}>Save</button>
            <button onClick={handleCancelClick}>Cancel</button>
          </>
        ) : (
          <p>{description}</p>
        )}
        {date && <div className="">{date}</div>}
      </div>
      <div className="tags">
        {tags &&
          tags?.map((tag, index) => (
            <div
              key={index}
              className={`tag ${tag.className}`}
              onClick={() => handleModalFn(tag.content)}
            >
              {tag.content}
            </div>
          ))}
      </div>
      {allenamentoModal && (
        <AdminCoachModal
          closedHandler={() => setAllenamentoModal(false)}
          type="admin-calendrio"
        />
      )}
      {noteModal && (
        <AdminCoachModal closedHandler={() => setNoteModal(false)} />
      )}
    </div>
  );
};

export default NoteCard;
