import React from "react";
import { NavLink } from "react-router-dom";

// Img
import HomeIcon from "../../assets/img/icons/home.svg";
import FitnessIcon from "../../assets/img/icons/fitness.svg";
import appleIcon from "../../assets/img/icons/apple.svg";
import users from "../../assets/img/icons/user.svg";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        {/* item-1 */}
        <div className="footer-navbar">
          <div className="footer-links">
            <NavLink to="/user/home" className="active">
              <img className="home" src={HomeIcon} alt="Home" />
            </NavLink>
            Home
          </div>
          {/* item-2 */}

          <div className=" footer-links">
            <NavLink to="/user/pre-allenamento-giorno-on" className="active">
              <img className="home" src={FitnessIcon} alt="Home" />
            </NavLink>
            Allenamento
          </div>

          {/* item-3 */}

          <div className=" footer-links">
            <NavLink to="/user/nutrizione" className="active">
              <img className="home" src={appleIcon} alt="Home" />
            </NavLink>
            Nutrizione
          </div>

          {/* item-4 */}

          <div className=" footer-links">
            <NavLink to="/user/profilo" className="active">
              <img className="home" src={users} alt="Home" />
            </NavLink>
            profilo
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
