import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Pages
import Login from "./pages/Login";
import Register from "./pages/Register";
import Home from "./pages/Home";
import Users from "./pages/User/Users";
import Dashboard from "./components/Users/Dashboard";
import CoachDashboard from "./pages/coachDashboard";
import AtletiCalendrio from "./components/Coach/AtletiCalendrio";
import NoteContent from "./pages/NoteContent";
import UPTheMeasrment from "./pages/User/UPTheMeasrment";
import FirstScreen from "./pages/Onboarding/FirstScreen";
import SecondStep from "./pages/Onboarding/SecondStep";
import ThirdStep from "./pages/Onboarding/ThirdStep";
import FourStep from "./pages/Onboarding/FourStep";
import FivethStep from "./pages/Onboarding/FivethStep";
import SixthStep from "./pages/Onboarding/SixthStep";
import SevenStep from "./pages/Onboarding/SevenStep";
import NutritionNewStep2 from "./components/Users/NutritionNewStep2";
import ForgotPass from "./components/Auth/ForgotPass";
import OtpScreen from "./components/Auth/OtpScreen";
import AdminAthleletStepOne from "./components/Admin/AdminAtheletStepOne";
import AdminDashboard from "./components/Users/AdminDashboard";
import AdminAtheletStepTwo from "./components/Admin/AdminAtheletStepTwo";
import AdminAtheletStepThree from "./components/Admin/AdminAtheletStepThree";
import UsersDashboard from "./components/Users/UsersDashboard";
import NutritionStepOne from "./components/Users/NutritionStepOne";
import NutritionNewStep3 from "./components/Users/NutritionnewStep3";
import NutritionNewStep4 from "./components/Users/NutritionNewStep4";
import NutritionNewStep5 from "./components/Users/NutritionNewStep5";
import Privacy from "./components/Privacy";
import TrainingFirstStep from "./components/Users/TrainingStepOne";
import TrainingSecondStep from "./components/Users/TrainingStepTwo";
import TrainingThirdStep from "./components/Users/TrainingStepThree";
import TrainingFourthStep from "./components/Users/TrainingStepFour";
import Persontage from "./components/Users/Persontage";
import AdminRoutes from "./AdminRoutes";
import CoachRoute from "./CoachRoute";
import {
  OnboardingStepOneGuard,
  ProtectedOnboardingRoute,
  ProtectedRoute,
} from "./ProtectedOnboardingRoute";
import NotFound from "./pages/NotFound";

const App = () => {
  // athlete
  // coach
  // admin

  return (
    <Provider store={store}>
      <div>
        <ToastContainer
          position="top-center"
          autoClose={2000}
          // autoClose={1000}
          // pauseOnHover
          // theme="light"
          // transition="Bounce"
        />
        <Router>
          <Routes>
            <Route path="*" element={<NotFound />} />
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/registrati" element={<Register />} />
            <Route path="/forgot-password" element={<ForgotPass />} />
            <Route path="/resetPassword" element={<OtpScreen />} />
            {/* Admin ROutesss */}
            <Route element={<AdminRoutes />}>
              <Route
                path="/admin/home"
                element={<Dashboard adminContent="adminHome" />}
              />
              <Route
                path="/admin/coach"
                element={<Dashboard adminContent="adminCoach" />}
              />
              <Route
                path="/admin/adminScheda"
                element={<Dashboard adminContent="adminSchedaCoach" />}
              />
              <Route
                path="/admin/calender"
                element={<Dashboard adminContent="adminCalender" />}
              />
              <Route
                path="/admin/note"
                element={<Dashboard adminContent="adminNote" />}
              />
              <Route
                path="/admin/profile"
                element={<Dashboard adminContent="adminProfile" />}
              />
              <Route
                path="/admin/atleti"
                element={<Dashboard adminContent="adminAtleti" />}
              />
              <Route
                path="/admin/atleti/attivita/:athelete_id"
                element={<Dashboard adminContent="atheleteActivity" />}
              />
              <Route
                path="/admin/atleti/allenamento"
                element={<Dashboard adminContent="atheleteAllenamento" />}
              />
              <Route
                path="/admin/atleti/nutrizione"
                element={<Dashboard adminContent="atheleteNutrizione" />}
              />
              <Route
                path="/admin/atleti/misurazioni/:atheleteId"
                element={<Dashboard adminContent="atheleteMisurazioni" />}
              />
              <Route path="/admin" element={<AdminDashboard />}>
                <Route path="atlet" element={<AdminAthleletStepOne />} />
                <Route
                  path="atlet/step-two"
                  element={<AdminAtheletStepTwo />}
                />
                <Route
                  path="atlet/step-three"
                  element={<AdminAtheletStepThree />}
                />
              </Route>
            </Route>

            {/* Coach Routess */}
            <Route element={<CoachRoute />}>
              <Route
                path="/coach/home"
                element={<Dashboard coachContent="coachHome" />}
              />
              <Route
                path="/coach/atleti/attivita"
                element={<Dashboard coachContent="coachActivity" />}
              />
              <Route
                path="/coach/atleti/misurazioni/:atheleteId"
                element={<Dashboard coachContent="coachAtheleteMisurazioni" />}
              />
              <Route
                path="/coach/atleti/nutrizione"
                element={<Dashboard coachContent="coachAtheleteNutrizione" />}
              />
              <Route
                path="/coach/atleti/allenamento"
                element={<Dashboard coachContent="coachAtheleteAllenamento" />}
              />
              <Route
                path="/coach/profilo"
                element={<Dashboard coachContent="coachProfilo" />}
              />
              <Route
                path="/coach/note"
                element={<Dashboard coachContent="coachNote" />}
              />
              <Route
                path="/coach/atleta/0"
                element={<Dashboard coachContent="atletiCalendrio" />}
              />
              <Route
                path="/coach/calendario"
                element={<Dashboard coachContent="calendrio" />}
              />
            </Route>

            {/* User Routess */}
            <Route element={<OnboardingStepOneGuard />}>
              <Route path="/onboardingstep1" element={<FirstScreen />} />
            </Route>
            <Route element={<ProtectedOnboardingRoute requiredStep={2} />}>
              <Route path="/onboardingstep2" element={<SecondStep />} />
            </Route>
            <Route element={<ProtectedOnboardingRoute requiredStep={3} />}>
              <Route path="/onboardingstep3" element={<ThirdStep />} />
            </Route>
            <Route element={<ProtectedOnboardingRoute requiredStep={4} />}>
              <Route path="/onboardingstep4" element={<FourStep />} />
            </Route>
            <Route element={<ProtectedOnboardingRoute requiredStep={5} />}>
              <Route path="/onboardingstep5" element={<FivethStep />} />
            </Route>
            <Route element={<ProtectedOnboardingRoute requiredStep={6} />}>
              <Route path="/onboardingstep6" element={<SixthStep />} />
            </Route>

            <Route element={<ProtectedRoute />}>
              <Route path="/end" element={<SevenStep />} />
              <Route path="/user" element={<Users />} />
              <Route
                path="/user/home"
                element={<Dashboard content="HomeContent" />}
              />
              <Route
                path="/user/pre-allenamento-giorno-on"
                element={<Dashboard content="TrainingContent" />}
              />
              <Route
                path="/user/aggiorn-le-misurazioni"
                element={<Dashboard content="newui" />}
              />
              <Route
                path="/user/profilo"
                element={<Dashboard content="profileContent" />}
              />
              <Route
                path="/user/nutrizione"
                element={<Dashboard content="nutritionContent" />}
              />
              <Route path="/user" element={<UsersDashboard />}>
                <Route
                  path="nutrizione/step-one"
                  element={<NutritionStepOne />}
                />
                <Route
                  path="nutrizione/step-two"
                  element={<NutritionNewStep2 />}
                />
                <Route
                  path="nutrizione/step-three"
                  element={<NutritionNewStep3 />}
                />
                <Route
                  path="nutrizione/step-four"
                  element={<NutritionNewStep4 />}
                />
                <Route
                  path="nutrizione/step-five"
                  element={<NutritionNewStep5 />}
                />
                <Route
                  path="training/step-one"
                  element={<TrainingFirstStep />}
                />
                <Route
                  path="training/step-two/:excerciseId"
                  element={<TrainingSecondStep />}
                />
                <Route
                  path="training/step-three/:excerciseId"
                  element={<TrainingThirdStep />}
                />
                <Route
                  path="training/step-four/:excerciseId"
                  element={<TrainingFourthStep />}
                />
                <Route
                  path="training/step-five/:excerciseId"
                  element={<Persontage />}
                />
              </Route>
            </Route>
          </Routes>
        </Router>
      </div>
    </Provider>
  );
};

export default App;
