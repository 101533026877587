import React from "react";
import "../css/modal.css";

const ConfirmModal = ({ isOpen, onConfirm, onCancel }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <p className="modal-title">
          Are you sure you want to complete this task?
        </p>
        <div
          className="button-group"
          style={{ display: "flex", flexDirection: "row", alignSelf: "center" }}
        >
          <button
            className="modal-btn confirm-btn"
            onClick={onConfirm}
            style={{ marginRight: "10px" }}
          >
            Yes
          </button>
          <button className="modal-btn cancel-btn" onClick={onCancel}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmModal;
