import React, { useState } from "react";
import editIcon from "../../assets/img/icons/edit.svg";
import Sidebar from "../sidebar";
import AppCalendarioAtleta from "./AppCalendarioAtleta";
import "../../css/coachCalendar.css";
import chartImg from "../../assets/images/chart.png";
import Header from "../header";

export default function AtletiCalendrio() {
  const [activeOption, setActiveOption] = useState("Atleti");

  const handleSidebarClick = (option) => {
    setActiveOption(option);
  };
  const dropDownClick = (event) => {
    // console.log(event);
  };

  const months = [
    "Gennaio",
    "Febbraio",
    "Marzo",
    "Aprile",
    "Maggio",
    "Giugno",
    "Luglio",
    "Agosto",
    "Settembre",
    "Ottobre",
    "Novembre",
    "Dicembre",
  ];
  const monthActive = new Date().getMonth();
  const yearActive = new Date().getFullYear();
  const dateToday = new Date().toISOString().split("T")[0];
  return (
    <div>
      <div className="content-columns">
        <div className="content-center">
          <AppCalendarioAtleta
            months={months}
            monthActive={monthActive}
            yearActive={yearActive}
            dateToday={dateToday}
          />
        </div>
        <div className="content-right-sidebar">
          <div className="card">
            <div className="title">iscrizione</div>
            <div className="description">
              <div className="nome">Nome piano</div>
              <div className="ddmm">GG/MM - GG/MM</div>
            </div>
          </div>
          <div className="card">
            <div className="top">
              <div className="title">
                <img src={editIcon} alt="edit" />
                piano alimentarea
              </div>
              <div className="description">
                <div className="nome">Definizione</div>
                <div className="ddmm">25/08 - 31/12</div>
              </div>
            </div>
            <div className="bottom">
              <div className="left">
                <div className="title">giorni on</div>
                <div className="description">1280 kcal</div>
              </div>
              <div className="right">
                <div className="title">giorni off</div>
                <div className="description">1036 kcal</div>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="top">
              <div className="title">
                <img src={editIcon} alt="edit" />
                programma di allenamento
              </div>
              <div className="description">
                <div className="left">Definizione massa muscolare</div>
                <div className="right">5 settimane</div>
              </div>
            </div>
            <div className="bottom column">
              <div className="title">sessioni</div>
              <div className="tags tag-group">
                <div className="tag outline">giorno 1</div>
                <div className="tag outline">giorno 2</div>
                <div className="tag outline">giorno 3</div>
              </div>
            </div>
          </div>
          <div className="total-group">
            <div className="total-box state-good">
              <div className="title">ADERENZA</div>
              <div className="values">
                <div className="score">7/10</div>
                <div className="percent">+2.51%</div>
              </div>
            </div>
            <div className="total-box state-good">
              <div className="title">COSTANZA</div>
              <div className="values">
                <div className="score">7/10</div>
                <div className="percent">+2.51%</div>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="diagram">
              <div className="title">ANDAMENTOsas</div>
              <div className="drop-downs">
                <details className="dropdown-menu" onClick={dropDownClick}>
                  <summary>MASSA GRASSA</summary>
                  <ul>
                    <li>UNO</li>
                    <li>DUE</li>
                    <li>TRE</li>
                  </ul>
                  <input type="hidden" name="someName" id="someID" />
                </details>
                <details className="dropdown-menu" onClick={dropDownClick}>
                  <summary>MENSILE</summary>
                  <ul>
                    <li>UNO</li>
                    <li>DUE</li>
                    <li>TRE</li>
                  </ul>
                  <input type="hidden" name="someName" id="someID" />
                </details>
              </div>
              <div className="chart"></div>
            </div>
          </div>
          <div className="card">
            <div className="tags column">
              <div className="tag">+ Aggiungi nuovo</div>
              <div className="tag">Tag</div>
              <div className="tag">Tag</div>
              <div className="tag">Tag</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
