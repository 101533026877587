import React, { useEffect, useState } from "react";
import AdminCoachStepone from "./AdminCoachStepone";
import AdminCoachStepTwo from "./AdminCoachStepTwo";

function AdminCoachs({ retrunFuncValue }) {
  const [step, setStep] = useState(1);

  const goToNextStep = async () => {
    setStep((step) => step + 1);
  };

  const goToNextStep1 = async () => {
    retrunFuncValue(true);
    setStep((step) => step + 1);
  };

  const goToNextStep4 = async () => {
    retrunFuncValue(false);
    setStep((step) => step + 1);
  };

  const goToNextStep5 = async () => {
    retrunFuncValue(false);
    setStep((step) => step + 1);
  };

  const goToPreviousStep = () => {
    setStep((step) => step - 1);
  };

  const renderStep = () => {
    switch (step) {
      case 1:
        return <AdminCoachStepone onButtonClick={goToNextStep} />;
      case 2:
        return (
          <AdminCoachStepTwo
            onButtonClick={goToNextStep1}
            onClick={goToPreviousStep}
          />
        );

      default:
        return <AdminCoachStepone onButtonClick={goToNextStep} />;
    }
  };

  return <div>{renderStep()}</div>;
}

export default AdminCoachs;
