import React, { useState, useEffect } from "react";
import "./Measurements.css";
//ReuseableIcon
import rightArrowIcon from "../../assets/img/icons/arrow-right-active.svg";
import ReactPlayer from "react-player";
import { useNavigate, useParams } from "react-router-dom";
import { BASE_URL } from "../../assets/constants";
import axios from "axios";
import { notify } from "../util/notify";

function Persontage({}) {
  const [countdown, setCountdown] = useState(3);
  const [showVideo, setShowVideo] = useState(false);
  const [review, setReview] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { excerciseId } = useParams();

  const adminData = JSON.parse(localStorage.getItem("adminData"));
  const token = adminData?.token;

  const handleBackClick = () => {
    navigate(-1);
  };
  const handleSleep = (value) => {
    setReview(value);
  };
  const handleReview = async () => {
    setIsLoading(true);
    try {
      const response = await axios.post(
        `${BASE_URL}athlete/training/${excerciseId}/review`,
        {
          review,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      notify(false, "Formazione completata...!");
      navigate("/user/training/step-one");
    } catch (error) {
      console.log("error", error);
      notify(true, error?.response?.data?.message);
    }
    setIsLoading(false);
  };
  const spinnerStyle = {
    border: "3px solid #f3f3f3",
    borderTop: "3px solid #a0fc6e",
    borderRadius: "50%",
    width: "30px",
    height: "30px",
    animation: "spin 1s linear infinite",
    margin: "0 auto",
  };

  const containerStyle = {
    display: "flex",
    justifyContent: "center",
    marginTop: "10px",
    position: "relative",
    top: "25%",
  };

  return (
    <div>
      <div className="timer" id="scheda-allenamento">
        <div className="title">
          COM'È ANDATO
          <br />
          L'ALLENAMENTO?
        </div>
        <div>
          {[1].map((i, ind) => {
            return (
              <div className="hunger-level-container">
                <div className="title" style={{ width: 350 }}>
                  {" "}
                </div>
                <div className="barP">
                  <div
                    style={{
                      display: "flex",
                    }}
                  >
                    <div
                      className="speedQuality"
                      onClick={() => handleSleep(1)}
                    >
                      <div className="isQ"></div>
                    </div>
                    <div
                      className="speedQuality"
                      onClick={() => handleSleep(2)}
                    >
                      <div className="isQ"></div>
                    </div>
                    <div
                      className="speedQuality"
                      onClick={() => handleSleep(3)}
                    >
                      <div className="isQ"></div>
                    </div>
                    <div
                      className="speedQuality"
                      onClick={() => handleSleep(4)}
                    >
                      <div className="isQ"></div>
                    </div>
                    <div
                      className="speedQuality"
                      onClick={() => handleSleep(5)}
                    >
                      <div className="isQ"></div>
                    </div>
                  </div>
                  <div
                    className="colorBar"
                    style={{
                      width:
                        review == 1
                          ? 100
                          : review == 2
                          ? 160
                          : review == 3
                          ? 230
                          : review == 4
                          ? 290
                          : review == 5
                          ? 330
                          : 100,
                    }}
                  ></div>
                </div>
                <div className="range-labels">
                  <span className="label-left">
                    Molto <br />
                    affaticato
                  </span>
                  <span className="label-right" style={{ marginRight: 20 }}>
                    Per niente <br />
                    affaticato
                  </span>
                </div>
              </div>
            );
          })}
        </div>
        {isLoading ? (
          <div style={containerStyle}>
            <div style={spinnerStyle}></div>
            <style>
              {`
              @keyframes spin {
                0% { transform: rotate(0deg); }
                100% { transform: rotate(360deg); }
              }
            `}
            </style>
          </div>
        ) : (
          <button
            type="button"
            className="button-default-no-icon"
            onClick={() => {
              handleReview();
            }}
            style={{ width: "30%", position: "absolute", bottom: 30 }}
          >
            SALVA E CHIUDI
          </button>
        )}
      </div>
    </div>
  );
}

export default Persontage;
