import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./nutrition.css";
// Reuseable
import WeekCalendar from "../Reuseable/WeekCalender";
import { AiOutlineCheck } from "react-icons/ai";

// Components
import { LeftArrowIcon } from "../Reuseable/Icons";
import { nutritionData } from "../../dumyData";

const NutritionNewStep2 = ({ onButtonClick }) => {
  const [currentIndex, setCuurentIndex] = useState(0);
  const [nData, setNData] = useState({});
  const [nDataIndex, setNDataindex] = useState(0);
  const navigate = useNavigate();
  const handleBackClick = () => {
    navigate(-1);
  };
  const monthNames = [
    "Gennaio",
    "Febbraio",
    "Marzo",
    "Aprile",
    "Maggio",
    "Giugno",
    "Luglio",
    "Agosto",
    "Settembre",
    "Ottobre",
    "Novembre",
    "Dicembre",
  ];

  const [currentDate, setCurrentDate] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(new Date());

  const generateCalendarDates = (date) => {
    const dates = [];
    const month = date.getMonth();
    const year = date.getFullYear();
    const firstDay = new Date(year, month, 1).getDay();
    const lastDate = new Date(year, month + 1, 0).getDate();

    // Fill in the dates of the previous month
    const prevMonthLastDate = new Date(year, month, 0).getDate();
    for (let i = firstDay === 0 ? 6 : firstDay - 1; i > 0; i--) {
      dates.push({
        day: prevMonthLastDate - i + 1,
        currentMonth: false,
      });
    }

    // Fill in the current month's dates
    for (let i = 1; i <= lastDate; i++) {
      dates.push({
        day: i,
        currentMonth: true,
        isToday:
          i === date.getDate() &&
          month === new Date().getMonth() &&
          year === new Date().getFullYear(),
        isSelected:
          i === selectedDate.getDate() &&
          month === selectedDate.getMonth() &&
          year === selectedDate.getFullYear(),
      });
    }

    return dates;
  };

  const prevMonth = () => {
    setCurrentDate(new Date(currentDate.setMonth(currentDate.getMonth() - 1)));
  };

  const nextMonth = () => {
    setCurrentDate(new Date(currentDate.setMonth(currentDate.getMonth() + 1)));
  };

  const handleDateClick = (day) => {
    setSelectedDate(
      new Date(currentDate.getFullYear(), currentDate.getMonth(), day)
    );
  };

  const calendarDates = generateCalendarDates(currentDate);

  // useEffect(() => {
  //   alert(2);
  // }, []);

  return (
    <div className="container">
      <div className="row">
        <div className=" nutrition-flex">
          <div className="top-bar">
            <button onClick={handleBackClick} className="icon">
              <LeftArrowIcon />
            </button>
            <div className="title">nutrizione</div>
          </div>
        </div>
        <div className="col-md-8">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
            }}
          >
            {["Colazione", "Pranzo", "Cena", "Spuntino"].map((item, index) => {
              return (
                <div>
                  <div
                    className="description"
                    style={{
                      cursor: "pointer",
                      borderBottom:
                        currentIndex === index ? "1px solid #7fff00" : null,
                      width: "10em",
                      alignItems: "center",
                      textAlign: "center",
                      padding: 10,
                    }}
                    onClick={() => {
                      setCuurentIndex(index);
                    }}
                  >
                    {item}
                  </div>
                </div>
              );
            })}
          </div>

          <div>
            {currentIndex === 0 ? (
              <div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-evenly",
                    paddingLeft: "10em",
                    paddingRight: "10em",
                    marginTop: 50,
                    marginBottom: "5em",
                    cursor: "pointer",
                  }}
                >
                  {[
                    { title: "1280 kcal", tag: "Calorie", person: 20 },
                    { title: "130 kcal", tag: "Carboidrati", person: 20 },
                    { title: "280 kcal", tag: "Proteine", person: 20 },
                    { title: "60 kcal", tag: "Grassi", person: 20 },
                  ].map((i) => {
                    return (
                      <div>
                        <div
                          className="title"
                          style={{ fontSize: 20, color: "#7fff00" }}
                        >
                          {i?.title}
                        </div>
                        <div
                          className="title"
                          style={{
                            fontSize: 13,
                            color: "#7fff00",
                            marginLeft: 10,
                          }}
                        >
                          {i?.tag}
                        </div>
                        <div
                          style={{
                            backgroundColor: "#666",
                            // width: 70,
                            height: 5,
                            borderRadius: 100,
                          }}
                        >
                          <div
                            style={{
                              backgroundColor: "#7fff00",
                              width: 30,
                              height: 5,
                              borderRadius: 100,
                            }}
                          ></div>
                        </div>
                      </div>
                    );
                  })}
                </div>

                {nutritionData?.map((meal, index) => (
                  <div
                    className="meal-item"
                    key={index}
                    onClick={() => {
                      setNData(meal);
                      setNDataindex(index);
                      onButtonClick();
                    }}
                  >
                    <h2 className="typeCls">{meal?.type}</h2>
                    {meal.consumed && <p className="cins">{meal.consumed}</p>}
                    {meal?.details && (
                      <div className="meal-details">
                        {meal?.details.map((detail, detailIndex) => (
                          <p key={detailIndex}>{detail}</p>
                        ))}
                      </div>
                    )}
                    <p className="meal-calories">{meal.calories}</p>
                    <span
                      className={`checkmark ${
                        meal.checked ? "" : "incomplete"
                      }`}
                    >
                      {meal.checked && <AiOutlineCheck />}
                    </span>
                  </div>
                ))}
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: 50,
                  marginBottom: "20em",
                }}
              ></div>
            )}
          </div>
        </div>

        {/* ............................Calender..................... */}
        <div className="col-md-4">
          <div className="calendar-container">
            <div className="calendar-header">
              <button className="nav-arrow" onClick={prevMonth}>
                &#8592;
              </button>
              <span className="month-name">
                {monthNames[currentDate.getMonth()]} {currentDate.getFullYear()}
              </span>
              <button className="nav-arrow" onClick={nextMonth}>
                &#8594;
              </button>
            </div>
            <div className="calendar-weekdays">
              <span>L</span>
              <span>M</span>
              <span>M</span>
              <span>G</span>
              <span>V</span>
              <span>S</span>
              <span>D</span>
            </div>
            <div className="calendar-dates">
              {calendarDates.map((date, index) => (
                <span
                  key={index}
                  className={`${!date.currentMonth ? "prev-month" : ""} ${
                    date.isToday ? "current-day" : ""
                  } ${date.isSelected ? "selected" : ""}`}
                  onClick={() => date.currentMonth && handleDateClick(date.day)}
                >
                  {date.day}
                </span>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* .................................END............................. */}
    </div>
  );
};

export default NutritionNewStep2;
