// src/Accordion.js
import React, { useState } from "react";
import "./Accordian.css";
import Icon from "../../assets/img/icons/plus-black.svg";

const Accordion = ({ data, click = false, icon }) => {
  const [openIndex, setOpenIndex] = useState(null);

  const handleToggle = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div className="accordion">
      {data?.map((item, index) => (
        <div key={index} className="accordion-item">
          <button
            className="accordion-header"
            onClick={() => click && handleToggle(index)}
          >
            {item.title}
            {icon && (
              <div>
                <img
                  src={Icon}
                  style={{ width: "25px", height: "25px" }}
                  alt="plus icon"
                />
              </div>
            )}
          </button>
          {openIndex === index && (
            <div className="accordion-body">{item.content}</div>
          )}
        </div>
      ))}
    </div>
  );
};

export default Accordion;
