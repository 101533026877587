import React, { useEffect, useState } from "react";
import AdminAthleletStepOne from "./AdminAtheletStepOne";
import AdminAtheletStepTwo from "./AdminAtheletStepTwo";
import AdminAtheletStepThree from "./AdminAtheletStepThree";
import AdminAtheletStepFour from "./AdminAtheletStepFour";
import AdminAtheletStepFive from "./AdminAtheletStepFive";
import AdminAtheletaStepSix from "./AdminAtheletaStepSix";
import AdminAtheletStepSeven from "./AdminAtheletStepSeven";
import { useNavigate } from "react-router-dom";

function AdminAthelet({ retrunFuncValue }) {
  const [step, setStep] = useState(1);
  const [selectedData, setSelectedData] = useState({});
  const [selectedAthlete, setSelectedAthlete] = useState({});
  const [selectedDate, setSelectedDate] = useState({});
  const [selectedItems, setSelectedItems] = useState({});
  const navigate = useNavigate();

  const goToNextStep = async () => {
    setStep((step) => step + 1);
  };

  const goToNextStepOne = (
    selectedAthlete = null,
    selectedDate = null,
    type
  ) => {
    if (selectedAthlete) {
      setSelectedAthlete(selectedAthlete);
    }
    if (selectedDate) {
      setSelectedDate(selectedDate);
    }
    setStep((step) => step + 1);
    if (type === "selectedAthlete") {
      setSelectedItems({});
    }
  };

  const goToNextStepTwo = (selectedItems = null) => {
    if (selectedItems) {
      setSelectedData(selectedItems);
    }
    setStep((step) => step + 1);
  };

  const goToNextStep1 = async () => {
    retrunFuncValue(true);
    setStep((step) => step + 1);
  };

  const goToNextStep6 = async () => {
    setStep((step) => step + 2);
  };

  const goToNextStep7 = async () => {
    setStep((step) => step + 3);
  };

  const goToPreviousStep = (type, selectedItems) => {
    if (type == "selectedItems") {
      setStep((step) => step - 1);
      setSelectedItems(selectedItems);
    } else {
      setStep((step) => step - 1);
    }
  };

  const goToPreviousStep2 = () => {
    setStep((step) => step - 2);
  };

  const formatDate = (date) => {
    if (!date) return "";

    const options = { day: "2-digit", month: "2-digit", year: "numeric" };
    const formattedDate = new Intl.DateTimeFormat("en-GB", options).format(
      date
    );

    return formattedDate.replace(/\//g, "-");
  };

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <AdminAthleletStepOne
            onButtonClick={(selectedAthlete, selectedDate, type) =>
              goToNextStepOne(selectedAthlete, selectedDate, type)
            }
          />
        );
      case 2:
        return (
          <AdminAtheletStepTwo
            onButtonClick={(selectedItems) => goToNextStepTwo(selectedItems)}
            onClick={goToPreviousStep}
            selectedAthlete={selectedAthlete}
            selectedDate={formatDate(selectedDate)}
            selectedItem={selectedItems}
          />
        );

      case 3:
        return (
          <AdminAtheletStepThree
            onButtonClick={goToNextStep}
            onClick={goToPreviousStep}
            onPrevStepOne={goToPreviousStep2}
            selectedItems={selectedData}
            selectedAthlete={selectedAthlete}
            selectedDate={formatDate(selectedDate)}
          />
        );

      case 4:
        return (
          <AdminAtheletStepFour
            onButtonClick={goToNextStep}
            onButtonClickSeven={goToNextStep7}
            onClick={goToPreviousStep}
            onButtonClickSix={goToNextStep6}
          />
        );

      case 5:
        return (
          <AdminAtheletStepFive
            onButtonClick={goToNextStep}
            onClick={goToPreviousStep}
          />
        );
      case 6:
        return (
          <AdminAtheletaStepSix
            onButtonClick={goToNextStep6}
            onClick={goToPreviousStep}
          />
        );
      case 7:
        return <AdminAtheletStepSeven onClick={goToPreviousStep} />;

      default:
        return <AdminAthleletStepOne onButtonClick={goToNextStep} />;
    }
  };

  return <div>{renderStep()}</div>;
}

export default AdminAthelet;
