import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./nutrition.css";
// // Reuseable
// import WeekCalendar from "../Reuseable/WeekCalender";
// import { AiOutlineCheck } from "react-icons/ai";
// import SearchIcons from "../../assets/img/icons/search-active.svg";

// // Icons
// import PlusIcon from "../../assets/img/icons/plus-active.svg";
// import CheckIcon from "../../assets/img/icons/check-active.svg";
// import CloseIcon from "../../assets/img/icons/close-active.svg";
// import PastaIcon from "../../assets/img/farfalle@2x.png";
// Components
import { LeftArrowIcon } from "../Reuseable/Icons";
// import { nutritionData } from "../../dumyData";
// import Recipes from "./Recipes";
import CalorieEditModal from "../CaloriesEditModal";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import AdminAtheleteCards from "../Admin/AdminAtheleteCards";
import DropContainer from "../Admin/DropContainer";
import { notify } from "../util/notify";
import axios from "axios";
import { BASE_URL } from "../../assets/constants";
import DietValidationModal from "../DietValidationModal";

const monthNames = [
  "Gennaio",
  "Febbraio",
  "Marzo",
  "Aprile",
  "Maggio",
  "Giugno",
  "Luglio",
  "Agosto",
  "Settembre",
  "Ottobre",
  "Novembre",
  "Dicembre",
];

const tabs = [
  { title: "Carboidrati", key: "Carbohydrate" },
  { title: "Proteine", key: "Protein" },
  { title: "Grassi", key: "Fat" },
  { title: "Fibre", key: "Fiber" },
];

const NutritionNewStep5 = ({
  onButtonClick,
  yourRecipe,
  nextStep,
  suggested,
}) => {
  const { state: dietData } = useLocation();
  const adminData = JSON.parse(localStorage.getItem("adminData"));
  const token = adminData?.token;
  const dietDate = dietData?.selectedDate;
  const [day, month, year] = dietDate?.split("-").map(Number);
  const date = new Date(year, month - 1, day, 18, 38, 7); // Manually set time to 18:38:07
  const formattedDate = date.toString();
  // const [nData, setNData] = useState({});
  // const [nDataIndex, setNDataindex] = useState(0);
  // const [iconCheck, setIconCheck] = useState(true);
  const [searchInput, setSearchInput] = useState("");
  const [currentIndex, setCuurentIndex] = useState(0);
  const [showAll, setShowAll] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editableItem, setEditableItem] = useState(null);
  const [itemsArray, setItemsArray] = useState([]);
  const [itemQuantities, setItemQuantities] = useState({});
  const [selectedItems, setSelectedItems] = useState(dietData?.tabSelection);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [openModal, setOpenModal] = useState(false);
  const [mealName, setMealName] = useState("");
  const [previousItems, SetPreviousItems] = useState(dietData?.tabSelection);
  const [droppedItems, setDroppedItems] = useState({
    [dietData?.dietTypes]: [],
  });

  const initialItemCount = 5;

  const navigate = useNavigate();
  const handleBackClick = () => {
    navigate("/user/nutrizione/step-four", {
      state: {
        selectedItem: previousItems,
        selectedDate: dietDate,
        dietType: dietData?.dietTypes,
      },
    });
  };

  const handleEditClick = (item) => {
    setEditableItem(item);
    setIsModalOpen(true);
  };

  const handleRemoveItem = (id) => {
    setSelectedItems((prevItems) => ({
      ...prevItems,
      Breakfast: prevItems.Breakfast.filter((item) => item.id !== id),
      Snacks: prevItems.Snacks.filter((item) => item.id !== id),
      Lunch: prevItems.Lunch.filter((item) => item.id !== id),
      Dinner: prevItems.Dinner.filter((item) => item.id !== id),
    }));
  };

  const getItemQuantity = (itemId, label) => {
    return itemQuantities[itemId] || 1;
  };

  const handleIncrement = (itemId) => {
    setItemQuantities((prev) => ({
      ...prev,
      [itemId]: (prev[itemId] || 1) + 1,
    }));
  };
  const handleDecrement = (itemId) => {
    setItemQuantities((prev) => ({
      ...prev,
      [itemId]: prev[itemId] > 1 ? prev[itemId] - 1 : 1,
    }));
  };
  const handleSaveQuantity = (quantity, type, editableItem) => {
    setIsModalOpen(false);
    setItemsArray((prevItemsArray) => {
      const existingItemIndex = prevItemsArray.findIndex(
        (item) => item.id === editableItem.id
      );

      if (existingItemIndex !== -1) {
        const updatedArray = [...prevItemsArray]; // Create a new array for immutability
        updatedArray[existingItemIndex] = { ...editableItem, quantity, type };
        return updatedArray;
      } else {
        return [...prevItemsArray, { ...editableItem, quantity, type }];
      }
    });
  };

  const handleShowAllClick = () => {
    setShowAll(true);
  };

  const filteredItems = suggested?.filter((item) =>
    item?.Descrizione?.toLowerCase().includes(searchInput.toLowerCase())
  );

  const itemsToDisplay = showAll
    ? filteredItems
    : filteredItems?.slice(0, initialItemCount);

  const generateCalendarDates = (date) => {
    const dates = [];
    const month = date.getMonth();
    const year = date.getFullYear();
    const firstDay = new Date(year, month, 1).getDay();
    const lastDate = new Date(year, month + 1, 0).getDate();

    // Fill in the dates of the previous month
    const prevMonthLastDate = new Date(year, month, 0).getDate();
    for (let i = firstDay === 0 ? 6 : firstDay - 1; i > 0; i--) {
      dates.push({
        day: prevMonthLastDate - i + 1,
        currentMonth: false,
      });
    }

    // Fill in the current month's dates
    for (let i = 1; i <= lastDate; i++) {
      dates.push({
        day: i,
        currentMonth: true,
        isToday:
          i === date.getDate() &&
          month === new Date().getMonth() &&
          year === new Date().getFullYear(),
        isSelected:
          i === selectedDate.getDate() &&
          month === selectedDate.getMonth() &&
          year === selectedDate.getFullYear(),
      });
    }

    return dates;
  };

  const calendarDates = generateCalendarDates(currentDate);

  const prevMonth = () => {
    setCurrentDate(new Date(currentDate.setMonth(currentDate.getMonth() - 1)));
  };

  const nextMonth = () => {
    setCurrentDate(new Date(currentDate.setMonth(currentDate.getMonth() + 1)));
  };

  const handleDateClick = (day) => {
    // setSelectedDate(
    //   new Date(currentDate.getFullYear(), currentDate.getMonth(), day)
    // );
  };

  const formatDate = (date) => {
    if (!date) return "";

    const options = { day: "2-digit", month: "2-digit", year: "numeric" };
    const formattedDate = new Intl.DateTimeFormat("en-GB", options).format(
      date
    );

    return formattedDate.replace(/\//g, "-");
  };

  const handleSave = async () => {
    const date = dietData?.selectedDate;

    const select_nutration = Object.entries(droppedItems)?.flatMap(
      ([mealType, items]) =>
        items?.map((item) => {
          const quantity = itemQuantities[item?.id] || 1;
          return {
            diet_id: item?.id,
            qty: item?.qty,
          };
        })
    );

    if (select_nutration.length === 0) {
      notify(true, "No items selected for nutrition.");
      return;
    }

    const body = {
      date,
      type: dietData?.dietTypes?.toLowerCase(),
      select_nutration,
      name: mealName,
    };

    try {
      const response = await axios.post(
        `${BASE_URL}athlete/nutrition-meal/store`,
        body,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      notify(false, response?.data?.message);
      navigate("/user/nutrizione/step-three");
      // fetchMealData();
    } catch (error) {
      if (error?.response?.data?.error) {
        const errors = error?.response?.data?.error || [];
        const uniqueErrorMessages = new Set();

        errors?.forEach((err) => {
          const key = Object.keys(err)[0];
          uniqueErrorMessages.add(err[key]);
        });

        const combinedErrors = Array?.from(uniqueErrorMessages).join(", ");
        notify(true, combinedErrors);
      } else if (error?.response?.data?.message) {
        notify(true, error?.response?.data?.message);
      }

      console.error("Error saving meal composition:", error);
    }
  };

  const onSave = async () => {
    setOpenModal(false);
  };

  useEffect(() => {
    setSelectedDate(
      new Date(currentDate.getFullYear(), currentDate.getMonth(), day)
    );
  }, [dietDate]);

  return (
    <div className="container">
      <DietValidationModal
        isOpen={openModal}
        onCancel={() => setOpenModal(false)}
        onConfirm={onSave}
        message="Componi il tuo piatto"
        droppedItems={droppedItems}
        setDroppedItems={setDroppedItems}
        setMealName={setMealName}
        mealName={mealName}
        url={`athlete/plan-meal`}
        setOpenModal={setOpenModal}
        token={token}
      />
      <div className="row">
        <div className=" nutrition-flex">
          <div className="top-bar">
            <button onClick={handleBackClick} className="icon">
              <LeftArrowIcon />
            </button>
            <div className="title">pianifica i tuoi pasti</div>
          </div>
        </div>
        <div className="col-md-8 dragAndDropBox">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
            }}
          >
            {tabs.map((item, index) => {
              return (
                <div key={index}>
                  <div
                    className="description"
                    style={{
                      cursor: "pointer",
                      borderBottom:
                        currentIndex === index ? "1px solid #7fff00" : null,
                      width: "10em",
                      alignItems: "center",
                      textAlign: "center",
                      padding: 10,
                    }}
                    onClick={() => {
                      setCuurentIndex(index);
                    }}
                  >
                    {item?.title}
                  </div>
                </div>
              );
            })}
          </div>

          <div>
            <DndProvider backend={HTML5Backend}>
              <div className="mealBox">
                <CalorieEditModal
                  isOpen={isModalOpen}
                  onClose={() => setIsModalOpen(false)}
                  editableItem={editableItem}
                  calories={getItemQuantity(editableItem?.id)}
                  handleIncrement={() => handleIncrement(editableItem?.id)}
                  handleDecrement={() => handleDecrement(editableItem?.id)}
                  handleSaveCalories={() =>
                    handleSaveQuantity(
                      getItemQuantity(editableItem?.id),
                      editableItem?.type,
                      editableItem
                    )
                  }
                />
                {selectedItems?.Breakfast?.filter(
                  (item) =>
                    item?.type?.toLowerCase() ===
                    tabs[currentIndex].key?.toLowerCase()
                ).map((data, i) => (
                  <AdminAtheleteCards
                    key={i}
                    data={data}
                    getItemQuantity={getItemQuantity}
                    handleEditClick={handleEditClick}
                    handleRemoveItem={handleRemoveItem}
                  />
                ))}

                {selectedItems?.Snacks?.filter(
                  (item) =>
                    item.type.toLowerCase() ===
                    tabs[currentIndex].key.toLowerCase()
                ).map((data, i) => (
                  <AdminAtheleteCards
                    key={i}
                    data={data}
                    getItemQuantity={getItemQuantity}
                    handleEditClick={handleEditClick}
                    handleRemoveItem={handleRemoveItem}
                  />
                ))}

                {selectedItems?.Lunch?.filter(
                  (item) =>
                    item.type.toLowerCase() ===
                    tabs[currentIndex].key.toLowerCase()
                ).map((data, i) => (
                  <AdminAtheleteCards
                    key={i}
                    data={data}
                    getItemQuantity={getItemQuantity}
                    handleEditClick={handleEditClick}
                    handleRemoveItem={handleRemoveItem}
                  />
                ))}

                {selectedItems?.Dinner?.filter(
                  (item) =>
                    item.type.toLowerCase() ===
                    tabs[currentIndex].key.toLowerCase()
                ).map((data, i) => (
                  <AdminAtheleteCards
                    key={i}
                    data={data}
                    getItemQuantity={getItemQuantity}
                    handleEditClick={handleEditClick}
                    handleRemoveItem={handleRemoveItem}
                  />
                ))}
              </div>

              <div className="drag-and-drop-plate">
                <DropContainer
                  droppedItems={droppedItems}
                  setDroppedItems={setDroppedItems}
                  getItemQuantity={getItemQuantity}
                  dietType={dietData?.dietTypes}
                  setSelectedItems={setSelectedItems}
                  selectedItems={selectedItems}
                />
              </div>
              {droppedItems[dietData?.dietTypes]?.length > 0 && (
                <button
                  type="button"
                  className="step-three-btn"
                  id="finish-meal-composition"
                  onClick={() => setOpenModal(true)}
                  style={{
                    zIndex: 999,
                    width: "50%",
                    position: "relative",
                    margin: "0 auto",
                    top: -20,
                  }}
                >
                  Componi il tuo piatto
                </button>
              )}
            </DndProvider>
          </div>
          <div>
            <div className="step-three-btn-container1 mt-3">
              <button
                type="button"
                className="step-three-btn"
                id="finish-meal-composition"
                onClick={handleSave}
                style={{ zIndex: 999, width: "100%", position: "inherit" }}
              >
                Salva e chiudia
              </button>
            </div>
          </div>
        </div>

        {/* ............................Calender..................... */}
        <div className="col-md-4">
          <div className="calendar-container">
            <div className="calendar-header">
              <button className="nav-arrow" onClick={prevMonth}>
                &#8592;
              </button>
              <span className="month-name">
                {monthNames[currentDate.getMonth()]} {currentDate.getFullYear()}
              </span>
              <button className="nav-arrow" onClick={nextMonth}>
                &#8594;
              </button>
            </div>
            <div className="calendar-weekdays">
              <span>L</span>
              <span>M</span>
              <span>M</span>
              <span>G</span>
              <span>V</span>
              <span>S</span>
              <span>D</span>
            </div>
            <div className="calendar-dates">
              {calendarDates.map((date, index) => (
                <span
                  key={index}
                  className={`${!date.currentMonth ? "prev-month" : ""} ${
                    date.isToday ? "current-day" : ""
                  } ${date.isSelected ? "selected" : ""}`}
                  // onClick={() => date.currentMonth && handleDateClick(date.day)}
                >
                  {date.day}
                </span>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* .................................END............................. */}
    </div>
  );
};

export default NutritionNewStep5;
