import React, { useEffect, useState } from "react";
import AtletiHeader from "../Reuseable/AtletiHeader";
import Dropdown from "../Reuseable/Dropdown";
import MeasurementTables from "../Admin/MeasurementTables";
import DashboardCard from "../Admin/DashboardCard";
import CoachHeader from "../Reuseable/CoachHeader";
import LineChart from "../Reuseable/LineChart";
import { BASE_URL } from "../../assets/constants";
import { useParams } from "react-router-dom";
import axios from "axios";

const CoachAtheletStepSeven = ({ onClick }) => {
  const [selectedOption, setSelectedOption] = useState("utlimi 3 mesi");
  const [monthlySelectedOption, setMonthlySelectedOption] = useState("mensile");
  const adminData = JSON.parse(localStorage.getItem("adminData"));
  const token = adminData?.data?.token;
  const { atheleteId } = useParams();
  const [atheleteData, setAtheleteData] = useState();

  const options = [
    "utlimi 3 mesi",
    "utlimi 3 mesi",
    "utlimi 3 mesi",
    "utlimi 3 mesi",
  ];

  const handleSelect = (option) => {
    setSelectedOption(option);
  };

  const handleSelectMonthly = (option) => {
    setSelectedOption(option);
  };

  const fetchAthelete = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}coach/${atheleteId}/profile-athlete`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setAtheleteData(response?.data);
    } catch (error) {
      console.error("Error fetching athlete :", error);
    } finally {
    }
  };

  useEffect(() => {
    fetchAthelete();
  }, []);

  return (
    <>
      <CoachHeader />
      <h1 className="Progressi-title" style={{ marginTop: "20px" }}>
        Progressi
      </h1>

      <div style={{ display: "flex", flexDirection: "row", marginTop: "20px" }}>
        <div className="d-flex w-50 mx-auto text-center">
          <div className="coach-total-box state-good">
            <div className="title">ADERENZA</div>
            <div className="values">
              <div className="score">0/10</div>
              {/* <div className="percent">+2.51%</div> */}
            </div>
          </div>
          <div className="coach-total-box state-bad">
            <div className="title">COSTANZA</div>
            <div className="values">
              <div className="score">0/10</div>
              {/* <div className="percent">+2.51%</div> */}
            </div>
          </div>
        </div>
        {/* <div className="diagram">
          <div className="title">ANDAMENTO</div>

          <div className="admin-coach-select">
            <Dropdown
              options={options}
              onSelect={handleSelect}
              btn="Massa grassa"
              bgList="true"
              className="drop-index"
              content={selectedOption}
            />
            <Dropdown
              options={options}
              onSelect={handleSelectMonthly}
              btn="mensile"
              bgList="true"
              className="drop-index"
              content={monthlySelectedOption}
            />
          </div>
          <LineChart width={"500px"} />
        </div> */}
      </div>
      <div>
        <div className="measurment-container">
          <MeasurementTables atheleteData={atheleteData} />
        </div>
      </div>
    </>
  );
};

export default CoachAtheletStepSeven;
