import React from "react";
import { Link } from "react-router-dom";

// Img
import logoImg from "../../assets/img/logo-splash.svg";

// Components

// CSS
import "./Home.css";

function HomePage() {
  return (
    <>
      <div className="main-container centered">
        <div className="content-home">
          <div className="welcome">
            <div>
              <img src={logoImg} alt="" />
            </div>
          </div>
          <div className="login-register">
            <Link to="/registrati" className="button-default-no-icon">
              REGISTRATI
            </Link>
            <Link to="/login" className="button-default-no-icon outline">
              LOGIN
            </Link>
            <div className="disclaimer">
              Creando un nuovo account, accetti i nostri Terms of Service &
              Privacy Policy.
            </div>
            {/*
                    <div className="accessi" style={{ textAlign: "center" }}>
                        <a href="admin/" className="login-link">Accesso Admin</a> - <a href="coach/" className="login-link">Accesso Coach</a>
                    </div>
                    */}
          </div>
        </div>
      </div>
    </>
  );
}

export default HomePage;
