import React, { useEffect, useState } from "react";
import { BASE_URL } from "../assets/constants";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { notify } from "./util/notify";

const CalendarioModal = ({
  isOpen,
  onClose,
  token,
  onSave,
  selectedNote,
  adminCoach,
}) => {
  const [title, setTitle] = useState("");
  const [date, setDate] = useState(null);
  const [desc, setDesc] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const monthAbbreviations = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  useEffect(() => {
    if (selectedNote) {
      setTitle(selectedNote.title);
      setDesc(selectedNote.description);

      const date = new Date(selectedNote.date);

      const day = date.getDate().toString().padStart(2, "0");
      const month = monthAbbreviations[date.getMonth()];
      const year = date.getFullYear();

      setDate(new Date(`${year}-${month}-${day}`));
    }
  }, [selectedNote]);

  const handleSave = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const apiUrl = adminCoach
      ? `${BASE_URL}admin/note/coach/update/${selectedNote?.uuid}`
      : `${BASE_URL}admin/note/athlete/update/${selectedNote?.uuid}`;

    try {
      const formattedDate = date
        ? `${date.getDate().toString().padStart(2, "0")}-${
            monthAbbreviations[date.getMonth()]
          }-${date.getFullYear()}`
        : "";

      const response = await axios.put(
        apiUrl,
        {
          title,
          date: formattedDate,
          description: desc,
          user_id: selectedNote?.id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status !== 200) {
        throw new Error("Failed to update the note");
      }
      setIsLoading(false);

      onSave();
      onClose();
    } catch (error) {
      let errors = error?.response?.data?.errors;
      if (errors) {
        let errorMessage = "";

        Object.keys(errors).forEach((field) => {
          errors[field].forEach((message) => {
            errorMessage += `${message}\n`;
          });
        });

        notify(true, errorMessage);
      } else {
        console.log("Unknown error:", error);
        alert("Something Went Wrong..!.");
      }

      setIsLoading(false);

      console.log("Error updating note:", errors);
    }
    setIsLoading(false);
  };

  const spinnerStyle = {
    border: "3px solid #f3f3f3",
    borderTop: "3px solid #a0fc6e",
    borderRadius: "50%",
    width: "30px",
    height: "30px",
    animation: "spin 1s linear infinite",
  };

  const containerStyle = {
    display: "flex",
    justifyContent: "center",
    marging: "0 auto",
    backgroundColor: "transparent",
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <form onSubmit={handleSave}>
          <div className="form-div">
            <label>titolo</label>
            <input
              type="text"
              placeholder="Enter title here"
              className="form-input"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>
          <div className="form-div picker">
            <label>date</label>
            <DatePicker
              selected={date}
              onChange={(date) => setDate(date)}
              dateFormat="dd-MMM-yyyy"
              placeholderText="dd-mmm-yyyy"
              isClearable
            />
          </div>
          <div className="form-div">
            <label>descrizione</label>
            <input
              type="text"
              placeholder="Enter description"
              className="form-input"
              value={desc}
              onChange={(e) => setDesc(e.target.value)}
            />
          </div>
          <div className="button-group" style={{ marginTop: "10px" }}>
            {isLoading ? (
              <div style={containerStyle}>
                <div style={spinnerStyle}></div>
                <style>
                  {`
              @keyframes spin {
                0% { transform: rotate(0deg); }
                100% { transform: rotate(360deg); }
              }
            `}
                </style>
              </div>
            ) : (
              <button
                type="submit"
                className="modal-btn"
                style={{ width: "100%" }}
              >
                Salva
              </button>
            )}
          </div>
        </form>
        <div onClick={onClose} className="modal-close">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="15"
            height="15"
            viewBox="0 0 16 16"
          >
            <path
              fillRule="evenodd"
              fill="#fff"
              d="M0 14.545L1.455 16 8 9.455 14.545 16 16 14.545 9.455 8 16 1.455 14.545 0 8 6.545 1.455 0 0 1.455 6.545 8z"
            ></path>
          </svg>
        </div>
      </div>
    </div>
  );
};

export default CalendarioModal;
