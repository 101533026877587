// NotFound.js
import React from "react";
import { useNavigate } from "react-router-dom";

const NotFound = () => {
  const navigate = useNavigate();
  return (
    <div style={styles.container}>
      <h1 style={styles.title}>404</h1>
      <p style={styles.message}>Pagina non trovata</p>
      <button onClick={() => navigate(-1)} style={styles.button}>
        Vai indietro
      </button>
    </div>
  );
};

const styles = {
  container: {
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#0b0b0b",
    color: "#fff",
    fontFamily: "Arial, sans-serif",
  },
  title: {
    fontSize: "8rem",
    color: "#a0fc6e", // Green accent color to match theme
    margin: "0",
  },
  message: {
    fontSize: "1.5rem",
    marginTop: "1rem",
    marginBottom: "2rem",
    color: "#fff",
  },
  button: {
    padding: "0.5rem 1.5rem",
    backgroundColor: "#a0fc6e",
    color: "#0b0b0b",
    fontSize: "1rem",
    fontWeight: "bold",
    textDecoration: "none",
    borderRadius: "5px",
    transition: "background 0.3s",
  },
};

export default NotFound;
