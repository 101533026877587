import React from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const LineChart = ({ width }) => {
  const data = {
    labels: ["Gen", "Feb", "Mar", "Apr", "Mag", "Giu", "Lug"],
    datasets: [
      {
        label: "My Dataset",
        data: [60, 70, 85, 75, 90, 95, 100], // Your data points
        fill: true,
        backgroundColor: "rgba(0, 128, 0, 0.2)", // Green gradient-like background
        borderColor: "rgba(75, 192, 192, 1)", // Blue line
        borderWidth: 2,
        tension: 0.4, // Smooth curve
        pointBackgroundColor: "#ffffff", // White point
        pointBorderColor: "rgba(75, 192, 192, 1)", // Blue border around points
        pointRadius: 8, // Larger radius for points
        pointHoverRadius: 8,
      },
    ],
  };

  const options = {
    responsive: true,
    scales: {
      x: {
        grid: {
          display: false, // Remove gridlines on the x-axis
        },
        ticks: {
          color: "#ffffff", // White text color for x-axis labels
        },
      },
      y: {
        min: 0,
        max: 120,
        ticks: {
          stepSize: 20,
          color: "#ffffff", // White text color for y-axis labels
        },
        grid: {
          display: false, // Completely remove gridlines on the y-axis
        },
      },
    },
    plugins: {
      legend: {
        display: false, // Hide the legend
      },
      tooltip: {
        enabled: true,
      },
    },
    elements: {
      line: {
        tension: 0.4, // Curve line to match the smoothness in the image
      },
    },
  };

  return (
    <div className="chart-container" style={{ width: width && "500px" }}>
      {" "}
      {/* Apply CSS class */}
      <Line data={data} options={options} />
    </div>
  );
};

export default LineChart;
