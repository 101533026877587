import React, { useEffect, useState } from "react";
import "../css/modal.css";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "../assets/constants";
import { notify } from "./util/notify";

const DietValidationModal = ({
  isOpen,
  message,
  droppedItems,
  setDroppedItems,
  setMealName,
  mealName,
  onConfirm,
  url,
  token,
  setOpenModal,
}) => {
  const [filterCaloriesData, setFilterCaloriesData] = useState(null);
  const [caloriesData, setCaloriesData] = useState(null);
  const { state } = useLocation();

  let caloriesDataArr = [
    {
      title: `${
        (filterCaloriesData && filterCaloriesData[0]?.calories) || 0
      } kcal`,
      tag: "Calorie",
      person: 20,
    },
    {
      title: `${
        (filterCaloriesData && filterCaloriesData[0]?.carbohydrates) || 0
      } g`,
      tag: "Carboidrati",
      person: 20,
      progressWidth: droppedItems[state?.dietTypes]
        ?.filter((item) => item.type === "carbohydrate")
        .map((item) => item.qty * item?.calories_per_gram)
        .reduce((sum, qty) => sum + qty, 0),
    },
    {
      title: `${
        (filterCaloriesData && filterCaloriesData[0]?.proteins) || 0
      } g`,
      tag: "Proteine",
      person: 20,
      progressWidth: droppedItems[state?.dietTypes]
        ?.filter((item) => item.type === "protein")
        .map((item) => item.qty * item?.calories_per_gram)
        .reduce((sum, qty) => sum + qty, 0),
    },
    {
      title: `${(filterCaloriesData && filterCaloriesData[0]?.fats) || 0} g`,
      tag: "Grassi",
      person: 20,
      progressWidth: droppedItems[state?.dietTypes]
        ?.filter((item) => item.type === "fat")
        .map((item) => item.qty * item?.calories_per_gram)
        .reduce((sum, qty) => sum + qty, 0),
    },
    {
      title: `${(filterCaloriesData && filterCaloriesData[0]?.fiber) || 0} g`,
      tag: "Fibre",
      person: 20,
      progressWidth: droppedItems[state?.dietTypes]
        ?.filter((item) => item.type === "fiber")
        .map((item) => item.qty * item?.calories_per_gram)
        .reduce((sum, qty) => sum + qty, 0),
    },
  ];

  const formatDate = (dateString) => {
    const [day, month, year] = dateString.split("-");
    return `${year}-${month}-${day}`;
  };

  const getCaloires = async () => {
    let date = formatDate(state?.selectedDate);

    try {
      let response = await axios.post(
        `${BASE_URL}${url}`,
        {
          date: date,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setCaloriesData(response?.data?.data);
    } catch (error) {
      console.log({ error });

      notify(
        false,
        error?.response?.data?.message?.date
          ? error?.response?.data?.message?.date[0]
          : error?.response?.data?.message
      );
    }
  };

  const increment = (index) => {
    const updatedItems = { ...droppedItems };
    if (!updatedItems[state?.dietTypes]) {
      updatedItems[state?.dietTypes] = [];
    }

    updatedItems[state?.dietTypes][index].qty += 1;
    setDroppedItems(updatedItems);
  };

  const decrement = (index) => {
    const updatedItems = { ...droppedItems };
    if (!updatedItems[state?.dietTypes]) {
      updatedItems[state?.dietTypes] = [];
    }

    if (updatedItems[state?.dietTypes][index].qty > 1) {
      updatedItems[state?.dietTypes][index].qty -= 1;
      setDroppedItems(updatedItems);
    }
  };

  const onsave = () => {
    let hasRedBar = false;
    caloriesDataArr?.forEach((i) => {
      const totalCalories = i?.title?.replace(/\D/g, "");
      if (i?.progressWidth > totalCalories) {
        hasRedBar = true;
      }
    });

    if (hasRedBar) {
      notify(
        true,
        "Please reduce the quantity, as one or more items exceed the calorie limit."
      );
    } else {
      if (!mealName) {
        notify(true, "Please Enter Meal Name ...!");
      } else {
        onConfirm();
      }
    }
  };

  useEffect(() => {
    if (state?.selectedDate) {
      getCaloires();
    }
  }, [state?.date]);

  useEffect(() => {
    let caories =
      caloriesData &&
      caloriesData?.filter(
        (meal) => meal?.type == state?.dietTypes?.toLowerCase()
      );

    if (caories) setFilterCaloriesData(caories);
  }, [caloriesData]);

  if (!isOpen) return null;
  return (
    <div className="modal-overlay">
      <div className="modal-content-valid">
        <div className="d-flex justify-content-between">
          <p className="modal-title">{message}</p>
          <div
            className=""
            style={{ cursor: "pointer" }}
            onClick={() => setOpenModal(false)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="15"
              height="15"
              viewBox="0 0 16 16"
            >
              <path
                fillRule="evenodd"
                fill="#fff"
                d="M0 14.545L1.455 16 8 9.455 14.545 16 16 14.545 9.455 8 16 1.455 14.545 0 8 6.545 1.455 0 0 1.455 6.545 8z"
              ></path>
            </svg>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-evenly",
            // paddingLeft: "10em",
            // paddingRight: "10em",
            // marginTop: 50,
            // marginBottom: "5em",
            // cursor: "pointer",
          }}
        >
          {caloriesDataArr?.map((i, index, value) => {
            const totalCalories = i?.title?.replace(/\D/g, "");
            return (
              <div key={index}>
                <div
                  className="title"
                  style={{ fontSize: 20, color: "#7fff00" }}
                >
                  {i?.title}
                </div>
                <div
                  className="title"
                  style={{
                    fontSize: 13,
                    color: "#7fff00",
                    marginLeft: 10,
                  }}
                >
                  {i?.tag}
                </div>
                <div
                  style={{
                    backgroundColor: "#666",
                    maxWidth: 100,
                    height: 5,
                    borderRadius: 100,
                  }}
                >
                  <div
                    style={{
                      backgroundColor:
                        i?.progressWidth > totalCalories ? "red" : "#7fff00",
                      width:
                        i?.progressWidth > totalCalories
                          ? "100%"
                          : `${(i?.progressWidth / totalCalories) * 100}%`,
                      height: 5,
                      borderRadius: 100,
                    }}
                  ></div>
                </div>
              </div>
            );
          })}
        </div>

        <div className="mt-3 scroll-container">
          {droppedItems[state?.dietTypes]?.length > 0 &&
            droppedItems[state?.dietTypes].map((value, index) => {
              return (
                <div className="box-for-data" key={index}>
                  <section className="showData">
                    <button
                      className="btnData"
                      onClick={() => decrement(index)}
                    >
                      -
                    </button>
                    <div>
                      {(value?.qty * value?.calories_per_gram)?.toFixed(2)} G
                    </div>
                    <button
                      className="btnData"
                      onClick={() => increment(index)}
                    >
                      +
                    </button>
                  </section>
                  <p className="">{value?.name}</p>
                </div>
              );
            })}
        </div>
        <div className="form-div">
          <label>Name Of The Dish</label>
          <input
            type="text"
            placeholder="Enter title here"
            className="form-input"
            value={mealName}
            onChange={(e) => setMealName(e.target.value)}
          />
        </div>

        <div
          className="button-group"
          style={{
            display: "flex",
            flexDirection: "row",
            alignSelf: "center",
            marginTop: "40px",
          }}
        >
          <button
            className="modal-btn confirm-btn"
            onClick={onsave}
            style={{ marginRight: "10px", width: "100%" }}
          >
            save
          </button>
        </div>
      </div>
    </div>
  );
};

export default DietValidationModal;
