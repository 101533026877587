import React from "react";
import AdminCoachStepTwo from "../../components/Admin/AdminCoachStepTwo";

const AdminCoachScheda = () => {
  return (
    <div>
      <AdminCoachStepTwo />
    </div>
  );
};

export default AdminCoachScheda;
