import React from "react";
import "../css/modal.css";
import DatePicker from "react-datepicker";

const DeleteModal = ({
  isOpen,
  onConfirm,
  onCancel,
  message,
  typeInp,
  setMealText,
  type = "text",
  meal,
  bar,
  isLoading,
}) => {
  if (!isOpen) return null;
  const handleSleep = (value) => {
    setMealText(value);
  };

  const spinnerStyle = {
    border: "3px solid #f3f3f3",
    borderTop: "3px solid #a0fc6e",
    borderRadius: "50%",
    width: "30px",
    height: "30px",
    animation: "spin 1s linear infinite",
  };

  const containerStyle = {
    display: "flex",
    alignContent: "center",
    backgroundColor: "transparent",
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        {bar && (
          <div className="barP">
            <div
              style={{
                display: "flex",
              }}
            >
              <div className="speedQuality" onClick={() => handleSleep(1)}>
                <div className="isQ"></div>
              </div>
              <div className="speedQuality" onClick={() => handleSleep(2)}>
                <div className="isQ"></div>
              </div>
              <div className="speedQuality" onClick={() => handleSleep(3)}>
                <div className="isQ"></div>
              </div>
              <div className="speedQuality" onClick={() => handleSleep(4)}>
                <div className="isQ"></div>
              </div>
              <div className="speedQuality" onClick={() => handleSleep(5)}>
                <div className="isQ"></div>
              </div>
            </div>
            <div
              className="colorBar"
              style={{
                width:
                  meal == 1
                    ? 100
                    : meal == 2
                    ? 160
                    : meal == 3
                    ? 230
                    : meal == 4
                    ? 290
                    : meal == 5
                    ? 320
                    : 100,
              }}
            ></div>
          </div>
        )}
        {typeInp ? (
          <div>
            <div className="form-div">
              {type === "text" ? (
                <>
                  <label>titolo</label>
                  <input
                    type={type}
                    placeholder="Enter title here"
                    className="form-input"
                    // value={title}
                    onChange={(e) => setMealText(e.target.value)}
                  />
                </>
              ) : (
                <>
                  <label>date</label>
                  <DatePicker
                    selected={meal}
                    onChange={(date) => setMealText(date)}
                    dateFormat="dd-MMM-yyyy"
                    placeholderText="dd-mmm-yyyy"
                    minDate={new Date()}
                    isClearable
                  />
                </>
              )}
            </div>
            <div
              className="button-group"
              style={{
                display: "flex",
                flexDirection: "row",
                alignSelf: "center",
                justifyContent: "center",
                marginTop: 5,
              }}
            >
              <button
                className="modal-btn confirm-btn"
                onClick={onConfirm}
                style={{ marginRight: "10px" }}
              >
                OK
              </button>
              {!bar && (
                <button className="modal-btn cancel-btn" onClick={onCancel}>
                  Cancellare
                </button>
              )}
            </div>
          </div>
        ) : (
          <>
            <p className="modal-title">{message}</p>

            <div
              className="button-group"
              style={{
                display: "flex",
                flexDirection: "row",
                alignSelf: "center",
              }}
            >
              {isLoading ? (
                <div style={containerStyle}>
                  <div style={spinnerStyle}></div>
                  <style>
                    {`
              @keyframes spin {
                0% { transform: rotate(0deg); }
                100% { transform: rotate(360deg); }
              }
            `}
                  </style>
                </div>
              ) : (
                <button
                  className="modal-btn confirm-btn"
                  onClick={onConfirm}
                  style={{ marginRight: "10px" }}
                >
                  SÌ
                </button>
              )}
              <button className="modal-btn cancel-btn" onClick={onCancel}>
                Cancellare
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default DeleteModal;
