import React, { useEffect, useState } from "react";
import { BASE_URL } from "../assets/constants";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { notify } from "./util/notify";
import Dropdown from "../components/Reuseable/Dropdown.jsx";

const CreateNoteModal = ({
  isOpen,
  onClose,
  token,
  onSave,
  adminCoach,
  coachAthelete,
  selectedNote,
  adminCoachNote,
  tableArr,
  athleteNote,
  userId,
}) => {
  const [title, setTitle] = useState("");
  const [date, setDate] = useState(null);
  const [desc, setDesc] = useState("");
  const [type, setType] = useState({ name: "Select options", id: 0 });
  const [isLoading, setIsLoading] = useState(false);

  const filteredCoach = tableArr?.map((val) => ({
    name: val?.name,
    id: val?.id,
  }));

  const monthAbbreviations = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const handleSelect2 = (option) => {
    setType(option);
  };

  useEffect(() => {
    if (selectedNote) {
      setTitle(selectedNote.title);
      setDesc(selectedNote.description);

      const date = new Date(selectedNote.date);

      const day = date.getDate().toString().padStart(2, "0");
      const month = monthAbbreviations[date.getMonth()];
      const year = date.getFullYear();

      setDate(new Date(`${year}-${month}-${day}`));
    } else {
      setTitle("");
      setDesc("");
      setDate("");
    }
  }, [selectedNote]);

  const handleSave = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const missingFields = [];

    if (!title) missingFields.push("Title");
    if (!date) missingFields.push("Date");
    if (!desc) {
      missingFields.push("Description");
    } else if (desc.length <= 5) {
      missingFields.push("Description (should be longer than 5 characters)");
    }

    if (missingFields.length > 0) {
      notify(true, `Required Fields Are Missing: ${missingFields.join(", ")}`);
      setIsLoading(false);
      return;
    }

    const formattedDate = date
      ? `${date.getDate().toString().padStart(2, "0")}-${
          monthAbbreviations[date.getMonth()]
        }-${date.getFullYear()}`
      : "";
    const noteData = {
      title: title,
      date: formattedDate,
      description: desc,
    };

    if (selectedNote) {
      try {
        const response = await axios.put(
          adminCoachNote
            ? `${BASE_URL}admincoach/admin-coach/note/${selectedNote?.id}/update`
            : `${BASE_URL}coach/note-update/${selectedNote?.uuid}`,
          noteData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setIsLoading(false);
        onSave();
        notify("", "Updated Successfully..!");
        onClose();
      } catch (error) {
        let errors = error?.response?.data?.errors;
        if (errors) {
          let errorMessage = "";

          Object.keys(errors).forEach((field) => {
            errors[field].forEach((message) => {
              errorMessage += `${message}\n`;
            });
          });

          notify(true, errorMessage);
        } else {
          console.log("Unknown error:", error);
          alert("Something Went Wrong..!.");
        }
        setIsLoading(false);

        console.log("error", error);
      }
      return;
    }

    try {
      const response = await axios.post(
        coachAthelete
          ? `${BASE_URL}coach/create-note`
          : adminCoach
          ? `${BASE_URL}admin/create/coach/note`
          : adminCoachNote
          ? `${BASE_URL}admincoach/admin-coach/note/store`
          : `${BASE_URL}admin/create/athlete/note`,
        {
          ...noteData,
          user_type: coachAthelete && "other",
          user_id: userId
            ? userId
            : (adminCoach || athleteNote || coachAthelete) && type?.id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status !== 200 && response.status !== 201) {
        throw new Error("Failed to update the note");
      }

      onSave();
      notify("", "Added Successfully..!");
      onClose();
      setIsLoading(false);
    } catch (error) {
      console.log(error, "ERROR");

      if (error?.response?.data?.data?.date) {
        notify(true, error?.response?.data?.data?.date);
      } else if (error?.response?.data?.data?.title) {
        notify(true, error?.response?.data?.data?.title);
      } else if (error?.response?.data?.data?.description) {
        notify(true, error?.response?.data?.data?.description);
      } else if (adminCoach && error?.response?.data?.data?.title) {
        notify(true, error?.response?.data?.data?.title);
      } else if (adminCoach && error?.response?.data?.data?.date) {
        notify(true, error?.response?.data?.data?.date);
      } else if (adminCoach && error?.response?.data?.data?.description) {
        notify(true, error?.response?.data?.data?.description);
      }

      console.error("Error updating note:", error);
      setIsLoading(false);
    }
    setTitle("");
    setDesc("");
    setDate("");
    setIsLoading(false);
  };

  const spinnerStyle = {
    border: "3px solid #f3f3f3",
    borderTop: "3px solid #a0fc6e",
    borderRadius: "50%",
    width: "30px",
    height: "30px",
    animation: "spin 1s linear infinite",
  };

  const containerStyle = {
    display: "flex",
    justifyContent: "center",
    marging: "0 auto",
    backgroundColor: "transparent",
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <form onSubmit={handleSave}>
          {!userId && (adminCoach || athleteNote || coachAthelete) && (
            <div className="custom-select-container mt-4 form-div">
              <label>Seleziona Utente</label>

              <Dropdown
                options={filteredCoach && filteredCoach}
                onSelect={handleSelect2}
                btn="Select account type"
                bgList="true"
                className="custom-select-container-drop "
                content={type}
              />
            </div>
          )}
          <div className="form-div">
            <label>titolo</label>
            <input
              type="text"
              placeholder="Enter title here"
              className="form-input"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>
          <div className="form-div picker">
            <label>date</label>
            <DatePicker
              selected={date}
              onChange={(date) => setDate(date)}
              dateFormat="dd-MMM-yyyy"
              placeholderText="dd-mmm-yyyy"
              minDate={new Date()}
              isClearable
            />
          </div>
          <div className="form-div">
            <label>descrizione</label>
            <input
              type="text"
              placeholder="Enter description"
              className="form-input"
              value={desc}
              onChange={(e) => setDesc(e.target.value)}
            />
          </div>

          <div className="button-group" style={{ marginTop: "10px" }}>
            {isLoading ? (
              <div style={containerStyle}>
                <div style={spinnerStyle}></div>
                <style>
                  {`
              @keyframes spin {
                0% { transform: rotate(0deg); }
                100% { transform: rotate(360deg); }
              }
            `}
                </style>
              </div>
            ) : (
              <button
                type="submit"
                className="modal-btn"
                style={{ width: "100%" }}
              >
                Salva
              </button>
            )}
          </div>
        </form>
        <div onClick={onClose} className="modal-close">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="15"
            height="15"
            viewBox="0 0 16 16"
          >
            <path
              fillRule="evenodd"
              fill="#fff"
              d="M0 14.545L1.455 16 8 9.455 14.545 16 16 14.545 9.455 8 16 1.455 14.545 0 8 6.545 1.455 0 0 1.455 6.545 8z"
            ></path>
          </svg>
        </div>
      </div>
    </div>
  );
};

export default CreateNoteModal;
