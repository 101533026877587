import React, { useState, useEffect } from "react";
import axios from "axios";
import { BASE_URL } from "../assets/constants";
import { notify } from "./util/notify";
import Dropdown from "./Reuseable/Dropdown";

const AssignAthleteModal = ({
  closedHandler,
  selectedAthlete,
  setSelectedAthlete,
  selectedCoach,
  setSelectedCoach,
}) => {
  const [athletes, setAthletes] = useState([]);
  const [coaches, setCoaches] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const selectedAthleteData = athletes?.find(
    (athlete) => athlete.id === parseInt(selectedAthlete)
  );
  const selectedCoachData = coaches?.find(
    (coach) => coach.id === parseInt(selectedCoach)
  );

  useEffect(() => {
    const fetchData = async () => {
      const adminData = JSON.parse(localStorage.getItem("adminData"));
      const token = adminData?.data?.token;

      try {
        const [athleteResponse, coachResponse] = await Promise.all([
          axios.get(`${BASE_URL}admin/athlete-all`, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }),
          axios.get(`${BASE_URL}admin/coach-all`, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }),
        ]);
        let filterAthlete = athleteResponse?.data?.athlete?.map((val) => ({
          name: val?.name,
          id: val?.uuid,
        }));
        let filterCoach = coachResponse?.data?.coaches?.map((val) => ({
          name: val?.name,
          id: val?.uuid,
        }));

        setAthletes(filterAthlete);
        setCoaches(filterCoach);
      } catch (error) {
        console.error(
          "Failed to fetch data:",
          error.response ? error.response.data : error.message
        );
      }
    };

    fetchData();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (!selectedCoach?.id || !selectedAthlete?.id) {
      setIsLoading(false);
      return;
    }

    const adminData = JSON.parse(localStorage.getItem("adminData"));
    const token = adminData?.data?.token;

    const url = `${BASE_URL}admin/assign/coach/${selectedCoach?.id}/athlete/${selectedAthlete?.id}`;

    try {
      const response = await axios.post(
        url,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      // alert(response?.data?.message);
      notify(false, response?.data?.message);

      closedHandler();
      setIsLoading(false);
    } catch (error) {
      notify(true, error.response?.data?.message);
      setIsLoading(false);
    }
    setIsLoading(false);
  };

  const spinnerStyle = {
    border: "3px solid #f3f3f3",
    borderTop: "3px solid #a0fc6e",
    borderRadius: "50%",
    width: "30px",
    height: "30px",
    animation: "spin 1s linear infinite",
  };

  const containerStyle = {
    display: "flex",
    justifyContent: "center",
    marging: "0 auto",
    backgroundColor: "transparent",
  };

  return (
    <div className="modal-overlayy">
      <div className="modal-content">
        <p className="modal-title">Assign Athlete</p>
        <form onSubmit={handleSubmit}>
          <div
            className="form-div custom-select-container"
            style={{ width: "100%" }}
          >
            <label>Choose Athlete</label>

            <Dropdown
              options={athletes && athletes}
              onSelect={(e) => setSelectedAthlete(e)}
              btn="Select account type"
              bgList="true"
              className="custom-select-container-drop"
              content={selectedAthlete}
            />
          </div>
          <div
            className="form-div custom-select-container"
            style={{ width: "100%" }}
          >
            <label>Choose Coach</label>
            <Dropdown
              options={coaches && coaches}
              onSelect={(e) => setSelectedCoach(e)}
              btn="Select account type"
              bgList="true"
              className="custom-select-container-drop"
              content={selectedCoach}
            />
          </div>
          {isLoading ? (
            <div style={containerStyle}>
              <div style={spinnerStyle}></div>
              <style>
                {`
              @keyframes spin {
                0% { transform: rotate(0deg); }
                100% { transform: rotate(360deg); }
              }
            `}
              </style>
            </div>
          ) : (
            <button type="submit" className="modal-btn">
              Assign
            </button>
          )}
        </form>
        <div onClick={closedHandler} className="modal-close">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="15"
            height="15"
            viewBox="0 0 16 16"
          >
            <path
              fillRule="evenodd"
              fill="#fff"
              d="M0 14.545L1.455 16 8 9.455 14.545 16 16 14.545 9.455 8 16 1.455 14.545 0 8 6.545 1.455 0 0 1.455 6.545 8z"
            ></path>
          </svg>
        </div>
      </div>
    </div>
  );
};

export default AssignAthleteModal;
