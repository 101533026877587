import { toast } from "react-toastify";

export const notify = (err, msg, time) => {
  if (err) {
    return toast.error(msg, {
      position: "top-right",
      // autoClose: 1000,
      // hideProgressBar: false,
      // closeOnClick: false,
      // pauseOnHover: false,
      // draggable: true,
      // progress: undefined,
      theme: "light",
    });
  } else if (!err) {
    return toast.success(msg, {
      position: "top-right",
      // autoClose: 1000,
      // hideProgressBar: false,
      // closeOnClick: true,
      // pauseOnHover: true,
      // draggable: true,
      // progress: undefined,
      theme: "light",
    });
  }
};
