import React, { useEffect, useState } from "react";
import axios from "axios";
import "../../css/index.css";
import atletiIcon from "../../assets/images/search-active.svg";
import editIcon from "../../assets/img/icons/edit.svg";
import calendarIcon from "../../assets/img/icons/calendar.svg";
import plusIcon from "../../assets/images/plus.png";
import { useNavigate } from "react-router-dom";
import AdminCoachModal from "../../components/AdminCoachModal";
import { BASE_URL } from "../../assets/constants";
import AssignAthleteModal from "../AssignAthleteModal";
import deleteIcon from "../../assets/img/icons/delete.svg";
import CalendarioModal from "../CalendarioModal";
import CreateNoteModal from "../CreateNoteModal";
import { notify } from "../util/notify";
import { MdOutlineCancel } from "react-icons/md";
import AddTagModal from "../AddTagModal";

import DeleteModal from "../DeleteModal";

const TaskEditor = ({
  title,
  description,
  date,
  handleEditClick,
  handleDeleteClick,
  loader,
}) => {
  const formatDate = (dateString) => {
    if (!dateString) {
      return "Invalid Date";
    }

    const [day, month, year] = dateString.split("/");

    const formattedDate = `${year}-${month}-${day}`;
    const parsedDate = new Date(formattedDate);

    if (isNaN(parsedDate)) {
      console.error("Invalid Date:", dateString);
      return "Invalid Date";
    }

    const weekday = parsedDate.toLocaleDateString("en-US", { weekday: "long" });
    const monthShort = parsedDate.toLocaleDateString("en-US", {
      month: "short",
    });

    return `${weekday}, ${day} ${monthShort}`;
  };

  return (
    <div className="task-editor">
      <div className="title">
        {title}
        <div>
          <img src={editIcon} alt="edit icon" onClick={handleEditClick} />
          <img
            src={deleteIcon}
            alt="delete icon"
            onClick={handleDeleteClick}
            style={{
              marginLeft: "10px",
              cursor: "pointer",
              width: "20px",
              height: "20px",
              filter: "invert(100%) brightness(100%)",
            }}
          />
        </div>
      </div>
      <div className="task-body">
        <img
          src={calendarIcon}
          alt="calendar icon"
          style={{ marginRight: "10px" }}
        />
        <label>{formatDate(date)}</label>
      </div>
      <div className="description">{description}</div>
    </div>
  );
};

export default function AdminCoach() {
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedAthlete, setSelectedAthlete] = useState({
    name: "Select options",
    id: 0,
  });
  const [selectedCoach, setSelectedCoach] = useState({
    name: "Select options",
    id: 0,
  });
  const [tableArr, setTableArr] = useState([]);
  const [loading, setLoading] = useState(true);
  const [notes, setNotes] = useState([]);
  const [isEditModal, setIsEditModal] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [isAddModal, setIsAddModal] = useState(false);
  const [isAddTagModalOpen, setIsAddTagModalOpen] = useState(false);
  const [coachModalOpen, setIsCoachModalOpen] = useState(false);
  const [type, setType] = useState({ name: "Select options", id: 0 });
  const [isLoading, setIsLoading] = useState(false);

  const [selectedNote, setSelectedNote] = useState(null);
  const [selectedCoachId, setSelectedCoachId] = useState(null);
  const [isSearching, setIsSearching] = useState(false);
  const [searchingValue, setSearchingValue] = useState("");

  const handleEditClick = (note) => {
    setIsEditModal(true);
    setSelectedNote(note);
  };

  const handleDeleteClick = async () => {
    setIsLoading(true);
    try {
      const token = adminData?.data?.token;

      await axios.delete(
        `${BASE_URL}admin/note/coach/delete/${confirmationModal}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setConfirmationModal(false);
      setIsLoading(false);

      fetchNotes();
    } catch (error) {
      console.error("Error deleting note:", error);
      setIsLoading(false);
    }
  };

  const handleCloseModal = () => {
    setIsEditModal(false);
  };

  const handleCloseNoteModal = () => {
    setIsAddModal(false);
  };

  const handleModalSave = () => {
    fetchNotes();
    setIsEditModal(false);
  };

  const handleNoteSave = () => {
    fetchNotes();
    setIsAddModal(false);
  };

  const staticData = {
    TassoRinnovo: "92%",
    ClientiSeguiti: 25,
    ClientiPersi: "6",
    Tags: [{ tag: "Tag" }, { tag: "Tag" }, { tag: "Tag" }],
  };

  const adminData = JSON.parse(localStorage.getItem("adminData"));
  const token = adminData?.data?.token;

  const fetchData = async () => {
    try {
      const response = await axios.get(`${BASE_URL}admin/coach-all`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const fetchedNames = response?.data?.coaches?.map((coach) => ({
        id: coach.id,
        name: coach.name,
        TassoRinnovo: coach.renewal_rate,
        ClientiSeguiti: coach.customers_followed,
        ClientiPersi: coach.lost_customers,
        Tags: coach.tags,
        uuid: coach.uuid,
      }));

      setTableArr(fetchedNames);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleNavigate = (item) => {
    navigate("/admin/adminScheda", { state: { ...item } });
  };

  const spinnerStyle = {
    border: "3px solid #f3f3f3",
    borderTop: "3px solid #a0fc6e",
    borderRadius: "50%",
    width: "30px",
    height: "30px",
    animation: "spin 1s linear infinite",
  };

  const containerStyle = {
    display: "flex",
    alignContent: "center",
    backgroundColor: "transparent",
  };

  const fetchNotes = async () => {
    try {
      const response = await axios.get(`${BASE_URL}admin/note/coach/all`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setNotes(response?.data?.data || []);
    } catch (error) {
      notify(true, error);
      console.error("Error fetching athlete notes:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteCoach = async (value) => {
    setIsLoading(true);
    try {
      const response = await axios.delete(
        `${BASE_URL}admin/delete/coach/${coachModalOpen?.id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response?.data);
      notify("", "Coach eliminato con successo");
      fetchData();
      setIsCoachModalOpen(false);
    } catch (error) {
      console.log("error", error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchNotes();
  }, []);

  const addTag = (coachId) => {
    setSelectedCoachId(coachId);
    setIsAddTagModalOpen(true);
  };

  const closeAddTagModal = () => {
    fetchData();
    setIsAddTagModalOpen(false);
  };

  const filteredDataCoach = tableArr?.filter((value) =>
    value?.name?.toLowerCase().includes(searchingValue.toLowerCase())
  );

  return (
    <div className="AtletiContent">
      <div
        className="topBar"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <img
          className="searchIcon"
          src={atletiIcon}
          onClick={() => {
            setIsSearching(!isSearching);
            setSearchingValue("");
          }}
          alt="Atleti"
        />
        {isSearching ? (
          <div
            className="form-div"
            style={{
              display: "flex",
              flexDirection: "row",
              position: "relative",
            }}
          >
            <input
              type="text"
              placeholder="Cerca qui...!"
              className="form-input pe-3"
              value={searchingValue}
              name="name"
              onChange={(e) => setSearchingValue(e.target.value)}
              style={{ marginBottom: "20px", marginLeft: 5, marginTop: 10 }}
            />
            <MdOutlineCancel
              style={{
                position: "absolute",
                right: 0,
                top: 20,
                fontSize: 18,
                cursor: "pointer",
              }}
              onClick={() => setSearchingValue("")}
            />
          </div>
        ) : (
          <span>I TUOI COACH</span>
        )}
        <div style={{ marginLeft: "auto" }}>
          <button
            onClick={() => setIsModalOpen(true)}
            style={{
              backgroundColor: "#a0fc6e",
              color: "black",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
              height: "30px",
              width: "100px",
              fontSize: "15px",
              padding: 0,
            }}
            className="name"
          >
            Assign Athlete
          </button>
        </div>
      </div>
      <div className="content-visibility">
        <div className="content-columns">
          <div className="content-center">
            <div className="table">
              <table className="admin-custom-table">
                <thead>
                  <tr>
                    <th className="border-right">
                      <div className="header-cell">
                        <div>Nome</div>
                      </div>
                    </th>
                    <th className="border-right">
                      <div className="header-cell">
                        <div>Tasso rinnovo</div>
                      </div>
                    </th>
                    <th className="border-right">
                      <div className="header-cell">
                        <div>Clienti seguiti</div>
                      </div>
                    </th>
                    <th className="border-right">
                      <div className="header-cell">
                        <div>Clienti persi</div>
                      </div>
                    </th>
                    <th>Tags</th>
                    <th>Azione</th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <tr style={containerStyle}>
                      <td style={spinnerStyle}></td>
                      <style>
                        {`
              @keyframes spin {
                0% { transform: rotate(0deg); }
                100% { transform: rotate(360deg); }
              }
            `}
                      </style>
                    </tr>
                  ) : (
                    <>
                      {filteredDataCoach?.map((item, i) => {
                        return (
                          <tr
                            key={i}
                            className={i % 2 === 0 ? "bg-color" : "bg-none"}
                          >
                            <td
                              onClick={() => handleNavigate(item)}
                              style={{ cursor: "pointer" }}
                            >
                              {item?.name}
                            </td>
                            <td>{item?.TassoRinnovo}</td>
                            <td>{item?.ClientiSeguiti}</td>
                            <td>{item?.ClientiPersi}</td>
                            <td
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                width: 200,
                              }}
                            >
                              {item?.Tags?.length > 0 ? (
                                <div className="tagLabelParent">
                                  {item?.Tags?.map((tag, index) => (
                                    <label key={index}>{tag?.tag_name}</label>
                                  ))}
                                </div>
                              ) : (
                                <div>No tags</div>
                              )}
                              <img
                                src={plusIcon}
                                alt="Add tag"
                                style={{
                                  marginLeft: "8px",
                                  marginTop: "8px",
                                  cursor: "pointer",
                                  filter: "invert(100%) brightness(100%)",
                                  width: "15px",
                                  height: "15px",
                                }}
                                onClick={() => addTag(item.id)}
                              />
                            </td>
                            <td>
                              <img
                                src={deleteIcon}
                                alt="delete icon"
                                onClick={() => {
                                  setIsCoachModalOpen({
                                    title: item?.name,
                                    id: item?.uuid,
                                  });
                                }}
                                style={{
                                  marginLeft: "10px",
                                  cursor: "pointer",
                                  width: "20px",
                                  height: "20px",
                                  filter: "invert(100%) brightness(100%)",
                                }}
                              />
                            </td>
                          </tr>
                        );
                      })}
                    </>
                  )}
                </tbody>
              </table>
              <div className="bottomTextHome">
                <div className="admin-bottom-container">
                  <a
                    href="#"
                    className="admin-bottom-button"
                    onClick={() => setOpenModal(true)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                    >
                      <g>
                        <g
                          fill="none"
                          stroke="#A0FC6E"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          data-name="add"
                        >
                          <path d="M12 19L12 5"></path>
                          <path d="M5 12L19 12"></path>
                        </g>
                      </g>
                    </svg>
                    aggiungi nuova
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="content-right-sidebar background-for-notes">
            <div className="sub---title ">calendario attivita</div>
            {notes?.length > 0 ? (
              notes?.map((activity, index) => (
                <TaskEditor
                  key={index}
                  title={activity.title}
                  date={new Date(activity.date).toLocaleDateString()}
                  description={activity.description}
                  loader={loading}
                  handleEditClick={() => handleEditClick(activity)}
                  handleDeleteClick={() => {
                    setConfirmationModal(activity.uuid);

                    // handleDeleteClick(activity.uuid);
                  }}
                />
              ))
            ) : (
              <div style={{ alignSelf: "center" }}>
                Nessuna attività nel calendario
              </div>
            )}
            <DeleteModal
              isOpen={confirmationModal}
              onCancel={() => setConfirmationModal(false)}
              onConfirm={handleDeleteClick}
              message="Sei sicuro di voler eliminare questa nota?"
              isLoading={isLoading}
            />
            <DeleteModal
              isOpen={coachModalOpen}
              onCancel={() => setIsCoachModalOpen(false)}
              onConfirm={handleDeleteCoach}
              message={`Sei sicuro di voler eliminare questa ${coachModalOpen?.title} ?`}
              isLoading={isLoading}
            />
            <CalendarioModal
              isOpen={isEditModal}
              onClose={handleCloseModal}
              token={token}
              onSave={handleModalSave}
              selectedNote={selectedNote}
              adminCoach
            />
            <a
              href="#"
              className="button-default-no-icon"
              onClick={() => setIsAddModal(true)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <g>
                  <g
                    fill="none"
                    stroke="#A0FC6E"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    data-name="add"
                  >
                    <path d="M12 19L12 5"></path>
                    <path d="M5 12L19 12"></path>
                  </g>
                </g>
              </svg>
              <p>aggiungi nuova</p>
            </a>
            <CreateNoteModal
              isOpen={isAddModal}
              onClose={handleCloseNoteModal}
              token={token}
              onSave={handleNoteSave}
              tableArr={tableArr}
              type={type}
              adminCoach
            />
          </div>
          {openModal && (
            <AdminCoachModal
              closedHandler={() => {
                setOpenModal(false);
                fetchData();
              }}
              type="admin-coach"
            />
          )}
        </div>
      </div>
      {isModalOpen && (
        <AssignAthleteModal
          closedHandler={() => setIsModalOpen(false)}
          selectedAthlete={selectedAthlete}
          setSelectedAthlete={setSelectedAthlete}
          selectedCoach={selectedCoach}
          setSelectedCoach={setSelectedCoach}
        />
      )}

      {isAddTagModalOpen && (
        <AddTagModal
          isOpen={isAddTagModalOpen}
          coachId={selectedCoachId}
          onClose={closeAddTagModal}
        />
      )}
    </div>
  );
}
