import React from "react";
import Home from "../Users/Home";
import NoteContent from "../../pages/NoteContent";
import ProfiloContent from "../../pages/ProfiloContent";
import CoachDashboard from "../../pages/coachDashboard";
import AtletiCalendrio from "../Coach/AtletiCalendrio";
import CalendarioContent from "../../pages/CalendarioContent";
import AtletiContent from "../../pages/AtletiContent";
import CoachAthleteStepOne from "../Coach/CoachAthleteStepOne";
import CoachAtheletStepSeven from "../Coach/CoachAthleteStepSeven";
import CoachAtheletaStepSix from "../Coach/CoachAthleteStepSix";
import CoachAtheletStepFive from "../Coach/CoachAthleteStepFive";

const CoachContent = ({ coachContent }) => {
  const renderContent = () => {
    switch (coachContent) {
      case "coachHome":
        return <CoachDashboard />;
      case "coachNote":
        return <NoteContent />;
      case "coachProfilo":
        return <ProfiloContent />;
      case "atletiCalendrio":
        return <AtletiCalendrio />;
      case "calendrio":
        return <CalendarioContent />;
      case "coachActivity":
        return <CoachAthleteStepOne />;

      case "coachAtheleteMisurazioni":
        return <CoachAtheletStepSeven />;

      case "coachAtheleteNutrizione":
        return <CoachAtheletaStepSix />;

      case "coachAtheleteAllenamento":
        return <CoachAtheletStepFive />;

      default:
        return <Home />;
    }
  };

  return <div className="content">{renderContent()}</div>;
};

export default CoachContent;
