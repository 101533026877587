import React from "react";
import AdminCoachs from "../../components/Admin/AdminCoachs";

const AdminCoach = () => {
  return (
    <div>
      <AdminCoachs />
    </div>
  );
};

export default AdminCoach;
