import React, { useEffect, useState } from "react";
import "./css/GridTwo.css";

const smoker = [
  { name: "Regolare", value: 1 },
  { name: "Occasionale", value: 2 },
  { name: "Non fumo", value: 3 },
];
const stressLevel = [
  { name: "Cronico, ingestibile", value: 1 },
  {
    name: "Ricorrente, a volte ingestibile",
    value: 2,
  },
  {
    name: "Occasionale, gestibile",
    value: 3,
  },
  { name: "Nessuno", value: 4 },
  { name: "Altro", value: 5 },
];
const coffee = [
  { name: "Non mi sveglio senza caffè", value: 1 },
  { name: "Senza caffè ho poca energia", value: 2 },
  { name: "Lo bevo quando sono stanco", value: 3 },
  { name: "Potrei farne a meno", value: 4 },
  { name: "Potrei farne a meno", value: 5 },
  { name: "Altro", value: 6 },
];
const wakeUp = [
  { name: "Fiacco e stanco", value: 1 },
  { name: "Energico", value: 2 },
];
const objectivo = [
  { name: "Dimagrire/benessere", value: 1 },
  { name: "Massa muscolare/Performance", value: 2 },
];
const previousNutrition = [
  {
    name: "Diete già seguite e alimentazione",
    value: "previous_diets_and_nutrition",
  },
  { name: "Cosa mangi a colazione", value: "breakfast_choices" },
  { name: "Cosa mangi a  pranzo", value: "lunch_choices" },
  { name: "Cosa mangi a  cena", value: "dinner_choices" },
  {
    name: "Cosa mangi come spuntino",
    value: "snack_choices",
  },
];
const breakFast = [
  {
    name: "Diete già seguite e alimentazione",
    value: "previous_diets_and_nutrition",
  },
  { name: "Cosa mangi a colazione", value: "breakfast_choices" },
  { name: "Cosa mangi a  pranzo", value: "lunch_choices" },
  { name: "Cosa mangi a  cena", value: "dinner_choices" },
  {
    name: "Cosa mangi come spuntino",
    value: "snack_choices",
  },
];
const hungerLevel = [
  {
    name: "Cronica, non riesco a smettere",
  },
  { name: "Molti giorni ho fame, altri per nulla" },
  { name: "Ho fame solo prima dei pasti" },
  { name: "Pochi giorni ho fame, altri per nulla" },
  { name: "Non ho mai fame" },
];
const mealPlanning = [
  { name: "Preparo tutti i pasti" },
  { name: "Ho fame solo prima dei pasti" },
  { name: "Preparo solo pasti veloci e semplici" },
  { name: "Preparo solo la cena" },
  { name: "Qualcuno prepara i pasti per me" },
];
const eatEverything = [
  { name: "Mangio tutto", value: "dont_eat_i_eat_everything" },
  {
    name: "Latticini e derivati",
    value: "dont_eat_dairy_products_and_derivatives",
  },
  { name: "Carne", value: "dont_eat_meat" },
  {
    name: "Pesce e molluschi",
    value: "dont_eat_fish_and_shellfish",
  },
  { name: "Legumi", value: "dont_eat_legumes" },
  { name: "Cereali", value: "dont_eat_cereals" },
  { name: "Frutta secca", value: "dont_eat_dried_fruit" },
  { name: "Altro", value: "dont_eat_other" },
];
let suplements = [
  { name: "Nessuno", value: "supplements_nobody" },
  {
    name: "Proteine in polvere",
    value: "supplements_protein_powder",
  },
  { name: "Creatina", value: "supplements_creatine" },
  { name: "BCAA / EAA", value: "supplements_bcaa_eaa" },
  { name: "Vitamine", value: "supplements_vitamins" },
  { name: "Omega 3", value: "supplements_omega_3" },
  { name: "ALA", value: "supplements_wing" },
  { name: "Elettroliti", value: "supplements_electrolytes" },
  { name: "Glutammina", value: "supplements_glutamine" },
  { name: "ALtro", value: "supplements_other" },
];

const GridTableMeasurement = ({ type, atheleteData }) => {
  const [filteredNames, setFilteredNames] = useState([]);
  const [filteredSupplements, setFilteredSupplements] = useState([]);
  const [suppleMentFilteredName, setSuppleMentFilteredName] = useState([]);
  const [performedExcercise, setPerformedExcercise] = useState({});
  const [performedExcerciseInTheLegs, setPerformedExcerciseInTheLegs] =
    useState({});
  const [performedExcerciseWithWeight, setPerformedExcerciseWithWeight] =
    useState({});
  const data = [
    {
      meal: "Domanda",
      calories: "20 kcal",
      carbs: "10 g",
      proteins: "65 g",
      fats: "32 g",
      fiber: "22 g",
      property: "feeling_hungry",
    },
    {
      meal: "Sensazione di fame",
      calories: "20 kcal",
      carbs: "10 g",
      proteins: "65 g",
      fats: "32 g",
      fiber: "22 g",
      property: "feeling_hungry",
    },
    {
      meal: "Aderenza la piano alimentare",
      calories: "34 kcal",
      carbs: "54 g",
      proteins: "12 g",
      fats: "3 g",
      fiber: "65 g",
      property: "how_closely_did_you_follow_the_diet_plan",
    },
    {
      meal: "Benessere intestinale",
      calories: "22 kcal",
      carbs: "66 g",
      proteins: "43 g",
      fats: "36 g",
      fiber: "32 g",
      property: "level_of_gut_health",
    },
    {
      meal: "Umore ed energia",
      calories: "54 kcal",
      carbs: "32 g",
      proteins: "34 g",
      fats: "12 g",
      fiber: "18 g",
      property: "mood_and_energy_level",
    },
    {
      meal: "Ore di sonno",
      calories: "1938 kcal",
      carbs: "543 g",
      proteins: "321 g",
      fats: "234 g",
      fiber: "51 g",
      property: "hours_of_sleep",
    },
    {
      meal: "Performance",
      calories: "1938 kcal",
      carbs: "543 g",
      proteins: "321 g",
      fats: "234 g",
      fiber: "51 g",
      property: "how_has_your_performance_changed",
    },
    {
      meal: "Note",
      calories: "1938 kcal",
      carbs: "543 g",
      proteins: "321 g",
      fats: "234 g",
      fiber: "51 g",
      property: "personal_notes",
    },
  ];

  const measurementData = [
    {
      meal: "Petto",
      calories: "20 kcal",
      carbs: "10 g",
      proteins: "65 g",
      fats: "32 g",
      fiber: "22 g",
      property: "chest",
    },
    {
      meal: "Addome",
      calories: "34 kcal",
      carbs: "54 g",
      proteins: "12 g",
      fats: "3 g",
      fiber: "65 g",
      property: "abdomen",
    },
    // {
    //   meal: "Girovita",
    //   calories: "22 kcal",
    //   carbs: "66 g",
    //   proteins: "43 g",
    //   fats: "36 g",
    //   fiber: "32 g",
    //   property: "buttocks",
    // },
    {
      meal: "Glutei",
      calories: "54 kcal",
      carbs: "32 g",
      proteins: "34 g",
      fats: "12 g",
      fiber: "18 g",
      property: "buttocks",
    },
    {
      meal: "Braccio sx",
      calories: "1938 kcal",
      carbs: "543 g",
      proteins: "321 g",
      fats: "234 g",
      fiber: "51 g",
      property: "left_arm",
    },
    {
      meal: "Braccio dx",
      calories: "1938 kcal",
      carbs: "543 g",
      proteins: "321 g",
      fats: "234 g",
      fiber: "51 g",
      property: "right_arm",
    },
    {
      meal: "Coscia sx",
      calories: "1938 kcal",
      carbs: "543 g",
      proteins: "321 g",
      fats: "234 g",
      fiber: "51 g",
      property: "left_thigh",
    },
    {
      meal: "Coscia dx",
      calories: "1938 kcal",
      carbs: "543 g",
      proteins: "321 g",
      fats: "234 g",
      fiber: "51 g",
      property: "right_thigh",
    },
  ];
  const onBoarding = [
    {
      title: "Altezza",
      cm: `${atheleteData?.profile?.height || 0} cm`,
    },
    {
      title: "Peso",
      cm: `${atheleteData?.profile?.weight || 0} cm`,
    },
    {
      title: "Sesso biologico",
      cm: atheleteData?.profile?.biological_sex,
    },
    {
      title: "Età",
      cm: `${atheleteData?.profile?.age || 0} cm`,
    },
    {
      title: "Professione",
      cm: atheleteData?.profile?.profession,
    },

    // more additional data...
  ];

  const wellBeing = [
    {
      title: "Qualità del sonno",
      cm: atheleteData?.well_being?.sleep_quality,
    },
    {
      title: "Livello di stress",
      cm: stressLevel[atheleteData?.well_being?.stress_level - 1]?.name,
    },
    {
      title: "Sei un fumatore?",
      cm: smoker[atheleteData?.well_being?.are_you_a_smoker - 1]?.name,
    },
    {
      title: "Bevi caffè?",
      cm: coffee[atheleteData?.well_being?.do_you_drink_coffee - 1]?.name,
    },
    {
      title: "Come ti senti appena sveglio?",
      cm: wakeUp[
        atheleteData?.well_being?.how_do_you_feel_when_you_first_wake_up - 1
      ]?.name,
    },
    {
      title: "Soffri di qualche patologia?",
      cm: atheleteData?.well_being?.do_you_suffer_from_any_medical_conditions,
    },

    // more additional data...
  ];

  const measurementsss = [
    {
      title: "Petto",
      cm: `${atheleteData?.measurements?.chest || 0} cm`,
    },
    {
      title: "Addome",
      cm: `${atheleteData?.measurements?.abdomen || 0} cm`,
    },
    // {
    //   title: "Girovita",
    //   cm: `${atheleteData?.measurements?.glutes || 0} cm`,
    // },
    {
      title: "Glutei",
      cm: `${atheleteData?.measurements?.glutes || 0} cm`,
    },
    {
      title: "Braccio destro",
      cm: `${atheleteData?.measurements?.right_arm || 0} cm`,
    },
    {
      title: "Braccio sinistro",
      cm: `${atheleteData?.measurements?.left_arm || 0} cm`,
    },

    {
      title: "Coscia destra",
      cm: `${atheleteData?.measurements?.left_thigh || 0} cm`,
    },
    {
      title: "Coscia sinistra",
      cm: `${atheleteData?.measurements?.left_thigh || 0} cm`,
    },

    // more additional data...
  ];

  const nutritions = [
    {
      title: "Obiettivo",
      cm: objectivo[atheleteData?.nutritions?.objective - 1]?.name,
    },
    {
      title: "hai già seguito alcune diete in passato?",
      cm: atheleteData?.nutritions?.previous_diets_and_nutrition,
    },
    {
      title: "Cosa mangi a colazione",
      cm: atheleteData?.nutritions?.breakfast_choices,
    },
    {
      title: "Cosa mangi a  pranzo",
      cm: atheleteData?.nutritions?.lunch_choices,
    },
    {
      title: "Cosa mangi a  cena",
      cm: atheleteData?.nutritions?.dinner_choices,
    },
    {
      title: "Cosa mangi come spuntino",
      cm: atheleteData?.nutritions?.snack_choices,
    },
    {
      title: "Quanto è importante per te la colazione?",
      cm: breakFast[atheleteData?.nutritions?.breakfast_importance - 1]?.name,
    },
    {
      title: "Livello di fame",
      cm: hungerLevel[atheleteData?.nutritions?.hunger_level - 1]?.name,
    },
    {
      title: "Organizzazione dei pasti",
      cm: mealPlanning[atheleteData?.nutritions?.meal_planning - 1]?.name,
    },
    {
      title: "Cosa non mangi",
      cm: filteredNames
        ?.map((v, index) =>
          index !== filteredNames.length - 1 ? `${v} - ` : `${v}`
        )
        .join(""),
    },
    {
      title: "Fai uso di integratori?",
      cm: filteredSupplements
        ?.map((v, index) =>
          index !== filteredSupplements.length - 1 ? `${v} - ` : `${v}`
        )
        .join(""),
    },

    // more additional data...
  ];

  const Training = [
    {
      title: "Da quanto tempo ti alleni?",
      cm: atheleteData?.trainings?.how_long_have_you_been_training,
    },
    {
      title: "Che tipo di allenamento hai seguito?",
      cm: atheleteData?.trainings?.what_kind_of_training_did_you_do,
    },
    {
      title: "Hai avuto infortuni in passato?",
      cm: atheleteData?.trainings?.have_you_had_any_injuries_in_the_past,
    },
    {
      title: "Quante volte ti alleni alla settimana?",
      cm: atheleteData?.trainings?.how_many_times_do_you_train_a_week,
    },
    {
      title: "Performance attuali a Corpo Libero",
      cm: Object?.keys(performedExcercise)?.map((v, index) =>
        index !== Object?.keys(performedExcercise)?.length - 1
          ? `${v} - `
          : `${v}`
      ),
      qty: Object?.values(performedExcercise)?.map((v, index) =>
        index !== Object?.keys(performedExcercise)?.length - 1
          ? `${v?.qty} - `
          : `${v?.qty}`
      ),
    },
    {
      title: "Performance attuali con Pesi",
      cm: Object?.keys(performedExcerciseWithWeight)?.map((v, index) =>
        index !== Object?.keys(performedExcerciseWithWeight)?.length - 1
          ? `${v?.replace(/_/g, " ")} - `
          : `${v?.replace(/_/g, " ")}`
      ),
      qty: Object?.values(performedExcerciseWithWeight)?.map((v, index) =>
        index !== Object?.keys(performedExcerciseWithWeight)?.length - 1
          ? `${v?.reps} - `
          : `${v?.reps}`
      ),
      weight: Object?.values(performedExcerciseWithWeight)?.map((v, index) =>
        index !== Object?.keys(performedExcerciseWithWeight)?.length - 1
          ? `${v?.weight} - `
          : `${v?.weight}`
      ),
    },
    {
      title: "Performance attuali compartimento Gambe",
      cm: Object?.keys(performedExcerciseInTheLegs)?.map((v, index) =>
        index !== Object?.keys(performedExcerciseInTheLegs)?.length - 1
          ? `${v?.replace(/_/g, " ")} - `
          : `${v?.replace(/_/g, " ")}`
      ),
      qty: Object?.values(performedExcerciseInTheLegs)?.map((v, index) =>
        index !== Object?.keys(performedExcerciseInTheLegs)?.length - 1
          ? `${v?.reps} - `
          : `${v?.reps}`
      ),
      weight: Object?.values(performedExcerciseInTheLegs)?.map((v, index) =>
        index !== Object?.keys(performedExcerciseInTheLegs)?.length - 1
          ? `${v?.weight} - `
          : `${v?.weight}`
      ),
    },

    // more additional data...
  ];

  useEffect(() => {
    if (atheleteData?.nutritions) {
      const filteredKeys = Object.keys(atheleteData?.nutritions)?.filter(
        (key) => atheleteData?.nutritions[key] === 1
      );
      const result = eatEverything
        .filter((item) => filteredKeys?.includes(item.value))
        .map((item) => item.name);
      const result2 = suplements
        .filter((item) => filteredKeys?.includes(item.value))
        .map((item) => item.name);

      const performedExercises = Object.entries(
        atheleteData?.trainings?.current_floor_exercise_performances
      )
        .filter(([key, value]) => value.is_performed === 1)
        .reduce((acc, [key, value]) => {
          acc[key] = value;
          return acc;
        }, {});
      const performedExercisesWithWeight = Object.entries(
        atheleteData?.trainings?.current_performances_with_weights
      )
        .filter(([key, value]) => value.is_performed === 1)
        .reduce((acc, [key, value]) => {
          acc[key] = value;
          return acc;
        }, {});
      const performedExercisesInTheLegs = Object.entries(
        atheleteData?.trainings?.current_performance_in_the_legs_compartment
      )
        .filter(([key, value]) => value.is_performed === 1)
        .reduce((acc, [key, value]) => {
          acc[key] = value;
          return acc;
        }, {});

      setPerformedExcercise(performedExercises);
      setPerformedExcerciseWithWeight(performedExercisesWithWeight);
      setPerformedExcerciseInTheLegs(performedExercisesInTheLegs);
      setFilteredNames(result);
      setFilteredSupplements(result2);
    }
  }, [atheleteData]);
  console.log("onBoarding", onBoarding);

  return (
    <>
      {type === "measurementData" ? (
        // <div className="gridd">
        //   <div className="grid-table-two head">
        //     <div className="head-col">Corpo</div>
        //     {atheleteData?.map((value, index) => {
        //       return <div className="head-col">{value["month/date"]}</div>;
        //     })}
        //   </div>
        //   {measurementData.map((item) => (
        //     <div className="grid-table-two">
        //       <div className="colss">{item.meal}</div>
        //       {atheleteData?.map((value, ind) => {
        //         return (
        //           <div className="colss" key={ind}>
        //             {value[item.property]}
        //           </div>
        //         );
        //       })}
        //     </div>
        //   ))}
        // </div>
        <div className="scrollTable">
          <table className="data-table">
            <thead>
              <tr>
                <td>Corpo</td>
                {measurementData.map((item, index) => (
                  <td>{item.meal}</td>
                ))}
              </tr>
            </thead>
            <tbody style={{ background: "#1A1A1A" }}>
              {atheleteData &&
                atheleteData?.map((value, ind) => {
                  return (
                    <tr key={ind}>
                      <td>{value["month/date"]}</td>
                      <td>{value["chest"]}</td>
                      <td>{value["abdomen"]}</td>
                      <td>{value["buttocks"]}</td>
                      <td>{value["left_arm"]}</td>
                      <td>{value["right_arm"]}</td>
                      <td>{value["left_thigh"]}</td>
                      <td>{value["left_thigh"]}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      ) : type === "onBoarding" ? (
        // <div className="gridd">
        //   <div className="grid-table-two-cols-head head">
        //     <div className="head-col">Profilo</div>
        //     <div className="head-col">Misura</div>
        //     {/* Other header columns */}
        //   </div>
        //   {onBoarding.map((item) => (
        //     <div className="grid-table-two-cols" key={item.title}>
        //       <div className="colss">{item.title}</div>
        //       <div className="colss">{item.cm}</div>
        //     </div>
        //   ))}
        // </div>

        <div className="scrollTable">
          <table className="data-table">
            <thead>
              <tr>
                <td>Profilo</td>
                {onBoarding.map((item, index) => (
                  <td>{item.title}</td>
                ))}
              </tr>
            </thead>
            <tbody style={{ background: "#1A1A1A" }}>
              <tr>
                <td>{"Misura"}</td>
                {onBoarding &&
                  onBoarding?.map((value, ind) => {
                    return <td key={ind}>{value?.cm}</td>;
                  })}
              </tr>
            </tbody>
          </table>
        </div>
      ) : type === "Training" ? (
        // <div className="gridd">
        //   <div className="grid-table-four-cols-head head">
        //     <div className="head-col">Allenamento</div>
        //     <div className="head-col">Misura</div>
        //     <div className="head-col">Ripetizioni</div>
        //     <div className="head-col">Carico</div>

        //     {/* Other header columns */}
        //   </div>
        //   {Training.map((item) => (
        //     <div className="grid-table-four-cols" key={item.title}>
        //       <div className="colss">{item.title || "N/A"}</div>
        //       <div className="colss">{item.cm || "N/A"}</div>
        //       <div className="colss">{item.qty || "N/A"}</div>
        //       <div className="colss">{item.weight || "N/A"}</div>
        //     </div>
        //   ))}
        // </div>
        <div className="scrollTable">
          <table className="data-table">
            <thead>
              <tr>
                <td>Allenamento</td>

                {Training?.map((value, ind) => {
                  return <td key={ind}>{value?.title}</td>;
                })}
              </tr>
            </thead>
            <tbody style={{ background: "#1A1A1A" }}>
              <tr>
                <td>{"Misura"}</td>
                {Training &&
                  Training.map((value, ind) => (
                    <td key={ind}>{value?.qty || "N/A"}</td>
                  ))}
              </tr>
              <tr>
                <td>{"Ripetizioni"}</td>
                {Training &&
                  Training.map((value, ind) => (
                    <td key={ind}>{value?.cm || "N/A"}</td>
                  ))}
              </tr>
              <tr>
                <td>{"Carico"}</td>
                {Training &&
                  Training.map((value, ind) => (
                    <td key={ind}>{value?.weight || "N/A"}</td>
                  ))}
              </tr>
            </tbody>
          </table>
        </div>
      ) : type === "nutritions" ? (
        // <div className="gridd">
        //   <div className="grid-table-two-cols-head head">
        //     <div className="head-col">Nutrizione</div>
        //     <div className="head-col">Misura</div>
        //     {/* Other header columns */}
        //   </div>
        //   {nutritions.map((item) => (
        //     <div className="grid-table-two-cols" key={item.title}>
        //       <div className="colss">{item.title}</div>
        //       <div className="colss">{item.cm}</div>
        //     </div>
        //   ))}
        // </div>
        <div className="scrollTable">
          <table className="data-table">
            <thead>
              <tr>
                <td>Nutrizione</td>
                {nutritions.map((item, index) => (
                  <td key={index}>{item.title}</td>
                ))}
              </tr>
            </thead>
            <tbody style={{ background: "#1A1A1A" }}>
              <tr>
                <td>{"Misura"}</td>
                {nutritions &&
                  nutritions.map((value, ind) => (
                    <td key={ind}>{value?.cm}</td>
                  ))}
              </tr>
            </tbody>
          </table>
        </div>
      ) : type === "measurementsss" ? (
        // <div className="gridd">
        //   <div className="grid-table-two-cols-head head">
        //     <div className="head-col">Misurazioni</div>
        //     <div className="head-col">Misura</div>
        //     {/* Other header columns */}
        //   </div>
        //   {measurementsss.map((item) => (
        //     <div className="grid-table-two-cols" key={item.title}>
        //       <div className="colss">{item.title}</div>
        //       <div className="colss">{item.cm}</div>
        //     </div>
        //   ))}
        // </div>
        <div className="scrollTable">
          <table className="data-table">
            <thead>
              <tr>
                <td>Misurazioni</td>
                {measurementsss.map((item, index) => (
                  <td>{item.title}</td>
                ))}
              </tr>
            </thead>
            <tbody style={{ background: "#1A1A1A" }}>
              <tr>
                <td>{"Misura"}</td>
                {measurementsss &&
                  measurementsss?.map((value, ind) => {
                    return <td key={ind}>{value?.cm}</td>;
                  })}
              </tr>
            </tbody>
          </table>
        </div>
      ) : type === "wellBeing" ? (
        // <div className="gridd">
        //   <div className="grid-table-two-cols-head head">
        //     <div className="head-col">Benessere</div>
        //     <div className="head-col">Misura</div>
        //     {/* Other header columns */}
        //   </div>
        //   {wellBeing.map((item) => (
        //     <div className="grid-table-two-cols" key={item.title}>
        //       <div className="colss">{item.title}</div>
        //       <div className="colss">{item.cm}</div>
        //     </div>
        //   ))}
        // </div>
        <div className="scrollTable">
          <table className="data-table">
            <thead>
              <tr>
                <td>Benessere</td>
                {wellBeing.map((item, index) => (
                  <td>{item.title}</td>
                ))}
              </tr>
            </thead>
            <tbody style={{ background: "#1A1A1A" }}>
              <tr>
                <td>{"Misura"}</td>
                {wellBeing &&
                  wellBeing?.map((value, ind) => {
                    return <td key={ind}>{value?.cm}</td>;
                  })}
              </tr>
            </tbody>
          </table>
        </div>
      ) : (
        // <div className="gridd overflow">
        //   <div className="grid-table-two head">
        //     <div className="head-col">Domanda</div>
        //     {atheleteData?.map((value, index) => {
        //       return <div className="head-col">{value["month/date"]}</div>;
        //     })}
        //   </div>
        //   {data.map((item, index) => (
        //     <div className="grid-table-two" key={index}>
        //       <div className="colss">{item.meal}</div>
        //       {atheleteData?.map((value, ind) => {
        //         return (
        //           <div className="colss" key={ind}>
        //             {value[item.property]}
        //           </div>
        //         );
        //       })}
        //     </div>
        //   ))}
        // </div>
        <div className="scrollTable">
          <table className="data-table">
            <thead>
              <tr>
                {data.map((item, index) => (
                  <td>{item.meal}</td>
                ))}
              </tr>
            </thead>
            <tbody style={{ background: "#1A1A1A" }}>
              {atheleteData &&
                atheleteData?.map((value, ind) => {
                  return (
                    <tr key={ind}>
                      <td>{value["month/date"]}</td>{" "}
                      <td>{value["feeling_hungry"]}</td>{" "}
                      <td>
                        {value["how_closely_did_you_follow_the_diet_plan"]}
                      </td>{" "}
                      <td>{value["level_of_gut_health"]}</td>{" "}
                      <td>{value["mood_and_energy_level"]}</td>{" "}
                      <td>{value["hours_of_sleep"]}</td>{" "}
                      <td>{value["how_has_your_performance_changed"]}</td>{" "}
                      <td>{value["personal_notes"]}</td>{" "}
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};

export default GridTableMeasurement;
