import React, { useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";

function SevenStep({}) {
  const [countdown, setCountdown] = useState(3);
  const [showVideo, setShowVideo] = useState(false);
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate("/user/home");
  };

  return (
    <div>
      <div className="timer" id="scheda-allenamento">
        <div className="title" style={{ marginTop: -300 }}>
          All set!
        </div>
        <div style={{ textAlign: "center" }}>
          Grazie per aver risposto a tutte le
          <br /> domande
        </div>

        <button
          type="button"
          className="button-default-no-icon"
          onClick={() => {
            handleBackClick();
          }}
          style={{ width: 300, position: "absolute", bottom: 30 }}
        >
          cominciamo
        </button>
      </div>
    </div>
  );
}

export default SevenStep;
