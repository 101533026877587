import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { LeftArrowIcon } from "../Reuseable/Icons";
import Thumbnail from "../../assets/img/thumbnail.jpg";
import "./TrainingStepOne.css";
import { useDispatch, useSelector } from "react-redux";
import { RiKeyboardLine } from "react-icons/ri";
import { setReset } from "../../redux/actions";
import { notify } from "../util/notify";
import axios from "axios";
import { BASE_URL } from "../../assets/constants";

const getCurrentDateForFilter = () => {
  const date = new Date();
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const day = String(date.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
};

const TrainingFirstStep = ({
  onButtonClick,
  completed,
  getTrainingListData,
  onSelectedDescriptionChange,
}) => {
  const dispatch = useDispatch();
  const getStatus = useSelector(
    (state) => state?.trainingReducer?.trainingCompleted
  );
  const adminData = JSON.parse(localStorage.getItem("adminData"));
  const token = adminData?.token;
  const currentDate = getCurrentDateForFilter();

  // useEffect(() => {
  //   dispatch(setReset());
  // }, []);

  const navigate = useNavigate();
  const [selectedDescription, setSelectedDescription] = useState("");
  const [trainingData, setTrainingData] = useState([]);

  const handleBackClick = () => {
    navigate(-1);
  };

  const getCurrentDate = () => {
    const today = new Date();
    const options = {
      weekday: "long",
      month: "long",
      day: "numeric",
    };
    const dateStr = today.toLocaleDateString("it-IT", options);
    const parts = dateStr.split(" ");
    return `${parts[0]}, ${parts[2]} ${parts[1]}`;
  };

  const getWeekOfMonth = (date) => {
    const startWeekDayIndex = 1;
    const firstDate = new Date(date.getFullYear(), date.getMonth(), 1);
    const firstDay = firstDate?.getDay();
    const offsetDate = date.getDate() + firstDay - startWeekDayIndex;
    return Math.ceil(offsetDate / 7);
  };

  const getWeeksInMonth = (date) => {
    const firstDate = new Date(date.getFullYear(), date.getMonth(), 1);
    const lastDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    const used = firstDate.getDay() + lastDate.getDate();
    return Math.ceil(used / 7);
  };

  const extractWeek = (descrizione) => {
    const match = descrizione?.match(/Settimana \d+/);
    return match ? match[0] : "Settimana 1";
  };

  const extractDay = (descrizione) => {
    const match = descrizione?.match(/Giorno \d+/);
    return match ? match[0] : "Giorno 1";
  };

  const today = new Date();
  const currentWeek = getWeekOfMonth(today);
  const totalWeeks = getWeeksInMonth(today);

  const handleButtonClick = (excerciseId) => {
    // onSelectedDescriptionChange(selectedDescription);
    navigate(`/user/training/step-two/${excerciseId}`);
  };

  const uniqueItems = Array.from(
    new Map(
      getTrainingListData?.map((item, index) => [
        `${extractDay(item?.descrizione)} ${extractWeek(item?.descrizione)}`,
        { item, index },
      ])
    ).values()
  );

  const isDisabled = (index, date) => {
    if (index === 0) return false;
    const previousDate = uniqueItems[index - 1]?.item.data;
    return !getStatus?.some((status) => status.date === previousDate);
  };

  const handleItemClick = (index) => {
    const selectedItemData = uniqueItems[index]?.item.data;
    const isCompleted = completed.some(
      (item) => item.data_res == selectedItemData
    );

    if (!isCompleted) {
      notify(true, "Oops! No exercise available");

      return;
    }
    localStorage.setItem("selectedItemIndex", index);
    setSelectedDescription(selectedItemData);
    onSelectedDescriptionChange(selectedItemData);
  };

  const getTrainingData = async () => {
    try {
      const response = await axios.get(`${BASE_URL}athlete/training`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setTrainingData(response?.data?.trainings);
    } catch (error) {}
  };

  useEffect(() => {
    getTrainingData();
  }, []);

  return (
    <>
      <div id="scheda-allenamento">
        <div className="top-bar">
          <button onClick={handleBackClick} className="icon">
            <LeftArrowIcon />
          </button>
          <div className="title">Allenamento</div>
        </div>

        <div className="step-indicator breadcrumb">
          <div className="title">{getCurrentDate()}</div>
          <div className="step-area">
            {Array.from({ length: totalWeeks }, (_, index) => (
              <div
                key={index}
                className={`step ${index + 1} ${index === 0 ? "active" : ""}`}
              ></div>
            ))}
          </div>
          <div className="description">
            Settimana 1 out of {trainingData?.length}
          </div>
        </div>

        <section className="weeksData">
          {trainingData?.map((value, index) => {
            return (
              <div className="trainingBox" key={index}>
                <h3>Settimana {index + 1}</h3>
                <div
                  className=""
                  style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(3, 1fr)",
                    gap: 10,
                  }}
                >
                  {value?.training_section_session?.map((val, ind) => {
                    return (
                      <section
                        className="excerciseCard"
                        onClick={() =>
                          val?.status == "pending" &&
                          val?.date === currentDate &&
                          handleButtonClick(val?.id)
                        }
                        key={ind}
                      >
                        <p>{val?.name}</p>
                        {val?.status !== "pending" && (
                          <div>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="25"
                              height="25"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <path
                                stroke="#a0fc6e"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M4 12.611L8.923 17.5 20 6.5"
                              ></path>
                            </svg>
                          </div>
                        )}
                      </section>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </section>

        {/* <button
          type="button"
          className="button-default-no-icon"
          onClick={handleButtonClick}
        >
          inizia l’allenamento di oggi
        </button> */}
      </div>
    </>
  );
};

export default TrainingFirstStep;
