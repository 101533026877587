import React, { useState, useEffect } from "react";
import axios from "axios";
import "../Users/Recipes.css";
import { LeftArrowIcon } from "../Reuseable/Icons";
import { BASE_URL } from "../../assets/constants";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { notify } from "../util/notify";

const formatDate = (date) => {
  if (!date) return "";

  const options = { day: "2-digit", month: "2-digit", year: "numeric" };
  const formattedDate = new Intl.DateTimeFormat("en-GB", options).format(date);

  return formattedDate.replace(/\//g, "-");
};

const AdminAtheletStepTwo = ({ onClick }) => {
  const [activeTab, setActiveTab] = useState(0);
  const [foodData, setFoodData] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedItems, setSelectedItems] = useState({
    Carbohydrate: [],
    Protein: [],
    Fat: [],
    Fiber: [],
  });
  const [tabSelection, setTabSelection] = useState({
    Breakfast: [],
    Lunch: [],
    Dinner: [],
    Snacks: [],
  });
  const [isTabDisabled, setIsTabDisabled] = useState(false);

  const { state } = useLocation();
  const navigate = useNavigate();
  const selectedAthlete = state?.selectedAthlete;
  const selectedDate = formatDate(state?.date) || state?.selectedDate;
  let selectedItem = state?.selectedItem || {};
  const adminData = JSON.parse(localStorage.getItem("adminData"));
  const token = adminData?.data?.token;

  let dietTypes = ["Breakfast", "Lunch", "Dinner", "Snack"];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${BASE_URL}admin/diet-type`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const apiData = response.data;

        const transformedData = [
          ...apiData.carbohydrate.map((item) => ({
            ...item,
            label: "Carbohydrate",
          })),
          ...apiData.protein.map((item) => ({ ...item, label: "Protein" })),
          ...apiData.fat.map((item) => ({ ...item, label: "Fat" })),
          ...apiData.fiber.map((item) => ({ ...item, label: "Fiber" })),
        ];

        setFoodData(transformedData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleCheckboxChange = (label, item) => {
    setSelectedItems((prevSelectedItems) => {
      const itemsInCategory = prevSelectedItems[label] || [];
      const itemExists = itemsInCategory.some(
        (selectedItem) => selectedItem.id === item.id
      );

      const updatedItemsInCategory = itemExists
        ? itemsInCategory.filter((selectedItem) => selectedItem.id !== item.id)
        : [...itemsInCategory, item];

      const updatedSelectedItems = {
        ...prevSelectedItems,
        [label]: updatedItemsInCategory,
      };

      const isAnySelected = Object.values(updatedSelectedItems).some(
        (categoryItems) => categoryItems.length > 0
      );

      setIsTabDisabled(isAnySelected);

      const allItemsSelected = foodData.every((foodItem) =>
        updatedSelectedItems[foodItem.label]?.some(
          (selectedItem) => selectedItem.id === foodItem.id
        )
      );
      setSelectAll(allItemsSelected);

      return updatedSelectedItems;
    });

    setTabSelection((prevSelection) => {
      const currentTab = ["Breakfast", "Lunch", "Dinner", "Snack"][activeTab];
      const itemsInMeal = prevSelection[currentTab] || [];
      const itemExistsInMeal = itemsInMeal.some(
        (selectedItem) => selectedItem.id === item.id
      );

      const updatedMealItems = itemExistsInMeal
        ? itemsInMeal.filter((selectedItem) => selectedItem.id !== item.id)
        : [...itemsInMeal, item];

      return {
        ...prevSelection,
        [currentTab]: updatedMealItems,
      };
    });
  };
  const validateTabSelection = () => {
    const { Breakfast, Lunch, Dinner, Snacks } = tabSelection;

    if (
      Breakfast.length === 0 &&
      Lunch.length === 0 &&
      Dinner.length === 0 &&
      Snacks.length === 0
    ) {
      return false;
    }

    return true;
  };
  const handleTabClick = (index) => {
    if (!isTabDisabled) {
      setActiveTab(index);
    }
  };
  function isEmpty(obj) {
    return Object.keys(obj).length === 0;
  }
  const onButtonClick = () => {
    if (!validateTabSelection()) {
      notify(true, "Seleziona le risorse nutrizionali..!");
      return;
    }
    let obj = {
      selectedAthlete,
      selectedDate,
      tabSelection,
      dietTypes: dietTypes[activeTab],
    };
    navigate("/admin/atlet/step-three", { state: obj });
  };

  const handleCheckboxChangeAll = () => {
    setSelectAll(!selectAll);

    const currentTab = ["Breakfast", "Lunch", "Dinner", "Snacks"][activeTab];

    if (!selectAll) {
      const allSelected = foodData.reduce((acc, item) => {
        const itemsInCategory = acc[item.label] || [];
        acc[item.label] = [...itemsInCategory, item];
        return acc;
      }, {});
      setSelectedItems(allSelected);
      setIsTabDisabled(true);

      setTabSelection((prevSelection) => {
        return {
          ...prevSelection,
          [currentTab]: foodData,
        };
      });
    } else {
      setSelectedItems({});
      setIsTabDisabled(false);

      setTabSelection((prevSelection) => ({
        ...prevSelection,
        [currentTab]: [],
      }));
    }
  };

  useEffect(() => {
    if (!isEmpty(selectedItem)) {
      let Carbohydrate = selectedItem[dietTypes[activeTab]]?.filter(
        (item) => item?.type == "carbohydrate"
      );
      let Fat = selectedItem[dietTypes[activeTab]]?.filter(
        (item) => item?.type == "fat"
      );
      let Fiber = selectedItem[dietTypes[activeTab]]?.filter(
        (item) => item?.type == "fiber"
      );
      let Protein = selectedItem[dietTypes[activeTab]]?.filter(
        (item) => item?.type == "protein"
      );

      setSelectedItems({ Carbohydrate, Fat, Fiber, Protein });
      setTabSelection(selectedItem);
      let checkingArr = [...Protein, ...Carbohydrate, ...Fat, ...Fiber];
      if (foodData?.length === checkingArr?.length) {
        setSelectAll(true);
      }
    }
  }, [foodData]);

  return (
    <section className="nutrition-sources">
      <div className="top-bar">
        <button className="icon" onClick={() => navigate(-1)}>
          <LeftArrowIcon />
        </button>
        <div className="title">Pianifica un pasto consigliato</div>
      </div>
      <div className="custom-tabs tab-mar-bottom">
        {["Breakfast", "Lunch", "Dinner", "Snacks"].map((tab, i) => (
          <div onClick={() => handleTabClick(i)} className="w-full-tab" key={i}>
            <div
              className={`custom-tab ${activeTab === i ? "active" : ""} ${
                isTabDisabled && activeTab !== i ? "disabled" : ""
              }`}
            >
              {tab}
            </div>
          </div>
        ))}
      </div>

      <div className="nutrition-sources-gap">
        <div className="title-inner">Scegli le fonti nutrizionali</div>
        <div>
          <label
            className="input-container"
            style={{ width: "fit-content" }}
            htmlFor={`square-checkbox-all`}
          >
            <input
              type="checkbox"
              className="square-checkbox"
              id={`square-checkbox-all`}
              checked={selectAll}
              onChange={handleCheckboxChangeAll}
            />
            <div className="font-key">All</div>
          </label>
        </div>
        <div className="inner">
          {foodData.length > 0 &&
            Object.entries(
              foodData.reduce((acc, item) => {
                if (!acc[item.label]) acc[item.label] = [];
                acc[item.label].push(item);
                return acc;
              }, {})
            ).map(([label, items]) => {
              return (
                <div className="fonte" key={label}>
                  <div className="title">{label}</div>
                  {items.map((item) => (
                    <label
                      className="input-container"
                      htmlFor={`square-checkbox-${item.id}`}
                      key={item.id}
                      style={{ width: "fit-content" }}
                    >
                      <input
                        type="checkbox"
                        className="square-checkbox"
                        id={`square-checkbox-${item.id}`}
                        checked={
                          selectedItems[label]?.some(
                            (selectedItem) => selectedItem.id === item.id
                          ) || false
                        }
                        onChange={() => handleCheckboxChange(label, item)}
                      />
                      <div className="font-key">{item.name}</div>
                    </label>
                  ))}
                </div>
              );
            })}
        </div>
        <br />
        <button
          className={`button-default-no-icon ${
            Object.keys(selectedItems).length === 0 ? "deactive" : ""
          }`}
          onClick={onButtonClick}
        >
          continua
        </button>
      </div>
    </section>
  );
};

export default AdminAtheletStepTwo;
