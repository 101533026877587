import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  BarElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend
);

const BarChart = ({ chartData }) => {
  let label = chartData?.map((value) => value.month);
  let follower = chartData?.map((value) =>
    value?.follower == 0 ? 0.09 : value.follower
  );
  let lost = chartData?.map((value) => (value?.lost == 0 ? 0.09 : value?.lost));
  const data = {
    labels: chartData
      ? label
      : ["GEN", "FEB", "MAR", "APR", "MAG", "GIU", "LUG", "AGO"],
    datasets: [
      {
        label: "Nuovi clienti",
        data: chartData ? follower : [0], // Replace with your data
        backgroundColor: "#41B104",
        borderWidth: 1,
      },
      {
        label: "clienti persi",
        data: chartData ? lost : [0], // Replace with your data
        backgroundColor: "#DA1E28",
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false, // Hides the legend
      },
      title: {
        display: false, // Hides the title
      },
    },
    scales: {
      x: {
        ticks: {
          color: "#fff", // White color for x-axis labels
          font: {
            size: 16,
            family: "Bebas Neue Regular",
          },
        },
      },
      y: {
        ticks: {
          color: "#fff",
          font: {
            size: 16,
            family: "Bebas Neue Regular",
          },
        },
        beginAtZero: true,
        min: 0,
        max: 10,
        stepSize: 10,
      },
    },
  };

  return (
    <div>
      <Bar data={data} options={options} />
    </div>
  );
};

export default BarChart;
