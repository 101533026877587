import React, { useState } from "react";
import SmallInput from "../Reuseable/SmallInput";
import SmallDropDown from "../Reuseable/SmallDropDown";
import Dropdown from "../Reuseable/Dropdown";
import { exceciseDropDownObj } from "../util/ExciseVideoData";

const WorkoutPlan = ({
  sessions,
  index,
  Index,
  sessionValues,
  setSessionValues,
  sessionData,
  type,
  isTemplate,
}) => {
  const [activities, setActivities] = useState([
    {
      id: 1,
      name: "Tabata",
      repetitions: "",
      rest: "",
      exercises: [{ name: "Planche", time: "", repetitions: "", load: "" }],
    },
  ]);

  const handleDropDownSelect = (
    selectedOption,
    activityIndex,
    exerciseIndex = null
  ) => {
    const updateTrainingSession = [...sessionValues.training_sections];

    if (exerciseIndex !== null) {
      updateTrainingSession[index].training_section_sessions[
        Index
      ].training_section_session_activities[
        activityIndex
      ].training_section_session_activities_exercise[exerciseIndex].name =
        selectedOption?.name;
      updateTrainingSession[index].training_section_sessions[
        Index
      ].training_section_session_activities[
        activityIndex
      ].training_section_session_activities_exercise[exerciseIndex].link =
        selectedOption.link;
    } else {
      updateTrainingSession[index].training_section_sessions[
        Index
      ].training_section_session_activities[activityIndex].name =
        selectedOption;
    }

    setSessionValues({
      ...sessionValues,

      training_sections: updateTrainingSession,
    });
  };

  const handleChange = (e, activityIndex) => {
    const { value, name } = e.target;
    const updateTrainingSession = [...sessionValues.training_sections];
    updateTrainingSession[index].training_section_sessions[
      Index
    ].training_section_session_activities[activityIndex][name] = value;

    setSessionValues({
      ...sessionValues,
      training_sections: updateTrainingSession,
    });
  };
  const handleChangeExcercise = (e, activityIndex, exerciseIndex) => {
    const { value, name } = e.target;
    const updateTrainingSession = [...sessionValues.training_sections];
    updateTrainingSession[index].training_section_sessions[
      Index
    ].training_section_session_activities[
      activityIndex
    ].training_section_session_activities_exercise[exerciseIndex][name] = value;

    setSessionValues({
      ...sessionValues,
      training_sections: updateTrainingSession,
    });
  };
  const handleCheckboxChange = (value, activityIndex, exerciseIndex) => {
    const updateTrainingSession = [...sessionValues.training_sections];
    updateTrainingSession[index].training_section_sessions[
      Index
    ].training_section_session_activities[
      activityIndex
    ].training_section_session_activities_exercise[exerciseIndex].type = value;

    setSessionValues({
      ...sessionValues,
      training_sections: updateTrainingSession,
    });
  };

  const handleInputChange = (activityId, field, value) => {
    const updatedActivities = activities.map((activity) => {
      if (activity.id === activityId) {
        return {
          ...activity,
          [field]: value,
        };
      }
      return activity;
    });
    setActivities(updatedActivities);
  };

  const handleExerciseChange = (
    activityId,
    exerciseIndex,
    field,
    value,
    activityIndex
  ) => {
    const updateTrainingSession = [...sessionValues.training_sections];
    updateTrainingSession[index].training_section_sessions[
      Index
    ].training_section_session_activities[
      activityIndex
    ].training_section_session_activities_exercise[exerciseIndex][field] =
      value;

    setSessionValues({
      ...sessionValues,
      training_sections: updateTrainingSession,
    });
  };

  const addActivity = () => {
    sessionValues.training_sections[index].training_section_sessions[
      Index
    ].training_section_session_activities.push({
      name: "",
      repatation: "",
      rest: "",
      training_section_session_activities_exercise: [
        {
          name: "",
          time: "",
          repatation: "",
          load: "",
          type: "linear",
          type_link: null,
        },
      ],
    });
    setSessionValues({
      ...sessionValues,
      training_sections: sessionValues.training_sections,
    });
  };

  const addExercise = (activityIndex) => {
    sessionValues.training_sections[index].training_section_sessions[
      Index
    ].training_section_session_activities[
      activityIndex
    ].training_section_session_activities_exercise.push({
      name: "",
      time: null,
      repatation: null,
      load: "",
      type: "linear",
      type_link: null,
    });
    setSessionValues({
      ...sessionValues,
      training_sections: sessionValues.training_sections,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const [openDropDown, setOpenDropDow] = useState(false);

  const [selectedOption, setSelectedOption] = useState("");

  const options = ["attività 1", "nuova attività", "duplica", "elimina"];

  const handleSelect = (option) => {
    setSelectedOption(option);
  };

  return (
    <div className="workout-container">
      <form onSubmit={handleSubmit}>
        {(sessionData
          ? sessions?.activities
          : sessions?.training_section_session_activities
        )?.map((activity, activityIndex) => (
          <div key={activityIndex} className="activity">
            <div className="activity-header">
              <div onClick={() => setOpenDropDow(!openDropDown)}>
                {/* <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="#fff"
                  version="1.1"
                  viewBox="0 0 32 32"
                  xmlSpace="preserve"
                >
                  <path d="M16 10c1.7 0 3-1.3 3-3s-1.3-3-3-3-3 1.3-3 3 1.3 3 3 3zM16 13c-1.7 0-3 1.3-3 3s1.3 3 3 3 3-1.3 3-3-1.3-3-3-3zM16 22c-1.7 0-3 1.3-3 3s1.3 3 3 3 3-1.3 3-3-1.3-3-3-3z"></path>
                </svg>{" "} */}
              </div>

              <span>ATTIVITÀ {activityIndex}</span>
              <span>RIPETIZIONI</span>
              <span>RIPOSO</span>
              {activity.name?.length > 0 && !type.id && !sessionData && (
                <span>Collegamento video</span>
              )}
            </div>

            {/* {openDropDown && (
              <Dropdown
                className="ml-dropdown"
                options={options}
                onSelect={handleSelect}
                type="dots-dropdown"
              />
            )} */}
            <div className="activity-details">
              <SmallInput
                // type="xs"
                name={"name"}
                onChange={(e) => handleChange(e, activityIndex)}
                val={activity.name}
                disabled={sessionData && true}
              />
              {/* <SmallDropDown
                content={activity.name}
                value={sessionData && activity.name}
                options={["Tabata", "HIIT", "Yoga"]}
                onSelect={(option) =>
                  handleDropDownSelect(activity.id, option, activityIndex)
                }
              /> */}
              <SmallInput
                type="xs"
                val={activity.repatation}
                inputType="number"
                name="repatation"
                onChange={(e) => handleChange(e, activityIndex)}
                disabled={sessionData && true}
              />
              <SmallInput
                // type="xs"
                name={"rest"}
                onChange={(e) => handleChange(e, activityIndex)}
                inputType="text"
                val={activity.rest}
                disabled={sessionData && true}
              />
              {activity.name?.length > 0 && !type.id && !sessionData && (
                <SmallInput
                  // type="xs"
                  name={"link"}
                  onChange={(e) => handleChange(e, activityIndex)}
                  inputType="text"
                  val={activity.link}
                  width={200}
                  disabled={sessionData && true}
                />
              )}
            </div>
            <div className="exercise-header">
              <div>
                {/* <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="#fff"
                  version="1.1"
                  viewBox="0 0 32 32"
                  xmlSpace="preserve"
                >
                  <path d="M16 10c1.7 0 3-1.3 3-3s-1.3-3-3-3-3 1.3-3 3 1.3 3 3 3zM16 13c-1.7 0-3 1.3-3 3s1.3 3 3 3 3-1.3 3-3-1.3-3-3-3zM16 22c-1.7 0-3 1.3-3 3s1.3 3 3 3 3-1.3 3-3-1.3-3-3-3z"></path>
                </svg>{" "} */}
              </div>

              <span>ESERCIZIO</span>
              <span>TEMPO</span>
              <span>RIPETIZIONI</span>
              <span>CARICO</span>
            </div>
            {(sessionData
              ? activity?.exercises
              : activity.training_section_session_activities_exercise
            )?.map((exercise, exerciseIndex) => {
              return (
                <div key={exerciseIndex} className="exercise">
                  <div className="exercise-details">
                    {/* <SmallInput
                      // type="xs"
                      name={"name"}
                      onChange={(e) =>
                        handleChangeExcercise(e, activityIndex, exerciseIndex)
                      }
                      val={exercise?.name}
                    /> */}
                    <SmallDropDown
                      content={exercise.name}
                      value={sessionData && exercise.name}
                      options={exceciseDropDownObj}
                      onSelect={(option) => {
                        !sessionData &&
                          handleDropDownSelect(
                            option,
                            activityIndex,
                            exerciseIndex
                          );
                      }}
                    />
                    <SmallInput
                      val={exercise.time}
                      name={"time"}
                      inputType={"text"}
                      width="120px"
                      disabled={sessionData && true}
                      onChange={(e) =>
                        handleChangeExcercise(e, activityIndex, exerciseIndex)
                      }
                    />
                    <SmallInput
                      val={exercise.repatation}
                      name={"repatation"}
                      inputType="number"
                      disabled={sessionData && true}
                      type={"number"}
                      onChange={(e) =>
                        handleChangeExcercise(e, activityIndex, exerciseIndex)
                      }
                    />
                    <SmallInput
                      val={exercise.load}
                      name={"load"}
                      disabled={sessionData && true}
                      inputType="number"
                      type={"number"}
                      onChange={(e) =>
                        handleChangeExcercise(e, activityIndex, exerciseIndex)
                      }
                    />
                    <div className="progression">
                      {/* <SmallDropDown
                      content={exercise.type}
                      value={sessionData && exercise.type}
                      options={["progressive", "linear"]}
                      onSelect={(option) =>
                        handleExerciseChange(
                          activity.id,
                          exerciseIndex,
                          "type",
                          option,
                          activityIndex
                        )
                      }
                    /> */}
                    </div>
                  </div>
                </div>
              );
            })}
            {!isTemplate && (
              <button
                className="input-btn"
                onClick={() => addExercise(activityIndex)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 24 24"
                >
                  <g>
                    <g
                      fill="none"
                      stroke="#A0FC6E"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      data-name="add"
                    >
                      <path d="M12 19L12 5"></path>
                      <path d="M5 12L19 12"></path>
                    </g>
                  </g>
                </svg>
                Aggiungi Esercizio
              </button>
            )}
          </div>
        ))}

        {/* <button className="input-btn" onClick={addActivity}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 24 24"
          >
            <g>
              <g
                fill="none"
                stroke="#A0FC6E"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                data-name="add"
              >
                <path d="M12 19L12 5"></path>
                <path d="M5 12L19 12"></path>
              </g>
            </g>
          </svg>
          Aggiungi Attività
        </button> */}
      </form>
    </div>
  );
};

export default WorkoutPlan;
