import React, { useEffect, useState } from "react";
import { LeftArrowIcon } from "../Reuseable/Icons";
import image from "../../assets/img/image@2x.png";
import clockActive from "../../assets/img/icons/clock-active.svg";
import caloriesActive from "../../assets/img/icons/calories-active.svg";
import dumbellActive from "../../assets/img/icons/dumbell-active.svg";
import musicNoteActive from "../../assets/img/icons/music-note-active.svg";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "../../assets/constants";

function TrainingSecondStep({ result, selectedData }) {
  const navigate = useNavigate();
  const coachName = localStorage.getItem("coachName");
  const { excerciseId } = useParams();
  const adminData = JSON.parse(localStorage.getItem("adminData"));
  const token = adminData?.token;

  const [excerciseData, setExcerciseData] = useState();
  const [activityData, setActivityData] = useState(null);

  const handleBackClick = () => {
    navigate(-1);
  };

  const onFullProgramClick = () => {
    navigate(-1);
  };

  const onButtonClick = () => {
    navigate(`/user/training/step-three/${excerciseId}`, {
      state: activityData,
    });
  };

  const getCurrentDate = () => {
    const today = new Date();
    const options = {
      weekday: "long",
      month: "long",
      day: "numeric",
    };
    const dateStr = today.toLocaleDateString("it-IT", options);
    const parts = dateStr.split(" ");
    return `${parts[0]}, ${parts[2]} ${parts[1]}`;
  };

  const getWeekOfMonth = (date) => {
    const startWeekDayIndex = 1;
    const firstDate = new Date(date.getFullYear(), date.getMonth(), 1);
    const firstDay = firstDate.getDay();
    const offsetDate = date.getDate() + firstDay - startWeekDayIndex;
    return Math.ceil(offsetDate / 7);
  };

  const getWeeksInMonth = (date) => {
    const firstDate = new Date(date.getFullYear(), date.getMonth(), 1);
    const lastDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    const used = firstDate.getDay() + lastDate.getDate();
    return Math.ceil(used / 7);
  };

  const today = new Date();
  const currentWeek = getWeekOfMonth(today);
  const totalWeeks = getWeeksInMonth(today);

  const getExcercise = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}athlete/training/${excerciseId}/section-sessions`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setExcerciseData(response?.data?.data);
      setActivityData(response?.data?.dataa);
    } catch (error) {}
  };

  useEffect(() => {
    getExcercise();
  }, [excerciseId]);

  return (
    <div id="scheda-allenamento">
      <div className="top-bar">
        <button onClick={handleBackClick} className="icon">
          <LeftArrowIcon />
        </button>
        <div className="title">Allenamento</div>
      </div>

      <div className="step-indicator breadcrumb">
        <div className="title">{getCurrentDate()}</div>
        <div className="step-area">
          {Array.from({ length: totalWeeks }, (_, index) => (
            <div
              key={index}
              className={`step ${index + 1} ${index === 1 ? "active" : ""}`}
            ></div>
          ))}
        </div>
        <div className="description">
          Settimana {currentWeek} out of {totalWeeks}
        </div>
      </div>
      <div className="training-step-two-flex step-gap">
        {/* <img src={excerciseData?.image} className="riposo-img" alt="" /> */}
        <img src={image} className="riposo-img" alt="" />
        <div className="riposo text">
          <div className="title">{excerciseData?.name || "---"}</div>
          <div className="subtitle">{excerciseData?.coach_name || "---"}</div>

          <div className="description">
            {excerciseData?.description || "---"}
          </div>
        </div>
        <div className="info-container">
          <div>
            <img src={clockActive} alt="" />
            {excerciseData?.total_time || "---"}
          </div>
          {/* <div>
            <img src={caloriesActive} alt="" />
            120 calorie
          </div>
          <div>
            <img src={dumbellActive} alt="" className="src" />
            Materassino
          </div>
          <div>
            <img src={musicNoteActive} alt="" />
            Afro beat, dancehall
          </div> */}
        </div>
        <button className="button-default-no-icon" onClick={onButtonClick}>
          inizia l’allenamento
        </button>
        <a
          className="button-default-no-icon outline"
          style={{ cursor: "pointer" }}
          onClick={() => navigate("/user/training/step-one")}
        >
          vedi il programma completo
        </a>
      </div>
    </div>
  );
}

export default TrainingSecondStep;
