import atletiIcon from "../assets/images/search-active.svg";
import plusIcon from "../assets/images/plus.png";
import { Link } from "react-router-dom";
import AdminCoachModal from "../components/AdminCoachModal";
import { useEffect, useState } from "react";
import icon from "../assets/img/icons/chevron-top-bottom-svgrepo-com.svg";
import editIcon from "../assets/img/icons/edit.svg";
import deleteIcon from "../assets/img/icons/delete.svg";
import calendarIcon from "../assets/img/icons/calendar.svg";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../assets/constants";
import axios from "axios";
import AddTagModal from "../components/AddTagModal";
import CreateNoteModal from "../components/CreateNoteModal";
import { toast } from "react-toastify";
import { notify } from "../components/util/notify";
import DeleteModal from "../components/DeleteModal";
import { MdOutlineCancel } from "react-icons/md";

const TaskEditor = ({
  title,
  date,
  description,
  handleEditClick,
  value,
  handleDelete,
}) => {
  const formatDate = (dateString) => {
    // Create a new Date object
    const date = new Date(dateString);

    // Format the date to "Saturday, 05 Oct"
    const options = { weekday: "long", day: "2-digit", month: "short" };
    return date.toLocaleDateString("en-US", options);
  };

  return (
    <div className="task-editor">
      <div className="title">
        {title}
        <div>
          <img
            src={editIcon}
            alt="edit icon"
            onClick={() => handleEditClick(value)}
          />
          <img
            src={deleteIcon}
            alt="delete icon"
            onClick={() => handleDelete(value)}
            style={{
              marginLeft: "10px",
              cursor: "pointer",
              width: "20px",
              height: "20px",
              filter: "invert(100%) brightness(100%)",
            }}
          />
        </div>
      </div>
      <div className="task-body">
        <img
          src={calendarIcon}
          alt="calendar icon"
          style={{ marginRight: "10px" }}
        />{" "}
        <label>{formatDate(date)}</label>
      </div>
      <div className="description">{description}</div>
    </div>
  );
};

export default function AtletiContent({ onButtonClick }) {
  const [openModal, setOpenModal] = useState(false);
  const [athelets, setAthelets] = useState([]);
  const [notes, setNotes] = useState([]);
  const [isAddTagModalOpen, setIsAddTagModalOpen] = useState(false);
  const [selectedAthleteId, setSelectedAthleteId] = useState(null);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [selectedNote, setSelectedNote] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [searchingValue, setSearchingValue] = useState("");

  const adminData = JSON.parse(localStorage.getItem("adminData"));
  const token = adminData?.data?.token;

  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();
  const activities = [
    {
      title: "Nome pasto",
      description: "Colazione",
      calorie: "80",
    },
    {
      title: "Nome pasto",
      description: "Pranzo",
      calorie: "130",
    },
    {
      title: "Nome pasto",
      description: "Spuntino",
      calorie: "24",
    },
  ];

  const activitiesTwo = [
    {
      title: "Attività1",
      date: "Lunedì, 3 lug",
      description: "Some description",
    },
    {
      title: "Attività2",
      date: "Lunedì, 3 lug",
      description: "Some description",
    },
  ];

  // const tableArr = [
  //   {
  //     name: "Atleta 1",
  //     Risulati: "n%",
  //     aderneza: "n%",
  //     Tags: [{ tag: "tag", tag: "tag", tag: "tag" }],
  //   },

  //   {
  //     name: "Atleta 2",
  //     Risulati: "n%",
  //     aderneza: "n%",
  //     Tags: [{ tag: "tag", tag: "tag", tag: "tag" }],
  //   },
  //   {
  //     name: "Atleta 3",
  //     Risulati: "n%",
  //     aderneza: "n%",
  //     Tags: [{ tag: "tag", tag: "tag", tag: "tag" }],
  //   },
  // ];

  const tableArr = [
    {
      name: "Atleta 1",
      Risulati: "n%",
      aderneza: "n%",
      Tags: [
        { tag: "In scadenza" },
        { tag: "Tag" },
        { tag: "Tag" },
        { tag: "Tag" },
      ],
    },
    {
      name: "Atleta 2",
      Risulati: "n%",
      aderneza: "n%",
      Tags: [{ tag: "Tag" }, { tag: "Tag" }, { tag: "Tag" }],
    },
    {
      name: "Atleta 3",
      Risulati: "n%",
      aderneza: "n%",
      Tags: [{ tag: "Tag" }, { tag: "Tag" }, { tag: "Tag" }],
    },
  ];

  const handleDelete = async (value) => {
    setIsLoading(true);
    try {
      await axios.delete(`${BASE_URL}coach/note-delete/${confirmationModal}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      fetchNotes();
      setConfirmationModal(false);
      setIsLoading(false);
      notify("", "Deleted Successfully..!");
    } catch (error) {}
    setIsLoading(false);
  };

  const handleEditClick = (note) => {
    setOpenModal(true);
    setSelectedNote(note);
  };

  const addTag = (athleteID) => {
    setSelectedAthleteId(athleteID);
    setIsAddTagModalOpen(true);
  };

  const handleNoteSave = () => {
    fetchNotes();
    setOpenModal(false);
  };

  const closeAddTagModal = () => {
    getAtheletes();
    setIsAddTagModalOpen(false);
  };

  const getAtheletes = async () => {
    try {
      const response = await axios.get(`${BASE_URL}coach/athletes`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setAthelets(response?.data?.data || []);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching athlete notes:", error);
    } finally {
    }
  };

  const fetchNotes = async () => {
    try {
      const response = await axios.get(`${BASE_URL}coach/note-all`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setNotes(response?.data?.data || []);
    } catch (error) {
      console.error("Error fetching athlete notes:", error);
    } finally {
    }
  };

  const handleNavigate = (id) => {
    navigate("/coach/atleti/attivita", { state: id });
  };

  const spinnerStyle = {
    border: "3px solid #f3f3f3",
    borderTop: "3px solid #a0fc6e",
    borderRadius: "50%",
    width: "30px",
    height: "30px",
    animation: "spin 1s linear infinite",
  };

  const containerStyle = {
    display: "flex",
    alignContent: "center",
    backgroundColor: "transparent",
  };

  useEffect(() => {
    getAtheletes();
    fetchNotes();
  }, []);
  const filteredDataAthlete = athelets?.filter((value) =>
    value?.name?.toLowerCase().includes(searchingValue.toLowerCase())
  );

  return (
    <div className="AtletiContent">
      <div className="topBar">
        <img
          className="searchIcon"
          src={atletiIcon}
          onClick={() => {
            setIsSearching(!isSearching);
            setSearchingValue("");
          }}
          alt="Atleti"
        />
        {isSearching ? (
          <div
            className="form-div"
            style={{
              display: "flex",
              flexDirection: "row",
              position: "relative",
            }}
          >
            <input
              type="text"
              placeholder="Cerca qui...!"
              className="form-input pe-3"
              value={searchingValue}
              name="name"
              onChange={(e) => setSearchingValue(e.target.value)}
              style={{ marginBottom: "20px", marginLeft: 5, marginTop: 10 }}
            />
            <MdOutlineCancel
              style={{
                position: "absolute",
                right: 0,
                top: 20,
                fontSize: 18,
                cursor: "pointer",
              }}
              onClick={() => setSearchingValue("")}
            />
          </div>
        ) : (
          <span>I TUOI ATLETI</span>
        )}
      </div>
      <div className="content-visibility">
        <div className="content-columns">
          <div className="content-center">
            <div className="table">
              <table className="admin-custom-table">
                <thead>
                  <tr>
                    <th className="border-right">
                      <div className="header-cell">
                        <div>Name</div>
                        {/* <img src={icon} className="header-icon" /> */}
                      </div>
                    </th>
                    <th className="border-right">
                      <div className="header-cell">
                        <div>Risulati</div>
                        {/* <img src={icon} className="header-icon" /> */}
                      </div>
                    </th>
                    <th className="border-right">
                      <div className="header-cell">
                        <div>Aderneza</div>
                        {/* <img src={icon} className="header-icon" /> */}
                      </div>
                    </th>
                    <th>Tags</th>
                  </tr>
                </thead>

                <tbody>
                  {/* {tableArr.map((item, i) => (
                    <tr
                      key={i}
                      className={i % 2 === 0 ? "bg-color" : "bg-none"}
                      onClick={handleNavigate}
                    >
                      <td>{item.name}</td>
                      <td>{item.Risulati}</td>
                      <td>{item.aderneza}</td>
                      {item.Tags.map((tag) => (
                        <td>
                          <label>{tag.tag}</label>
                          <label>{tag.tag}</label>
                          <label>{tag.tag}</label>
                        </td>
                      ))}
                    </tr>
                  ))} */}

                  {loading ? (
                    <tr style={containerStyle}>
                      <td style={spinnerStyle}></td>
                      <style>
                        {`
              @keyframes spin {
                0% { transform: rotate(0deg); }
                100% { transform: rotate(360deg); }
              }
            `}
                      </style>
                    </tr>
                  ) : (
                    filteredDataAthlete?.map((item, i) => (
                      <tr
                        key={i}
                        className={i % 2 === 0 ? "bg-color" : "bg-none"}
                      >
                        <td
                          onClick={() => handleNavigate(item)}
                          style={{ cursor: "pointer" }}
                        >
                          {item.name}
                        </td>
                        <td>{item.result}</td>
                        <td>{item.adherence}</td>
                        <td
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          {item?.tags?.length > 0 ? (
                            <>
                              {item.tags.map((tag, index) => (
                                <label
                                  key={index}
                                  style={{
                                    background:
                                      item.name === "Atleta 1" && index === 0
                                        ? "red"
                                        : "#0441B1",
                                  }}
                                >
                                  {tag.tag_name}
                                </label>
                              ))}
                            </>
                          ) : (
                            <div>No tags</div>
                          )}
                          <img
                            src={plusIcon}
                            alt="Add tag"
                            style={{
                              marginLeft: "8px",
                              marginTop: "8px",
                              cursor: "pointer",
                              filter: "invert(100%) brightness(100%)",
                              width: "15px",
                              height: "15px",
                            }}
                            onClick={() => addTag(item.id)}
                          />
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <DeleteModal
            isOpen={confirmationModal}
            onCancel={() => setConfirmationModal(false)}
            onConfirm={handleDelete}
            message="Sei sicuro di voler eliminare questa nota?"
            isLoading={isLoading}
          />
          <div className="content-right-sidebar-container">
            <div className="right-sidebar">
              <div className="sub---title">calendario attività</div>
              {notes?.length > 0
                ? notes?.map((activity, index) => (
                    <TaskEditor
                      key={index}
                      title={activity.title}
                      date={activity.date}
                      description={activity.description}
                      handleEditClick={handleEditClick}
                      value={activity}
                      handleDelete={() => setConfirmationModal(activity?.uuid)}
                    />
                  ))
                : "Nessuna attivita nel calendario"}
              <a
                href="#"
                onClick={() => {
                  setOpenModal(true);
                  setSelectedNote(null);
                }}
                className="button-default-no-icon"
              >
                <img src={plusIcon} alt="plus icon" />
                <p>aggiungi nuova</p>
              </a>
            </div>{" "}
          </div>

          <CreateNoteModal
            isOpen={openModal}
            onClose={() => setOpenModal(false)}
            token={token}
            selectedNote={selectedNote}
            onSave={handleNoteSave}
            tableArr={athelets}
            coachAthelete
            userId={selectedNote?.id}
          />
        </div>
      </div>

      <div className="admin-coach-mobile">
        <div>
          <div className="admin-coach-select">
            <select className="">
              <option className="">filtra per: tag 1</option>

              <option className="">athlete 1</option>
              <option className="">athlete 2</option>
              <option className="">athlete 3</option>
            </select>
            <select className="">
              <option className="">filtra per: tag 2</option>

              <option className="">athlete 1</option>
              <option className="">athlete 2</option>
              <option className="">athlete 3</option>
            </select>
          </div>
        </div>
        {tableArr.map((item, index) => (
          <div className="admin-coach-mobile-flex" key={index}>
            <div>
              <p className="mobile-card-para">{item.name}</p>
              <div className="mobile-tags">
                <p>Tag</p>
                <p>Tag</p>
              </div>

              <div className="card-flex">
                <div>
                  {" "}
                  <p>
                    Risulati <span>{item.Risulati}</span>
                  </p>
                </div>
                <div>
                  {" "}
                  <p>
                    aderneza <span>{item.aderneza}</span>
                  </p>
                </div>
              </div>
            </div>
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="#a0fc6e"
                version="1.1"
                viewBox="0 0 24 24"
                xmlSpace="preserve"
              >
                <path d="M6.8 23.7L5.4 22.3 15.7 12 5.4 1.7 6.8 0.3 18.5 12z"></path>
              </svg>
            </div>
          </div>
        ))}
      </div>
      {isAddTagModalOpen && (
        <AddTagModal
          isOpen={isAddTagModalOpen}
          athleteId={selectedAthleteId}
          onClose={closeAddTagModal}
          athleteTag
          coachAthelete
        />
      )}
    </div>
  );
}
