import React, { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import dishImg from "../../assets/images/Farfalle.png";
import { MdCancel } from "react-icons/md";

const ItemTypes = {
  ITEM: "item",
};

const RearrangeableItem = ({ data, index, moveItem, handleCancel, icon }) => {
  const ref = useRef(null);

  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.ITEM,
    item: { index },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  const [, drop] = useDrop({
    accept: ItemTypes.ITEM,
    hover: (item, monitor) => {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      moveItem(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  drag(drop(ref));

  return (
    <div
      ref={ref}
      className="card-food example"
      style={{
        // marginBottom: "10px",
        opacity: isDragging ? 0.5 : 1,
      }}
    >
      <div className="dish-icon" style={{ position: "relative" }}>
        <MdCancel
          style={{
            color: "green",
            fontSize: 20,
            position: "absolute",
            top: -6,
            right: -10,
            zIndex: 999,
            cursor: "pointer",
          }}
          onClick={() => handleCancel(data)}
        />
        <img src={icon} width={"50px"} alt={data.name} />
      </div>
      <div className="text">{data.name}</div>
    </div>
  );
};

export default RearrangeableItem;
