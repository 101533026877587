import React, { useEffect, useState } from "react";
import axios from "axios";

// Components
import NutritionStepOne from "./NutritionStepOne";
import NutritionStepTwo from "./NutritionStepTwo";
import NutritionStepThree from "./NutritionStepThree";
import NutritionStepFour from "./NutritionStepFour";
import { useDispatch, useSelector } from "react-redux";
import { foodList, mealData } from "../../redux/actions";
import { useNavigate } from "react-router-dom";
import { BASEURL } from "../../assets/constants";
import NutritionNewStep2 from "./NutritionNewStep2";
import NutritionNewStep3 from "./NutritionnewStep3";
import NutritionNewStep4 from "./NutritionNewStep4";
import NutritionNewStep5 from "./NutritionNewStep5";

function Nutrition() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userData = useSelector((state) => state?.userReducer?.data);
  const title = userData?.Fabbisogno_Giornaliero;
  const meal = userData?.Progressione;
  const suggested = userData?.Suggerite;
  const yourRecipe = userData?.your_recipe;
  const label = userData?.label;
  const token = localStorage.getItem("token");
  const userId = localStorage.getItem("userID");

  const [foodData, setFoodData] = useState(null);

  useEffect(() => {
    const fetchFoodData = async () => {
      try {
        const response = await axios.post(
          `${BASEURL}/services/users/meals/getMacroFoodList.php`,
          {
            token,
            idutente: userId,
          }
        );
        setFoodData(response?.data);
      } catch (error) {
        console.error("Error fetching food data:", error);
      }
    };

    if (token) {
      fetchFoodData();
    }
  }, [token]);

  // useEffect(() => {
  //   if (!token) {
  //     navigate("/login");
  //   }
  // }, []);

  useEffect(() => {
    const data = {
      token,
      idutente: userId,
    };
    dispatch(mealData(data));
  }, []);

  const [step, setStep] = useState(1);

  const goToNextStep = async () => {
    // retrunFuncValue();

    setStep((step) => step + 1);
  };

  const renderStep = () => {
    switch (step) {
      case 1:
        return <NutritionStepOne onButtonClick={goToNextStep} meal={meal} />;
      case 2:
        return (
          <NutritionNewStep2 onButtonClick={goToNextStep} />
          // <NutritionStepTwo
          //   onButtonClick={goToNextStep}
          //   title={title}
          //   suggested={suggested}
          //   yourRecipe={yourRecipe}
          // />
        );
      case 3:
        return (
          <NutritionNewStep3
            onButtonClick={goToNextStep}
            title={title}
            suggested={suggested}
            yourRecipe={yourRecipe}
          />
          // <NutritionStepThree
          //   onButtonClick={goToNextStep}
          //   label={label}
          //   foodData={foodData}
          // />
        );
      case 4:
        return (
          <NutritionNewStep4
            onButtonClick={goToNextStep}
            title={title}
            suggested={suggested}
            yourRecipe={yourRecipe}
          />
          // <NutritionStepThree
          //   onButtonClick={goToNextStep}
          //   label={label}
          //   foodData={foodData}
          // />
        );
      case 5:
        return (
          // <NutritionStepFour foodData={foodData} />
          <NutritionNewStep5
            onButtonClick={goToNextStep}
            title={title}
            suggested={suggested}
            yourRecipe={yourRecipe}
          />
          // <NutritionStepThree
          //   onButtonClick={goToNextStep}
          //   label={label}
          //   foodData={foodData}
          // />
        );

      // <NutritionStepFour foodData={foodData} />;
      default:
        return <NutritionStepOne onButtonClick={goToNextStep} />;
    }
  };

  return <div>{renderStep()}</div>;
}

export default Nutrition;
