// src/components/Dashboard.js
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import Header from "../Layout/Header";
import SideBar from "./SideBar";
import MainContent from "./MainContent";
import Footer from "./Footer";
import "./Dashboard.css";
import CoachContent from "./CoachContent";
import CoachSidebar from "../sidebar";
import AdminContent from "./AdminContent";
import AdminSideBar from "./AdminSideBar";

const Dashboard = ({ content, coachContent, adminContent }) => {
  const location = useLocation();
  const [bol, setBol] = useState(false);

  const getActiveOption = () => {
    const path = location.pathname;
    if (path.includes("/user/home")) return "Home";
    if (path.includes("/user/pre-allenamento-giorno-on")) return "Allenamento";
    if (path.includes("/user/nutrizione")) return "Nutrizione";
    if (path.includes("/user/profilo")) return "Profilo";
    if (path.includes("/user/aggiorn-le-misurazioni")) return "newui";

    if (path.includes("/coach/home")) return "Atleti";
    if (path.includes("/coach/atleti/attivita")) return "Atleti";
    if (path.includes("/coach/atleti/nutrizione")) return "Atleti";
    if (path.includes("/coach/atleti/allenamento")) return "Atleti";
    if (path.includes("/coach/calendario")) return "Calendario";
    if (path.includes("/coach/note")) return "Note";
    if (path.includes("/coach/profilo")) return "Profilo";

    if (path.includes("/admin/atleti")) return "Atleti";
    if (path.includes("/admin/coach")) return "Coach";
    if (path.includes("/admin/adminScheda")) return "Coach";
    if (path.includes("/admin/calender")) return "Calendario";
    if (path.includes("/admin/note")) return "Note";
    if (path.includes("/admin/profile")) return "Profilo";

    return "Home";
  };

  return (
    <div className="dashboard">
      <Header />
      {adminContent ? (
        <AdminSideBar activeOption={getActiveOption()} />
      ) : coachContent ? (
        <CoachSidebar activeOption={getActiveOption()} />
      ) : (
        !bol && <SideBar activeOption={getActiveOption()} />
      )}

      {adminContent ? (
        <AdminContent adminContent={adminContent} />
      ) : coachContent ? (
        <CoachContent coachContent={coachContent} />
      ) : (
        <MainContent content={content} retrunFuncValue={(v) => setBol(v)} />
      )}
      <Footer />
    </div>
  );
};

export default Dashboard;
