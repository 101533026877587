import React from "react";
import { privacyData } from "./util/data";

function Privacy() {
  return (
    <div
      style={{ padding: 10 }}
      dangerouslySetInnerHTML={{ __html: privacyData }}
    />
  );
}

export default Privacy;
