import React, { useEffect, useState } from "react";
import MeasurementTables from "./MeasurementTables";
import DashboardCard from "./DashboardCard";
import AtletiHeader from "../Reuseable/AtletiHeader";
import Dropdown from "../Reuseable/Dropdown";
import { useLocation, useParams } from "react-router-dom";
import { BASE_URL } from "../../assets/constants";
import axios from "axios";

const AdminAtheletStepSeven = ({ onClick }) => {
  const adminData = JSON.parse(localStorage.getItem("adminData"));
  const token = adminData?.data?.token;
  const { atheleteId } = useParams();
  const [selectedOption, setSelectedOption] = useState("utlimi 3 mesi");
  const [atheleteData, setAtheleteData] = useState();

  const options = [
    "utlimi 3 mesi",
    "utlimi 3 mesi",
    "utlimi 3 mesi",
    "utlimi 3 mesi",
  ];

  const handleSelect = (option) => {
    setSelectedOption(option);
  };

  const fetchAthelete = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}admin/${atheleteId}/profile`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setAtheleteData(response?.data);
    } catch (error) {
      console.error("Error fetching athlete :", error);
    } finally {
    }
  };

  useEffect(() => {
    fetchAthelete();
  }, []);

  return (
    <>
      <AtletiHeader atheleteId={atheleteId} />
      <div>
        <div className="measurment-container">
          <h1 className="Progressi-title">Progressi</h1>

          {/* <Dropdown
            options={options}
            onSelect={handleSelect}
            btn="utlimi 3 mesi"
            bgList="true"
            content={selectedOption}
          /> */}

          <div className="measurment-flex">
            <div className="measurment-flex-one">
              <div className="dashboard-container">
                <DashboardCard
                  value={`${atheleteData?.progress?.user || 0} mesi`}
                  label="LIFE TIME VALUE"
                  type="noRation"
                />
                <DashboardCard
                  value={atheleteData?.progress?.session_days || 0}
                  label="GIORNI DI SESSIONE"
                  type="noRation"
                />
                <DashboardCard
                  value={atheleteData?.progress?.inactivity || 0}
                  label="GIORNI DI INATTIVITÀ"
                  type="noRation"
                />
                <DashboardCard
                  value={atheleteData?.progress?.use || "00:00 H"}
                  label="utilizzo"
                  type="noRation"
                />
              </div>
              {/* <div className="measurment-flex-cards">
                <div className="measurment-card">
                  <div className="value">
                    {atheleteData?.progress?.use || "00:00 H"}
                  </div>
                  <div className="label">utilizzo </div>
                </div>
                <div className="measurment-card">
                  <div className="value">€1750</div>
                  <div className="label">spesa media </div>
                </div>
              </div> */}
            </div>
            {/* <div className="measurment-flex-two">
              <div className="measurment-flex-two-img">
                <svg
                  width="261"
                  height="261"
                  viewBox="0 0 263 204"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <mask id="path-1-inside-1_201_14549" fill="white">
                    <path d="M262.346 131.048C262.346 96.3079 248.546 62.9909 223.981 38.4261C199.416 13.8614 166.1 0.0610378 131.36 0.0610352C96.6199 0.0610325 63.3029 13.8614 38.7382 38.4261C14.1734 62.9909 0.373052 96.3078 0.373047 131.048H11.9785C11.9785 99.3858 24.5561 69.0207 46.9444 46.6324C69.3327 24.2441 99.6978 11.6664 131.36 11.6665C163.022 11.6665 193.387 24.2441 215.775 46.6324C238.163 69.0207 250.741 99.3858 250.741 131.048H262.346Z" />
                  </mask>
                  <path
                    d="M262.346 131.048C262.346 96.3079 248.546 62.9909 223.981 38.4261C199.416 13.8614 166.1 0.0610378 131.36 0.0610352C96.6199 0.0610325 63.3029 13.8614 38.7382 38.4261C14.1734 62.9909 0.373052 96.3078 0.373047 131.048H11.9785C11.9785 99.3858 24.5561 69.0207 46.9444 46.6324C69.3327 24.2441 99.6978 11.6664 131.36 11.6665C163.022 11.6665 193.387 24.2441 215.775 46.6324C238.163 69.0207 250.741 99.3858 250.741 131.048H262.346Z"
                    stroke="#565656"
                    stroke-width="12.5496"
                    mask="url(#path-1-inside-1_201_14549)"
                  />
                  <mask id="path-2-inside-2_201_14549" fill="white">
                    <path d="M0.373371 131.048C0.373369 108.014 6.44719 85.3878 17.9823 65.4506C29.5173 45.5135 46.1058 28.9705 66.0745 17.4903C86.0433 6.00999 108.686 -0.00163452 131.72 0.0616264C154.753 0.124918 177.363 6.26087 197.268 17.8507L191.429 27.8799C173.287 17.317 152.681 11.7247 131.688 11.667C110.695 11.6093 90.0584 17.0883 71.8588 27.5515C53.6593 38.0146 38.5406 53.0918 28.0275 71.2625C17.5145 89.4333 11.9788 110.055 11.9788 131.048L0.373371 131.048Z" />
                  </mask>
                  <path
                    d="M0.373371 131.048C0.373369 108.014 6.44719 85.3878 17.9823 65.4506C29.5173 45.5135 46.1058 28.9705 66.0745 17.4903C86.0433 6.00999 108.686 -0.00163452 131.72 0.0616264C154.753 0.124918 177.363 6.26087 197.268 17.8507L191.429 27.8799C173.287 17.317 152.681 11.7247 131.688 11.667C110.695 11.6093 90.0584 17.0883 71.8588 27.5515C53.6593 38.0146 38.5406 53.0918 28.0275 71.2625C17.5145 89.4333 11.9788 110.055 11.9788 131.048L0.373371 131.048Z"
                    stroke="#78A7FC"
                    stroke-width="12.5496"
                    mask="url(#path-2-inside-2_201_14549)"
                  />
                  <mask id="path-3-inside-3_201_14549" fill="white">
                    <path d="M241.743 131.048C241.743 101.772 230.113 73.6961 209.412 52.9953C188.712 32.2946 160.635 20.665 131.36 20.665C102.085 20.665 74.0086 32.2946 53.3078 52.9953C32.6071 73.6961 20.9775 101.772 20.9775 131.048H32.9632C32.9632 104.951 43.33 79.9235 61.783 61.4705C80.236 43.0175 105.264 32.6507 131.36 32.6507C157.457 32.6507 182.484 43.0175 200.937 61.4705C219.39 79.9235 229.757 104.951 229.757 131.048H241.743Z" />
                  </mask>
                  <path
                    d="M241.743 131.048C241.743 101.772 230.113 73.6961 209.412 52.9953C188.712 32.2946 160.635 20.665 131.36 20.665C102.085 20.665 74.0086 32.2946 53.3078 52.9953C32.6071 73.6961 20.9775 101.772 20.9775 131.048H32.9632C32.9632 104.951 43.33 79.9235 61.783 61.4705C80.236 43.0175 105.264 32.6507 131.36 32.6507C157.457 32.6507 182.484 43.0175 200.937 61.4705C219.39 79.9235 229.757 104.951 229.757 131.048H241.743Z"
                    stroke="#565656"
                    stroke-width="12.5496"
                    mask="url(#path-3-inside-3_201_14549)"
                  />
                  <mask id="path-4-inside-4_201_14549" fill="white">
                    <path d="M220.506 131.048C220.506 107.405 211.114 84.7307 194.396 68.0128C177.678 51.2949 155.003 41.9028 131.361 41.9028C107.718 41.9028 85.0437 51.2949 68.3258 68.0128C51.6079 84.7307 42.2158 107.405 42.2158 131.048H53.8554C53.8554 110.492 62.0211 90.7783 76.5562 76.2432C91.0913 61.7081 110.805 53.5424 131.361 53.5424C151.916 53.5424 171.63 61.7081 186.165 76.2432C200.7 90.7783 208.866 110.492 208.866 131.048H220.506Z" />
                  </mask>
                  <path
                    d="M220.506 131.048C220.506 107.405 211.114 84.7307 194.396 68.0128C177.678 51.2949 155.003 41.9028 131.361 41.9028C107.718 41.9028 85.0437 51.2949 68.3258 68.0128C51.6079 84.7307 42.2158 107.405 42.2158 131.048H53.8554C53.8554 110.492 62.0211 90.7783 76.5562 76.2432C91.0913 61.7081 110.805 53.5424 131.361 53.5424C151.916 53.5424 171.63 61.7081 186.165 76.2432C200.7 90.7783 208.866 110.492 208.866 131.048H220.506Z"
                    stroke="#565656"
                    stroke-width="12.5496"
                    mask="url(#path-4-inside-4_201_14549)"
                  />
                  <mask id="path-5-inside-5_201_14549" fill="white">
                    <path d="M20.978 131.048C20.978 115.151 24.4116 99.4417 31.0439 84.9946C37.6762 70.5475 47.3508 57.7034 59.4058 47.3409C71.4608 36.9783 85.6118 29.3418 100.891 24.9537C116.17 20.5657 132.217 19.5295 147.933 21.9162L146.134 33.766C132.124 31.6385 117.819 32.5621 104.199 36.4737C90.5794 40.3853 77.9649 47.1927 67.2189 56.43C56.4728 65.6674 47.8488 77.1168 41.9366 89.9952C36.0245 102.874 32.9637 116.877 32.9637 131.048H20.978Z" />
                  </mask>
                  <path
                    d="M20.978 131.048C20.978 115.151 24.4116 99.4417 31.0439 84.9946C37.6762 70.5475 47.3508 57.7034 59.4058 47.3409C71.4608 36.9783 85.6118 29.3418 100.891 24.9537C116.17 20.5657 132.217 19.5295 147.933 21.9162L146.134 33.766C132.124 31.6385 117.819 32.5621 104.199 36.4737C90.5794 40.3853 77.9649 47.1927 67.2189 56.43C56.4728 65.6674 47.8488 77.1168 41.9366 89.9952C36.0245 102.874 32.9637 116.877 32.9637 131.048H20.978Z"
                    stroke="#B8FD92"
                    stroke-width="12.5496"
                    mask="url(#path-5-inside-5_201_14549)"
                  />
                  <mask id="path-6-inside-6_201_14549" fill="white">
                    <path d="M42.215 131.048C42.215 111.917 48.369 93.2946 59.7678 77.9311C71.1665 62.5676 87.2051 51.2785 105.514 45.7319C123.822 40.1854 143.429 40.6757 161.438 47.1303C179.446 53.585 194.901 65.6616 205.517 81.5756L195.859 88.0187C186.625 74.1773 173.184 63.6735 157.521 58.0594C141.858 52.4454 124.804 52.019 108.88 56.8432C92.9557 61.6673 79.0058 71.4862 69.0917 84.8488C59.1775 98.2115 53.8249 114.409 53.8249 131.048H42.215Z" />
                  </mask>
                  <path
                    d="M42.215 131.048C42.215 111.917 48.369 93.2946 59.7678 77.9311C71.1665 62.5676 87.2051 51.2785 105.514 45.7319C123.822 40.1854 143.429 40.6757 161.438 47.1303C179.446 53.585 194.901 65.6616 205.517 81.5756L195.859 88.0187C186.625 74.1773 173.184 63.6735 157.521 58.0594C141.858 52.4454 124.804 52.019 108.88 56.8432C92.9557 61.6673 79.0058 71.4862 69.0917 84.8488C59.1775 98.2115 53.8249 114.409 53.8249 131.048H42.215Z"
                    stroke="#022059"
                    stroke-width="12.5496"
                    mask="url(#path-6-inside-6_201_14549)"
                  />
                  <path
                    d="M5.70663 164.945C4.15885 164.945 3.00847 164.386 2.25549 163.267C1.50251 162.148 1.12602 160.595 1.12602 158.608C1.12602 156.621 1.50251 155.068 2.25549 153.949C3.00847 152.83 4.15885 152.27 5.70663 152.27C7.26488 152.27 8.41526 152.83 9.15778 153.949C9.91076 155.068 10.2872 156.621 10.2872 158.608C10.2872 160.595 9.91076 162.148 9.15778 163.267C8.41526 164.386 7.26488 164.945 5.70663 164.945ZM5.70663 163.533C6.70015 163.533 7.41652 163.126 7.85576 162.31C8.30545 161.484 8.5303 160.25 8.5303 158.608C8.5303 156.966 8.30545 155.737 7.85576 154.921C7.41652 154.095 6.70015 153.682 5.70663 153.682C4.72358 153.682 4.00721 154.095 3.55751 154.921C3.10782 155.737 2.88297 156.966 2.88297 158.608C2.88297 160.25 3.10782 161.484 3.55751 162.31C4.00721 163.126 4.72358 163.533 5.70663 163.533Z"
                    fill="white"
                  />
                  <path
                    d="M249.389 164.726H242.048V163.377H244.965V156.098H242.267V154.874L243.083 154.843C243.48 154.822 243.831 154.764 244.134 154.67C244.437 154.566 244.688 154.351 244.887 154.027C245.096 153.692 245.258 153.18 245.373 152.49H246.66V163.377H249.389V164.726ZM256.381 164.945C254.833 164.945 253.682 164.386 252.929 163.267C252.177 162.148 251.8 160.595 251.8 158.608C251.8 156.621 252.177 155.068 252.929 153.949C253.682 152.83 254.833 152.27 256.381 152.27C257.939 152.27 259.089 152.83 259.832 153.949C260.585 155.068 260.961 156.621 260.961 158.608C260.961 160.595 260.585 162.148 259.832 163.267C259.089 164.386 257.939 164.945 256.381 164.945ZM256.381 163.533C257.374 163.533 258.091 163.126 258.53 162.31C258.979 161.484 259.204 160.25 259.204 158.608C259.204 156.966 258.979 155.737 258.53 154.921C258.091 154.095 257.374 153.682 256.381 153.682C255.398 153.682 254.681 154.095 254.232 154.921C253.782 155.737 253.557 156.966 253.557 158.608C253.557 160.25 253.782 161.484 254.232 162.31C254.681 163.126 255.398 163.533 256.381 163.533Z"
                    fill="white"
                  />
                </svg>
                <div className="measurmentss">
                  <div className="measurmentss-card">
                    <div className="light-blue-clr"></div>
                    <div>Risultati fisici</div>
                  </div>
                  <div className="measurmentss-card">
                    <div className="green-clr"></div>
                    <div>Performance</div>
                  </div>
                  <div className="measurmentss-card">
                    <div className="dark-blue-clr"></div>
                    <div>Aderenza</div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
          <MeasurementTables atheleteData={atheleteData} />
        </div>
      </div>
    </>
  );
};

export default AdminAtheletStepSeven;
