import atletiIcon from "../../assets/images/search-active.svg";
import plusIcon from "../../assets/images/plus.png";
import { Link } from "react-router-dom";
import AdminCoachModal from "../../components/AdminCoachModal";
import { useEffect, useState } from "react";
import icon from "../../assets/img/icons/chevron-top-bottom-svgrepo-com.svg";
import editIcon from "../../assets/img/icons/edit.svg";
import deleteIcon from "../../assets/img/icons/delete.svg";
import calendarIcon from "../../assets/img/icons/calendar.svg";
import { MdOutlineCancel } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "../../assets/constants";
import CalendarioModal from "../CalendarioModal";
import CreateNoteModal from "../CreateNoteModal";
import AddTagModal from "../AddTagModal";
import NewActivityModal from "../NewActivityModal";
import { notify } from "../util/notify";
import DeleteModal from "../DeleteModal";

const TaskEditor = ({ title, description, calorie }) => (
  <div className="task-editor task-border ">
    <div className="title-two">{title}</div>
    <div className="task-editor-flex">
      <div className="description">{description}</div>
      <div>{calorie}kcal</div>
    </div>
  </div>
);

const TaskEditorTwo = ({
  title,
  description,
  date,
  handleEditClick,
  handleDeleteClick,
  loader,
}) => {
  const formatDate = (dateString) => {
    if (!dateString) {
      return "Invalid Date";
    }

    const [day, month, year] = dateString.split("/");

    const formattedDate = `${year}-${month}-${day}`;
    const parsedDate = new Date(formattedDate);

    if (isNaN(parsedDate)) {
      console.error("Invalid Date:", dateString);
      return "Invalid Date";
    }

    const weekday = parsedDate.toLocaleDateString("en-US", { weekday: "long" });
    const monthShort = parsedDate.toLocaleDateString("en-US", {
      month: "short",
    });

    return `${weekday}, ${day} ${monthShort}`;
  };

  return (
    <>
      {loader ? (
        <p>Loading</p>
      ) : (
        <div className="task-editor">
          <div className="title">
            {title}
            <div>
              <img
                src={editIcon}
                alt="edit iconsss"
                onClick={handleEditClick}
              />
              <img
                src={deleteIcon}
                alt="delete icon"
                onClick={handleDeleteClick}
                style={{
                  marginLeft: "10px",
                  cursor: "pointer",
                  width: "20px",
                  height: "20px",
                  filter: "invert(100%) brightness(100%)",
                }}
              />
            </div>
          </div>
          <div className="task-body">
            <img
              src={calendarIcon}
              alt="calendar icon"
              style={{ marginRight: "10px" }}
            />
            <label>{formatDate(date)}</label>
          </div>
          <div className="description">{description}</div>
        </div>
      )}
    </>
  );
};

export default function AdminAthleletStepOne({}) {
  const [openModal, setOpenModal] = useState(false);
  const navigate = useNavigate();
  const [tableArr, setTableArr] = useState([]);
  const [loading, setLoading] = useState(true);
  const [notes, setNotes] = useState([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const [isAddModal, setIsAddModal] = useState(false);
  const [isAddTagModalOpen, setIsAddTagModalOpen] = useState(false);
  const [isNewActivityModalOpen, setIsNewActivityModalOpen] = useState(false);
  const [selectedAthlete, setSelectedAthlete] = useState({
    name: "Select Athlete",
    id: 0,
  });
  const [date, setDate] = useState(null);
  const [mealData, setMealData] = useState([]);
  const [selectedNote, setSelectedNote] = useState(null);
  const [selectedAthleteId, setSelectedAthleteId] = useState(null);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [searchingValue, setSearchingValue] = useState("");

  const adminData = JSON.parse(localStorage.getItem("adminData"));
  const token = adminData?.data?.token;

  const handleEditClick = (note) => {
    setModalOpen(true);
    setSelectedNote(note);
  };

  const handleDeleteClick = async () => {
    setIsLoading(true);
    try {
      const token = adminData?.data?.token;

      await axios.delete(
        `${BASE_URL}admin/note/athlete/delete/${confirmationModal}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setConfirmationModal(false);
      fetchNotes();
    } catch (error) {
      console.error("Error deleting note:", error);
    }
    setIsLoading(false);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleCloseNoteModal = () => {
    setIsAddModal(false);
  };
  const handleDeleteAthelte = async (value) => {
    setIsLoading(true);
    try {
      const response = await axios.delete(
        `${BASE_URL}admin/delete/athlete/${confirmationModal?.id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response?.data);
      notify("", "Atleta eliminato con successo");
      fetchData();
      setConfirmationModal(false);
    } catch (error) {
      console.log("error", error);
    }
    setIsLoading(false);
  };

  const fetchNotes = async () => {
    try {
      const response = await axios.get(`${BASE_URL}admin/note/athlete/all`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setConfirmationModal(false);

      setNotes(response?.data?.data || []);
    } catch (error) {
      console.error("Error fetching athlete notes:", error);
    } finally {
      setLoading(false);
    }
  };
  const getRecommendedMealData = async () => {
    try {
      const getResponse = await axios.get(`${BASE_URL}admin/recomended-meal`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (getResponse.data.status === 200) {
        setMealData(getResponse?.data?.data);
      }
    } catch (error) {
      console.error("Error fetching session data:", error);
    }
  };
  const onButtonClick = (event) => {
    event.preventDefault();
    let obj = {
      selectedAthlete: selectedAthlete?.id,
      date,
    };
    if ((selectedAthlete, date)) {
      navigate("/admin/atlet/step-two", { state: obj });
    } else {
      notify(true, "Date Field Is Missing");
    }
  };

  useEffect(() => {
    fetchNotes();
    getRecommendedMealData();
  }, []);

  const activities = [
    {
      title: "Nome pastoss",
      description: "Colazione",
      calorie: "80",
    },
    {
      title: "Nome pasto",
      description: "Pranzo",
      calorie: "130",
    },
    {
      title: "Nome pasto",
      description: "Spuntino",
      calorie: "24",
    },
  ];

  const activitiesTwo = [
    {
      title: "Attivita1",
      date: "Lunedì, 3 lug",
      description: "Some description",
    },
    {
      title: "Attivita2",
      date: "Lunedì, 3 lug",
      description: "Some description",
    },
  ];

  const staticData = {
    // Risulati: "92%",
    // Aderneza: "25%",
    Tags: [{ tag: "Tag" }, { tag: "Tag" }, { tag: "Tag" }],
  };

  const fetchData = async () => {
    try {
      const response = await axios.get(`${BASE_URL}admin/athlete-all`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const fetchedNames = response?.data?.athlete?.map((athlete) => ({
        id: athlete?.id,
        name: athlete?.name,
        Risulati: athlete?.results,
        Aderneza: athlete?.adherence,
        Tags: athlete?.tags,
        uuid: athlete?.uuid,
        // ...staticData,
      }));
      localStorage.setItem("athleteAll", fetchedNames?.id);

      setTableArr(fetchedNames);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleNavigate = (item) => {
    localStorage.setItem("athleteData", JSON.stringify(item));
    navigate(`/admin/atleti/attivita/${item?.id}`, { state: item });
  };

  const handleModalSave = () => {
    fetchNotes();
    setModalOpen(false);
  };

  const handleNoteSave = () => {
    fetchNotes();
    setIsAddModal(false);
  };

  const addTag = (athleteID) => {
    setSelectedAthleteId(athleteID);
    setIsAddTagModalOpen(true);
  };

  const closeAddTagModal = () => {
    fetchData();
    setIsAddTagModalOpen(false);
  };

  const spinnerStyle = {
    border: "3px solid #f3f3f3",
    borderTop: "3px solid #a0fc6e",
    borderRadius: "50%",
    width: "30px",
    height: "30px",
    animation: "spin 1s linear infinite",
  };

  const containerStyle = {
    display: "flex",
    alignContent: "center",
    backgroundColor: "transparent",
  };

  const filteredDataAthlete = tableArr?.filter((value) =>
    value?.name?.toLowerCase().includes(searchingValue.toLowerCase())
  );

  return (
    <div className="AtletiContent">
      <div className="topBar">
        <img
          className="searchIcon"
          src={atletiIcon}
          onClick={() => {
            setIsSearching(!isSearching);
            setSearchingValue("");
          }}
          alt="Atleti"
        />
        {isSearching ? (
          <div
            className="form-div"
            style={{
              display: "flex",
              flexDirection: "row",
              position: "relative",
            }}
          >
            <input
              type="text"
              placeholder="Cerca qui...!"
              className="form-input pe-3"
              value={searchingValue}
              name="name"
              onChange={(e) => setSearchingValue(e.target.value)}
              style={{ marginBottom: "20px", marginLeft: 5, marginTop: 10 }}
            />
            <MdOutlineCancel
              style={{
                position: "absolute",
                right: 0,
                top: 20,
                fontSize: 18,
                cursor: "pointer",
              }}
              onClick={() => setSearchingValue("")}
            />
          </div>
        ) : (
          <span>I TUOI ATLETI</span>
        )}
      </div>
      <div className="content-visibility">
        <div className="content-columns">
          <div className="content-center">
            <div className="table">
              <table className="admin-custom-table">
                <thead>
                  <tr>
                    <th className=" border-right">
                      <div className="header-cell">
                        <div>Name</div>
                        {/* <img src={icon} className="header-icon" /> */}
                      </div>
                    </th>
                    <th className="border-right">
                      <div className="header-cell">
                        <div>Risulati</div>
                        {/* <img src={icon} className="header-icon" /> */}
                      </div>
                    </th>
                    <th className="border-right">
                      <div className="header-cell">
                        <div>Aderneza</div>
                        {/* <img src={icon} className="header-icon" /> */}
                      </div>
                    </th>
                    <th>Tags</th>
                    <th>Azione</th>
                  </tr>
                </thead>

                <tbody>
                  {/* {tableArr.map((item, i) => (
                    <tr
                      key={i}
                      className={i % 2 === 0 ? "bg-color" : "bg-none"}
                      onClick={handleNavigate}
                    >
                      <td>{item.name}</td>
                      <td>{item.Risulati}</td>
                      <td>{item.aderneza}</td>
                      {item.Tags.map((tag) => (
                        <td>
                          <label>{tag.tag}</label>
                          <label>{tag.tag}</label>
                          <label>{tag.tag}</label>
                        </td>
                      ))}
                    </tr>
                  ))} */}
                  {loading ? (
                    <tr style={containerStyle}>
                      <td style={spinnerStyle}></td>
                      <style>
                        {`
                         @keyframes spin {
                        0% { transform: rotate(0deg); }
                        100% { transform: rotate(360deg); }
                        }`}
                      </style>
                    </tr>
                  ) : (
                    <>
                      {filteredDataAthlete?.map((item, i) => {
                        return (
                          <tr
                            key={i}
                            className={i % 2 === 0 ? "bg-color" : "bg-none"}
                          >
                            <td
                              onClick={() => handleNavigate(item)}
                              style={{ cursor: "pointer" }}
                            >
                              {item.name}
                            </td>
                            <td>{item.Risulati}</td>
                            <td>{item.Aderneza}</td>
                            <td
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                width: 200,
                              }}
                            >
                              {item?.Tags?.length > 0 ? (
                                <div className="tagLabelParent">
                                  {item.Tags.map((tag, index) => (
                                    <label
                                      className=""
                                      key={index}
                                      style={{
                                        background:
                                          item.name === "Atleta 1" &&
                                          index === 0
                                            ? "red"
                                            : "#0441B1",
                                      }}
                                    >
                                      {tag.tag_name}
                                    </label>
                                  ))}
                                </div>
                              ) : (
                                <div>No tags</div>
                              )}
                              <img
                                src={plusIcon}
                                alt="Add tag"
                                style={{
                                  marginLeft: "8px",
                                  marginTop: "8px",
                                  cursor: "pointer",
                                  filter: "invert(100%) brightness(100%)",
                                  width: "15px",
                                  height: "15px",
                                }}
                                onClick={() => addTag(item.id)}
                              />
                            </td>
                            <td>
                              <img
                                src={deleteIcon}
                                alt="delete icon"
                                onClick={() =>
                                  setConfirmationModal({
                                    title: item?.name,
                                    id: item?.uuid,
                                  })
                                }
                                style={{
                                  marginLeft: "10px",
                                  cursor: "pointer",
                                  width: "20px",
                                  height: "20px",
                                  filter: "invert(100%) brightness(100%)",
                                }}
                              />
                            </td>
                          </tr>
                        );
                      })}
                    </>
                  )}
                </tbody>
              </table>

              <div className="bottomTextHome">
                <div className="admin-bottom-container">
                  <a
                    href="#"
                    className="admin-bottom-button"
                    onClick={() => setOpenModal(true)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                    >
                      <g>
                        <g
                          fill="none"
                          stroke="#A0FC6E"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          data-name="add"
                        >
                          <path d="M12 19L12 5"></path>
                          <path d="M5 12L19 12"></path>
                        </g>
                      </g>
                    </svg>
                    aggiungi nuova
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="content-right-sidebar-container">
            <DeleteModal
              isOpen={confirmationModal}
              onCancel={() => setConfirmationModal(false)}
              onConfirm={
                confirmationModal?.title
                  ? handleDeleteAthelte
                  : handleDeleteClick
              }
              message={`Sei sicuro di voler eliminare questa ${
                confirmationModal?.title ? confirmationModal?.title : "nota"
              } ?`}
              isLoading={isLoading}
            />
            <div className="right-sidebar">
              <div className="sub---title-no-border">pasti consigliati</div>
              {mealData?.map((activity, index) => (
                <TaskEditor
                  key={index}
                  title={activity?.meal_name[0] || "--"}
                  description={activity?.type || "--"}
                  calorie={activity?.total_calories || "--"}
                />
              ))}
              <a
                href="javascript:void(0)"
                className="button-default-no-icon"
                onClick={() => setIsNewActivityModalOpen(true)}
              >
                <img src={plusIcon} alt="plus icon" />
                <p>crea nuovo</p>
              </a>
            </div>
            <div className="right-sidebar">
              <div className="sub---title">calendario attività</div>
              {notes.length > 0 ? (
                notes?.map((note) => (
                  <TaskEditorTwo
                    key={note.id}
                    title={note.title}
                    date={new Date(note.date).toLocaleDateString()}
                    description={note.description}
                    loader={loading}
                    handleEditClick={() => handleEditClick(note)}
                    handleDeleteClick={() => setConfirmationModal(note.uuid)}
                  />
                ))
              ) : (
                <div style={{ alignSelf: "center" }}>
                  Nessuna attività nel calendario
                </div>
              )}
              <CalendarioModal
                isOpen={isModalOpen}
                onClose={handleCloseModal}
                token={token}
                onSave={handleModalSave}
                selectedNote={selectedNote}
              />
              {isNewActivityModalOpen && (
                <NewActivityModal
                  closedHandler={() => {
                    setIsNewActivityModalOpen(false);
                    setSelectedAthlete("");
                  }}
                  selectedAthlete={selectedAthlete}
                  setSelectedAthlete={setSelectedAthlete}
                  date={date}
                  setDate={setDate}
                  onButtonClick={onButtonClick}
                />
              )}
              <a
                href="#"
                className="button-default-no-icon"
                onClick={() => setIsAddModal(true)}
              >
                <img src={plusIcon} alt="plus icon" />
                <p>aggiungi nuova</p>
              </a>
              <CreateNoteModal
                isOpen={isAddModal}
                onClose={handleCloseNoteModal}
                token={token}
                onSave={handleNoteSave}
                tableArr={tableArr}
                athleteNote
              />
            </div>{" "}
          </div>

          {openModal && (
            <AdminCoachModal
              closedHandler={() => {
                setOpenModal(false);
                fetchData();
              }}
              type="admin-atleta"
            />
          )}
        </div>
      </div>

      <div className="admin-coach-mobile">
        <div>
          <div className="admin-coach-select">
            <select className="">
              <option className="">filtra per: tag 1</option>

              <option className="">athlete 1</option>
              <option className="">athlete 2</option>
              <option className="">athlete 3</option>
            </select>
            <select className="">
              <option className="">filtra per: tag 2</option>

              <option className="">athlete 1</option>
              <option className="">athlete 2</option>
              <option className="">athlete 3</option>
            </select>
          </div>
        </div>
        {tableArr.map((item, ind) => (
          <div className="admin-coach-mobile-flex" key={ind}>
            <div>
              <p className="mobile-card-para">{item.name}</p>
              <div className="mobile-tags">
                <p>Tag</p>
                <p>Tag</p>
              </div>

              <div className="card-flex">
                <div>
                  {" "}
                  <p>
                    Risulati <span>{item.Risulati}</span>
                  </p>
                </div>
                <div>
                  {" "}
                  <p>
                    aderneza <span>{item.aderneza}</span>
                  </p>
                </div>
              </div>
            </div>
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="#a0fc6e"
                version="1.1"
                viewBox="0 0 24 24"
                xmlSpace="preserve"
              >
                <path d="M6.8 23.7L5.4 22.3 15.7 12 5.4 1.7 6.8 0.3 18.5 12z"></path>
              </svg>
            </div>
          </div>
        ))}
      </div>
      {isAddTagModalOpen && (
        <AddTagModal
          isOpen={isAddTagModalOpen}
          athleteId={selectedAthleteId}
          onClose={closeAddTagModal}
          athleteTag
        />
      )}
    </div>
  );
}
