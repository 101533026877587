import React from "react";

// Componets
import Home from "../Users/Home";
import ProfileContent from "./ProfileContent";
import Training from "./Training";
import Nutrition from "./Nutrition";
import NoteContent from "../../pages/NoteContent";
import ProfiloContent from "../../pages/ProfiloContent";
import CoachDashboard from "../../pages/coachDashboard";
import UPTheMeasrment from "../../pages/User/UPTheMeasrment";

const MainContent = ({ content, retrunFuncValue }) => {
  const renderContent = () => {
    switch (content) {
      case "HomeContent":
        return <Home />;
      case "TrainingContent":
        return <Training retrunFuncValue={retrunFuncValue} />;
      case "nutritionContent":
        return <Nutrition />;
      case "profileContent":
        return <ProfileContent />;
      case "coachHome":
        return <CoachDashboard />;
      case "coachNote":
        return <NoteContent />;
      case "coachProfilo":
        return <ProfiloContent />;
      case "newui":
        return <UPTheMeasrment />;

      default:
        return <Home />;
    }
  };

  return <div className="content">{renderContent()}</div>;
};

export default MainContent;
