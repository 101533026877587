import React, { useState, useEffect } from "react";
import arrowLeft from "../../assets/img/icons/arrow-left.svg";
import arrowRight from "../../assets/img/icons/arrow-right.svg";
import atletiIcon from "../../assets/images/search-active.svg";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import calendarIcon from "../../assets/img/icons/calendar.svg";
import ResponsiveHeader from "../Reuseable/ResponsiveHeader";
import AdminCoachModal from "../AdminCoachModal";
import { Link } from "react-router-dom";
import Dropdown from "../Reuseable/Dropdown";
import { BASE_URL } from "../../assets/constants";
import axios from "axios";
import { notify } from "../util/notify";

const CoachCalendar = ({
  months,
  monthActive,
  yearActive,
  dateToday,

  events = [],
}) => {
  const [selectedOption, setSelectedOption] = useState("mese");
  const [currentMonth, setCurrentMonth] = useState(monthActive);
  const [currentYear, setCurrentYear] = useState(yearActive);
  const [dates, setDates] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [feedbackSent, setFeedbackSent] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [calenderData, setCalenderData] = useState([]);
  const [calenderDataObj, setCalenderDataObj] = useState(null);
  const [feedbackValues, setFeedbackValues] = useState({
    date: "",
    description: "",
  });

  const { athelete_id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const adminData = JSON.parse(localStorage.getItem("adminData"));
  const token = adminData?.data?.token;
  const role = adminData?.data?.role;
  const { state } = useLocation();
  const userId = localStorage.getItem("userID");
  const feedbackMsg = useSelector((state) => state?.userReducer?.data);
  const [disabled, setDisabled] = useState(false);

  localStorage.setItem("month", months[currentMonth]);

  useEffect(() => {
    generateDates(currentMonth, currentYear);
  }, [currentMonth, currentYear]);

  useEffect(() => {
    if (Array.isArray(feedbackMsg)) {
      const feedbackMap = {};
      feedbackMsg.forEach((feedback) => {
        if (feedback.date) {
          feedbackMap[feedback.date] = feedback.feedback_exercise;
        }
      });
      setFeedbackSent(feedbackMap);
    } else {
      console.warn("Feedback message is not an array:", feedbackMsg);
    }
  }, [feedbackMsg]);

  const prevMonth = () => {
    if (currentMonth === 0) {
      setCurrentMonth(11);
      setCurrentYear(currentYear - 1);
    } else {
      setCurrentMonth(currentMonth - 1);
    }
  };

  const nextMonth = () => {
    if (currentMonth === 11) {
      setCurrentMonth(0);
      setCurrentYear(currentYear + 1);
    } else {
      setCurrentMonth(currentMonth + 1);
    }
  };

  const generateDates = (month, year) => {
    const startDate = new Date(year, month, 1);
    const endDate = new Date(year, month + 1, 0);
    const datesArray = [];

    const startDay = startDate.getDay();
    if (startDay !== 1) {
      const prevMonthEndDate = new Date(year, month, 0);
      const prevMonthDays = (startDay + 6) % 7;

      for (let i = prevMonthDays; i > 0; i--) {
        const date = new Date(prevMonthEndDate);
        date.setDate(prevMonthEndDate.getDate() - (i - 1));
        datesArray.push({
          date: date,
          feedback: "Feedback",
          day: "",
          isCurrentMonth: false,
        });
      }
    }

    for (
      let date = startDate;
      date <= endDate;
      date.setDate(date.getDate() + 1)
    ) {
      const isoDate = date.toISOString().split("T")[0];
      const event = events.find((event) => event.date === isoDate) || {};
      datesArray.push({
        date: new Date(date),
        feedback: event.feedback || "Feedback",
        day: event.day || "allenamento",
        isCurrentMonth: true,
      });
    }

    const endDay = endDate.getDay();
    if (endDay !== 0) {
      const nextMonthStartDate = new Date(year, month + 1, 1);
      const nextMonthDays = 7 - endDay;

      for (let i = 0; i < nextMonthDays; i++) {
        const date = new Date(nextMonthStartDate);
        date.setDate(nextMonthStartDate.getDate() + i);
        datesArray.push({
          date: date,
          feedback: "Feedback",
          day: "",
          isCurrentMonth: false,
        });
      }
    }

    setDates(datesArray);
  };

  const isCurrentMonth = (date) => {
    return (
      date &&
      date.getMonth() === currentMonth &&
      date.getFullYear() === currentYear
    );
  };

  const openModal = (date) => {
    setSelectedDate(date);
    setInputValue(feedbackSent[date] || "");
  };

  const closeModal = () => {
    setInputValue("");
  };

  const options = [
    "Gennaio",
    "Febbraio",
    "Marzo",
    "Aprile",
    "Maggio",
    "Giugno",
    "Luglio",
    "Agosto",
    "Settembre",
    "Ottobre",
    "Novembre",
    "Dicembre",
  ];

  const handleSelect = (option) => {
    setSelectedOption(option);
  };

  const dateFormat = (date) => {
    return `${date?.getFullYear()}-${String(date?.getMonth() + 1).padStart(
      2,
      "0"
    )}-${String(date?.getDate())?.padStart(2, "0")}`;
  };
  const getTypeForDate = (date, value) => {
    const formattedDate = date.toISOString().split("T")[0];
    let formatedDate = `${date.getFullYear()}-${String(
      date.getMonth() + 1
    ).padStart(2, "0")}-${String(date.getDate()).padStart(2, "0")}`;
    const matchedDate = calenderData?.find(
      (item) => item?.name === formattedDate
    );
    return matchedDate?.[value] || "--"; // Return type if matched, otherwise default to 'nutrizione'
  };

  const isDateDisabled = (date) => {
    const matchedDate = calenderData?.find((item) => item?.name == date);
    return !matchedDate;
  };

  const fetchCalender = async () => {
    try {
      const response = await axios.get(
        role == "admin"
          ? `${BASE_URL}admin/calender/${athelete_id}`
          : `${BASE_URL}coach/calender/${state?.id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setCalenderData(response?.data?.matching_dates || []);
    } catch (error) {
      console.error("Error fetching athlete notes:", error);
    } finally {
    }
  };
  const fetchFeedBack = async (item) => {
    try {
      const response = await axios.get(
        `${BASE_URL}admincoach/training-feedback/${athelete_id}/${dateFormat(
          item?.date
        )}/detail`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // setCalenderDataFeedBack(response?.data?.trainingFeedback[0] || {});
      if (response?.data?.trainingFeedback?.length > 0) {
        setFeedbackValues({
          ...feedbackValues,
          description: response?.data.trainingFeedback[0].description,
        });
        setDisabled(true);
        setModalOpen(true);
      } else {
        setDisabled(false);
        setModalOpen(true);
        setFeedbackValues({
          date: "",
          description: "",
        });
      }
    } catch (error) {
      console.error("Error fetching athlete notes:", error);
    } finally {
    }
  };

  const storeFeedback = async () => {
    if (disabled) {
      notify("", "Feedback already save");
      return;
    }
    let formattedDate = `${String(calenderDataObj?.date?.getDate())?.padStart(
      2,
      "0"
    )}-${
      calenderDataObj?.date?.getMonth() + 1
    }-${calenderDataObj?.date?.getFullYear()}`;
    let feedback = {
      description: feedbackValues?.description,
      date: formattedDate,
    };

    try {
      try {
        const response = await axios.post(
          `${BASE_URL}admincoach/training-feedback/${athelete_id}/store`,
          feedback,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        setModalOpen(false);
        // setCalenderData(response?.data?.matching_dates || []);
      } catch (error) {
        console.error("Error fetching athlete notes:", error);
      } finally {
      }
    } catch (error) {}
  };

  useEffect(() => {
    fetchCalender();
  }, []);
  // useEffect(() => {
  //   fetchFeedBack();
  // }, [calenderDataObj]);

  return (
    <div>
      <div className="coach-calendar">
        <div className="calender-navbar">
          <div className="calendar-nav">
            <div className="switch-buttons">
              <img onClick={prevMonth} src={arrowLeft} alt="Previous Month" />
              <img onClick={nextMonth} src={arrowRight} alt="Next Month" />
            </div>
            <div className="month-year">{`${months[currentMonth]} ${currentYear}`}</div>

            {/* <Dropdown
              options={options}
              onSelect={handleSelect}
              btn="mese"
              bgList="true"
              content={selectedOption}
            /> */}
          </div>
        </div>
        <div className="days-names active">
          <div>Lun</div>
          <div>Mar</div>
          <div>Mer</div>
          <div>Gio</div>
          <div>Ven</div>
          <div>Sab</div>
          <div>Dom</div>
        </div>
        <div className="days-group">
          {dates?.map((item, index) => {
            const day = item.date.getDate().toString().padStart(2, "0");
            const month = (item.date.getMonth() + 1)
              .toString()
              .padStart(2, "0");
            const year = item.date.getFullYear();
            const isDisabled = isDateDisabled(`${year}-${month}-${day}`);

            return (
              <div
                key={index}
                style={{
                  opacity: isDisabled ? 0.4 : 1,
                }}
                onClick={() => {
                  !isDisabled && fetchFeedBack(item);
                  setCalenderDataObj(item);
                }}
                className={`day ${
                  isCurrentMonth(item.date) ? "current-month" : ""
                } ${`${year}-${month}-${day}` === dateToday ? "active" : ""}`}
              >
                <div className="day-number">{item.date.getDate()}</div>
                <div className="button-group-display">
                  <div className="button-group">
                    <a
                      className="button-square strongblue"
                      style={{ cursor: "pointer" }}
                    >
                      {getTypeForDate(item.date, "type")}
                    </a>

                    <a
                      className="button-square softblue"
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        openModal(item?.date?.toISOString()?.split("T")[0])
                      }
                    >
                      {getTypeForDate(item.date, "calories")}
                    </a>

                    {item.feedback && (
                      <a
                        className="button-square"
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          openModal(item?.date?.toISOString()?.split("T")[0])
                        }
                      >
                        {item.feedback}
                      </a>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        {modalOpen && (
          <AdminCoachModal
            closedHandler={() => setModalOpen(false)}
            type="admin-calendrio"
            calenderDataObj={calenderDataObj}
            calender
            setFeedbackValues={setFeedbackValues}
            feedbackValues={feedbackValues}
            storeFeedback={storeFeedback}
          />
        )}
      </div>
    </div>
  );
};

export default CoachCalendar;
