import React, { useState } from "react";
import "./css/Grid.css";
import { notify } from "../util/notify";

const GridTable = ({ mealData, setMealData, isDataAvailable }) => {
  const athleteData = JSON.parse(localStorage.getItem("athleteData"));
  const calculateCaloriesFromGrams = (carbohydrates, proteins, fats, fiber) => {
    const totalCalories =
      (parseFloat(carbohydrates) * 4 || 0) +
      (parseFloat(proteins) * 4 || 0) +
      (parseFloat(fats) * 9 || 0) +
      (parseFloat(fiber) || 0);

    return {
      totalCalories,
    };
  };

  const handleInputChange = (index, field, value) => {
    const newMealData = [...mealData];
    newMealData[index][field] = value;
    newMealData[index]["calories"] = parseFloat(value) || 0;

    const { calories, carbohydrates, proteins, fats, fiber } =
      newMealData[index];

    const { totalCalories } = calculateCaloriesFromGrams(
      carbohydrates,
      proteins,
      fats,
      fiber
    );

    newMealData[index]["calories"] = totalCalories;
    setMealData(newMealData);
  };
  console.log("mealData", mealData);

  // const parsedCalories = parseFloat(calories);
  // // const maxAllowedCaloriesInGrams = !isNaN(parsedCalories)
  // //   ? parsedCalories * 0.129598
  // //   : 0;

  // // let alertMessage = "";

  // // if (!isNaN(totalCalories) && totalCalories > maxAllowedCaloriesInGrams) {
  // //   alertMessage += `The total calorie contribution for ${newMealData[index].meal} exceeds ${calories} kcal!\n`;
  // // }

  // // if (alertMessage) {
  // //   notify(true, alertMessage);
  // //   newMealData[index][field] = "";
  // // }

  const calculateTotals = () => {
    return mealData.reduce(
      (totals, meal) => {
        totals.calories += parseFloat(meal.calories) || 0;
        totals.carbohydrates += parseFloat(meal.carbohydrates) || 0;
        totals.proteins += parseFloat(meal.proteins) || 0;
        totals.fats += parseFloat(meal.fats) || 0;
        totals.fiber += parseFloat(meal.fiber) || 0;
        return totals;
      },
      { calories: 0, carbohydrates: 0, proteins: 0, fats: 0, fiber: 0 }
    );
  };

  const totals = calculateTotals();

  return (
    <>
      <div className="gridd">
        <div className="grid-table head">
          <div className="head-col">Pasto</div>
          <div className="head-col">Calorie (kcal)</div>
          <div className="head-col">Carboidrati (g)</div>
          <div className="head-col">Proteine (g)</div>
          <div className="head-col">Grassi (g)</div>
          <div className="head-col">Fibre (g)</div>
        </div>
        {mealData.map((item, index) => (
          <div className="grid-table" key={index}>
            <div className="col col-padding">{item.meal_name}</div>
            <input
              className="col col-padding"
              style={{ color: "white", borderWidth: "0px" }}
              type="text"
              value={item.calories}
              disabled
              placeholder="Calories (kcal)"
              // onChange={(e) =>
              //   handleInputChange(index, "calories", e.target.value)
              // }
            />
            <input
              className="col col-padding"
              style={{ color: "white", borderWidth: "0px" }}
              type="text"
              value={item.carbohydrates}
              placeholder="Carbs (g)"
              onChange={(e) =>
                handleInputChange(index, "carbohydrates", e.target.value)
              }
            />
            <input
              className="col col-padding"
              style={{ color: "white", borderWidth: "0px" }}
              type="text"
              value={item.proteins}
              placeholder="Proteins (g)"
              onChange={(e) =>
                handleInputChange(index, "proteins", e.target.value)
              }
            />
            <input
              className="col col-padding"
              style={{ color: "white", borderWidth: "0px" }}
              type="text"
              value={item.fats}
              placeholder="Fats (g)"
              onChange={(e) => handleInputChange(index, "fats", e.target.value)}
            />

            <input
              className="col col-padding"
              style={{ color: "white", borderWidth: "0px" }}
              type="text"
              value={item.fiber}
              placeholder="Fiber (g)"
              onChange={(e) =>
                handleInputChange(index, "fiber", e.target.value)
              }
            />
          </div>
        ))}
        <div className="grid-table">
          <div className="col col-padding">Totale</div>
          <div className="col col-padding">
            {totals.calories.toFixed(2)} kcal
          </div>
          <div className="col col-padding">
            {totals.carbohydrates.toFixed(2)} g
          </div>
          <div className="col col-padding">{totals.proteins.toFixed(2)} g</div>
          <div className="col col-padding">{totals.fats.toFixed(2)} g</div>
          <div className="col col-padding">{totals.fiber.toFixed(2)} g</div>
        </div>
      </div>
    </>
  );
};

export default GridTable;
