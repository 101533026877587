export const nutritionData = [
  {
    type: "Acqua",
    consumed: "2/2L",
    checked: true,
  },
  {
    type: "Colazione",
    details: ["Cereali muesli + 1 kiwi"],
    calories: "130/130 CAL",
    checked: true,
  },
  {
    type: "Pranzo",
    details: ["Riso integrale con zucchine e gamberetti", "Yogurt bianco"],
    calories: "0/130 CAL",
    checked: false,
  },
  {
    type: "Cena",
    details: ["Petto di pollo alla griglia con broccoli", "Insalata verde"],
    calories: "0/130 CAL",
    checked: false,
  },
  {
    type: "Spuntino",
    details: ["Noci", "Una mela"],
    calories: "0/130 CAL",
    checked: false,
  },
];
