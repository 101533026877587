import React, { useEffect, useRef, useState } from "react";

import WorkoutPlan from "./WorkPlan";
import Dropdown from "../Reuseable/Dropdown";
import SmallInput from "../Reuseable/SmallInput";
import AdminCoachModal from "../AdminCoachModal";
import ActivitesGrid from "./ActivitesGrid";
import DatePicker from "react-datepicker";
import axios from "axios";
import { BASE_URL } from "../../assets/constants";
import { useLocation } from "react-router-dom";
import { notify } from "../util/notify";
import DeleteModal from "../DeleteModal";

const AdminTranining = () => {
  const { state } = useLocation();
  const adminData = JSON.parse(localStorage.getItem("adminData"));
  const token = adminData?.data?.token;
  const [openDropDown, setOpenDropDown] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openDropDownTwo, setOpenDropDownTwo] = useState(false);
  const [type, setType] = useState({ name: "Select options", id: 0 });
  const [allow, setAllow] = useState(true);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [isSaveLoading, setIsSaveLoading] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);

  // const options = ["athlete", "coach"];

  const [weekCount, setWeekCount] = useState(1); // Week count state
  const [selectedOption, setSelectedOption] = useState("video riscaladamento");
  const [weeksBetween, setWeeksBetween] = useState(1);
  const [sessionData, setSessionData] = useState(null);
  const [template, setTemplate] = useState([]);
  const [errors, setErrors] = useState({});
  const [isTemplate, setIsTemplate] = useState(false);
  const [sessionValues, setSessionValues] = useState({
    start_date: new Date(),
    end_date: "",
    name: "",
    training_sections: [
      {
        training_section_sessions: Array.from({ length: 7 }, () => ({
          image: "",
          name: "",
          description: "",
          training_section_session_activities: [
            {
              name: "",
              repatation: "",
              rest: "",
              training_section_session_activities_exercise: [
                {
                  name: "",
                  time: null,
                  repatation: null,
                  load: "",
                  type: "linear",
                  type_link: null,
                },
              ],
            },
          ],
        })),
      },
      {
        training_section_sessions: Array.from({ length: 7 }, () => ({
          image: "",
          name: "",
          description: "",
          training_section_session_activities: [
            {
              name: "",
              repatation: "",
              rest: "",
              training_section_session_activities_exercise: [
                {
                  name: "",
                  time: null,
                  repatation: null,
                  load: "",
                  type: "linear",
                  type_link: null,
                },
              ],
            },
          ],
        })),
      },
    ],
  });

  const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
  const inputRef = useRef(null);

  const handleDivClick = () => {
    inputRef?.current?.click();
  };

  const options = [{ label: "Full-Body Strength & Core", value: 1 }];
  const optionsTwo = ["sessione 1", "nuova sessione", "duplica", "elimina"];
  const formatDate = (date) => {
    if (date) {
      const day = String(date?.getDate()).padStart(2, "0");
      const month = String(date?.getMonth() + 1).padStart(2, "0");
      const year = date?.getFullYear();
      return `${day}-${month}-${year}`;
    }
  };

  const handleSelect = (option) => {
    setSelectedOption(option);
  };

  const [cards, setCards] = useState([]);
  const calculateWeeksBetween = (startDate, endDate) => {
    if (
      !startDate ||
      !endDate ||
      !(startDate instanceof Date) ||
      !(endDate instanceof Date)
    ) {
      return 0;
    }

    // Reset time to midnight to ignore time differences
    const start = new Date(
      startDate.getFullYear(),
      startDate.getMonth(),
      startDate.getDate()
    );
    const end = new Date(
      endDate.getFullYear(),
      endDate.getMonth(),
      endDate.getDate()
    );

    const diffInMs = end.getTime() - start.getTime();
    const diffInDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24)); // Total number of days between dates

    // Calculate weeks, with Day 1 as Week 1
    const weeks = Math.floor(diffInDays / 7) + 1;
    return weeks;
  };

  const addCard = () => {
    sessionValues.training_sections.push({
      training_section_sessions: [
        {
          image: "",
          name: "",
          description: "",
          training_section_session_activities: [
            {
              name: "",
              repatation: "",
              rest: "",
              training_section_session_activities_exercise: [
                {
                  name: "",
                  time: null,
                  repatation: null,
                  load: "",
                  type: "linear",
                  type_link: null,
                },
              ],
            },
          ],
        },
      ],
    });
    setSessionValues({
      ...sessionValues,
      training_sections: sessionValues.training_sections,
    });
  };

  const handleChange = (event) => {
    let { value, name } = event.target;
    setSessionValues({ ...sessionValues, [name]: value });
  };

  const handleDateChange = (value, name) => {
    setSessionValues({ ...sessionValues, [name]: value });
  };
  const fetchSessions = async () => {
    const adminData = JSON.parse(localStorage.getItem("adminData"));
    const token = adminData?.data?.token;
    const athleteData = JSON.parse(localStorage.getItem("athleteData"));

    try {
      const getResponse = await axios.get(
        `${BASE_URL}admincoach/${state?.id}/session-all`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (getResponse.data.status === 200) {
        setIsTemplate(true);
        setSessionData(getResponse?.data?.trainings[0]);
      }
    } catch (error) {
      console.error("Error fetching session data:", error);
    }
  };

  const validateFields = () => {
    const newErrors = {};

    // Validate top-level fields
    if (!sessionValues.name) newErrors.name = "Name is required.";
    if (!sessionValues.end_date) newErrors.end_date = "End date is required.";

    // Validate nested fields in training sections
    sessionValues.training_sections.forEach((section, sectionIndex) => {
      if (!section.name) {
        newErrors[`section_name_${sectionIndex}`] = `Training section ${
          sectionIndex + 1
        } name is required.`;
      }

      section.training_section_sessions.forEach((session, sessionIndex) => {
        if (!session.name) {
          newErrors[
            `session_name_${sectionIndex}_${sessionIndex}`
          ] = `Session ${sessionIndex + 1} in section ${
            sectionIndex + 1
          } name is required.`;
        }
        if (!session.image) {
          newErrors[
            `session_image_${sectionIndex}_${sessionIndex}`
          ] = `Session ${sessionIndex + 1} in section ${
            sectionIndex + 1
          } image is required.`;
        }

        session.training_section_session_activities.forEach(
          (activity, activityIndex) => {
            if (!activity.name) {
              newErrors[
                `activity_name_${sectionIndex}_${sessionIndex}_${activityIndex}`
              ] = `Activity ${activityIndex + 1} in session ${
                sessionIndex + 1
              } name is required.`;
            }
            if (!activity.repatation) {
              newErrors[
                `activity_repatation_${sectionIndex}_${sessionIndex}_${activityIndex}`
              ] = `Repetition for Activity ${activityIndex + 1} in session ${
                sessionIndex + 1
              } is required.`;
            }

            activity.training_section_session_activities_exercise.forEach(
              (exercise, exerciseIndex) => {
                if (!exercise.name) {
                  newErrors[
                    `exercise_name_${sectionIndex}_${sessionIndex}_${activityIndex}_${exerciseIndex}`
                  ] = `Exercise ${exerciseIndex + 1} in Activity ${
                    activityIndex + 1
                  } name is required.`;
                }
                if (!exercise.time) {
                  newErrors[
                    `exercise_time_${sectionIndex}_${sessionIndex}_${activityIndex}_${exerciseIndex}`
                  ] = `Time for Exercise ${exerciseIndex + 1} in Activity ${
                    activityIndex + 1
                  } is required.`;
                }
              }
            );
          }
        );
      });
    });

    setErrors(newErrors);

    if (Object.keys(newErrors).length > 0) {
      const errorMessages = Object.values(newErrors).join("\n");
      notify("", `Validation failed:\n${errorMessages}`);
      return false;
    }
    return true;
  };

  const addSession = async () => {
    if (isTemplate) {
      if (weeksBetween !== sessionValues?.training_sections?.length) {
        notify(true, "Seleziona una data che corrisponda al modello");
        return;
      }
    }

    setIsSaveLoading(true);

    let requestData = {
      ...sessionValues,
      start_date: dateRegex?.test(sessionValues.start_date)
        ? sessionValues.start_date
        : formatDate(sessionValues.start_date),
      end_date: dateRegex?.test(sessionValues.end_date)
        ? sessionValues.end_date
        : formatDate(sessionValues.end_date),
    };
    try {
      await axios.post(
        `${BASE_URL}admincoach/${state?.id}/session-store`,
        requestData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      notify("", "Training Stored");
      fetchSessions();
      setIsSaveLoading(false);
    } catch (error) {
      if (error?.response?.data?.message) {
        notify(true, error?.response?.data?.message);
        setIsSaveLoading(false);

        return;
      }
      let errors = error?.response?.data?.error || {};

      const errorMessages = Object.keys(errors)
        .map((key) => errors[key].join("\n"))
        .join("\n");
      console.log("errorMessages", error);
      notify(true, `Validation failed:\n${errorMessages}`);
      setIsSaveLoading(false);
    }
  };

  const deleteSessions = async () => {
    setIsDeleteLoading(true);
    try {
      await axios.delete(
        `${BASE_URL}admincoach/${sessionData?.id}/session-delete`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // fetchSessions();
      setIsTemplate(false);
      notify("", "Training Deleted");
      setSessionData(null);
      setWeeksBetween(1);
      setSessionValues({
        name: "",
        start_date: new Date(),
        end_date: "",
        training_sections: [
          {
            name: "",
            training_section_sessions: Array.from({ length: 7 }, () => ({
              image: "",
              name: "",
              description: "",
              training_section_session_activities: [
                {
                  name: "",
                  repatation: "",
                  rest: "",
                  training_section_session_activities_exercise: [
                    {
                      name: "",
                      time: "",
                      repatation: "",
                      load: "",
                      type: "linear",
                      type_link: null,
                    },
                  ],
                },
              ],
            })),
          },
        ],
      });
      setIsDeleteLoading(false);
      setConfirmationModal(false);
    } catch (error) {
      setIsDeleteLoading(false);
    }
    setIsDeleteLoading(false);
  };

  const getTemplateData = async (id) => {
    setAllow(false);
    try {
      let response = await axios.get(
        `${BASE_URL}admincoach/${id}/session-template`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      let templateData = response?.data?.data;
      setIsTemplate(true);

      if (templateData) {
        let dynamicTrainingSections = templateData.training_sections.map(
          (section) => ({
            ...section,
            training_section_sessions: section.training_section_sessions.map(
              (session) => ({
                ...session,
                training_section_session_activities:
                  session.training_section_session_activities.map(
                    (activity) => ({
                      ...activity,
                      training_section_session_activities_exercise:
                        activity.training_section_session_activities_exercise.map(
                          (exercise) => ({
                            ...exercise,
                            name: exercise.name || "",
                            time: exercise.time || "",
                            repatation: exercise.repatation || "",
                            load: exercise.load || "",
                            type: exercise.type || "linear",
                          })
                        ),
                    })
                  ),
              })
            ),
          })
        );

        // Update the session values with the template data
        setSessionValues({
          ...sessionValues,
          name: templateData.name,
          training_sections: dynamicTrainingSections,
        });
      }
    } catch (error) {
      setAllow(true);
    }
  };
  const getTemplateDropdownData = async (id) => {
    try {
      let response = await axios.get(
        `${BASE_URL}admincoach/session-templates`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setTemplate(response?.data?.data);
    } catch (error) {}
  };

  const handleSelect2 = (option) => {
    setType(option);
    getTemplateData(option?.id);
  };

  useEffect(() => {
    fetchSessions();
    getTemplateDropdownData();
  }, []);

  useEffect(() => {
    if (sessionValues.start_date && sessionValues.end_date) {
      const weeks = calculateWeeksBetween(
        sessionValues.start_date,
        sessionValues.end_date
      );
      setWeeksBetween(weeks);
    }
  }, [sessionValues.start_date, sessionValues.end_date]);

  const spinnerStyle = {
    border: "3px solid #f3f3f3",
    borderTop: "3px solid #a0fc6e",
    borderRadius: "50%",
    width: "30px",
    height: "30px",
    animation: "spin 1s linear infinite",
  };

  const containerStyle = {
    display: "flex",
    justifyContent: "center",
    marging: "0 auto",
    backgroundColor: "transparent",
  };

  useEffect(() => {
    if (!type.id) {
      const updatedTrainingSections = Array.from(
        { length: weeksBetween },
        () => ({
          training_section_sessions: Array.from({ length: 7 }, () => ({
            image: "",
            name: "",
            description: "",
            training_section_session_activities: [
              {
                name: "",
                repatation: "",
                rest: "",
                training_section_session_activities_exercise: [
                  {
                    name: "",
                    time: "",
                    repatation: "",
                    load: "",
                    type: "linear",
                    type_link: null,
                  },
                ],
              },
            ],
          })),
        })
      );
      setSessionValues((prevValues) => ({
        ...prevValues,
        training_sections: updatedTrainingSections,
      }));
    }
  }, [weeksBetween, type.id]);

  return (
    <>
      <div className="Training-container">
        <div className="Training-container-margin">
          <h1 className="header-title">programma di allenamento</h1>

          <div className="flex-container">
            {/* <h1 className="subheader-title">Definizione massa muscolare</h1> */}
            <div className="form-div subheader-title">
              <input
                type="text"
                placeholder="Enter program name"
                className="form-input"
                value={sessionData ? sessionData.name : sessionValues.name}
                name="name"
                onChange={handleChange}
                style={{ marginBottom: "20px" }}
                disabled={sessionData && true}
              />
            </div>
            <div className="date-duration">
              <p className="food-para-setimane">{weeksBetween} SETTIMANE</p>

              <DatePicker
                selected={
                  sessionData
                    ? sessionData.start_date
                    : sessionValues.start_date
                }
                onChange={(value) => handleDateChange(value, "start_date")}
                dateFormat="MM/dd"
                minDate={new Date()}
                name="start_date"
                className="input-container-activity large"
                disabled={sessionData && true}
              />

              <DatePicker
                selected={
                  sessionData ? sessionData.end_date : sessionValues.end_date
                }
                onChange={(value) => handleDateChange(value, "end_date")}
                dateFormat="MM/dd"
                className="input-container-activity large"
                minDate={
                  // sessionData ? sessionData.end_date : sessionValues.end_date
                  // ? new Date(
                  //     sessionValues?.start_date?.getTime() +
                  //       1 * 24 * 60 * 60 * 1000
                  //   )
                  // : null
                  new Date()
                }
                disabled={sessionData && true}
              />
            </div>
          </div>
        </div>
        {(sessionData
          ? sessionData.sections
          : sessionValues.training_sections
        )?.map((value, index) => {
          return (
            <div key={index}>
              <div className="Training-container-cards">
                <div className="inp_box">
                  {/* <div onClick={() => setOpenDropDown(!openDropDown)}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="#fff"
                  version="1.1"
                  viewBox="0 0 32 32"
                  xmlSpace="preserve"
                >
                  <path d="M16 10c1.7 0 3-1.3 3-3s-1.3-3-3-3-3 1.3-3 3 1.3 3 3 3zM16 13c-1.7 0-3 1.3-3 3s1.3 3 3 3 3-1.3 3-3-1.3-3-3-3zM16 22c-1.7 0-3 1.3-3 3s1.3 3 3 3 3-1.3 3-3-1.3-3-3-3z"></path>
                </svg>{" "}
              </div> */}

                  <h1 className="text">settimana {index + 1}</h1>
                  <div className="form-div">
                    {/* <input
                      type="text"
                      placeholder="Enter settimana name"
                      // className="form-input"
                      value={value.name}
                      name="name"
                      onChange={(e) => {
                        sessionValues.training_sections[index].name =
                          e.target.value;
                        setSessionValues({
                          ...sessionValues,
                          training_sections: sessionValues.training_sections,
                        });
                      }}
                      style={{ marginBottom: "20px" }}
                    /> */}
                  </div>
                  {/* <button onClick={addCard}>aggiungi settimana</button> */}

                  {/* <button className="input-btn" onClick={addCard}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      viewBox="0 0 24 24"
                    >
                      <g>
                        <g
                          fill="none"
                          stroke="#A0FC6E"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          data-name="add"
                        >
                          <path d="M12 19L12 5"></path>
                          <path d="M5 12L19 12"></path>
                        </g>
                      </g>
                    </svg>
                    Aggiungi settimana
                  </button> */}
                </div>

                {(sessionData
                  ? value.sessions
                  : value.training_section_sessions
                )?.map((sessions, Index) => {
                  return (
                    <div key={Index}>
                      {openDropDown && (
                        // <Dropdown
                        //   className="ml-dropdown"
                        //   options={options}
                        //   onSelect={handleSelect}
                        //   type="dots-dropdown"
                        // />
                        <button onClick={addCard}>Add</button>
                      )}
                      <div className="item secondary">
                        {/* <div
                          onClick={() => setOpenDropDownTwo(!openDropDownTwo)}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            fill="#fff"
                            version="1.1"
                            viewBox="0 0 32 32"
                            xmlSpace="preserve"
                          >
                            <path d="M16 10c1.7 0 3-1.3 3-3s-1.3-3-3-3-3 1.3-3 3 1.3 3 3 3zM16 13c-1.7 0-3 1.3-3 3s1.3 3 3 3 3-1.3 3-3-1.3-3-3-3zM16 22c-1.7 0-3 1.3-3 3s1.3 3 3 3 3-1.3 3-3-1.3-3-3-3z"></path>
                          </svg>{" "}
                        </div> */}
                        <h1
                          className="text"
                          //  onClick={() => setOpenModal(true)}
                        >
                          session {Index + 1}
                        </h1>
                        {openModal && (
                          <AdminCoachModal
                            type="admin-event"
                            closedHandler={() => setOpenModal(false)}
                          />
                        )}
                      </div>
                      {openDropDownTwo && (
                        <Dropdown
                          className="ml-dropdown"
                          type="dots-dropdown"
                          options={optionsTwo}
                          onSelect={handleSelect}
                        />
                      )}

                      <div className="Training-container-cards-flex">
                        <div className="gradient-box-image">
                          {sessions.image && (
                            <img
                              onClick={() => {
                                const updateTrainingSession = [
                                  ...sessionValues.training_sections,
                                ];
                                updateTrainingSession[
                                  index
                                ].training_section_sessions[Index].image = "";

                                setSessionValues({
                                  ...sessionValues,
                                  training_sections: updateTrainingSession,
                                });
                                handleDivClick();
                              }}
                              src={
                                sessionData
                                  ? sessions?.image_url
                                  : sessions.image
                              }
                              width={"100%"}
                              // height={100}
                            />
                          )}
                          <input
                            type="file"
                            ref={inputRef}
                            accept="image/*"
                            onChange={(e) => {
                              const file = e.target.files[0];
                              if (file) {
                                const reader = new FileReader();
                                reader.onloadend = () => {
                                  const updateTrainingSession = [
                                    ...sessionValues.training_sections,
                                  ];
                                  updateTrainingSession[
                                    index
                                  ].training_section_sessions[Index].image =
                                    reader.result;

                                  setSessionValues({
                                    ...sessionValues,
                                    training_sections: updateTrainingSession,
                                  });
                                };
                                reader.readAsDataURL(file);
                              }
                            }}
                          />
                        </div>
                        <div className="form-container">
                          <div>
                            <input
                              type="text"
                              id="session_name"
                              className="input-field"
                              value={sessions.name}
                              disabled={sessionData && true}
                              placeholder="Enter Session Name"
                              onChange={(e) => {
                                const updateTrainingSession = [
                                  ...sessionValues.training_sections,
                                ];
                                updateTrainingSession[
                                  index
                                ].training_section_sessions[Index].name =
                                  e.target.value;
                                setSessionValues({
                                  ...sessionValues,
                                  training_sections: updateTrainingSession,
                                });
                              }}
                            />
                          </div>
                          <div>
                            <textarea
                              type="text"
                              id="session_description"
                              disabled={sessionData && true}
                              className="input-field description"
                              value={sessions.description}
                              placeholder="Enter Session Description"
                              onChange={(e) => {
                                const updateTrainingSession = [
                                  ...sessionValues.training_sections,
                                ];
                                updateTrainingSession[
                                  index
                                ].training_section_sessions[Index].description =
                                  e.target.value;
                                setSessionValues({
                                  ...sessionValues,
                                  training_sections: updateTrainingSession,
                                });
                              }}
                            />
                          </div>
                        </div>

                        {/* <Dropdown
                          btn="video riscaladamento"
                          options={optionsTwo}
                          onSelect={handleSelect}
                          content={selectedOption}
                        /> */}
                      </div>
                      <WorkoutPlan
                        sessionValues={sessionValues}
                        setSessionValues={setSessionValues}
                        sessions={sessions}
                        index={index}
                        Index={Index}
                        sessionData={sessionData}
                        type={type}
                        isTemplate={isTemplate}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
        {isSaveLoading ? (
          <div style={{ ...containerStyle, width: 100 }}>
            <div style={spinnerStyle}></div>
            <style>
              {`
           @keyframes spin {
             0% { transform: rotate(0deg); }
             100% { transform: rotate(360deg); }
           }
         `}
            </style>
          </div>
        ) : (
          <button type="submit" className="save-btn" onClick={addSession}>
            salva
          </button>
        )}
      </div>
      <div
        className="right-side-content"
        onClick={() => setConfirmationModal(true)}
      >
        <p style={{ cursor: "pointer" }}>Ripristina il piano alimentare</p>
      </div>
      <div
        className="custom-select-container"
        style={{ width: "30%", marginLeft: "auto" }}
      >
        {sessionValues?.end_date && !sessionData && (
          <Dropdown
            options={template}
            onSelect={handleSelect2}
            btn="Select account type"
            bgList="true"
            className="custom-select-container-drop mb-5"
            content={type}
          />
        )}
      </div>
      <DeleteModal
        isOpen={confirmationModal}
        onCancel={() => setConfirmationModal(false)}
        onConfirm={deleteSessions}
        message="Vuoi eliminare questa Sessione ?"
        isLoading={isDeleteLoading}
      />
    </>
  );
};

export default AdminTranining;
