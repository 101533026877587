import React, { useState, useEffect } from "react";
import "./onboardingCss.css";
import "../../components/Users/Measurements.css";
import { useNavigate } from "react-router-dom";
import { notify } from "../../components/util/notify";
import { BASE_URL } from "../../assets/constants";
import axios from "axios";

function FivethStep({}) {
  const navigate = useNavigate();
  const adminData = JSON.parse(localStorage.getItem("adminData"));
  const token = adminData?.token;

  const [isLoading, setIsLoading] = useState(false);
  const [atheleteData, setAtheleteData] = useState({
    sleep_quality: 1,
    stress_level: "",
    are_you_a_smoker: "",
    do_you_drink_coffee: "",
    how_do_you_feel_when_you_first_wake_up: "",
    do_you_suffer_from_any_medical_conditions: "",
  });

  const handleBackClick = async () => {
    setIsLoading(true);
    const errors = [];

    if (!atheleteData?.stress_level) {
      errors.push("Please select a stress level.");
    }
    if (!atheleteData?.are_you_a_smoker) {
      errors.push("Please select if you are a smoker.");
    }
    if (!atheleteData?.do_you_drink_coffee) {
      errors.push("Please select your coffee drinking habit.");
    }
    if (!atheleteData?.how_do_you_feel_when_you_first_wake_up) {
      errors.push("Please select how you feel when you first wake up.");
    }

    if (!atheleteData?.do_you_suffer_from_any_medical_conditions.trim()) {
      errors.push("Please specify if you suffer from any medical conditions.");
    }

    if (errors.length > 0) {
      notify(true, errors.join(" "));
      setIsLoading(false);
      return;
    }

    try {
      let response = await axios.post(
        `${BASE_URL}athlete/step-4`,
        atheleteData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response?.data?.status) {
        let step = await axios.get(`${BASE_URL}athlete/step-form`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        let steps = step?.data?.forms_completed;
        localStorage.setItem("onboardingProgress", JSON.stringify(steps));
        notify(false, "Passaggio 4 completato");
        navigate("/onboardingstep6");
      }
    } catch (error) {
      const errorMessages = error?.response?.data?.message;

      if (errorMessages) {
        const combinedErrors = Object.values(errorMessages).flat().join("\n");

        notify(true, combinedErrors);
      } else {
        notify(true, "Something went wrong!");
      }
    }
    setIsLoading(false);
  };

  const handleSleep = (value) => {
    setAtheleteData({
      ...atheleteData,
      sleep_quality: value,
    });
  };
  const handleChange = (event) => {
    const { value, name, checked, type } = event?.target;
    setAtheleteData({
      ...atheleteData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const spinnerStyle = {
    border: "3px solid #f3f3f3",
    borderTop: "3px solid #a0fc6e",
    borderRadius: "50%",
    width: "30px",
    height: "30px",
    animation: "spin 1s linear infinite",
    margin: "0 auto",
  };

  const containerStyle = {
    display: "flex",
    justifyContent: "center",
    marginTop: "10px",
  };

  return (
    <div className="mainContainer">
      <div>
        <div
          className="hunger-level-container1"
          style={{
            backgroundColor: "#080808",
            marginTop: 20,
            padding: 20,
            width: "25em",
          }}
        >
          <div className="title" style={{ fontSize: "2em" }}>
            benessere
          </div>

          <div className="sprateColorsStep">
            <div className="colorBar" style={{ height: 8 }}></div>
            <div
              className="colorBar"
              style={{ height: 6, marginLeft: 10 }}
            ></div>
            <div
              className="colorBar"
              style={{ height: 6, marginLeft: 10 }}
            ></div>
            <div
              className="colorBar"
              style={{ height: 6, marginLeft: 10 }}
            ></div>
            <div
              className="colorBar"
              style={{ height: 6, marginLeft: 10, backgroundColor: "#fff" }}
            ></div>
          </div>

          <div className="range-labels" style={{ marginTop: -15 }}>
            <span className="label-left" style={{ color: "#7A7A7A" }}>
              Step 4 di 5
            </span>
          </div>

          <div
            className="hunger-level-container"
            style={{ float: "left", marginTop: 50 }}
          >
            <div className="title" style={{ width: 350 }}>
              Qualità del sonno
            </div>
            <div className="barP">
              <div
                style={{
                  display: "flex",
                }}
              >
                <div className="speedQuality" onClick={() => handleSleep(1)}>
                  <div className="isQ"></div>
                </div>
                <div className="speedQuality" onClick={() => handleSleep(2)}>
                  <div className="isQ"></div>
                </div>
                <div className="speedQuality" onClick={() => handleSleep(3)}>
                  <div className="isQ"></div>
                </div>
                <div className="speedQuality" onClick={() => handleSleep(4)}>
                  <div className="isQ"></div>
                </div>
                <div className="speedQuality" onClick={() => handleSleep(5)}>
                  <div className="isQ"></div>
                </div>
              </div>
              <div
                className="colorBar"
                style={{
                  width:
                    atheleteData.sleep_quality == 1
                      ? 100
                      : atheleteData.sleep_quality == 2
                      ? 160
                      : atheleteData.sleep_quality == 3
                      ? 230
                      : atheleteData.sleep_quality == 4
                      ? 290
                      : 330,
                }}
              ></div>
            </div>
            <div className="range-labels">
              <span className="label-left">Pessima</span>
              <span className="label-right" style={{ marginRight: 20 }}>
                Ottima
              </span>
            </div>
          </div>

          <div className="sleep-hours-container">
            <h2
              className="sleep-title"
              style={{ marginTop: 160, fontSize: "2.1em" }}
            >
              Livello di stress
            </h2>
            {[
              { name: "Cronico, ingestibile", value: 1 },
              {
                name: "Ricorrente, a volte ingestibile",
                value: 2,
              },
              {
                name: "Occasionale, gestibile",
                value: 3,
              },
              { name: "Nessuno", value: 4 },
              { name: "Altro", value: 5 },
            ].map((i, ind) => {
              return (
                <div className="sleep-option">
                  <input
                    type="radio"
                    id={`option${i.value}`}
                    name="stress_level"
                    value={i.value}
                    onChange={handleChange}
                  />
                  <label for={`option${i.value}`}>
                    <span className="custom-radio"></span>
                    {i.name}
                  </label>
                </div>
              );
            })}
          </div>

          <div className="sleep-hours-container">
            <h2
              className="sleep-title"
              style={{ marginTop: 50, fontSize: "2.1em" }}
            >
              Sei un fumatore?
            </h2>
            {[
              { name: "Regolare", value: 1 },
              { name: "Occasionale", value: 2 },
              { name: "Non fumo", value: 3 },
            ].map((i, ind) => {
              return (
                <div className="sleep-option" key={ind}>
                  <input
                    type="radio"
                    id={`option1${i.value}`}
                    name="are_you_a_smoker"
                    value={i.value}
                    // checked={selectedValue == i.value} // Check the selected radio
                    onChange={handleChange} // Handle the change event
                  />
                  <label htmlFor={`option1${i.value}`}>
                    <span className="custom-radio"></span>
                    {i.name}
                  </label>
                </div>
              );
            })}
          </div>

          <div className="sleep-hours-container">
            <h2
              className="sleep-title"
              style={{ marginTop: 50, fontSize: "2.1em" }}
            >
              Bevi caffè?
            </h2>
            {[
              { name: "Non mi sveglio senza caffè", value: 1 },
              { name: "Senza caffè ho poca energia", value: 2 },
              { name: "Lo bevo quando sono stanco", value: 3 },
              { name: "Potrei farne a meno", value: 4 },
              { name: "Potrei farne a meno", value: 5 },
              { name: "Altro", value: 6 },
            ].map((i, ind) => {
              return (
                <div className="sleep-option" key={ind}>
                  <input
                    type="radio"
                    id={`option2${i.value}`}
                    name="do_you_drink_coffee"
                    value={i.value}
                    // checked={selectedValue == i.value} // Check the selected radio
                    onChange={handleChange} // Handle the change event
                  />
                  <label htmlFor={`option2${i.value}`}>
                    <span className="custom-radio"></span>
                    {i.name}
                  </label>
                </div>
              );
            })}
          </div>

          <div className="sleep-hours-container">
            <h2
              className="sleep-title"
              style={{ marginTop: 50, fontSize: "2.1em" }}
            >
              Come ti senti appena sveglio?
            </h2>
            {/* {["Fiacco e stanco", "Energico"].map((i, ind) => {
              return (
                <div className="sleep-option">
                  <input type="radio" id="option1" name="sleep" checked />
                  <label for="option1">
                    <span className="custom-radio"></span>
                    {i}
                  </label>
                </div>
              );
            })} */}
            {[
              { name: "Fiacco e stanco", value: 1 },
              { name: "Energico", value: 2 },
            ].map((i, ind) => {
              return (
                <div className="sleep-option" key={ind}>
                  <input
                    type="radio"
                    id={`option3${i.value}`}
                    name="how_do_you_feel_when_you_first_wake_up"
                    value={i.value}
                    // checked={selectedValue == i.value} // Check the selected radio
                    onChange={handleChange} // Handle the change event
                  />
                  <label htmlFor={`option3${i.value}`}>
                    <span className="custom-radio"></span>
                    {i.name}
                  </label>
                </div>
              );
            })}
          </div>

          <div className="text-input" style={{ marginTop: 50 }}>
            <div className="title">Soffri di qualche patologia?</div>
            <div className="input input-control">
              <input
                type="text"
                name={"do_you_suffer_from_any_medical_conditions"}
                id={"i"}
                onChange={handleChange}
                placeholder={"Entra Soffri di qualche patologia..."}
              />
            </div>
          </div>

          {/* ............................. */}

          {isLoading ? (
            <div style={containerStyle}>
              <div style={spinnerStyle}></div>
              <style>
                {`
              @keyframes spin {
                0% { transform: rotate(0deg); }
                100% { transform: rotate(360deg); }
              }
            `}
              </style>
            </div>
          ) : (
            <button
              type="button"
              className="button-default-no-icon"
              onClick={() => {
                handleBackClick();
              }}
              style={{ width: "100%", marginTop: 20 }}
            >
              continua
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default FivethStep;
