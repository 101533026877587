import React, { useEffect, useRef, useState } from "react";
// import Sidebar from "./components/Sidebar";
import "../../components/Users/Measurements.css";
import "../../components/Auth/LoginForm.css";
import { LeftArrowIcon } from "../../components/Reuseable/Icons";
import { useNavigate } from "react-router-dom";
import { notify } from "../../components/util/notify";
import axios from "axios";
import { BASE_URL } from "../../assets/constants";
import bannerImage from "../../assets/img/image@2x.png";

const UPTheMeasrment = () => {
  const [atheleteMeasurement, setAtheleteMeasurement] = useState({
    daily: {
      waist: "",
      weight: "",
      hips: "",
    },
    weekly: {
      chest: "",
      abdomen: "",
      buttocks: "",
      left_arm: "",
      right_arm: "",
      left_thigh: "",
      right_thigh: "",
      nutrition_feeling_hungry: 1,
      nutrition_how_closely_did_you_follow_the_diet_plan: 1,
      nutrition_level_of_gut_health: 1,
      well_being_mood_and_energy_level: 1,
      well_being_hours_of_sleep: "",
      training_how_has_your_performance_changed: 1,
      training_personal_notes: "",
    },
    monthly: {
      front_photo: "",
      back_photo: "",
      side_photo: "",
    },
  });
  const [isLoading, setIsLoading] = useState(false);
  const [formsType, setFormsType] = useState("daily");
  const navigate = useNavigate();
  const fileInputRef = useRef(null);
  const fileInputRef1 = useRef(null);
  const fileInputRef2 = useRef(null);
  const adminData = JSON.parse(localStorage.getItem("adminData"));
  const token = adminData?.token;

  const handleButtonClick = () => {
    fileInputRef?.current?.click();
  };
  const handleButtonClick1 = () => {
    fileInputRef1?.current?.click();
  };
  const handleButtonClick2 = () => {
    fileInputRef2?.current?.click();
  };
  const validateForm = () => {
    const { daily, weekly, monthly } = atheleteMeasurement;

    if (formsType === "daily") {
      // Check if any daily fields are empty
      return daily.waist !== "" && daily.weight !== "" && daily.hips !== "";
    } else if (formsType === "weekly") {
      // Check if any weekly fields are empty
      return (
        weekly.chest !== "" &&
        weekly.abdomen !== "" &&
        weekly.buttocks !== "" &&
        weekly.left_arm !== "" &&
        weekly.right_arm !== "" &&
        weekly.left_thigh !== "" &&
        weekly.right_thigh !== "" &&
        weekly.nutrition_feeling_hungry !== "" &&
        weekly.nutrition_how_closely_did_you_follow_the_diet_plan !== "" &&
        weekly.nutrition_level_of_gut_health !== "" &&
        weekly.well_being_mood_and_energy_level !== "" &&
        weekly.well_being_hours_of_sleep !== "" &&
        weekly.training_how_has_your_performance_changed !== "" &&
        weekly.training_personal_notes !== ""
      );
    } else if (formsType === "monthly") {
      // Check if any monthly fields are empty
      return (
        monthly.front_photo !== "" &&
        monthly.back_photo !== "" &&
        monthly.side_photo !== ""
      );
    }

    return false; // If no form type matches, return false
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    if (!validateForm()) {
      notify(true, "Required Fields are Missing.");
      setIsLoading(false);
    } else {
      try {
        let response = await axios.post(
          `${BASE_URL}athlete/measurement/store`,
          {
            ...atheleteMeasurement[formsType],
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response?.data?.status) {
          notify(false, response?.data?.message);
          setAtheleteMeasurement({
            daily: {
              waist: "",
              weight: "",
              hips: "",
            },
            weekly: {
              chest: "",
              abdomen: "",
              buttocks: "",
              left_arm: "",
              right_arm: "",
              left_thigh: "",
              right_thigh: "",
              nutrition_feeling_hungry: 1,
              nutrition_how_closely_did_you_follow_the_diet_plan: 1,
              nutrition_level_of_gut_health: 1,
              well_being_mood_and_energy_level: 1,
              well_being_hours_of_sleep: "",
              training_how_has_your_performance_changed: 1,
              training_personal_notes: "",
            },
            monthly: {
              front_photo: "",
              back_photo: "",
              side_photo: "",
            },
          });
          setIsLoading(false);
        }
      } catch (error) {
        const errorData = error?.response?.data?.error;

        if (errorData && typeof errorData === "object") {
          const errorMessages = Object.entries(errorData)
            .map(([field, messages]) => `${field}: ${messages.join(", ")}`)
            .join(" | ");

          notify(true, errorMessages);
        } else {
          notify(true, error?.response?.data?.message);
        }
        setIsLoading(false);
      }
    }
  };

  const handleMeasurements = (value, property) => {
    setAtheleteMeasurement({
      ...atheleteMeasurement,
      weekly: {
        ...atheleteMeasurement.weekly,
        [property]: formsType === "weekly" ? value : 1,
      },
    });
  };

  const handleBackClick = () => {
    navigate(-1);
  };

  const handleChange = (event, measurement) => {
    let { value, name } = event.target;
    setAtheleteMeasurement({
      ...atheleteMeasurement,
      [measurement]: {
        ...atheleteMeasurement[measurement],
        [name]: value,
      },
    });
  };

  const handleFileChange = (event, value) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => {
        setAtheleteMeasurement({
          ...atheleteMeasurement,
          monthly: { ...atheleteMeasurement.monthly, [value]: reader.result },
        });
      };

      reader.onerror = (error) => {
        console.error("Error reading file:", error);
      };
    }
  };

  const handleChangeRadio = (event) => {
    setFormsType(event?.target?.value);
  };

  useEffect(() => {
    if (formsType === "daily") {
      // Clear weekly and monthly values when switching to daily
      setAtheleteMeasurement((prevState) => ({
        ...prevState,
        weekly: {
          chest: "",
          abdomen: "",
          buttocks: "",
          left_arm: "",
          right_arm: "",
          left_thigh: "",
          right_thigh: "",
          nutrition_feeling_hungry: 1,
          nutrition_how_closely_did_you_follow_the_diet_plan: 1,
          nutrition_level_of_gut_health: 1,
          well_being_mood_and_energy_level: 1,
          well_being_hours_of_sleep: "",
          training_how_has_your_performance_changed: 1,
          training_personal_notes: "",
        },
        monthly: {
          front_photo: "",
          back_photo: "",
          side_photo: "",
        },
      }));
    } else if (formsType === "weekly") {
      // Clear daily and monthly values when switching to weekly
      setAtheleteMeasurement((prevState) => ({
        ...prevState,
        daily: {
          waist: "",
          weight: "",
          hips: "",
        },
        monthly: {
          front_photo: "",
          back_photo: "",
          side_photo: "",
        },
      }));
    } else if (formsType === "monthly") {
      // Clear daily and weekly values when switching to monthly
      setAtheleteMeasurement((prevState) => ({
        ...prevState,
        daily: {
          waist: "",
          weight: "",
          hips: "",
        },
        weekly: {
          chest: "",
          abdomen: "",
          buttocks: "",
          left_arm: "",
          right_arm: "",
          left_thigh: "",
          right_thigh: "",
          nutrition_feeling_hungry: 1,
          nutrition_how_closely_did_you_follow_the_diet_plan: 1,
          nutrition_level_of_gut_health: 1,
          well_being_mood_and_energy_level: 1,
          well_being_hours_of_sleep: "",
          training_how_has_your_performance_changed: 1,
          training_personal_notes: "",
        },
      }));
    }
  }, [formsType]);
  const spinnerStyle = {
    border: "3px solid #f3f3f3",
    borderTop: "3px solid #a0fc6e",
    borderRadius: "50%",
    width: "30px",
    height: "30px",
    animation: "spin 1s linear infinite",
  };

  const containerStyle = {
    display: "flex",
    justifyContent: "center",
    marging: "0 auto",
    backgroundColor: "transparent",
  };

  return (
    <div className="container">
      <div className="top-bar">
        <button onClick={handleBackClick} className="icon">
          <LeftArrowIcon />
        </button>
        <div className="title">Aggiorna le Misurazioni</div>
      </div>

      <div className="row">
        <div className="col-md-4">
          <div
            style={{
              border: "1px solid gray",
              borderRadius: 10,
              padding: 10,
              position: "relative",
            }}
          >
            <div className="d-flex justify-content-between align-items-baseline">
              <h3 className="headingStyling">MISURAZIONI GIORNALIERR</h3>
              <div className="sleep-option">
                <input
                  type="radio"
                  id="option1"
                  name="objective"
                  value={"daily"}
                  checked={formsType === "daily"}
                  onChange={handleChangeRadio}
                />
                <label for="option1">
                  <span className="custom-radio"></span>
                </label>
              </div>
            </div>
            <div className="showBanner">
              {" "}
              <img src={bannerImage} width={"100%"} height={220} />
            </div>

            {[
              { name: "Girovita", value: "waist" },
              { name: "PESO", value: "weight" },
              { name: "FIANCHI", value: "hips" },
            ].map((i, ind) => {
              return (
                <div>
                  <div className="text-input">
                    <div className="title">{i.name}</div>
                    <div className="input input-control">
                      <input
                        type="number"
                        name={i.value}
                        id={i.value}
                        disabled={
                          formsType === "weekly" ||
                          (formsType === "monthly" && true)
                        }
                        value={atheleteMeasurement?.daily[i.name]}
                        placeholder={(ind == 1 && "kg") || "cm"}
                        onChange={(event) => handleChange(event, "daily")}
                      />
                    </div>
                  </div>
                  <p className="help-text">
                    Non sai come fare? Tappa qui e vedi la foto
                  </p>
                </div>
              );
            })}
          </div>
        </div>

        <div className="col-md-4">
          <div
            style={{ border: "1px solid gray", borderRadius: 10, padding: 10 }}
          >
            <div className="d-flex justify-content-between align-items-baseline">
              <h3 className="headingStyling">MISURAZIONI SETTIMANALI</h3>
              <div className="sleep-option">
                <input
                  type="radio"
                  id="option2"
                  name="objective"
                  value={"weekly"}
                  checked={formsType === "weekly"}
                  onChange={handleChangeRadio}
                />
                <label for="option2">
                  <span className="custom-radio"></span>
                </label>
              </div>
            </div>

            {[
              { name: "PETTO", value: "chest" },
              { name: "ADDOME", value: "abdomen" },
              { name: "GLUTEI", value: "buttocks" },
            ].map((i, ind) => {
              return (
                <div>
                  <div>
                    <div className="text-input">
                      <div className="title">{i.name}</div>
                      <div className="input input-control">
                        <input
                          type="number"
                          name={i.value}
                          id={i.value}
                          disabled={
                            formsType === "daily" ||
                            (formsType === "monthly" && true)
                          }
                          placeholder={"cm"}
                          value={atheleteMeasurement?.weekly[i.value]}
                          onChange={(event) => handleChange(event, "weekly")}
                        />
                      </div>
                    </div>
                    <p className="help-text">
                      Non sai come fare? Tappa qui e vedi la foto
                    </p>
                  </div>
                </div>
              );
            })}
            {[1, 2].map((i, ind) => {
              return (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <div className="text-input">
                    <div className="title">
                      {ind == 0 ? "BRACCIO SX" : "COSCIA SX"}
                    </div>
                    <div className="input input-control">
                      <input
                        type="text"
                        id={"i"}
                        placeholder={"cm"}
                        name={ind == 0 ? "left_arm" : "left_thigh"}
                        disabled={
                          formsType === "daily" ||
                          (formsType === "monthly" && true)
                        }
                        onChange={(event) => handleChange(event, "weekly")}
                      />
                    </div>
                    <p className="help-text">
                      Non sai come fare? Tappa qui e vedi la foto
                    </p>
                  </div>
                  <div className="text-input" style={{ marginLeft: 5 }}>
                    <div className="title">
                      {ind == 0 ? "BRACCIO DX" : "COSCIA DX"}
                    </div>
                    <div className="input input-control">
                      <input
                        type="text"
                        name={ind == 0 ? "right_arm" : "right_thigh"}
                        id={"i"}
                        placeholder={"cm"}
                        disabled={
                          formsType === "daily" ||
                          (formsType === "monthly" && true)
                        }
                        onChange={(event) => handleChange(event, "weekly")}
                      />
                    </div>
                  </div>
                </div>
              );
            })}

            <div>
              <h3 className="headingStyling">NUTRIZIONE</h3>
            </div>

            <div>
              {[
                {
                  name: "SENSAZIONE DI FAME",
                  value: "nutrition_feeling_hungry",
                },
                {
                  name: "Quanto hai seguito il piano di alimentazione?",
                  value: "nutrition_how_closely_did_you_follow_the_diet_plan",
                },
                {
                  name: "Livello di benessere intestinale",
                  value: "nutrition_level_of_gut_health",
                },
              ].map((i, ind) => {
                return (
                  <div className="hunger-level-container">
                    <div className="title">{i.name}</div>
                    <div className="barP">
                      <div
                        style={{
                          display: "flex",
                        }}
                      >
                        <div
                          className="speedQuality"
                          disabled={
                            formsType === "daily" ||
                            (formsType === "monthly" && true)
                          }
                          onClick={() => handleMeasurements(1, i.value)}
                        >
                          <div className="isQ"></div>
                        </div>
                        <div
                          className="speedQuality"
                          onClick={() => handleMeasurements(2, i.value)}
                        >
                          <div className="isQ"></div>
                        </div>
                        <div
                          className="speedQuality"
                          onClick={() => handleMeasurements(3, i.value)}
                        >
                          <div className="isQ"></div>
                        </div>
                        <div
                          className="speedQuality"
                          onClick={() => handleMeasurements(4, i.value)}
                        >
                          <div className="isQ"></div>
                        </div>
                        <div
                          className="speedQuality"
                          onClick={() => handleMeasurements(5, i.value)}
                        >
                          <div className="isQ"></div>
                        </div>
                      </div>
                      <div
                        className="colorBar"
                        style={{
                          width:
                            atheleteMeasurement.weekly[i.value] == 1
                              ? "30%"
                              : atheleteMeasurement.weekly[i.value] == 2
                              ? "50%"
                              : atheleteMeasurement.weekly[i.value] == 3
                              ? "70%"
                              : atheleteMeasurement.weekly[i.value] == 4
                              ? "90%"
                              : "100%",
                        }}
                      ></div>
                    </div>
                    <div className="range-labels">
                      <span>{atheleteMeasurement.weekly[i.value]}</span>
                      <span style={{ marginRight: 25 }}>5</span>
                    </div>
                  </div>
                );
              })}
            </div>
            <div>
              <h3 className="headingStyling">Benessere</h3>
            </div>

            <div>
              <div className="hunger-level-container">
                <div className="title">Livello di umore ed energia</div>
                <div className="barP">
                  <div
                    style={{
                      display: "flex",
                    }}
                  >
                    <div
                      className="speedQuality"
                      onClick={() =>
                        handleMeasurements(
                          1,
                          "well_being_mood_and_energy_level"
                        )
                      }
                    >
                      <div className="isQ"></div>
                    </div>
                    <div
                      className="speedQuality"
                      onClick={() =>
                        handleMeasurements(
                          2,
                          "well_being_mood_and_energy_level"
                        )
                      }
                    >
                      <div className="isQ"></div>
                    </div>
                    <div
                      className="speedQuality"
                      onClick={() =>
                        handleMeasurements(
                          3,
                          "well_being_mood_and_energy_level"
                        )
                      }
                    >
                      <div className="isQ"></div>
                    </div>
                    <div
                      className="speedQuality"
                      onClick={() =>
                        handleMeasurements(
                          4,
                          "well_being_mood_and_energy_level"
                        )
                      }
                    >
                      <div className="isQ"></div>
                    </div>
                    <div
                      className="speedQuality"
                      onClick={() =>
                        handleMeasurements(
                          5,
                          "well_being_mood_and_energy_level"
                        )
                      }
                    >
                      <div className="isQ"></div>
                    </div>
                  </div>
                  <div
                    className="colorBar"
                    style={{
                      width:
                        atheleteMeasurement.weekly[
                          "well_being_mood_and_energy_level"
                        ] == 1
                          ? "30%"
                          : atheleteMeasurement.weekly[
                              "well_being_mood_and_energy_level"
                            ] == 2
                          ? "50%"
                          : atheleteMeasurement.weekly[
                              "well_being_mood_and_energy_level"
                            ] == 3
                          ? "70%"
                          : atheleteMeasurement.weekly[
                              "well_being_mood_and_energy_level"
                            ] == 4
                          ? "90%"
                          : "100%",
                    }}
                  ></div>
                </div>
                <div className="range-labels">
                  <span>
                    {
                      atheleteMeasurement.weekly[
                        "well_being_mood_and_energy_level"
                      ]
                    }
                  </span>
                  <span style={{ marginRight: 25 }}>5</span>
                </div>
              </div>
            </div>

            <div>
              <div className="sleep-hours-container">
                <h2 className="sleep-title">ORE DI SONNO</h2>
                <div className="sleep-option">
                  <input
                    type="radio"
                    id="option55"
                    name="well_being_hours_of_sleep"
                    value={1}
                    disabled={
                      formsType === "daily" || (formsType === "monthly" && true)
                    }
                    onChange={(event) => handleChange(event, "weekly")}
                  />
                  <label for="option55">
                    <span className="custom-radio"></span>
                    Meno di 3 ore
                  </label>
                </div>
                <div className="sleep-option">
                  <input
                    type="radio"
                    id="option56"
                    value={2}
                    name="well_being_hours_of_sleep"
                    disabled={
                      formsType === "daily" || (formsType === "monthly" && true)
                    }
                    onChange={(event) => handleChange(event, "weekly")}
                  />
                  <label for="option56">
                    <span className="custom-radio"></span>
                    Da 3 a 5 ore
                  </label>
                </div>
                <div className="sleep-option">
                  <input
                    type="radio"
                    id="option32"
                    value={3}
                    name="well_being_hours_of_sleep"
                    onChange={(event) => handleChange(event, "weekly")}
                    disabled={
                      formsType === "daily" || (formsType === "monthly" && true)
                    }
                  />
                  <label for="option32">
                    <span className="custom-radio"></span>
                    Da 6 a 7 ore
                  </label>
                </div>
                <div className="sleep-option">
                  <input
                    type="radio"
                    value={4}
                    id="option41"
                    name="well_being_hours_of_sleep"
                    onChange={(event) => handleChange(event, "weekly")}
                    disabled={
                      formsType === "daily" || (formsType === "monthly" && true)
                    }
                  />
                  <label for="option41">
                    <span className="custom-radio"></span>
                    Più di 7 ore
                  </label>
                </div>
              </div>
            </div>

            <div style={{ marginTop: 20, marginBottom: 20 }}>
              <div className="hunger-level-container">
                <div className="title">Com’è cambiata la tua performance?</div>
                <div className="barP">
                  <div
                    style={{
                      display: "flex",
                    }}
                  >
                    <div
                      className="speedQuality"
                      onClick={() =>
                        handleMeasurements(
                          1,
                          "training_how_has_your_performance_changed"
                        )
                      }
                    >
                      <div className="isQ"></div>
                    </div>
                    <div
                      className="speedQuality"
                      onClick={() =>
                        handleMeasurements(
                          2,
                          "training_how_has_your_performance_changed"
                        )
                      }
                    >
                      <div className="isQ"></div>
                    </div>
                    <div
                      className="speedQuality"
                      onClick={() =>
                        handleMeasurements(
                          3,
                          "training_how_has_your_performance_changed"
                        )
                      }
                    >
                      <div className="isQ"></div>
                    </div>
                    <div
                      className="speedQuality"
                      onClick={() =>
                        handleMeasurements(
                          4,
                          "training_how_has_your_performance_changed"
                        )
                      }
                    >
                      <div className="isQ"></div>
                    </div>
                    <div
                      className="speedQuality"
                      onClick={() =>
                        handleMeasurements(
                          5,
                          "well_being_mood_and_energy_level"
                        )
                      }
                    >
                      <div className="isQ"></div>
                    </div>
                  </div>
                  <div
                    className="colorBar"
                    style={{
                      width:
                        atheleteMeasurement.weekly[
                          "training_how_has_your_performance_changed"
                        ] == 1
                          ? "30%"
                          : atheleteMeasurement.weekly[
                              "training_how_has_your_performance_changed"
                            ] == 2
                          ? "50%"
                          : atheleteMeasurement.weekly[
                              "training_how_has_your_performance_changed"
                            ] == 3
                          ? "70%"
                          : atheleteMeasurement.weekly[
                              "training_how_has_your_performance_changed"
                            ] == 4
                          ? "90%"
                          : "100%",
                    }}
                  ></div>
                </div>
                <div className="range-labels">
                  <span>Peggiorata</span>
                  <span style={{ marginRight: 25 }}>Migliorata</span>
                </div>
              </div>
            </div>

            <div className="text-input">
              <div className="title">annotazioni personali</div>
              <div className="input input-control">
                <input
                  type="text"
                  name="training_personal_notes"
                  onChange={(event) => handleChange(event, "weekly")}
                  value={atheleteMeasurement?.weekly.training_personal_notes}
                  id=""
                  placeholder={"Input text"}
                  disabled={
                    formsType === "daily" || (formsType === "monthly" && true)
                  }
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div
            style={{ border: "1px solid gray", borderRadius: 10, padding: 10 }}
          >
            <div className="d-flex justify-content-between align-items-baseline">
              <h3 className="headingStyling">MISURAZIONI MENSILI</h3>
              <div className="sleep-option">
                <input
                  type="radio"
                  id="option3"
                  name="objective"
                  value={"monthly"}
                  checked={formsType === "monthly"}
                  onChange={handleChangeRadio}
                />
                <label for="option3">
                  <span className="custom-radio"></span>
                </label>
              </div>
            </div>
            <div className="showBanner">
              <img src={bannerImage} width={"100%"} height={220} />
            </div>

            <div>
              <div className="text-input">
                <div className="title">FOTO FRONTALE</div>

                <div
                  className="upload-button"
                  style={{
                    backgroundColor: "#7fff00",
                    border: 0,
                    color: "#000",
                  }}
                >
                  <span className="upload-icon">&#10003;</span>
                  <span className="upload-text">NOME FOTO JPEG</span>
                  <span className="upload-text"></span>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      width: 40,
                      height: 45,
                      border: atheleteMeasurement.monthly?.front_photo
                        ? "0"
                        : "1px dashed white",
                    }}
                  >
                    {atheleteMeasurement.monthly?.front_photo && (
                      <img
                        src={atheleteMeasurement.monthly?.front_photo}
                        width={40}
                      />
                    )}
                    <input
                      type="file"
                      ref={fileInputRef}
                      accept="image/*"
                      style={{ display: "none" }}
                      disabled={
                        formsType === "daily" ||
                        (formsType === "weekly" && true)
                      }
                      onChange={(event) =>
                        handleFileChange(event, "front_photo")
                      } // Handle the file selection
                    />
                  </div>
                  <div
                    className="upload-button"
                    style={{ width: 150 }}
                    onClick={handleButtonClick}
                  >
                    <span className="upload-icon">&#8593;</span>
                    <span className="upload-text">UPLOAD</span>
                    <span className="upload-text"></span>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      width: 40,
                      height: 45,
                      border: atheleteMeasurement.monthly?.back_photo
                        ? "0"
                        : "1px dashed white",
                    }}
                  >
                    {atheleteMeasurement.monthly?.back_photo && (
                      <img
                        src={atheleteMeasurement.monthly?.back_photo}
                        width={40}
                      />
                    )}
                    <input
                      type="file"
                      ref={fileInputRef1}
                      accept="image/*"
                      style={{ display: "none" }}
                      disabled={
                        formsType === "daily" ||
                        (formsType === "weekly" && true)
                      }
                      onChange={(event) =>
                        handleFileChange(event, "back_photo")
                      } // Handle the file selection
                    />
                  </div>
                  <div
                    className="upload-button"
                    style={{ width: 150 }}
                    onClick={handleButtonClick1}
                  >
                    <span className="upload-icon">&#8593;</span>
                    <span className="upload-text">UPLOAD</span>
                    <span className="upload-text"></span>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      width: 40,
                      height: 45,
                      border: atheleteMeasurement.monthly?.side_photo
                        ? "0"
                        : "1px dashed white",
                    }}
                  >
                    {atheleteMeasurement.monthly?.side_photo && (
                      <img
                        src={atheleteMeasurement.monthly?.side_photo}
                        width={40}
                      />
                    )}
                    <input
                      type="file"
                      ref={fileInputRef2}
                      accept="image/*"
                      style={{ display: "none" }}
                      disabled={
                        formsType === "daily" ||
                        (formsType === "weekly" && true)
                      }
                      onChange={(event) =>
                        handleFileChange(event, "side_photo")
                      } // Handle the file selection
                    />
                  </div>
                  <div
                    className="upload-button"
                    style={{ width: 150 }}
                    onClick={handleButtonClick2}
                  >
                    <span className="upload-icon">&#8593;</span>
                    <span className="upload-text">UPLOAD</span>
                    <span className="upload-text"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        {isLoading ? (
          <div style={containerStyle}>
            <div style={spinnerStyle}></div>
            <style>
              {`
            @keyframes spin {
              0% { transform: rotate(0deg); }
              100% { transform: rotate(360deg); }
            }
          `}
            </style>
          </div>
        ) : (
          <button
            type="button"
            className="button-default-no-icon"
            onClick={() => {
              handleSubmit();
            }}
            style={{ width: "100%", marginTop: 20 }}
          >
            continua
          </button>
        )}
      </div>
    </div>
  );
};

export default UPTheMeasrment;
