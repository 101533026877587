import React, { useState, useEffect } from "react";
import "./onboardingCss.css";
import "../../components/Users/Measurements.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "../../assets/constants";
import { notify } from "../../components/util/notify";

function SixthStep({}) {
  const adminData = JSON.parse(localStorage.getItem("adminData"));
  const token = adminData?.token;
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [countdown, setCountdown] = useState(3);
  const [showVideo, setShowVideo] = useState(false);
  const [atheleteData, setAtheleteData] = useState({
    how_long_have_you_been_training: "",
    what_kind_of_training_did_you_do: "",
    have_you_had_any_injuries_in_the_past: "",
    how_many_times_do_you_train_a_week: 1,
    current_floor_exercise_performances: {
      pushups: {
        is_performed: false,
        qty: 0,
      },
      dips: {
        is_performed: false,
        qty: 0,
      },
      hspu: {
        is_performed: false,
        qty: 0,
      },
      pullups: {
        is_performed: false,
        qty: 0,
      },
      body_row: {
        is_performed: false,
        qty: 0,
      },
    },
    current_performances_with_weights: {
      flat_bench_with_dumbbells: {
        is_performed: false,
        reps: 0,
        weight: 0,
      },
      parallel_dips: {
        is_performed: false,
        reps: 0,
        weight: 0,
      },
      flat_bench_press: {
        is_performed: false,
        reps: 0,
        weight: 0,
      },
      weighted_dips: {
        is_performed: false,
        reps: 0,
        weight: 0,
      },
      military_press: {
        is_performed: false,
        reps: 0,
        weight: 0,
      },
      lat_machine: {
        is_performed: false,
        reps: 0,
        weight: 0,
      },
      weighted_pull_ups: {
        is_performed: false,
        reps: 0,
        weight: 0,
      },
      rowing: {
        is_performed: false,
        reps: 0,
        weight: 0,
      },
    },
    current_performance_in_the_legs_compartment: {
      bodyweight_squat: {
        is_performed: false,
        reps: 0,
        weight: 0,
      },
      barbell_squat: {
        is_performed: false,
        reps: 0,
        weight: 0,
      },
      bodyweight_weighted_squat: {
        is_performed: false,
        reps: 0,
        weight: 0,
      },
      barbell_deadlifts: {
        is_performed: false,
        reps: 0,
        weight: 0,
      },
    },
  });

  const updateQty = (exercise, operation) => {
    setAtheleteData((prevState) => {
      const currentQty =
        prevState.current_floor_exercise_performances[exercise].qty || 0;
      const updatedQty =
        operation === "increment"
          ? currentQty + 1
          : currentQty > 0
          ? currentQty - 1
          : 0;

      return {
        ...prevState,
        current_floor_exercise_performances: {
          ...prevState.current_floor_exercise_performances,
          [exercise]: {
            ...prevState.current_floor_exercise_performances[exercise],
            qty: updatedQty,
          },
        },
      };
    });
  };
  const updateQtyWithWeight = (exercise, field, operation, mai) => {
    setAtheleteData((prevState) => {
      const currentValue =
        prevState.current_performances_with_weights[exercise][field];
      const updatedValue =
        operation === "increment"
          ? currentValue + 1
          : currentValue > 0
          ? currentValue - 1
          : 0;

      return {
        ...prevState,
        current_performances_with_weights: {
          ...prevState.current_performances_with_weights,
          [exercise]: {
            ...prevState.current_performances_with_weights[exercise],
            [field]: updatedValue,
          },
        },
      };
    });
  };
  const updateQtyWithWeight2 = (exercise, field, operation, mai) => {
    setAtheleteData((prevState) => {
      const currentValue =
        prevState.current_performance_in_the_legs_compartment[exercise][field];
      const updatedValue =
        operation === "increment"
          ? currentValue + 1
          : currentValue > 0
          ? currentValue - 1
          : 0;

      return {
        ...prevState,
        current_performance_in_the_legs_compartment: {
          ...prevState.current_performance_in_the_legs_compartment,
          [exercise]: {
            ...prevState.current_performance_in_the_legs_compartment[exercise],
            [field]: updatedValue,
          },
        },
      };
    });
  };

  const handleTrainingInAWeek = (value) => {
    setAtheleteData({
      ...atheleteData,
      how_many_times_do_you_train_a_week: value,
    });
  };

  const handleChange = (event, key) => {
    const { value, name, checked, type } = event?.target;
    if (key) {
      setAtheleteData((prevState) => ({
        ...prevState,
        [key]: {
          ...prevState[key],
          [name]: {
            ...prevState[key][name],
            is_performed: checked,
          },
        },
      }));
      return;
    }
    setAtheleteData({
      ...atheleteData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  // const handleBackClick = () => {
  //   navigate("/end");
  // };

  const validateData = (data) => {
    let errors = [];

    for (let key in data) {
      if (typeof data[key] === "string" && data[key].trim() === "") {
        errors.push(`${key.replace(/_/g, " ")} is required.`);
      }
    }

    const checkExercises = (exercises, sectionName) => {
      let hasSelectedExercise = false;

      for (let exercise in exercises) {
        const { is_performed, qty, reps, weight } = exercises[exercise];

        if (is_performed) {
          hasSelectedExercise = true;

          if (
            (qty !== undefined && qty <= 0) ||
            (reps !== undefined && reps <= 0) ||
            (weight !== undefined && weight <= 0)
          ) {
            errors.push(
              `In ${sectionName}, ${exercise.replace(
                /_/g,
                " "
              )} must have valid repetitions or weight values greater than 0.`
            );
          }
        }
      }

      if (!hasSelectedExercise) {
        errors.push(`Please select at least one exercise in ${sectionName}.`);
      }
    };

    checkExercises(data.current_floor_exercise_performances, "Floor Exercises");

    checkExercises(data.current_performances_with_weights, "Weight Exercises");

    checkExercises(
      data.current_performance_in_the_legs_compartment,
      "Leg Exercises"
    );

    if (errors.length > 0) {
      notify(true, errors.join(" "));
      return false;
    }

    return true;
  };

  const handleBackClick = async () => {
    setIsLoading(true);
    if (validateData(atheleteData)) {
      try {
        let response = await axios.post(
          `${BASE_URL}athlete/step-5`,
          atheleteData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response?.data?.status) {
          let step = await axios.get(`${BASE_URL}athlete/step-form`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          let steps = step?.data?.forms_completed;
          localStorage.setItem("onboardingProgress", JSON.stringify(steps));
          notify(false, "Passaggio 5 completato");
          navigate("/end");
        }
      } catch (error) {
        const errorMessages = error?.response?.data?.message;

        if (errorMessages) {
          const combinedErrors = Object.values(errorMessages).flat().join("\n");

          notify(true, combinedErrors);
        } else {
          notify(true, "Something went wrong!");
        }
      }
    }
    setIsLoading(false);
  };

  const spinnerStyle = {
    border: "3px solid #f3f3f3",
    borderTop: "3px solid #a0fc6e",
    borderRadius: "50%",
    width: "30px",
    height: "30px",
    animation: "spin 1s linear infinite",
    margin: "0 auto",
  };

  const containerStyle = {
    display: "flex",
    justifyContent: "center",
    marginTop: "10px",
  };

  return (
    <div className="mainContainer">
      <div>
        <div
          className="hunger-level-container1"
          style={{
            backgroundColor: "#080808",
            marginTop: 20,
            padding: 20,
            width: "25em",
          }}
        >
          <div className="title" style={{ fontSize: "2em" }}>
            allenamento
          </div>

          <div className="sprateColorsStep">
            <div className="colorBar" style={{ height: 8 }}></div>
            <div
              className="colorBar"
              style={{ height: 6, marginLeft: 10 }}
            ></div>
            <div
              className="colorBar"
              style={{ height: 6, marginLeft: 10 }}
            ></div>
            <div
              className="colorBar"
              style={{ height: 6, marginLeft: 10 }}
            ></div>
            <div
              className="colorBar"
              style={{ height: 6, marginLeft: 10 }}
            ></div>
          </div>

          <div className="range-labels" style={{ marginTop: -15 }}>
            <span className="label-left" style={{ color: "#7A7A7A" }}>
              Step 5 di 5
            </span>
          </div>

          {[
            {
              name: "Da quanto tempo ti alleni",
              value: "how_long_have_you_been_training",
            },
            {
              name: "Che tipo di allenamento hai seguito",
              value: "what_kind_of_training_did_you_do",
            },
            {
              name: "Hai avuto infortuni in passato",
              value: "have_you_had_any_injuries_in_the_past",
            },
          ].map((i, ind) => {
            return (
              <div className="text-input" style={{ marginTop: 50 }}>
                <div className="title">{i.name} ?</div>
                <div className="input input-control">
                  <input
                    type="text"
                    name={i.value}
                    id={i.value}
                    onChange={handleChange}
                    placeholder={`Entra ${i?.name} ...`}
                  />
                </div>
              </div>
            );
          })}

          <div
            className="hunger-level-container"
            style={{ float: "left", marginTop: 50 }}
          >
            <div className="title" style={{ width: 350 }}>
              Quante volte ti alleni alla settimana?
            </div>
            <div className="barP">
              <div
                style={{
                  display: "flex",
                }}
              >
                <div
                  className="speedQuality"
                  onClick={() => handleTrainingInAWeek(1)}
                >
                  <div className="isQ"></div>
                </div>
                <div
                  className="speedQuality"
                  onClick={() => handleTrainingInAWeek(2)}
                >
                  <div className="isQ"></div>
                </div>
                <div
                  className="speedQuality"
                  onClick={() => handleTrainingInAWeek(3)}
                >
                  <div className="isQ"></div>
                </div>
                <div
                  className="speedQuality"
                  onClick={() => handleTrainingInAWeek(4)}
                >
                  <div className="isQ"></div>
                </div>
                <div
                  className="speedQuality"
                  onClick={() => handleTrainingInAWeek(5)}
                >
                  <div className="isQ"></div>
                </div>
              </div>
              <div
                className="colorBar"
                style={{
                  width:
                    atheleteData.how_many_times_do_you_train_a_week == 1
                      ? 100
                      : atheleteData.how_many_times_do_you_train_a_week == 2
                      ? 160
                      : atheleteData.how_many_times_do_you_train_a_week == 3
                      ? 230
                      : atheleteData.how_many_times_do_you_train_a_week == 4
                      ? 290
                      : 330,
                }}
              ></div>
            </div>
            <div className="range-labels">
              <span className="label-left">
                {atheleteData.how_many_times_do_you_train_a_week}
              </span>
              <span className="label-right" style={{ marginRight: 20 }}>
                +5
              </span>
            </div>
          </div>

          <div className="sleep-hours-container" style={{ marginTop: 20 }}>
            <h2 className="sleep-title" style={{ fontSize: "2em" }}>
              Performance attuali a Corpo Libero
            </h2>
            {[
              {
                name: "Piegamenti sulle braccia (pushups)",
                value: "current_floor_exercise_performances",
                value2: "pushups",
                is_performed:
                  atheleteData.current_floor_exercise_performances.pushups
                    .is_performed,
              },
              {
                name: "Dips alle parallele",
                value: "current_floor_exercise_performances",
                value2: "dips",
                is_performed:
                  atheleteData.current_floor_exercise_performances.dips
                    .is_performed,
              },
              {
                name: "Piegamenti in verticale (HSPU)",
                value: "current_floor_exercise_performances",
                value2: "hspu",
                is_performed:
                  atheleteData.current_floor_exercise_performances.hspu
                    .is_performed,
              },
              {
                name: "Trazioni alla sbarra (pullups)",
                value: "current_floor_exercise_performances",
                value2: "pullups",
                is_performed:
                  atheleteData.current_floor_exercise_performances.pullups
                    .is_performed,
              },
              {
                name: "Trazioni orizzontali (body row)",
                value: "current_floor_exercise_performances",
                value2: "body_row",
                is_performed:
                  atheleteData.current_floor_exercise_performances.body_row
                    .is_performed,
              },
            ].map((i, ind) => {
              return (
                <div key={ind}>
                  <div className="sleep-option" key={ind}>
                    <input
                      type="checkbox"
                      id={`eatoption${ind}`}
                      name={i.value2}
                      // checked
                      // checked={!!atheleteData[i.value]} // Control the checked state based on the selected options
                      onChange={(event) => handleChange(event, i.value)} // Handle change event
                      style={{
                        borderRadius: 5,
                        border: "1px solid lightgray",
                      }}
                    />
                    <label htmlFor={`eatoption${ind}`}>
                      <span className="custom-checkbox"></span>
                      {i.name}
                    </label>
                  </div>
                  {i.is_performed && (
                    <div className="counter-container">
                      <label htmlFor="repetitions" className="counter-label">
                        Numero ripetizioni
                      </label>
                      <div className="counter-controls">
                        <button
                          className="counter-btn"
                          id="decrement"
                          onClick={() => updateQty(i.value2, "decrement")}
                        >
                          &#x2212;
                        </button>
                        <span id="count" className="counter-value">
                          {
                            atheleteData.current_floor_exercise_performances[
                              i.value2
                            ].qty
                          }
                        </span>
                        <button
                          className="counter-btn"
                          id="increment"
                          onClick={() => updateQty(i.value2, "increment")}
                        >
                          &#x2b;
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
          </div>

          {/* ............................................................ */}

          <div className="sleep-hours-container" style={{ marginTop: 20 }}>
            <h2 className="sleep-title" style={{ fontSize: "2em" }}>
              Performance attuali con Pesi
            </h2>
            {[
              {
                name: "Panca piana con manubri",
                value: "current_performances_with_weights",
                value2: "flat_bench_with_dumbbells",
                is_performed:
                  atheleteData.current_performances_with_weights
                    .flat_bench_with_dumbbells.is_performed,
              },
              {
                name: "Dips alle parallele",
                value: "current_performances_with_weights",
                value2: "parallel_dips",
                is_performed:
                  atheleteData.current_performances_with_weights.parallel_dips
                    .is_performed,
              },
              {
                name: "Panca piana con bilancere",
                value: "current_performances_with_weights",
                value2: "flat_bench_press",
                is_performed:
                  atheleteData.current_performances_with_weights
                    .flat_bench_press.is_performed,
              },
              {
                name: "Dips zavorrati",
                value: "current_performances_with_weights",
                value2: "weighted_dips",
                is_performed:
                  atheleteData.current_performances_with_weights.weighted_dips
                    .is_performed,
              },
              {
                name: "Military press / lento avanti",
                value: "current_performances_with_weights",
                value2: "military_press",
                is_performed:
                  atheleteData.current_performances_with_weights.military_press
                    .is_performed,
              },
              {
                name: "Lat machine",
                value: "current_performances_with_weights",
                value2: "lat_machine",
                is_performed:
                  atheleteData.current_performances_with_weights.lat_machine
                    .is_performed,
              },
              {
                name: "Trazioni zavorrate",
                value: "current_performances_with_weights",
                value2: "weighted_pull_ups",
                is_performed:
                  atheleteData.current_performances_with_weights
                    .weighted_pull_ups.is_performed,
              },
              {
                name: "Rematore",
                value: "current_performances_with_weights",
                value2: "rowing",
                is_performed:
                  atheleteData.current_performances_with_weights.rowing
                    .is_performed,
              },
            ].map((i, ind) => {
              return (
                <div key={ind}>
                  <div className="sleep-option" key={ind}>
                    <input
                      type="checkbox"
                      id={`eatoption1${ind}`}
                      name={i.value2}
                      // checked
                      // checked={!!atheleteData[i.value]} // Control the checked state based on the selected options
                      onChange={(event) => handleChange(event, i.value)} // Handle change event
                      style={{
                        borderRadius: 5,
                        border: "1px solid lightgray",
                      }}
                    />
                    <label htmlFor={`eatoption1${ind}`}>
                      <span className="custom-checkbox"></span>
                      {i.name}
                    </label>
                  </div>
                  {i.is_performed && (
                    <div>
                      <div className="counter-container">
                        <label htmlFor="repetitions" className="counter-label">
                          Numero ripetizioni
                        </label>
                        <div className="counter-controls">
                          <button
                            className="counter-btn"
                            id="decrement"
                            onClick={() =>
                              updateQtyWithWeight(i.value2, "reps", "decrement")
                            }
                          >
                            &#x2212;
                          </button>
                          <span id="count" className="counter-value">
                            {
                              atheleteData.current_performances_with_weights[
                                i.value2
                              ].reps
                            }
                          </span>
                          <button
                            className="counter-btn"
                            id="increment"
                            onClick={() =>
                              updateQtyWithWeight(i.value2, "reps", "increment")
                            }
                          >
                            &#x2b;
                          </button>
                        </div>
                      </div>
                      <div className="counter-container">
                        <label htmlFor="weight" className="counter-label">
                          Peso in kg
                        </label>
                        <div className="counter-controls">
                          <button
                            className="counter-btn"
                            id="decrement"
                            onClick={() =>
                              updateQtyWithWeight(
                                i.value2,
                                "weight",
                                "decrement"
                              )
                            }
                          >
                            &#x2212;
                          </button>
                          <span id="count" className="counter-value">
                            {
                              atheleteData.current_performances_with_weights[
                                i.value2
                              ].weight
                            }
                          </span>
                          <button
                            className="counter-btn"
                            id="increment"
                            onClick={() =>
                              updateQtyWithWeight(
                                i.value2,
                                "weight",
                                "increment"
                              )
                            }
                          >
                            &#x2b;
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
          </div>

          {/* ............................... */}

          <div className="sleep-hours-container" style={{ marginTop: 20 }}>
            <h2 className="sleep-title" style={{ fontSize: "2em" }}>
              Performance attuali compartimento Gambe
            </h2>
            {[
              {
                name: "Squat a corpo libero",
                value: "current_performance_in_the_legs_compartment",
                value2: "bodyweight_squat",
                is_performed:
                  atheleteData.current_performance_in_the_legs_compartment
                    .bodyweight_squat.is_performed,
              },
              {
                name: "Squat con bilanciere / sovraccarichi",
                value: "current_performance_in_the_legs_compartment",
                value2: "barbell_squat",
                is_performed:
                  atheleteData.current_performance_in_the_legs_compartment
                    .barbell_squat.is_performed,
              },
              {
                name: "Squat a corpo libero / sovraccarichi",
                value: "current_performance_in_the_legs_compartment",
                value2: "bodyweight_weighted_squat",
                is_performed:
                  atheleteData.current_performance_in_the_legs_compartment
                    .bodyweight_weighted_squat.is_performed,
              },
              {
                name: "Stacchi con bilanciere",
                value: "current_performance_in_the_legs_compartment",
                value2: "barbell_deadlifts",
                is_performed:
                  atheleteData.current_performance_in_the_legs_compartment
                    .barbell_deadlifts.is_performed,
              },
            ].map((i, ind) => {
              return (
                <div key={ind}>
                  <div className="sleep-option" key={ind}>
                    <input
                      type="checkbox"
                      id={`eatoption2${ind}`}
                      name={i.value2}
                      // checked
                      // checked={!!atheleteData[i.value]} // Control the checked state based on the selected options
                      onChange={(event) => handleChange(event, i.value)} // Handle change event
                      style={{
                        borderRadius: 5,
                        border: "1px solid lightgray",
                      }}
                    />
                    <label htmlFor={`eatoption2${ind}`}>
                      <span className="custom-checkbox"></span>
                      {i.name}
                    </label>
                  </div>
                  {i.is_performed && (
                    <div>
                      <div className="counter-container">
                        <label htmlFor="repetitions" className="counter-label">
                          Numero ripetizioni
                        </label>
                        <div className="counter-controls">
                          <button
                            className="counter-btn"
                            id="decrement"
                            onClick={() =>
                              updateQtyWithWeight2(
                                i.value2,
                                "reps",
                                "decrement"
                              )
                            }
                          >
                            &#x2212;
                          </button>
                          <span id="count" className="counter-value">
                            {
                              atheleteData
                                .current_performance_in_the_legs_compartment[
                                i.value2
                              ].reps
                            }
                          </span>
                          <button
                            className="counter-btn"
                            id="increment"
                            onClick={() =>
                              updateQtyWithWeight2(
                                i.value2,
                                "reps",
                                "increment"
                              )
                            }
                          >
                            &#x2b;
                          </button>
                        </div>
                      </div>
                      <div className="counter-container">
                        <label htmlFor="weight" className="counter-label">
                          Peso in kg
                        </label>
                        <div className="counter-controls">
                          <button
                            className="counter-btn"
                            id="decrement"
                            onClick={() =>
                              updateQtyWithWeight2(
                                i.value2,
                                "weight",
                                "decrement"
                              )
                            }
                          >
                            &#x2212;
                          </button>
                          <span id="count" className="counter-value">
                            {
                              atheleteData
                                .current_performance_in_the_legs_compartment[
                                i.value2
                              ].weight
                            }
                          </span>
                          <button
                            className="counter-btn"
                            id="increment"
                            onClick={() =>
                              updateQtyWithWeight2(
                                i.value2,
                                "weight",
                                "increment"
                              )
                            }
                          >
                            &#x2b;
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
          </div>

          {/* ............................. */}

          {isLoading ? (
            <div style={containerStyle}>
              <div style={spinnerStyle}></div>
              <style>
                {`
              @keyframes spin {
                0% { transform: rotate(0deg); }
                100% { transform: rotate(360deg); }
              }
            `}
              </style>
            </div>
          ) : (
            <button
              type="button"
              className="button-default-no-icon"
              onClick={() => {
                handleBackClick();
              }}
              style={{ width: "100%", marginTop: 20 }}
            >
              continua
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default SixthStep;
