import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../../css/index.css";
import HomeIcon from "../../assets/img/icons/home.svg";
import FitnessIcon from "../../assets/img/icons/fitness.svg";
import AppleIcon from "../../assets/img/icons/apple.svg";
import UsersIcon from "../../assets/img/icons/user.svg";
import { Bars } from "react-loader-spinner";
import Loader from "./globalLoader";

export default function SideBar({ activeOption }) {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleNavigation = (option) => {
    setLoading(true);
    setTimeout(() => {
      switch (option) {
        case "Home":
          navigate("/user/home");
          break;
        case "Allenamento":
          navigate("/user/training/step-one");
          break;
        case "Nutrizione":
          navigate("/user/nutrizione/step-three");
          break;
        case "Profilo":
          navigate("/user/profilo");
          break;
        case "newui":
          navigate("/user/newui");
          break;
        default:
          navigate("/");
      }
      setLoading(false);
    }, 500);
  };

  return (
    <aside className="sidebar">
      {loading && <Loader />}
      <div className="sidebar-content">
        <div
          className={`sidebar-item ${activeOption === "Home" ? "active" : ""}`}
          onClick={() => handleNavigation("Home")}
        >
          <img className="icon" src={HomeIcon} alt="Home" />
          <span>Home</span>
        </div>
        <div
          className={`sidebar-item ${
            activeOption === "Allenamento" ? "active" : ""
          }`}
          onClick={() => handleNavigation("Allenamento")}
        >
          <img className="icon" src={FitnessIcon} alt="Allenamento" />
          <span>Allenamento</span>
        </div>
        <div
          className={`sidebar-item ${
            activeOption === "Nutrizione" ? "active" : ""
          }`}
          onClick={() => handleNavigation("Nutrizione")}
        >
          <img className="icon" src={AppleIcon} alt="Nutrizione" />
          <span>Nutrizione</span>
        </div>
        <div
          className={`sidebar-item ${
            activeOption === "Profilo" ? "active" : ""
          }`}
          onClick={() => handleNavigation("Profilo")}
        >
          <img className="icon" src={UsersIcon} alt="Profilo" />
          <span>Profilo</span>
        </div>
      </div>
    </aside>
  );
}
