import React, { useEffect, useState } from "react";
import "../Users/Recipes.css";
import { LeftArrowIcon } from "../Reuseable/Icons";
import dishImg from "../../assets/images/Farfalle.png";
import { BASE_URL } from "../../assets/constants";
import axios from "axios";
import { notify } from "../util/notify";
import CalorieEditModal from "../CaloriesEditModal";
import { useLocation, useNavigate } from "react-router-dom";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import AdminAtheleteCards from "./AdminAtheleteCards";
import DropContainer from "./DropContainer";
import DeleteModal from "../DeleteModal";
import DietValidationModal from "../DietValidationModal";

const AdminAtheletStepThree = ({ onClick, onPrevStepOne }) => {
  const adminData = JSON.parse(localStorage.getItem("adminData"));
  const token = adminData?.data?.token;
  const navigate = useNavigate();
  const { state } = useLocation();
  let selectedDate = state?.selectedDate;
  let selectedAthlete = state?.selectedAthlete;

  const [isLoading, setIsLoading] = useState(false);
  const [droppedItems, setDroppedItems] = useState({ [state?.dietTypes]: [] });
  const [selectedItems, setSelectedItems] = useState(state?.tabSelection);
  const [previousItems, SetPreviousItems] = useState(state?.tabSelection);
  const [activeTab, setActiveTab] = useState(0);
  const [editableItem, setEditableItem] = useState(null);
  const [afterCalculated, setAfterCalculated] = useState(null);
  const [itemQuantities, setItemQuantities] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [mealText, setMealText] = useState("");
  const [openModal, setOpenModal] = useState(false);

  const [itemsArray, setItemsArray] = useState([]);

  const validMealTypes = ["breakfast", "lunch", "dinner", "snack"];

  const tabs = [
    { title: "Carboidrati", key: "Carbohydrate" },
    { title: "Proteine", key: "Protein" },
    { title: "Grassi", key: "Fat" },
    { title: "Fibre", key: "Fiber" },
  ];

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  const handleEditClick = (item) => {
    setEditableItem(item);
    setIsModalOpen(true);
  };

  const handleIncrement = (itemId) => {
    setItemQuantities((prev) => ({
      ...prev,
      [itemId]: (prev[itemId] || 1) + 1,
    }));
  };

  const handleDecrement = (itemId) => {
    setItemQuantities((prev) => ({
      ...prev,
      [itemId]: prev[itemId] > 1 ? prev[itemId] - 1 : 1,
    }));
  };

  const getItemQuantity = (itemId, label) => {
    return itemQuantities[itemId] || 1;
  };

  const calculateNutritionalValues = (quantity, type) => {
    const totalCalories = Object.values(previousItems)?.reduce((acc, items) => {
      return (
        acc +
        items?.reduce(
          (sum, item) => sum + parseFloat(item.calories_per_gram),
          0
        )
      );
    }, 0);

    const totalCarbohydrates = previousItems.Snacks?.filter(
      (item) => item.type === "carbohydrate"
    )?.reduce((sum, item) => sum + parseFloat(item.calories_per_gram), 0);
    const totalProteins = previousItems.Snacks?.filter(
      (item) => item.type === "protein"
    )?.reduce((sum, item) => sum + parseFloat(item.calories_per_gram), 0);
    const totalFats = previousItems.Snacks?.filter(
      (item) => item.type === "fat"
    )?.reduce((sum, item) => sum + parseFloat(item.calories_per_gram), 0);
    const totalFibres = previousItems.Snacks?.filter(
      (item) => item.type === "fiber"
    )?.reduce((sum, item) => sum + parseFloat(item.calories_per_gram), 0);

    const totalCarbohydratesB = previousItems?.Breakfast?.filter(
      (item) => item.type === "carbohydrate"
    )?.reduce((sum, item) => sum + parseFloat(item.calories_per_gram), 0);

    const totalProteinsB = previousItems?.Breakfast?.filter(
      (item) => item.type === "protein"
    )?.reduce((sum, item) => sum + parseFloat(item.calories_per_gram), 0);
    const totalFatsB = previousItems?.Breakfast?.filter(
      (item) => item.type === "fat"
    )?.reduce((sum, item) => sum + parseFloat(item.calories_per_gram), 0);
    const totalFibresB = previousItems?.Breakfast?.filter(
      (item) => item.type === "fiber"
    )?.reduce((sum, item) => sum + parseFloat(item.calories_per_gram), 0);

    const totalCarbohydratesL = previousItems?.Lunch?.filter(
      (item) => item.type.toLowerCase() === "carbohydrate"
    )?.reduce((sum, item) => sum + parseFloat(item.calories_per_gram), 0);
    const totalProteinsL = previousItems.Lunch?.filter(
      (item) => item.type.toLowerCase() === "protein"
    )?.reduce((sum, item) => sum + parseFloat(item.calories_per_gram), 0);
    const totalFatsL = previousItems.Lunch?.filter(
      (item) => item.type.toLowerCase() === "fat"
    )?.reduce((sum, item) => sum + parseFloat(item.calories_per_gram), 0);
    const totalFibresL = previousItems.Lunch?.filter(
      (item) => item.type.toLowerCase() === "fiber"
    )?.reduce((sum, item) => sum + parseFloat(item.calories_per_gram), 0);

    const totalCarbohydratesD = previousItems.Dinner?.filter(
      (item) => item.type.toLowerCase() === "carbohydrate"
    )?.reduce((sum, item) => sum + parseFloat(item.calories_per_gram), 0);
    const totalProteinsD = previousItems.Dinner?.filter(
      (item) => item.type.toLowerCase() === "protein"
    )?.reduce((sum, item) => sum + parseFloat(item.calories_per_gram), 0);
    const totalFatsD = previousItems.Dinner?.filter(
      (item) => item.type.toLowerCase() === "fat"
    )?.reduce((sum, item) => sum + parseFloat(item.calories_per_gram), 0);
    const totalFibresD = previousItems.Dinner?.filter(
      (item) => item.type.toLowerCase() === "fiber"
    )?.reduce((sum, item) => sum + parseFloat(item.calories_per_gram), 0);

    return {
      totalCalories,
      totalCarbohydrates,
      totalProteins,
      totalFats,
      totalFibres,
      totalCarbohydratesB,
      totalProteinsB,
      totalFatsB,
      totalFibresB,
      totalCarbohydratesL,
      totalProteinsL,
      totalFatsL,
      totalFibresL,
      totalCarbohydratesD,
      totalProteinsD,
      totalFatsD,
      totalFibresD,
    };
  };

  const handleSetCalculation = async (diet) => {
    try {
      let response = await axios.post(
        `${BASE_URL}admincoach/diet/calculate`,
        {
          diet,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setAfterCalculated(response?.data);
      setIsModalOpen(false);
    } catch (error) {}
  };
  let arr = [];

  const handleSaveQuantity = (quantity, type, editableItem) => {
    setItemsArray((prevItemsArray) => {
      const existingItemIndex = prevItemsArray.findIndex(
        (item) => item.id === editableItem.id
      );

      if (existingItemIndex !== -1) {
        const updatedArray = [...prevItemsArray]; // Create a new array for immutability
        updatedArray[existingItemIndex] = { ...editableItem, quantity, type };
        return updatedArray;
      } else {
        return [...prevItemsArray, { ...editableItem, quantity, type }];
      }
    });
  };

  // const handleSaveQuantity = (quantity, type, editableItem) => {
  //   arr.push({ ...editableItem, quantity });
  //   console.log({ arr });
  //   // let updatedBreakfast = selectedItems.Breakfast?.map((item) => {
  //   //   if (item.id === editableItem.id) {
  //   //     return {
  //   //       ...item,
  //   //       quantity: quantity,
  //   //     };
  //   //   }
  //   //   return item;
  //   // });
  //   let updatedBreakfast = selectedItems.Breakfast;

  //   let saveobj = updatedBreakfast?.map((value) => {
  //     if (value?.id === editableItem?.id) {
  //       return {
  //         ...value,
  //         quantity,
  //       };
  //     } else {
  //       return { ...value };
  //     }
  //   });

  //   return;
  //   setIsModalOpen(false);
  //   setEditableItem(null);
  // };

  const nutritionalValues = calculateNutritionalValues();

  const handleSave = async () => {
    setIsLoading(true);
    const date = selectedDate;

    const select_nutration = Object.entries(droppedItems)?.flatMap(
      ([mealType, items]) =>
        items?.map((item) => {
          const quantity = itemQuantities[item?.id] || 1;
          return {
            diet_id: item?.id,
            qty: item?.qty,
          };
        })
    );

    if (select_nutration.length === 0) {
      notify(true, "No items selected for nutrition.");
      setIsLoading(false);
      return;
    }

    const type =
      Object.keys(selectedItems)
        .find(
          (mealType) =>
            validMealTypes.includes(mealType.toLowerCase()) &&
            selectedItems[mealType].length > 0
        )
        ?.toLowerCase() || "unknown";

    const body = {
      date,
      name: mealText,
      type:
        state?.dietTypes?.toLowerCase() === "snacks"
          ? "snack"
          : state?.dietTypes?.toLowerCase(),
      select_nutration,
    };
    try {
      const response = await axios.post(
        `${BASE_URL}admin/nutrition-meal/${selectedAthlete}/store`,
        body,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      notify(false, response?.data?.message);
      // fetchMealData();
      setIsLoading(false);
      navigate("/admin/atlet");

      onPrevStepOne();
    } catch (error) {
      console.log("error1", error?.response?.data?.error);
      if (error?.response?.data?.error?.name) {
        notify(true, error?.response?.data?.error?.name[0]);
        setIsLoading(false);

        return;
      }
      if (error?.response?.data?.error) {
        setIsLoading(false);

        const errors = error?.response?.data?.error || [];
        const uniqueErrorMessages = new Set();

        errors?.forEach((err) => {
          const key = Object.keys(err)[0];
          uniqueErrorMessages.add(err[key]);
        });

        const combinedErrors = Array.from(uniqueErrorMessages).join(", ");
        notify(true, combinedErrors);
      } else if (error?.response?.data?.message) {
        setIsLoading(false);

        notify(true, error?.response?.data?.message);
      }
      setIsLoading(false);

      console.error("Error saving meal composition:", error);
    }
  };
  const handleRemoveItem = (id) => {
    setSelectedItems((prevItems) => ({
      ...prevItems,
      Breakfast: prevItems.Breakfast.filter((item) => item.id !== id),
      Snacks: prevItems.Snacks.filter((item) => item.id !== id),
      Lunch: prevItems.Lunch.filter((item) => item.id !== id),
      Dinner: prevItems.Dinner.filter((item) => item.id !== id),
    }));
  };

  const fetchMealData = async () => {
    try {
      const response = await axios.post(
        `${BASE_URL}admin/nutrition/${selectedAthlete}/meal`,
        {
          date: state?.selectedDate,
          type: state?.dietTypes?.toLowerCase(),
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      let [year, month, day] =
        response?.data?.data[0]?.nutritionMeal?.date?.split("-");
      let formattedDate = `${day}-${month}-${year}`;
      if (selectedDate === formattedDate) {
        let data = response?.data?.data[0]?.nutritionMeal?.foods;
        let filterData =
          response?.data?.data[0]?.nutritionMeal?.foods &&
          response?.data?.data[0]?.nutritionMeal?.foods?.map((value) => ({
            id: value?.diet_id,
            name: value?.diet[0]?.name,
            qty: value?.quantity,
            calories_per_gram: value?.diet[0]?.calories_per_gram,
            // label: data?.label,
            type: value?.diet[0]?.type,
          }));
        let filteredFoods2 = selectedItems[state?.dietTypes]?.filter(
          (food2Item) =>
            !data?.some((foodItem) => foodItem?.diet_id === food2Item.id)
        );

        setDroppedItems({
          ...droppedItems,
          [state?.dietTypes]: filterData,
        });
        setSelectedItems({
          ...selectedItems,
          [state?.dietTypes]: filteredFoods2,
        });
      }
    } catch (error) {
      console.log("err ", error);
    }
  };

  const handleOpenModal = () => {
    setConfirmationModal(true);
  };
  const onSave = async () => {
    setOpenModal(false);
  };

  const handleDeleteClick = async () => {
    setConfirmationModal(false);
  };

  useEffect(() => {
    // fetchMealData();
  }, []);

  useEffect(() => {
    if (itemsArray.length !== 0) {
      let filterData = itemsArray?.map((value) => ({
        diet_id: value?.id,
        qty: value?.quantity,
      }));
      handleSetCalculation(filterData);
    }
  }, [itemsArray]);

  const spinnerStyle = {
    border: "3px solid #f3f3f3",
    borderTop: "3px solid #a0fc6e",
    borderRadius: "50%",
    width: "30px",
    height: "30px",
    animation: "spin 1s linear infinite",
  };

  const containerStyle = {
    display: "flex",
    justifyContent: "center",
    marging: "0 auto",
    backgroundColor: "transparent",
  };
  return (
    <div>
      <DietValidationModal
        isOpen={openModal}
        onCancel={() => setOpenModal(false)}
        onConfirm={onSave}
        message="Componi il tuo piatto"
        droppedItems={droppedItems}
        setDroppedItems={setDroppedItems}
        setMealName={setMealText}
        mealName={mealText}
        url={`admin/plan-meal/${selectedAthlete}`}
        token={token}
        setOpenModal={setOpenModal}
      />
      <div className="top-bar">
        <button
          onClick={() =>
            navigate("/admin/atlet/step-two", {
              state: {
                selectedItem: previousItems,
                selectedDate,
                selectedAthlete,
              },
            })
          }
          className="icon"
        >
          <LeftArrowIcon />
        </button>
        <div className="title">Pianifica i tuoi pasti</div>
      </div>
      <div className="meal-compositor-flex">
        <div className="meal-compositor nutrition-sources-gap">
          <div className="navigation-bar top">
            {tabs.map((tab, index) => (
              <div
                key={index}
                className={`nav-icon ${activeTab === index ? "active" : ""}`}
                onClick={() => handleTabClick(index)}
              >
                {tab.title}
              </div>
            ))}
          </div>
          <div className="">
            <DndProvider backend={HTML5Backend}>
              <div className="mealBox">
                <CalorieEditModal
                  isOpen={isModalOpen}
                  onClose={() => setIsModalOpen(false)}
                  editableItem={editableItem}
                  calories={getItemQuantity(editableItem?.id)}
                  handleIncrement={() => handleIncrement(editableItem?.id)}
                  handleDecrement={() => handleDecrement(editableItem?.id)}
                  handleSaveCalories={() =>
                    handleSaveQuantity(
                      getItemQuantity(editableItem?.id),
                      editableItem?.type,
                      editableItem
                    )
                  }
                />
                {/* For Breakfast */}
                {selectedItems?.Breakfast?.filter(
                  (item) =>
                    item?.type?.toLowerCase() ===
                    tabs[activeTab].key?.toLowerCase()
                ).map((data, i) => (
                  <AdminAtheleteCards
                    key={i}
                    data={data}
                    getItemQuantity={getItemQuantity}
                    handleEditClick={handleEditClick}
                    handleRemoveItem={handleRemoveItem}
                  />
                ))}
                {/* For Snacks */}
                {selectedItems?.Snacks?.filter(
                  (item) =>
                    item.type.toLowerCase() ===
                    tabs[activeTab].key.toLowerCase()
                ).map((data, i) => (
                  <AdminAtheleteCards
                    key={i}
                    data={data}
                    getItemQuantity={getItemQuantity}
                    handleEditClick={handleEditClick}
                    handleRemoveItem={handleRemoveItem}
                  />
                ))}
                {/* For Lunch */}
                {selectedItems?.Lunch?.filter(
                  (item) =>
                    item.type.toLowerCase() ===
                    tabs[activeTab].key.toLowerCase()
                ).map((data, i) => (
                  <AdminAtheleteCards
                    key={i}
                    data={data}
                    getItemQuantity={getItemQuantity}
                    handleEditClick={handleEditClick}
                    handleRemoveItem={handleRemoveItem}
                  />
                ))}
                {/* For Dinner */}
                {selectedItems?.Dinner?.filter(
                  (item) =>
                    item.type.toLowerCase() ===
                    tabs[activeTab].key.toLowerCase()
                ).map((data, i) => (
                  <AdminAtheleteCards
                    key={i}
                    data={data}
                    getItemQuantity={getItemQuantity}
                    handleEditClick={handleEditClick}
                    handleRemoveItem={handleRemoveItem}
                  />
                ))}
              </div>
              <div className="parentPlate">
                <div className="drag-and-drop-plate">
                  <DropContainer
                    droppedItems={droppedItems}
                    setDroppedItems={setDroppedItems}
                    getItemQuantity={getItemQuantity}
                    dietType={state?.dietTypes}
                    setSelectedItems={setSelectedItems}
                    selectedItems={selectedItems}
                  />
                </div>
              </div>

              {droppedItems[state?.dietTypes]?.length > 0 && (
                <button
                  type="button"
                  className="step-three-btn"
                  id="finish-meal-composition"
                  onClick={() => setOpenModal(true)}
                  style={{
                    zIndex: 999,
                    width: "50%",
                    position: "relative",
                    margin: "0 auto",
                    top: -20,
                  }}
                >
                  Componi il tuo piatto
                </button>
              )}
            </DndProvider>
          </div>
          <DeleteModal
            setMealText={setMealText}
            isOpen={confirmationModal}
            onCancel={() => setConfirmationModal(false)}
            onConfirm={handleDeleteClick}
            message="Inserisci il testo"
            typeInp
          />

          <div className="plate-droparea">
            <div className="action">
              <button
                type="button"
                className="button-square"
                style={{ display: "none" }}
              >
                Componi il tuo piatto
              </button>
            </div>
          </div>
          <div className="step-three-btn-container1 my-3">
            {isLoading ? (
              <div style={containerStyle}>
                <div style={spinnerStyle}></div>
                <style>
                  {`
            @keyframes spin {
              0% { transform: rotate(0deg); }
              100% { transform: rotate(360deg); }
            }
          `}
                </style>
              </div>
            ) : (
              <button
                type="button"
                className="step-three-btn"
                id="finish-meal-composition"
                onClick={handleSave}
                style={{
                  zIndex: 999,
                  width: "100%",
                  position: "inherit",
                  margin: "20px 0",
                }}
              >
                Salva e chiudia
              </button>
            )}
          </div>
        </div>
        <div className="meal-composition-card-two">
          <div className="progress-bar-group">
            <div
              className="card-text-with-progress-bars"
              data-category="calories"
            >
              <div className="info">
                {nutritionalValues.totalCalories?.toFixed(2)} kcal
              </div>
              <div className="description">Calorie Totali</div>
              <progress
                max="100"
                value={(nutritionalValues.totalCalories / 2000) * 100}
              ></progress>
            </div>
            <div
              className="card-text-with-progress-bars"
              data-category="carbohydrates"
            >
              <div className="info">
                {afterCalculated && afterCalculated?.carbohydrate !== "0.00"
                  ? afterCalculated?.carbohydrate
                  : nutritionalValues.totalCarbohydrates
                  ? nutritionalValues.totalCarbohydrates?.toFixed(2)
                  : nutritionalValues.totalCarbohydratesB
                  ? nutritionalValues.totalCarbohydratesB?.toFixed(2)
                  : nutritionalValues.totalCarbohydratesL
                  ? nutritionalValues.totalCarbohydratesL?.toFixed(2)
                  : nutritionalValues.totalCarbohydratesD?.toFixed(2)}{" "}
                g
              </div>
              <div className="description">Carboidrati</div>
              <progress
                max="300"
                value={
                  ((afterCalculated?.carbohydrate || 0) > 0
                    ? ((afterCalculated?.carbohydrate || 0) / 300) * 100
                    : nutritionalValues.totalCarbohydrates || 0) > 0
                    ? ((nutritionalValues.totalCarbohydrates || 0) / 300) * 100
                    : (nutritionalValues.totalCarbohydratesB || 0) > 0
                    ? ((nutritionalValues.totalCarbohydratesB || 0) / 300) * 100
                    : (nutritionalValues.totalCarbohydratesL || 0) > 0
                    ? ((nutritionalValues.totalCarbohydratesL || 0) / 300) * 100
                    : ((nutritionalValues.totalCarbohydratesD || 0) / 300) * 100
                }
              />
            </div>
            <div
              className="card-text-with-progress-bars"
              data-category="proteins"
            >
              <div className="info">
                {afterCalculated && afterCalculated?.protein != "0.00"
                  ? afterCalculated?.protein
                  : nutritionalValues.totalProteins
                  ? nutritionalValues.totalProteins?.toFixed(2)
                  : nutritionalValues.totalProteinsB
                  ? nutritionalValues.totalProteinsB?.toFixed(2)
                  : nutritionalValues.totalProteinsL
                  ? nutritionalValues.totalProteinsL?.toFixed(2)
                  : nutritionalValues.totalProteinsLB?.toFixed(2)}{" "}
                g
              </div>
              <div className="description">Proteine</div>
              <progress
                max="100"
                value={
                  (nutritionalValues.totalProteins || 0) > 0
                    ? ((nutritionalValues.totalProteins || 0) / 100) * 100
                    : (nutritionalValues.totalProteinsB || 0) > 0
                    ? ((nutritionalValues.totalProteinsB || 0) / 100) * 100
                    : (nutritionalValues.totalProteinsL || 0) > 0
                    ? ((nutritionalValues.totalProteinsL || 0) / 100) * 100
                    : ((nutritionalValues.D || 0) / 100) * 100
                }
              />
            </div>

            {/* Fats */}
            <div className="card-text-with-progress-bars" data-category="fats">
              <div className="info">
                {afterCalculated && afterCalculated?.fat != "0.00"
                  ? afterCalculated?.fat
                  : nutritionalValues.totalFats
                  ? nutritionalValues.totalFats?.toFixed(2)
                  : nutritionalValues.totalFatsB
                  ? nutritionalValues.totalFatsB?.toFixed(2)
                  : nutritionalValues.totalFatsL
                  ? nutritionalValues.totalFatsL?.toFixed(2)
                  : nutritionalValues.totalFatsD?.toFixed(2)}{" "}
                g
              </div>
              <div className="description">Grassi</div>
              <progress
                max="70"
                value={
                  (nutritionalValues.totalFats || 0) > 0
                    ? ((nutritionalValues.totalFats || 0) / 70) * 100
                    : (nutritionalValues.totalFatsB || 0) > 0
                    ? ((nutritionalValues.totalFatsB || 0) / 70) * 100
                    : (nutritionalValues.totalFatsL || 0) > 0
                    ? ((nutritionalValues.totalFatsL || 0) / 70) * 100
                    : ((nutritionalValues.totalFatsD || 0) / 70) * 100
                }
              />
            </div>

            <div
              className="card-text-with-progress-bars"
              data-category="fibers"
            >
              <div className="info">
                {afterCalculated && afterCalculated?.fiber != "0.00"
                  ? afterCalculated?.fiber
                  : nutritionalValues.totalFibres
                  ? nutritionalValues.totalFibres?.toFixed(2)
                  : nutritionalValues.totalFibresB
                  ? nutritionalValues.totalFibresB?.toFixed(2)
                  : nutritionalValues.totalFibresL
                  ? nutritionalValues.totalFibresL?.toFixed(2)
                  : nutritionalValues.totalFibresD?.toFixed(2)}{" "}
                g
              </div>
              <div className="description">Fibre</div>
              <progress
                max="30"
                value={
                  (nutritionalValues.totalFibres || 0) > 0
                    ? ((nutritionalValues.totalFibres || 0) / 30) * 100
                    : (nutritionalValues.totalFibresB || 0) > 0
                    ? ((nutritionalValues.totalFibresB || 0) / 30) * 100
                    : (nutritionalValues.totalFibresL || 0) > 0
                    ? ((nutritionalValues.totalFibresL || 0) / 30) * 100
                    : ((nutritionalValues.totalFibresD || 0) / 30) * 100
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminAtheletStepThree;
