import React from "react";
import AtletiContent from "./AtletiContent";
import CalendarioContent from "./CalendarioContent";
import NoteContent from "./NoteContent";
import ProfiloContent from "./ProfiloContent";
import UPTheMeasrment from "./User/UPTheMeasrment";

export default function Main({ activeOption }) {
  return (
    <div className="main">
      {activeOption === "Atleti" && <AtletiContent />}
      {activeOption === "Calendario" && <CalendarioContent />}
      {activeOption === "Note" && <NoteContent />}
      {activeOption === "Profilo" && <ProfiloContent />}
    </div>
  );
}
