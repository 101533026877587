import React, { useState, useEffect } from "react";

// ReuseableIcon
import rightArrowIcon from "../../assets/img/icons/arrow-right-active.svg";
import ReactPlayer from "react-player";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "../../assets/constants";

function TrainingThirdStep({ onButtonClick, getTrainingListData }) {
  const navigate = useNavigate();
  const { excerciseId } = useParams();
  const warmupVideo = "https://vimeo.com/726157907/bcc461fa1b";
  const adminData = JSON.parse(localStorage.getItem("adminData"));
  const { state: warmUp } = useLocation();
  const token = adminData?.token;
  const videoSrc = getTrainingListData?.[0]?.link || "";
  const [countdown, setCountdown] = useState(3);
  const [showVideo, setShowVideo] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [excerciseData, setExcerciseData] = useState();
  const [activityData, setActivityData] = useState([]);
  const [video, setVideo] = useState("");

  const getExcercise = async (excerciseId) => {
    try {
      const response = await axios.get(
        `${BASE_URL}athlete/training/${excerciseId}/activities`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setExcerciseData(response?.data);
      setActivityData(response?.data?.activityTimer?.activity?.exercises);
    } catch (error) {}
  };

  useEffect(() => {
    if (countdown > 0) {
      const timerId = setTimeout(() => setCountdown(countdown - 1), 1000);

      return () => clearTimeout(timerId);
    } else {
      if (warmUp == "false") {
        handleVideoEnd();
        setShowVideo(true);
      } else if (
        warmUp == "false" &&
        !excerciseData?.activityTimer?.activity?.link &&
        excerciseData?.activityTimer?.activity?.link == "null"
      ) {
        navigate(`/user/training/step-four/${excerciseId}`);
      }
      setShowVideo(true);
      setIsPlaying(true);
    }
  }, [countdown]);

  const handleVideoEnd = () => {
    if (
      excerciseData?.activityTimer?.activity?.link &&
      excerciseData?.activityTimer?.activity?.link !== "null"
    ) {
      setVideo(excerciseData?.activityTimer?.activity?.link);
    } else navigate(`/user/training/step-four/${excerciseId}`);
  };

  const handleVideoEndActivityVideo = () => {
    navigate(`/user/training/step-four/${excerciseId}`);
  };

  useEffect(() => {
    getExcercise(excerciseId);
  }, [excerciseId]);
  useEffect(() => {
    // return () => localStorage.setItem("boolean", false);
  }, [video]);
  useEffect(() => {}, [warmUp]);

  if (showVideo) {
    return (
      <div className="video-parent">
        <div className="training-video">
          {video && video != "null" ? (
            <ReactPlayer
              url={video}
              controls
              playing={isPlaying}
              muted={true}
              width="100%"
              height="100%"
              onEnded={handleVideoEndActivityVideo}
            />
          ) : (
            <ReactPlayer
              url={warmupVideo}
              controls
              playing={isPlaying}
              muted={true}
              width="100%"
              height="100%"
              onEnded={handleVideoEnd}
            />
          )}
        </div>
        {!video && (
          <div onClick={handleVideoEnd} className="next-step-button">
            <div className="next-step-button-flex">
              Saltare
              <img src={rightArrowIcon} alt="Next" />
            </div>
          </div>
        )}
      </div>
    );
  }

  return (
    <div>
      <div className="timer" id="scheda-allenamento">
        <div className="title">
          sei pronto?
          <br />
          iniziamo tra
        </div>
        <div className="clock">{countdown}</div>
      </div>
    </div>
  );
}

export default TrainingThirdStep;
