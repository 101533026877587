import React, { useState, useEffect } from "react";
import { LeftArrowIcon } from "../Reuseable/Icons";
import { useSelector, useDispatch } from "react-redux";
import { setTrainingStatus } from "../../redux/actions";
import axios from "axios";
import { BASE_URL } from "../../assets/constants";
import { useNavigate, useParams } from "react-router-dom";
import { notify } from "../util/notify";
import DeleteModal from "../DeleteModal";
import VideoModal from "../VideoModal";
import TimerModal from "../TimerModal";
import ActivityFeedbackModals from "../ActivityFeedbackModal";

const timeStringToSeconds = (timeString) => {
  const [hours, minutes, seconds] = timeString.split(":").map(Number);
  return hours * 3600 + minutes * 60 + seconds;
};

const secondsToTimeString = (seconds) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const secs = seconds % 60;

  return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
    2,
    "0"
  )}:${String(secs).padStart(2, "0")}`;
};

function TrainingFourthStep({ selectedData, moreIncrement }) {
  const { excerciseId } = useParams();
  const navigate = useNavigate();

  const trainingData = useSelector((state) => state?.userReducer?.data?.result);
  const concludedExercises = useSelector(
    (state) => state?.userReducer?.concludedExercises
  );
  const adminData = JSON.parse(localStorage.getItem("adminData"));
  const token = adminData?.token;

  const getITEM = localStorage.getItem("selectedItemIndex");

  const [isLoading, setIsLoading] = useState(false);
  const [excerciseData, setExcerciseData] = useState();
  const [activityData, setActivityData] = useState([]);
  const [activeActivity, setActiveActivity] = useState(null);
  const [dateFilteredData, setDateFilteredData] = useState([]);
  const [videoSrc, setVideoSrc] = useState("");
  const [exerciseStates, setExerciseStates] = useState({});
  const [activeExercise, setActiveExercise] = useState(null);
  const [bool, setBool] = useState(false);
  const [timerEnded, setTimerEnded] = useState(false);
  const [feedBackText, setFeedBackText] = useState(1);
  const [totalActivity, setTotalActivity] = useState([]);
  const [totalExcercise, setTotalExcercise] = useState([]);
  const [previousCount, setPreviousCount] = useState(0);
  const [showAlertOnce, setShowAlertOnce] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [timerModalOpen, setTimeModalOpen] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(true);
  const [isLoadingVideo, setIsLoadingVideo] = useState(true);
  const [isModalOpen, setModalOpen] = useState(false);
  const [isModalOpenSkip, setModalOpenSkip] = useState(false);
  const [excerciseCompleteModal, setExcerciseCompleteModal] = useState(false);
  const [excerciseIsLoading, setExcerciseIsLoading] = useState(false);

  const [time, setTime] = useState(0);
  const [isRunning, setIsRunning] = useState(false);

  const dispatch = useDispatch();
  const date = dateFilteredData[parseInt(getITEM)]?.data_res;

  const completeTrianing = (link) => {
    setModalOpenSkip("skip");
  };

  const getExcercise = async (excerciseId, add) => {
    try {
      const response = await axios.get(
        `${BASE_URL}athlete/training/${excerciseId}/activities`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setExcerciseData(response?.data);
      if (
        response?.data?.activityTimer?.activity?.count ==
        response?.data?.activityTimer?.activity?.repetitions
      ) {
        setOpenModal(true);
        return;
      }
      setActivityData(
        response?.data?.activityTimer?.activity?.exercises?.slice(1)
      );
      setActiveActivity(response?.data?.activityTimer?.activity?.exercises[0]);
      if (add) {
        setIsOpenModal(true);
      }

      if (
        response?.data?.activityTimer?.activity?.count > previousCount &&
        showAlertOnce
      ) {
        if (
          response?.data?.activityTimer?.activity?.exercises[0]?.id !==
          totalExcercise?.slice(-1)[0]?.id
        ) {
          setPreviousCount(response?.data?.activityTimer?.activity?.count);
          setTimeModalOpen(true);
          setShowAlertOnce(false);
        }
      }
    } catch (error) {}
    setIsLoadingVideo(false);
  };

  const getTotalActiviies = async (excerciseId, add) => {
    try {
      const response = await axios.get(
        `${BASE_URL}athlete/session/${excerciseId}/activity`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setTotalActivity(response?.data?.excercise);
    } catch (error) {}
    setIsLoadingVideo(false);
  };
  const getTotalExcercise = async (excerciseId, add) => {
    if (!excerciseData?.activityTimer?.activity?.id) return;
    try {
      const response = await axios.get(
        `${BASE_URL}athlete/activity/${excerciseData?.activityTimer?.activity?.id}/excersise`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setTotalExcercise(response?.data?.activity);
    } catch (error) {}
    setIsLoadingVideo(false);
  };

  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;

    return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
      2,
      "0"
    )}:${String(secs).padStart(2, "0")}`;
  };

  const startTimer = () => {
    if (activeActivity?.time === "00:00:00" || !activeActivity?.time) {
      setExcerciseCompleteModal(activeActivity?.name);
    } else {
      if (activeActivity && !isRunning) {
        const initialTime = timeStringToSeconds(activeActivity.time);
        setTime(initialTime);
        setIsRunning(true);
        setTimerEnded(false);
      } else if (!activeActivity) {
        notify(true, "Seleziona un esercizio");
      }
    }
  };
  const handleVideoEnd = () => {
    notify("", "Fare clic sul pulsante Continua per iniziare l'esercizio....!");
    setIsOpenModal(false);
  };

  const handleTimerEnd = async () => {
    if (!activeActivity?.id) return;
    setExcerciseIsLoading(true);

    try {
      const response = await axios.get(
        `${BASE_URL}athlete/excercise/${activeActivity?.id}/count`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      notify(false, response?.data?.message);
      getExcercise(excerciseId, true);
      setActiveActivity(null);
    } catch (error) {
      console.error("Error in handleTimerEnd:", error);
    }
    setExcerciseIsLoading(false);
    setExcerciseCompleteModal(false);
  };

  useEffect(() => {
    if (concludedExercises?.length + 1 === dateFilteredData?.length) {
      setBool(true);
    }
  }, [concludedExercises?.length]);

  useEffect(() => {
    if (bool) {
      dispatch(setTrainingStatus(bool, date));
    }
  }, [bool]);

  useEffect(() => {
    let intervalId;

    if (isRunning && time > 0) {
      intervalId = setInterval(() => {
        setTime((prevTime) => prevTime - 1);
      }, 1000);
    } else if (time === 0 && !timerEnded) {
      setActiveActivity(null);

      clearInterval(intervalId);
      setIsRunning(false);
      handleTimerEnd();
      setTimerEnded(true);
      setShowAlertOnce(true);
    }

    return () => clearInterval(intervalId);
  }, [isRunning, time, timerEnded]);
  useEffect(() => {
    if (!isRunning && timerEnded) {
      setShowAlertOnce(true);
    }
  }, [isRunning, timerEnded]);

  useEffect(() => {
    getExcercise(excerciseId);
    getTotalActiviies(excerciseId);
  }, [excerciseId]);

  useEffect(() => {
    if (
      excerciseData &&
      excerciseData?.activityTimer?.activity?.count ===
        excerciseData?.activityTimer?.activity?.repetitions
    ) {
    }
  }, [activityData]);
  useEffect(() => {
    getTotalExcercise(excerciseId);
  }, [excerciseData]);

  return (
    <div id="scheda-allenamento">
      <div className="top-bar">
        <a href="#" className="icon">
          <LeftArrowIcon />
        </a>
        <div className="title">Allenamento</div>
        <DeleteModal
          isOpen={excerciseCompleteModal}
          message={`Hai completato ${excerciseCompleteModal} ?`}
          onCancel={() => setExcerciseCompleteModal(false)}
          isLoading={excerciseIsLoading}
          onConfirm={() => {
            handleTimerEnd("false");
          }}
        />
        {excerciseData?.activityTimer?.activity?.count === 0 &&
          excerciseData?.activityTimer?.activity?.exercises[0]?.link && (
            <VideoModal
              video={excerciseData?.activityTimer?.activity?.exercises[0]?.link}
              isOpen={isOpenModal}
              setIsOpenModal={setIsOpenModal}
              loading={isLoadingVideo}
              handleVideoEnd={handleVideoEnd}
            />
          )}
        {excerciseData?.activityTimer?.forza && (
          <TimerModal
            initialTime={excerciseData?.activityTimer?.forza}
            // initialTime={"00:00:03"}
            isOpen={timerModalOpen}
            setIsOpen={setTimeModalOpen}
            setShowAlertOnce={setShowAlertOnce}
          />
        )}
        <ActivityFeedbackModals
          isOpen={openModal}
          setIsOpenModal={setIsOpenModal}
          // onCancel={() => setOpenModal(false)}
          excerciseDataComplete={excerciseData}
          excerciseData={excerciseData?.activityTimer?.activity?.exercises}
          id={excerciseData?.activityTimer?.activity?.id}
          totalActivity={totalActivity}
        />
        <ActivityFeedbackModals
          isOpen={isModalOpenSkip}
          setIsOpenModal={setModalOpenSkip}
          // onCancel={() => setOpenModal(false)}
          excerciseDataComplete={excerciseData}
          excerciseData={excerciseData?.activityTimer?.activity?.exercises}
          id={excerciseData?.activityTimer?.activity?.id}
          totalActivity={totalActivity}
        />
      </div>
      <div className="head-info right-gap">
        <div className="title">{excerciseData?.name || "--"}</div>
        <div className="description">{excerciseData?.description || "--"}</div>
      </div>
      <div className="right-gap">
        <div className="card-allenamento">
          <div className="desktop-container">
            <div
              className="allenamento-card-left"
              style={{ marginBottom: 50, backgroundColor: "#080808" }}
            >
              <div className="clock-and-info">
                <div className="header">
                  <div className="title">forza!</div>
                  <div className="info">
                    Mettici tutta l’energia e concludi al meglio gli esercizi.
                  </div>
                </div>
                <div className="clock">
                  <svg className="progress-ring" width="107" height="107">
                    <circle
                      className="progress-ring"
                      r="52"
                      cx="53"
                      cy="53"
                    ></circle>
                    <text
                      x="50%"
                      y="50%"
                      textAnchor="middle"
                      alignmentBaseline="middle"
                    >
                      {time
                        ? secondsToTimeString(time)
                        : activeActivity?.time || "00:00:00"}
                    </text>
                  </svg>
                </div>
              </div>
            </div>

            <div className="allenamento-card-middle">
              <section>
                <h2>{activeActivity?.name || "--"}</h2>
                <h5>{activeActivity?.repetition || "--"} ripetizioni</h5>
                <h5>{activeActivity?.load || "--"} kg</h5>
              </section>
              <div>
                <button
                  type="button"
                  className="button-default-no-icon"
                  onClick={startTimer}
                >
                  fatto, continua
                </button>
              </div>
            </div>
            <div className="excercise-scrollable-container">
              {activityData?.length > 0 &&
                activityData?.map((value, index) => {
                  return (
                    <section className="excercise-box" key={index}>
                      <h3>{value?.name || "--"}</h3>
                      <h5>{value?.repetition || "--"} </h5>
                    </section>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
      <button
        type="button"
        className="button-default-no-icon"
        onClick={completeTrianing}
      >
        concludi l’allenamento
      </button>
    </div>
  );
}

export default TrainingFourthStep;
