import React from "react";

// Components
import RegisterForm from "../components/Auth/RegisterForm";

function Register() {
  return <RegisterForm />;
}

export default Register;
