import React, { useState } from "react";
import Main from "./Main";
import "../css/index.css";
import Header from "../components/header";

export default function CoachDashboard() {
  const [activeOption, setActiveOption] = useState("Atleti");

  const handleSidebarClick = (option) => {
    setActiveOption(option);
  };

  return (
    <div className="app">
      <div className="container">
        <Main activeOption={activeOption} />
      </div>
    </div>
  );
}
