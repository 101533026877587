import { SET_TRAINING_STATUS } from "../actions/actionTypes";

const initialState = {
  trainingCompleted: [],
};

const trainingReducer = (state = initialState, action) => {
  let data;
  switch (action.type) {
    case SET_TRAINING_STATUS:
      if (state?.trainingCompleted?.length) {
        data = state?.trainingCompleted?.concat([action.payload]);
      } else {
        data = [action.payload];
      }

      return {
        ...state,
        trainingCompleted: data,
      };
    case "RESET":
      return { trainingCompleted: [] };

    default:
      return state;
  }
};

export default trainingReducer;
