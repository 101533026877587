import React from "react";

const DashboardCard = ({ value, label, ratio, type }) => {
  return (
    <>
      {type === "noRation" ? (
        <div className="dashboard-card">
          <div className="value">{value}</div>
          <div className="ratio">{ratio}</div>
          <div className="label">{label}</div>
        </div>
      ) : (
        <div className="dashboard-card">
          <div className="ratio-val">
            <div className="value">{value}</div>
            <div className="ratio">{ratio}</div>
          </div>
          <div className="label">{label}</div>
        </div>
      )}
    </>
  );
};

export default DashboardCard;
