import {
  CONCLUDE_EXERCISE,
  FAILURE,
  FETCH_DATA_REQUEST,
  FETCH_FEEDBACK,
  GET_DAILY_TRAINING,
  GET_FOOD_LIST,
  GET_MEAL_PLAN,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  REGISTRATION,
  SET_FEEDBACK,
  SET_TOTAL_VALUES,
  SUCCESS,
  SUCCESS_RECIPE,
} from "../actions/actionTypes";

const initialState = {
  loading: false,
  loginData: {},
  data: {},
  error: null,
  feedbacks: {},
  totalC: 0,
  totalP: 0,
  totalF: 0,
  totalG: 0,
  exercises: [],
  concludedExercises: [],
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_DATA_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_FEEDBACK:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SET_FEEDBACK:
      return {
        ...state,
        feedbacks: {
          ...state.feedbacks,
          [action.payload.date]: action.payload.feedbackText,
        },
      };
    case GET_MEAL_PLAN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_RECIPE:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_DAILY_TRAINING:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_FOOD_LIST:
      return {
        loading: true,
        error: null,
      };
    case REGISTRATION:
      return {
        loading: true,
        error: null,
      };
    case LOGIN_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        loginData: action.payload,
      };
    case SET_TOTAL_VALUES:
      return {
        ...state,
        totalC: action.payload.totalC,
        totalP: action.payload.totalP,
        totalF: action.payload.totalF,
        totalG: action.payload.totalG,
      };
    case CONCLUDE_EXERCISE:
      return {
        ...state,
        concludedExercises: Array.isArray(state.concludedExercises)
          ? [...state.concludedExercises, action.payload]
          : [action.payload],
      };
    case SUCCESS:
      return {
        loading: false,
        data: action.payload,
        error: null,
      };
    case LOGIN_SUCCESS:
      return {
        loading: false,
        loginData: action.payload,
        error: null,
      };
    case FAILURE:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default userReducer;
