import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";

const ProtectedOnboardingRoute = ({ requiredStep }) => {
  const onboardingProgress = JSON.parse(
    localStorage.getItem("onboardingProgress")
  );
  const lastScreen = localStorage.getItem("lastScreen");
  const location = useLocation();

  if (!onboardingProgress) {
    return <Navigate to={lastScreen ? lastScreen : "/login"} />;
  }

  const stepAccess = {
    2: onboardingProgress.has_created_step_1,
    3: onboardingProgress.has_created_step_2,
    4: onboardingProgress.has_created_step_3,
    5: onboardingProgress.has_created_step_4,
    6: onboardingProgress.has_created_step_5,
  };

  const firstIncompleteStep = Object.keys(stepAccess).find(
    (step) => !stepAccess[step]
  );

  if (firstIncompleteStep && requiredStep > parseInt(firstIncompleteStep)) {
    return <Navigate to={`/onboardingstep${firstIncompleteStep}`} />;
  }
  localStorage.setItem("lastScreen", location.pathname);

  return <Outlet />;
};

const OnboardingStepOneGuard = () => {
  const isLoggedIn = localStorage.getItem("adminData");
  const onboardingProgress = isLoggedIn
    ? JSON.parse(localStorage.getItem("onboardingProgress"))
    : false;
  const lastScreen = localStorage.getItem("lastScreen");
  const location = useLocation();
  const allStepsIncomplete =
    onboardingProgress &&
    !onboardingProgress.has_created_step_1 &&
    !onboardingProgress.has_created_step_2 &&
    !onboardingProgress.has_created_step_3 &&
    !onboardingProgress.has_created_step_4 &&
    !onboardingProgress.has_created_step_5;

  if (!isLoggedIn) {
    return <Navigate to="/login" />;
  } else if (!allStepsIncomplete) {
    return <Navigate to={lastScreen ? lastScreen : "/login"} />;
  }
  localStorage.setItem("lastScreen", location.pathname);

  return <Outlet />;
};

const ProtectedRoute = () => {
  const isLoggedIn = localStorage.getItem("adminData");
  const onboardingProgress = JSON.parse(
    localStorage.getItem("onboardingProgress")
  );
  const lastScreen = localStorage.getItem("lastScreen");
  const location = useLocation();

  const allStepsCompleted =
    onboardingProgress &&
    onboardingProgress.has_created_step_1 &&
    onboardingProgress.has_created_step_2 &&
    onboardingProgress.has_created_step_3 &&
    onboardingProgress.has_created_step_4 &&
    onboardingProgress.has_created_step_5;

  if (!isLoggedIn) {
    return <Navigate to="/login" />;
  } else if (!allStepsCompleted) {
    if (onboardingProgress) {
      const firstIncompleteStep = Object.keys(onboardingProgress).find(
        (key) => !onboardingProgress[key]
      );
      return (
        <Navigate
          to={`/onboardingstep${
            Number(firstIncompleteStep.replace("has_created_step_", "")) + 1
          }`}
        />
      );
    } else {
      return <Navigate to={lastScreen ? lastScreen : "/login"} />;
    }
  }
  localStorage.setItem("lastScreen", location.pathname);

  return <Outlet />;
};

export { OnboardingStepOneGuard, ProtectedOnboardingRoute, ProtectedRoute };
