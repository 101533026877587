import React from "react";
import "../css/modal.css";
import DatePicker from "react-datepicker";
import ReactPlayer from "react-player";

const VideoModal = ({ isOpen, loading, video, handleVideoEnd }) => {
  if (!isOpen) return null;

  const spinnerStyle = {
    border: "3px solid #f3f3f3",
    borderTop: "3px solid #a0fc6e",
    borderRadius: "50%",
    width: "30px",
    height: "30px",
    animation: "spin 1s linear infinite",
  };

  const containerStyle = {
    display: "flex",
    alignContent: "center",
    backgroundColor: "transparent",
    justifyContent: "center",
  };

  return (
    <div className="modal-overlay ">
      <div className="modal-content videoModal">
        <div>
          {loading ? (
            <div style={containerStyle}>
              <div style={spinnerStyle}></div>
              <style>
                {`
              @keyframes spin {
                0% { transform: rotate(0deg); }
                100% { transform: rotate(360deg); }
              }
            `}
              </style>
            </div>
          ) : (
            <ReactPlayer
              url={video}
              controls
              playing={true}
              muted={true}
              width="100%"
              height="90vh"
              onEnded={handleVideoEnd}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default VideoModal;
