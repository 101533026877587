import React from "react";
import { useDrag } from "react-dnd";
import dishImg from "../../assets/images/Farfalle.png";

const ItemTypes = {
  ITEM: "item",
};

const AdminAtheleteCards = ({
  data,
  getItemQuantity,
  handleEditClick,
  handleRemoveItem,
}) => {
  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.ITEM,
    item: {
      id: data.id,
      name: data.name,
      calories_per_gram: data?.calories_per_gram,
      label: data?.label,
      icon: data?.icon,
      qty: getItemQuantity(data?.id),
      type: data?.type,
    },
    end: (item, monitor) => {
      const didDrop = monitor.didDrop();
      if (didDrop) {
        handleRemoveItem(data.id); // Remove the item from the list after dropping
      }
    },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  return (
    <div
      ref={drag}
      className="card-food example"
      style={{
        marginBottom: "40px",
        opacity: isDragging ? 0.5 : 1,
      }}
      onClick={() => handleEditClick(data)}
    >
      <div className="dish-icon">
        <img src={data?.icon} alt={data.name} />
      </div>
      <div className="text">
        <div className="title">{data.name}</div>
        <div className="description">
          {data.calories_per_gram} calories/gram
        </div>
        <div className="description">Quantity: {getItemQuantity(data.id)}</div>
      </div>
    </div>
  );
};

export default AdminAtheleteCards;
