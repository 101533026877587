import React, { useEffect, useRef, useState } from "react";
import NutritionTable from "./Nutritionaltable";
import SecondTable from "./SecondTable";
import Dropdown from "../Reuseable/Dropdown";
import Grid from "./GridTable";
import SmallDropDown from "../Reuseable/SmallDropDown";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import moment from "moment";
import { BASE_URL } from "../../assets/constants";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { notify } from "../util/notify";
import DeleteModal from "../DeleteModal";
import DropDownForFoodPlan from "../Reuseable/DropdownForFoodPlan";

const AdminFoodPlan = ({ type }) => {
  const navigate = useNavigate();
  const [isChecked, setIsChecked] = useState(false);
  const [openDropDown, setOpenDropDown] = useState(false);
  const [selectedOption, setSelectedOption] = useState("Giorno on");
  const [mealType, setSelectedMealType] = useState("Carboidrati");
  const [selectedDays, setSelectedDays] = useState([]);
  const [selectedDayOff, setSelectedDayOff] = useState([]);
  const [programName, setProgramName] = useState("");
  const [programName1, setProgramName1] = useState("");
  const [weekDate, setWeekDate] = useState(new Date());
  const [weekTwoDate, setWeekTwoDate] = useState(null);
  const [isDataAvailable, setIsDataAvailable] = useState(false);
  const [nutrition, setNutrition] = useState({});
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [isSaveLoading, setIsSaveLoading] = useState(false);

  const weekRef = useRef("");
  const weektwoRef = useRef("");
  const daysOnRef = useRef("");
  const daysOffRef = useRef("");
  const progressivePlanOneInputRef = useRef("");
  const progressivePlanTwoInputRef = useRef("");
  const { state } = useLocation();
  const [confirmationModal, setConfirmationModal] = useState(false);
  const options = ["Giorno on", "Giorno off"];
  const option = ["mon", "tue", "wed", "thu", "fri", "sat", "sun"];
  const mealTypeOption = ["Carboidrati", "Grassi"];

  const handleCheckboxChange = () => {
    setIsChecked((prevState) => !prevState);
  };

  const handleSelect = (option) => {
    setSelectedOption(option);
  };

  const handleSelectMeal = (option) => {
    setSelectedMealType(option);
  };

  const handleSelectedVal = (option) => {
    setSelectedDays((prevSelected) => {
      if (prevSelected.includes(option)) {
        return prevSelected.filter((day) => day !== option);
      } else {
        return [...prevSelected, option];
      }
    });
  };

  const handleSelectedValOffDay = (option) => {
    setSelectedDayOff((prevSelected) => {
      if (prevSelected.includes(option)) {
        return prevSelected.filter((day) => day !== option);
      } else {
        return [...prevSelected, option];
      }
    });
  };

  const [mealDataOn, setMealDataOn] = useState([
    {
      meal_name: "breakfast",
      calories: "",
      carbohydrates: "",
      proteins: "",
      fats: "",
      fiber: "",
    },
    {
      meal_name: "lunch",
      calories: "",
      carbohydrates: "",
      proteins: "",
      fats: "",
      fiber: "",
    },
    {
      meal_name: "dinner",
      calories: "",
      carbohydrates: "",
      proteins: "",
      fats: "",
      fiber: "",
    },
    {
      meal_name: "snack",
      calories: "",
      carbohydrates: "",
      proteins: "",
      fats: "",
      fiber: "",
    },
  ]);

  const [mealDataOff, setMealDataOff] = useState([
    {
      meal_name: "breakfast",
      meal_name: "breakfast",
      calories: "",
      carbohydrates: "",
      proteins: "",
      fats: "",
      fiber: "",
    },
    {
      meal_name: "lunch",
      meal_name: "lunch",
      calories: "",
      carbohydrates: "",
      proteins: "",
      fats: "",
      fiber: "",
    },
    {
      meal_name: "dinner",
      meal_name: "dinner",
      calories: "",
      carbohydrates: "",
      proteins: "",
      fats: "",
      fiber: "",
    },
    {
      meal_name: "snack",
      meal_name: "snack",
      calories: "",
      carbohydrates: "",
      proteins: "",
      fats: "",
      fiber: "",
    },
  ]);
  const fetchNutritionData = async () => {
    const adminData = JSON.parse(localStorage.getItem("adminData"));
    const token = adminData?.data?.token;
    const athleteData = JSON.parse(localStorage.getItem("athleteData"));

    try {
      const getResponse = await axios.get(
        type === "coachNutrition"
          ? `${BASE_URL}coach/${state?.id}/nutrition-all`
          : `${BASE_URL}admin/${athleteData?.id}/nutrition-all`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (getResponse.data) {
        setIsDataAvailable(true);
        const nutritionData = getResponse.data.data[0];
        setNutrition(nutritionData);

        const { nutration_plan_day } = nutritionData;

        const onDays = nutration_plan_day.filter((day) => day.type === "ON");
        const offDays = nutration_plan_day.filter((day) => day.type === "OFF");
        setProgramName1(nutritionData?.name);
        setIsChecked(nutritionData?.is_progressive);
        const startDate = new Date(nutritionData?.start_date);
        setWeekDate(startDate);
        const endDate = new Date(nutritionData?.end_date);

        setWeekTwoDate(endDate);
        setMealDataOn(onDays.flatMap((day) => day.nutration_play_day_meal));
        setMealDataOff(offDays.flatMap((day) => day.nutration_play_day_meal));
        setSelectedDays(onDays.flatMap((day) => JSON.parse(day.days_of_week)));
        setSelectedDayOff(
          offDays.flatMap((day) => JSON.parse(day.days_of_week))
        );
      }
    } catch (error) {
      console.error("Error fetching nutrition data:", error);
    }
  };

  useEffect(() => {
    fetchNutritionData();
  }, []);

  const isDataAvailableOn = mealDataOn.some(
    (meal) =>
      meal.calories ||
      meal.carbohydrates ||
      meal.proteins ||
      meal.fats ||
      meal.fiber
  );
  const isDataAvailableOff = mealDataOff.some(
    (meal) =>
      meal.calories ||
      meal.carbohydrates ||
      meal.proteins ||
      meal.fats ||
      meal.fiber
  );

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSaveLoading(true);

    const onDays = {
      type: "ON",
      day_names: selectedDays,
      meals: mealDataOn.map((meal) => ({
        meal_name: meal.meal_name,
        calories: meal.calories || "0",
        carbohydrates: meal.carbohydrates || "0",
        proteins: meal.proteins || "0",
        fats: meal.fats || "0",
        fiber: meal.fiber || "0",
      })),
    };

    const offDays = {
      type: "OFF",
      day_names: selectedDayOff,
      meals: mealDataOff.map((meal) => ({
        meal_name: meal.meal_name,
        calories: meal.calories || "0",
        carbohydrates: meal.carbohydrates || "0",
        proteins: meal.proteins || "0",
        fats: meal.fats || "0",
        fiber: meal.fiber || "0",
      })),
    };

    const requestData = {
      name: programName || "Nutrition One",
      is_progressive: isChecked,
      start_date: moment(weekDate).format("DD-MM-YYYY"),
      end_date: moment(weekTwoDate).format("DD-MM-YYYY"),
      nutrition_plan: {
        on_days: onDays,
        off_days: offDays,
      },
    };

    if (!weekDate || !weeksBetween) {
      notify(true, "Invalid date");
    }

    const adminData = JSON.parse(localStorage.getItem("adminData"));
    const token = adminData?.data?.token;
    const athleteData = JSON.parse(localStorage.getItem("athleteData"));

    try {
      const response = await axios.post(
        type === "coachNutrition"
          ? `${BASE_URL}coach/${state?.id}/nutrition-store`
          : `${BASE_URL}admin/${athleteData?.id}/nutrition-store`,
        requestData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      fetchNutritionData();
      notify("", response.data.success);
      setIsSaveLoading(false);

      if (response.ok) {
        navigate("/admin/atleti");
      }
    } catch (error) {
      const errorMessages = error?.response?.data?.message;

      if (errorMessages) {
        const alertMessages = [];

        for (const key in errorMessages) {
          if (Array.isArray(errorMessages[key])) {
            alertMessages.push(...errorMessages[key]);
          }
        }

        if (alertMessages.length > 0) {
          notify(true, alertMessages.join("\n"));
        }
      } else if (error?.response?.data?.message?.user) {
        notify(true, error.response.data.message.user);
      }
      setIsSaveLoading(false);
    }
  };

  const handleDelete = async () => {
    setIsDeleteLoading(true);
    const adminData = JSON.parse(localStorage.getItem("adminData"));
    const token = adminData?.data?.token;
    try {
      const response = await axios.delete(
        type === "coachNutrition"
          ? `${BASE_URL}coach/${nutrition?.id}/nutrition-delete`
          : `${BASE_URL}admin/${nutrition?.id}/nutrition-delete`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      fetchNutritionData();
      notify("", response.data.success);
      setSelectedOption("");
      setSelectedDays([]);
      setSelectedDayOff([]);
      setProgramName("");
      setProgramName1("");
      setWeekDate(new Date());
      setWeekTwoDate("");
      setNutrition({});
      setMealDataOn([
        {
          meal_name: "breakfast",
          calories: "",
          carbohydrates: "",
          proteins: "",
          fats: "",
          fiber: "",
        },
        {
          meal_name: "lunch",
          calories: "",
          carbohydrates: "",
          proteins: "",
          fats: "",
          fiber: "",
        },
        {
          meal_name: "dinner",
          calories: "",
          carbohydrates: "",
          proteins: "",
          fats: "",
          fiber: "",
        },
        {
          meal_name: "snack",
          calories: "",
          carbohydrates: "",
          proteins: "",
          fats: "",
          fiber: "",
        },
      ]);
      setMealDataOff([
        {
          meal_name: "breakfast",
          calories: "",
          carbohydrates: "",
          proteins: "",
          fats: "",
          fiber: "",
        },
        {
          meal_name: "lunch",
          calories: "",
          carbohydrates: "",
          proteins: "",
          fats: "",
          fiber: "",
        },
        {
          meal_name: "dinner",
          calories: "",
          carbohydrates: "",
          proteins: "",
          fats: "",
          fiber: "",
        },
        {
          meal_name: "snack",
          calories: "",
          carbohydrates: "",
          proteins: "",
          fats: "",
          fiber: "",
        },
      ]);
      setIsDeleteLoading(false);
      setConfirmationModal(false);
    } catch (error) {
      setConfirmationModal(false);
      setIsDeleteLoading(false);
      console.log(error);
    }
  };

  const [weeksBetween, setWeeksBetween] = useState(0);
  const isStartDateAvailable = weekTwoDate !== null;

  const calculateWeeksBetween = (startDate, endDate) => {
    if (
      !startDate ||
      !endDate ||
      !(startDate instanceof Date) ||
      !(endDate instanceof Date)
    ) {
      return 0;
    }

    const diffInMs = endDate.getTime() - startDate.getTime();
    const diffInDays = diffInMs / (1000 * 60 * 60 * 24);
    const weeks = Math.ceil(diffInDays / 7);
    return weeks;
  };

  useEffect(() => {
    if (weekDate && weekTwoDate) {
      const weeks = calculateWeeksBetween(weekDate, weekTwoDate);
      setWeeksBetween(weeks);
    }
  }, [weekDate, weekTwoDate]);

  const handleDateChange = (date, isStartDate) => {
    if (isStartDate) {
      setWeekDate(date);
    } else {
      setWeekTwoDate(date);
    }
  };

  const spinnerStyle = {
    border: "3px solid #f3f3f3",
    borderTop: "3px solid #a0fc6e",
    borderRadius: "50%",
    width: "30px",
    height: "30px",
    animation: "spin 1s linear infinite",
  };

  const containerStyle = {
    display: "flex",
    justifyContent: "center",
    marging: "0 auto",
    backgroundColor: "transparent",
  };

  return (
    <>
      <form>
        <div className="food-plan-conatiner">
          <div className="food-plan-conatiner-flex">
            <div>
              <h1 className="food-title">piano alimentare</h1>
              <div className="form-div">
                <label className="food-para margin">Nome Programma</label>
                {programName1 ? (
                  <label className="margin" style={{ color: "grey" }}>
                    {programName1}
                  </label>
                ) : (
                  <input
                    type="text"
                    placeholder="Enter program name"
                    className="form-input"
                    value={programName}
                    onChange={(e) => setProgramName(e.target.value)}
                    style={{ marginBottom: "20px" }}
                  />
                )}
              </div>
              {isChecked == 1 && (
                <div className="checkbox-flex">
                  <input
                    type="checkbox"
                    className="food-plan-check-box"
                    id="food-plan-check-box"
                    onChange={handleCheckboxChange}
                  />
                  <label
                    htmlFor="food-plan-check-box"
                    className="food-plan-check-box-label"
                  ></label>

                  <p className="food-para ">Piano Prograssivo</p>
                </div>
              )}
            </div>
            <div className="food-plan-flex">
              <p className="food-para-setimane">{weeksBetween} SETTIMANE</p>

              <DatePicker
                selected={weekDate}
                onChange={(date) => handleDateChange(date, true)}
                dateFormat="MM/dd"
                minDate={new Date()}
                className="input-container-activity large"
                disabled={isStartDateAvailable}
              />

              <DatePicker
                selected={weekTwoDate}
                onChange={(date) => handleDateChange(date, false)}
                dateFormat="MM/dd"
                className="input-container-activity large"
                minDate={
                  weekDate
                    ? new Date(weekDate.getTime() + 1 * 24 * 60 * 60 * 1000)
                    : null
                }
                // disabled={ !weekDate || isStartDateAvailable}
              />
            </div>
          </div>
          {isChecked == 1 ? (
            <div className="select-flex">
              <Dropdown
                options={options}
                onSelect={handleSelect}
                btn="mese"
                bgList="true"
                content={selectedOption}
              />
              <Dropdown
                options={mealTypeOption}
                onSelect={handleSelectMeal}
                btn="mese"
                bgList="true"
                content={mealType}
              />
              <div>
                <input
                  type="text"
                  ref={progressivePlanOneInputRef}
                  className="input-container-activity large"
                  required
                />
              </div>

              <div>
                <input
                  type="text"
                  className="input-container-activity large"
                  ref={progressivePlanTwoInputRef}
                  required
                />
              </div>
            </div>
          ) : null}

          <div className="NutritionTable">
            <div className="giorno-container">
              <div className="giorno-flex">
                <div className="giorno-flex-title">Giorno on</div>
                <input
                  type="text"
                  id="first_name"
                  ref={daysOnRef}
                  required
                  value={selectedDays.length}
                  className="input-container-activity medium"
                  readOnly
                />
                {programName1 && selectedDays.join(", ")}
                {!programName1 && (
                  <DropDownForFoodPlan
                    content={selectedDays.join(", ")}
                    options={option}
                    onSelect={handleSelectedVal}
                    adminFood
                  />
                )}
              </div>
              <Grid
                mealData={mealDataOn}
                setMealData={setMealDataOn}
                isDataAvailable={programName1}
              />
            </div>

            <div className="giorno-flex">
              <div className="giorno-flex-title">Giorno off</div>
              <input
                type="text"
                id="first_name"
                required
                ref={daysOffRef}
                readOnly
                value={selectedDayOff.length}
                className="input-container-activity medium"
              />
              {programName1 && selectedDayOff.join(", ")}

              {!programName1 && (
                <DropDownForFoodPlan
                  content={selectedDayOff.join(", ")}
                  options={option}
                  onSelect={handleSelectedValOffDay}
                  className="padding-select"
                  adminFood
                />
              )}
            </div>
            <Grid
              type="giorno-off"
              mealData={mealDataOff}
              setMealData={setMealDataOff}
              isDataAvailable={programName1}
            />
          </div>

          <div className="right-side-content">
            {isSaveLoading ? (
              <div style={{ ...containerStyle, width: 100 }}>
                <div style={spinnerStyle}></div>
                <style>
                  {`
           @keyframes spin {
             0% { transform: rotate(0deg); }
             100% { transform: rotate(360deg); }
           }
         `}
                </style>
              </div>
            ) : (
              <button type="submit" className="save-btn" onClick={handleSubmit}>
                salva
              </button>
            )}

            <p
              style={{ cursor: "pointer" }}
              onClick={() => setConfirmationModal(true)}
            >
              Ripristina il piano alimentare
            </p>
          </div>
          <DeleteModal
            isOpen={confirmationModal}
            onCancel={() => setConfirmationModal(false)}
            onConfirm={handleDelete}
            message="Vuoi eliminare questa Nutrizione"
            isLoading={isDeleteLoading}
          />
        </div>
      </form>
    </>
  );
};

export default AdminFoodPlan;
