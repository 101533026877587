import React, { useEffect, useState } from "react";
import axios from "axios";
import editIcon from "..//assets/img/icons/edit.svg";
import calendarIcon from "../assets/img/icons/calendar.svg";
import tickIcon from "..//assets/img/icons/tick-icon.svg";
import "../css/index.css";
import plusIcon from "..//assets/img/icons/plus-black.svg";
import { BASE_URL } from "../assets/constants/index";
import ConfirmModal from "../components/ConfirmModal";
import CalendarioModal from "../components/CalendarioModal";
import deleteIcon from "../assets/img/icons/delete.svg";

const Loader = () => {
  return (
    <div
      className="loader-container"
      style={{ display: "flex", justifyContent: "center" }}
    >
      <div className="spinner" style={{ width: "50px", height: "50px" }}></div>
    </div>
  );
};

const TaskEditor = ({
  title,
  date,
  description,
  status,
  onComplete,
  showEdit,
  type,
  handleEditClick,
  handleDeleteClick,
}) => {
  const [isChecked, setIsChecked] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleCheckboxClick = () => {
    setIsChecked(!isChecked);
    setIsModalOpen(true);
  };

  const handleConfirm = () => {
    onComplete();
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsChecked(false);
    setIsModalOpen(false);
  };

  return (
    <div className="task-editor">
      <div className="title">
        {title}
        {showEdit && (
          <div>
            <img src={editIcon} alt="edit icon" onClick={handleEditClick} />
            <img
              src={deleteIcon}
              alt="delete icon"
              onClick={handleDeleteClick}
              style={{
                marginLeft: "10px",
                cursor: "pointer",
                width: "20px",
                height: "20px",
                filter: "invert(100%) brightness(100%)",
              }}
            />
          </div>
        )}
      </div>
      <div className="task-body">
        <img src={calendarIcon} alt="calendar icon" />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <label>{date}</label>
          {status === "pending" && (
            <div
              onClick={handleCheckboxClick}
              style={{
                display: "flex",
                width: "30px",
                height: "30px",
                border: isChecked ? "none" : "2px solid gray",
                backgroundColor: isChecked ? "#a0fc6e" : "transparent",
                borderRadius: "15px",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              {isChecked && (
                <img
                  src={tickIcon}
                  alt="tick icon"
                  className="tick-icon"
                  style={{ width: "20px", height: "20px" }}
                />
              )}
            </div>
          )}
        </div>
      </div>
      <div className="description desc-font">{description}</div>

      {isModalOpen && (
        <ConfirmModal
          isOpen={isModalOpen}
          onConfirm={handleConfirm}
          onCancel={handleCancel}
        />
      )}
    </div>
  );
};

const CalendarioContent = () => {
  const [todayTasks, setTodayTasks] = useState([]);
  const [futureTasks, setFutureTasks] = useState([]);
  const [completedTasks, setCompletedTasks] = useState([]);
  const [loading, setLoading] = useState(false);

  const [selectedNote, setSelectedNote] = useState(null);
  const [isModalOpenEdit, setModalOpen] = useState(false);

  const handleEditClick = (note) => {
    setModalOpen(true);
    setSelectedNote(note);
  };

  const adminData = JSON.parse(localStorage.getItem("adminData"));
  const token = adminData?.data?.token;

  const fetchTasks = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${BASE_URL}admincoach/note/all`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const data = response.data;
      setTodayTasks(data.today.pending);
      setFutureTasks(data.future);
      setCompletedTasks(data.complete);
    } catch (error) {
      console.error("Error fetching tasks:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleMarkComplete = async (taskId) => {
    const adminData = JSON.parse(localStorage.getItem("adminData"));
    const token = adminData?.data?.token;

    try {
      setLoading(true);
      await axios.get(`${BASE_URL}admin/note/${taskId}/update`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      fetchTasks();
    } catch (error) {
      console.error("Error updating task status:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTasks();
  }, []);

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleModalSave = () => {
    fetchTasks();
    setModalOpen(false);
  };

  const handleDeleteClick = async (noteId, user_type) => {
    try {
      const token = adminData?.data?.token;

      await axios.delete(
        `${BASE_URL}admin/note/${user_type}/delete/${noteId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      fetchTasks();
    } catch (error) {
      console.error("Error deleting note:", error);
    }
  };

  return (
    <div className="calendrioContainer">
      <div className="topBar">
        <span>calendario task</span>
      </div>

      {loading ? (
        <Loader />
      ) : (
        <div className="admin-calendar-group">
          <div
            className={`admin-future ${
              todayTasks?.length > 0 ? "green-border" : ""
            }`}
          >
            <div className="title border-topp-title">Da fare</div>
            {todayTasks?.length > 0 ? (
              todayTasks.map((task) => (
                <TaskEditor
                  key={task.id}
                  title={task.title}
                  date={task.date}
                  description={task.description}
                  status={task.status}
                  type={task.user_type}
                  handleEditClick={() => handleEditClick(task)}
                  onComplete={() => handleMarkComplete(task.id)}
                  handleDeleteClick={() =>
                    handleDeleteClick(task.uuid, task.user_type)
                  }
                  showEdit
                />
              ))
            ) : (
              <p>No tasks for today</p>
            )}
          </div>
          <CalendarioModal
            isOpen={isModalOpenEdit}
            onClose={handleCloseModal}
            token={token}
            onSave={handleModalSave}
            selectedNote={selectedNote}
            adminCoach={selectedNote?.user_type === "coach"}
          />

          <div
            className={`admin-future ${
              futureTasks?.length > 0 ? "green-border" : ""
            }`}
          >
            <div className="title border-topp-title">Future</div>
            {futureTasks?.length > 0 ? (
              futureTasks.map((task) => (
                <TaskEditor
                  key={task.id}
                  title={task.title}
                  date={task.date}
                  description={task.description}
                  status={task.status}
                  type={task.user_type}
                  handleEditClick={() => handleEditClick(task)}
                  onComplete={() => handleMarkComplete(task.id)}
                  handleDeleteClick={() =>
                    handleDeleteClick(task.uuid, task.user_type)
                  }
                  showEdit
                />
              ))
            ) : (
              <p>No future tasks</p>
            )}
          </div>

          <div
            className={`admin-completed ${
              completedTasks?.length > 0 ? "green-border" : ""
            }`}
          >
            <div className="title border-topp-title">Completate</div>
            {completedTasks?.length > 0 ? (
              completedTasks?.map((task) => (
                <TaskEditor
                  key={task.id}
                  title={task.title}
                  date={task.date}
                  description={task.description}
                />
              ))
            ) : (
              <p>No completed tasks</p>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default CalendarioContent;
