import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./nutrition.css";
// Reuseable
import WeekCalendar from "../Reuseable/WeekCalender";
import { AiOutlineCheck } from "react-icons/ai";
import SearchIcons from "../../assets/img/icons/search-active.svg";

// Icons
import PlusIcon from "../../assets/img/icons/plus-active.svg";
import CheckIcon from "../../assets/img/icons/check-active.svg";
import CloseIcon from "../../assets/img/icons/close-active.svg";
// Components
import { LeftArrowIcon } from "../Reuseable/Icons";
import { nutritionData } from "../../dumyData";
import axios from "axios";
import { BASE_URL } from "../../assets/constants";
import { FaRegCircle } from "react-icons/fa6";
import { notify } from "../util/notify";
import DeleteModal from "../DeleteModal";

const NutritionNewStep3 = ({
  onButtonClick,
  yourRecipe,
  nextStep,
  suggested,
}) => {
  const adminData = JSON.parse(localStorage.getItem("adminData"));
  const token = adminData?.token;

  const [mealData, setMealData] = useState(null);
  const [recomendedMeal, setRecomendedMeal] = useState(null);
  const [recomendedMealFiltered, setRecomendedMealFiltered] = useState(null);
  const [filteredMealData, setFilteredMealData] = useState(null);
  const [currentIndex, setCuurentIndex] = useState("breakfast");
  const [nData, setNData] = useState({});
  const [nDataIndex, setNDataindex] = useState(0);
  const [searchInput, setSearchInput] = useState("");
  const [iconCheck, setIconCheck] = useState(true);
  const [showAll, setShowAll] = useState(false);
  const [openIndex, setOpenIndex] = useState(null);
  const [openIndexFavorite, setOpenIndexFavorite] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [openModal, setOpenModal] = useState(0);
  const [date, setDate] = useState("");
  const [type, setType] = useState(currentIndex);
  const [caloriesData, setCaloriesData] = useState(null);
  const [filterCaloriesData, setFilterCaloriesData] = useState(null);

  const navigate = useNavigate();
  const handleBackClick = () => {
    navigate(-1);
  };
  const handleToggle = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };
  const handleToggleFavorite = (index) => {
    setOpenIndexFavorite(openIndexFavorite === index ? null : index);
  };
  const monthNames = [
    "Gennaio",
    "Febbraio",
    "Marzo",
    "Aprile",
    "Maggio",
    "Giugno",
    "Luglio",
    "Agosto",
    "Settembre",
    "Ottobre",
    "Novembre",
    "Dicembre",
  ];

  const initialItemCount = 5;

  const handleShowAllClick = () => {
    setShowAll(true);
  };

  const filteredItems = suggested?.filter((item) =>
    item?.Descrizione?.toLowerCase().includes(searchInput.toLowerCase())
  );

  const itemsToDisplay = showAll
    ? filteredItems
    : filteredItems?.slice(0, initialItemCount);

  const [currentDate, setCurrentDate] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(new Date());

  const generateCalendarDates = (date) => {
    const dates = [];
    const month = date.getMonth();
    const year = date.getFullYear();
    const firstDay = new Date(year, month, 1).getDay();
    const lastDate = new Date(year, month + 1, 0).getDate();

    // Fill in the dates of the previous month
    const prevMonthLastDate = new Date(year, month, 0).getDate();
    for (let i = firstDay === 0 ? 6 : firstDay - 1; i > 0; i--) {
      dates.push({
        day: prevMonthLastDate - i + 1,
        currentMonth: false,
      });
    }

    // Fill in the current month's dates
    for (let i = 1; i <= lastDate; i++) {
      dates.push({
        day: i,
        currentMonth: true,
        isToday:
          i === date.getDate() &&
          month === new Date().getMonth() &&
          year === new Date().getFullYear(),
        isSelected:
          i === selectedDate.getDate() &&
          month === selectedDate.getMonth() &&
          year === selectedDate.getFullYear(),
      });
    }

    return dates;
  };

  const prevMonth = () => {
    setCurrentDate(new Date(currentDate.setMonth(currentDate.getMonth() - 1)));
  };

  const nextMonth = () => {
    setCurrentDate(new Date(currentDate.setMonth(currentDate.getMonth() + 1)));
  };

  const handleDateClick = (day) => {
    setSelectedDate(
      new Date(currentDate.getFullYear(), currentDate.getMonth(), day)
    );
    // console.log(
    //   new Date(currentDate.getFullYear(), currentDate.getMonth(), day)
    // );
  };

  const calendarDates = generateCalendarDates(currentDate);

  const getMealPlan = async () => {
    let date = new Date(selectedDate);
    const formattedDate =
      date.getFullYear() +
      "-" +
      String(date.getMonth() + 1).padStart(2, "0") +
      "-" +
      String(date.getDate()).padStart(2, "0");

    try {
      let response = await axios.post(
        `${BASE_URL}athlete/meal`,
        { date: formattedDate },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setMealData(response?.data?.athlete_meal);
      setRecomendedMeal(response?.data?.recomended_meal);
    } catch (error) {
      console.log("err", error);
      setMealData([]);
      setRecomendedMeal([]);
    }
  };

  const handleChangeStatus = async (dietId) => {
    // console.log(2);
    try {
      let response = await axios.post(
        `${BASE_URL}athlete/meal/${dietId}/status`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      notify(false, response?.data?.message);
      getMealPlan();
    } catch (error) {}
  };

  // const filteredDataAthlete = filteredMealData?.[0]?.today_diet?.filter(
  //   (value) => value?.diet?.toLowerCase().includes(searchQuery.toLowerCase())
  // );

  const onCopyMeal = async () => {
    const formattedDate = date.toLocaleDateString("en-GB").replace(/\//g, "-");

    try {
      let response = await axios.post(
        `${BASE_URL}athlete/meal/${openModal}/copy`,
        {
          type: type,
          date: formattedDate,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      notify("", response?.data?.message);
      setOpenModal(0);
      getMealPlan();
    } catch (error) {
      console.log("error", error);
      notify(
        true,
        error?.response?.data?.message || error?.response?.data?.error
      );
    }
  };
  const getCaloires = async () => {
    let date = new Date(selectedDate);
    const formattedDate =
      date.getFullYear() +
      "-" +
      String(date.getMonth() + 1).padStart(2, "0") +
      "-" +
      String(date.getDate()).padStart(2, "0");
    try {
      let response = await axios.post(
        `${BASE_URL}athlete/plan-meal`,
        {
          date: formattedDate,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setCaloriesData(response?.data?.data);
    } catch (error) {
      console.log({ error });

      // notify(
      //   false,
      //   error?.response?.data?.message?.date
      //     ? error?.response?.data?.message?.date[0]
      //     : error?.response?.data?.message
      // );
    }
  };

  useEffect(() => {
    let meal =
      mealData && mealData?.filter((meal) => meal?.type == currentIndex);
    if (meal) setFilteredMealData(meal);
    let recomended =
      recomendedMeal &&
      recomendedMeal?.filter((meal) => meal?.type == currentIndex);
    if (meal) setRecomendedMealFiltered(recomended);
    setType(currentIndex);
    let caories =
      caloriesData &&
      caloriesData?.filter((meal) => meal?.type == currentIndex);
    if (caories) setFilterCaloriesData(caories);
  }, [currentIndex, mealData, caloriesData]);

  useEffect(() => {
    getMealPlan();
    getCaloires();
  }, [selectedDate]);

  return (
    <div className="container">
      <div className="row">
        <div className=" nutrition-flex">
          <div className="top-bar">
            <button onClick={handleBackClick} className="icon">
              <LeftArrowIcon />
            </button>
            <div className="title">nutrizione</div>
          </div>
        </div>
        <div className="col-md-8">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
            }}
          >
            {[
              { name: "Colazione", value: "breakfast" },
              { name: "Pranzo", value: "lunch" },
              { name: "Cena", value: "dinner" },
              { name: "Spuntino", value: "snack" },
            ].map((item, index) => {
              return (
                <div key={index}>
                  <div
                    className="description"
                    style={{
                      cursor: "pointer",
                      borderBottom:
                        currentIndex === item?.value
                          ? "1px solid #7fff00"
                          : null,
                      width: "10em",
                      alignItems: "center",
                      textAlign: "center",
                      padding: 10,
                    }}
                    onClick={() => {
                      setCuurentIndex(item?.value);
                      setOpenIndex(null);
                      setSearchQuery("");
                    }}
                  >
                    {item.name}
                  </div>
                </div>
              );
            })}
          </div>

          <div>
            <div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-evenly",
                  paddingLeft: "10em",
                  paddingRight: "10em",
                  marginTop: 50,
                  marginBottom: "5em",
                  cursor: "pointer",
                }}
              >
                {[
                  {
                    title: `${
                      (filterCaloriesData && filterCaloriesData[0]?.calories) ||
                      0
                    } kcal`,
                    tag: "Calorie",
                    person: 20,
                  },
                  {
                    title: `${
                      (filterCaloriesData &&
                        filterCaloriesData[0]?.carbohydrates) ||
                      0
                    } G`,
                    tag: "Carboidrati",
                    person: 20,
                  },
                  {
                    title: `${
                      (filterCaloriesData && filterCaloriesData[0]?.proteins) ||
                      0
                    } G`,
                    tag: "Proteine",
                    person: 20,
                  },
                  {
                    title: `${
                      (filterCaloriesData && filterCaloriesData[0]?.fats) || 0
                    } G`,
                    tag: "Grassi",
                    person: 20,
                  },
                  {
                    title: `${
                      (filterCaloriesData && filterCaloriesData[0]?.fiber) || 0
                    } G`,
                    tag: "Fibre",
                    person: 20,
                  },
                ].map((i) => {
                  return (
                    <div>
                      <div
                        className="title"
                        style={{ fontSize: 20, color: "#7fff00" }}
                      >
                        {i?.title}
                      </div>
                      <div
                        className="title"
                        style={{
                          fontSize: 13,
                          color: "#7fff00",
                          marginLeft: 10,
                        }}
                      >
                        {i?.tag}
                      </div>
                      <div
                        style={{
                          backgroundColor: "#666",
                          // width: 70,
                          height: 5,
                          borderRadius: 100,
                        }}
                      >
                        <div
                          style={{
                            backgroundColor: "#7fff00",
                            width: 30,
                            height: 5,
                            borderRadius: 100,
                          }}
                        ></div>
                      </div>
                    </div>
                  );
                })}
              </div>

              {/* ....................... */}

              <div className="search-bar">
                <div className="icon left">
                  <img src={SearchIcons} alt="search-icon" />
                </div>
                <div className="input-textarea">
                  <input
                    type="search"
                    name="searchInput"
                    placeholder="Search input text"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                  />
                </div>
                <div className="icon right">
                  {searchInput && (
                    <img
                      onClick={() => setSearchInput("")}
                      src={CloseIcon}
                      alt="close-icon"
                    />
                  )}
                </div>
              </div>

              {/* <div style={{ marginTop: 50 }}>
                {filteredData &&
                  filteredData?.map((value, index) => (
                    <div key={index} className="accordion-item">
                      <button className="accordion-header p-0">
                        <div
                          onClick={() => handleToggle(index)}
                          style={{
                            width: "95%",
                            height: "50px",
                            padding: "10px",
                          }}
                        >
                          {value?.diet}
                        </div>
                        <div>
                          {value?.status === "pending" ? (
                            <FaRegCircle
                              onClick={() => handleChangeStatus(value?.id)}
                              style={{
                                position: "relative",
                                top: 10,
                                right: 10,
                                fontSize: 18,
                              }}
                            />
                          ) : (
                            <img
                              src={CheckIcon}
                              alt=""
                              style={{
                                position: "relative",
                                top: 12,
                                right: 10,
                              }}
                            />
                          )}
                        </div>
                      </button>
                      {openIndex === index && (
                        <div
                          className="accordion-body"
                          style={{ background: "#080808", color: "#8F8F8F" }}
                        >
                          <div className="d-flex gap-2 text-uppercase">
                            <section>
                              <p>calorie</p>
                              <p>{value?.caloriess} G</p>
                            </section>
                            <section>
                              <p>carboidrati</p>
                              <p>{value?.carbohydrates} G</p>
                            </section>
                            <section>
                              <p>proteine</p>
                              <p>{value?.proteins} G</p>
                            </section>
                            <section>
                              <p>grassi</p>
                              <p>{value?.fats} G</p>
                            </section>
                            <section>
                              <p>Fibre</p>
                              <p>{value?.fiber} G</p>
                            </section>
                          </div>
                        </div>
                      )}
                    </div>
                  ))}

                <br />
                <button
                  className="button-default-no-icon"
                  onClick={() => {
                    navigate("/user/nutrizione/step-four");
                  }}
                >
                  aggiungi nuova ricetta
                </button>
              </div> */}

              <div style={{ marginTop: 50 }}>
                <p className="title fs-2">consigliati</p>
                {recomendedMealFiltered &&
                  recomendedMealFiltered?.map((value, index) => (
                    <div key={index} className="accordion-item">
                      <button className="accordion-header p-2 text-capitalize">
                        <div
                          onClick={() => handleToggle(index)}
                          style={{
                            width: "95%",
                            height: "50px",
                            padding: "10px",
                          }}
                        >
                          {value?.name}
                        </div>
                        <div className="d-flex align-items-end gap-3">
                          <img
                            src={PlusIcon}
                            onClick={() => setOpenModal(value?.id)}
                            style={{
                              position: "relative",
                              top: 13,
                              cursor: "pointer",
                            }}
                          />
                          {value?.today_diet[0]?.status === "pending" ? (
                            <FaRegCircle
                              onClick={() => handleChangeStatus(value?.id)}
                              style={{
                                position: "relative",
                                top: 10,
                                right: 10,
                                fontSize: 18,
                              }}
                            />
                          ) : (
                            <img
                              src={CheckIcon}
                              alt=""
                              style={{
                                position: "relative",
                                top: 12,
                                right: 10,
                              }}
                            />
                          )}
                        </div>
                      </button>
                      {openIndex === index && (
                        <div
                          className="accordion-body"
                          style={{ background: "#080808", color: "#8F8F8F" }}
                        >
                          <div className="d-flex gap-2 text-uppercase">
                            {value?.today_diet?.map((value, index) => {
                              return (
                                <section key={index}>
                                  <p>
                                    {value?.diet} {value?.caloriess}G,
                                  </p>
                                </section>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </div>
                  ))}

                <p className="title fs-2">i tuoi preferiti</p>
                {filteredMealData &&
                  filteredMealData?.map((value, index) => (
                    <div key={index} className="accordion-item">
                      <button className="accordion-header p-2 text-capitalize">
                        <div
                          onClick={() => handleToggleFavorite(index)}
                          style={{
                            width: "95%",
                            height: "50px",
                            padding: "10px",
                          }}
                        >
                          {value?.name}
                        </div>
                        <div className="d-flex align-items-end gap-3">
                          <img
                            src={PlusIcon}
                            onClick={() => setOpenModal(value?.id)}
                            style={{
                              position: "relative",
                              top: 13,
                              cursor: "pointer",
                            }}
                          />
                          {value?.today_diet[0]?.status === "pending" ? (
                            <FaRegCircle
                              onClick={() => handleChangeStatus(value?.id)}
                              style={{
                                position: "relative",
                                top: 10,
                                right: 10,
                                fontSize: 18,
                              }}
                            />
                          ) : (
                            <img
                              src={CheckIcon}
                              alt=""
                              style={{
                                position: "relative",
                                top: 12,
                                right: 10,
                              }}
                            />
                          )}
                        </div>
                      </button>
                      {openIndexFavorite === index && (
                        <div
                          className="accordion-body"
                          style={{ background: "#080808", color: "#8F8F8F" }}
                        >
                          <div className="d-flex gap-2 text-uppercase">
                            {value?.today_diet?.map((value, index) => {
                              return (
                                <section key={index}>
                                  <p>
                                    {value?.diet} {value?.caloriess} G,
                                  </p>
                                </section>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </div>
                  ))}

                <DeleteModal
                  setMealText={setDate}
                  meal={date}
                  isOpen={openModal}
                  onCancel={() => setOpenModal(0)}
                  onConfirm={onCopyMeal}
                  message="Inserisci il testo"
                  typeInp
                  type={"date"}
                />
                <br />
                <button
                  className="button-default-no-icon"
                  onClick={() => {
                    navigate("/user/nutrizione/step-four");
                  }}
                >
                  aggiungi nuova ricetta
                </button>
              </div>

              {/* ................................... */}
            </div>
          </div>
        </div>

        {/* ............................Calender..................... */}
        <div className="col-md-4">
          <div className="calendar-container">
            <div className="calendar-header">
              <button className="nav-arrow" onClick={prevMonth}>
                &#8592;
              </button>
              <span className="month-name">
                {monthNames[currentDate.getMonth()]} {currentDate.getFullYear()}
              </span>
              <button className="nav-arrow" onClick={nextMonth}>
                &#8594;
              </button>
            </div>
            <div className="calendar-weekdays">
              <span>L</span>
              <span>M</span>
              <span>M</span>
              <span>G</span>
              <span>V</span>
              <span>S</span>
              <span>D</span>
            </div>
            <div className="calendar-dates">
              {calendarDates.map((date, index) => (
                <span
                  key={index}
                  className={`${!date.currentMonth ? "prev-month" : ""} ${
                    date.isToday ? "current-day" : ""
                  } ${date.isSelected ? "selected" : ""}`}
                  onClick={() => date.currentMonth && handleDateClick(date.day)}
                >
                  {date.day}
                </span>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* .................................END............................. */}
    </div>
  );
};

export default NutritionNewStep3;
