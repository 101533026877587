import React, { useEffect, useState } from "react";
import "../../css/profilo.css";
import userActive from "../../assets/img/icons/user-active.svg";
import clipboardActive from "../../assets/img/icons/clipboard-active.svg";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import logoutIcon from "../../../src/assets/img/icons/log-out-red.svg";
import { LeftArrowIcon } from "../../../src/components/Reuseable/Icons";
import Loader from "../../components/Users/globalLoader";

export default function AdminProfile() {
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const [loading, setLoading] = useState(false);

  const handleBackClick = () => {
    navigate(-1);
  };

  // useEffect(() => {
  //   if (!token) {
  //     navigate("/login");
  //   }
  // }, []);

  const handleLogout = (e) => {
    e.preventDefault();
    setLoading(true);
    setTimeout(() => {
      localStorage.removeItem("token");
      localStorage.removeItem("adminData");
      navigate("/login");
    }, 500);
  };

  return (
    <div className="profilo">
      {loading && <Loader />}
      <div className="headerCalendrio">
        <button onClick={handleBackClick} className="icon">
          <LeftArrowIcon />
        </button>
        <div className="titleText">Profilo</div>
      </div>
      <div className="horiLine" />
      <div className="main-profile-link-group">
        <a className="main-profile-link" href="">
          <img src={userActive} alt="" />
          <p>Modifica profilo</p>
        </a>
        <Link to={"/privacy"} target="_blank">
          <a
            className="main-profile-link"
            to="/privacy"
            // href="https://www.appilgiusto.com/privacy.php"
            target="_blank"
          >
            {/* <img src={clipboardIcon} alt="" /> */}
            <img src={clipboardActive} alt="" />
            Privacy
          </a>
        </Link>
        <a
          className="main-profile-link"
          onClick={handleLogout}
          style={{ cursor: "pointer" }}
        >
          <img src={logoutIcon} alt="Logout" />
          <p>Logout</p>
        </a>
      </div>
    </div>
  );
}
