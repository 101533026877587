import React, { useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setRegistration } from "../../redux/actions";
import image from "../../assets/images/calendar.png";
import { IoMdEye } from "react-icons/io";
import { IoMdEyeOff } from "react-icons/io";

// CSS
import "./Register.css";

// Components
import Header from "../Layout/Header";
import axios from "axios";
import { KApiPostRegister } from "../../config/WebServices";
import { notify } from "../util/notify";
import { BASE_URL } from "../../assets/constants";
import Dropdown from "../Reuseable/Dropdown";

function RegisterForm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const nameRef = useRef();
  const emailRef = useRef();
  const passwordRef = useRef();
  const passwordCRef = useRef();
  const phoneRef = useRef();
  const accountTypeRef = useRef();
  const [type, setType] = useState("athlete");
  const inputRef = useRef(null);

  const [profileImage, setProfileImage] = useState();
  const [showPassword, setShowPassword] = useState(false);
  const [showCPassword, setShowCPassword] = useState(false);
  const [imageName, setImageName] = useState("");

  const [errors, setErrors] = useState({});
  const registerUser = useSelector((state) => state?.userReducer?.data);

  const handleDivClick = () => {
    inputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setImageName(file?.name);
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => {
        setProfileImage(reader?.result);
      };

      reader.onerror = (error) => {
        console.error("Error reading file:", error);
      };
    }
  };

  const validateForm = () => {
    const errors = {};

    const name = nameRef.current.value.trim();
    if (!name) {
      errors.name = "Name is required";
    } else if (name.length < 3) {
      errors.name = "Name must be at least 3 characters long";
    }

    const email = emailRef.current.value.trim();
    if (!email) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      errors.email = "Email address is invalid";
    }

    const password = passwordRef.current.value.trim();
    if (!password) {
      errors.password = "Password is required";
    } else if (password.length < 6) {
      errors.password = "Password must be at least 6 characters long";
    }

    const phone = phoneRef.current.value.trim();
    if (!phone) {
      errors.phone = "Phone number is required";
    } else if (phone.length < 8 || phone.length > 15) {
      errors.phone = "Phone number must be between 8 and 15 digits long";
    } else if (!/^\d+$/.test(phone)) {
      errors.phone = "Phone number must contain only digits";
    }

    if (!type) {
      errors.accountType = "Account type is required";
    }

    if (!profileImage) {
      errors.profileImage = "Profile Image is required";
    }

    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    setErrors({});

    const formData = {
      profile: profileImage,
      name: nameRef.current.value,
      email: emailRef.current.value,
      password: passwordRef.current.value,
      password_confirmation: passwordCRef.current.value,
      role: type,
      phone: phoneRef?.current?.value,
    };

    try {
      const res = await axios.post(`${BASE_URL}register`, formData);

      if (res?.data?.status === 200) {
        notify(false, "Successful Registered");
        navigate("/login");
      }
    } catch (error) {
      if (error.response) {
        notify(true, `Error: ${error.response.data.message}`);
      } else if (error.request) {
        notify("No response received from server");
      } else {
        console.log("Error message:", error.message);
        notify(true, error.message);
      }
    }
  };

  const togglePasswordVisibility = () => {
    if (showPassword) {
      setShowPassword(!showPassword);
    } else if (showCPassword) {
      setShowCPassword(!showCPassword);
    }
  };
  const options = ["athlete", "coach"];

  const handleSelect = (option) => {
    setType(option);
  };

  return (
    <>
      <Header />
      <div className="main-container centered">
        <div className="register-content">
          <form
            onSubmit={handleSubmit}
            id="registrazione"
            name="registrazione"
            method="post"
            encType="multipart/form-data"
          >
            <div className="headline">Registra un nuovo account</div>

            <div className="social-login-buttons">
              <a href="#" className="button-default-no-icon">
                continua con google
              </a>
              <a href="#" className="button-default-no-icon">
                continua con facebook
              </a>
            </div>

            <div className="oppure">oppure</div>

            <div className="user-info">
              <div className="text-input">
                <div className="title">Nome</div>
                <div className="input input-control">
                  <input
                    type="text"
                    ref={nameRef}
                    id="name"
                    placeholder="name"
                  />
                  {errors.name && <div className="error">{errors.name}</div>}
                </div>
              </div>

              <div className="text-input" style={{ marginTop: "20px" }}>
                <div className="title">Indirizzo email</div>
                <div className="input input-control">
                  <input
                    type="text"
                    ref={emailRef}
                    id="userEmail"
                    placeholder="something@domain.com"
                  />
                  {errors.email && <div className="error">{errors.email}</div>}
                </div>
              </div>

              <div
                className="text-input margin-top"
                style={{ marginTop: "20px" }}
              >
                <div className="title">Password</div>
                <div
                  className="input input-control"
                  style={{ position: "relative" }}
                >
                  <input
                    type={showPassword ? "text" : "password"}
                    ref={passwordRef}
                    id="userPassword"
                    placeholder="MyP@assword"
                  />
                  <div style={{ position: "absolute", top: 10, right: 10 }}>
                    {showPassword ? (
                      <IoMdEyeOff
                        style={{ cursor: "pointer" }}
                        onClick={() => setShowPassword(false)}
                      />
                    ) : (
                      <IoMdEye
                        style={{ cursor: "pointer" }}
                        onClick={() => setShowPassword(true)}
                      />
                    )}
                  </div>

                  {errors.password && (
                    <div className="error">{errors.password}</div>
                  )}
                </div>
              </div>

              <div
                className="text-input margin-top"
                style={{ marginTop: "20px" }}
              >
                <div className="title">Confirm Password</div>
                <div
                  className="input input-control"
                  style={{ position: "relative" }}
                >
                  <input
                    type={showCPassword ? "text" : "password"}
                    ref={passwordCRef}
                    id="userPassword"
                    placeholder="MyPassword"
                  />
                  <div style={{ position: "absolute", top: 10, right: 10 }}>
                    {showCPassword ? (
                      <IoMdEyeOff
                        style={{ cursor: "pointer" }}
                        onClick={() => setShowCPassword(false)}
                      />
                    ) : (
                      <IoMdEye
                        style={{ cursor: "pointer" }}
                        onClick={() => setShowCPassword(true)}
                      />
                    )}
                  </div>
                  {errors.confirmpassword && (
                    <div className="error">{errors.password}</div>
                  )}
                </div>
              </div>

              {/* <div
                className="input-control show-password"
                style={{ marginBottom: "1em", marginTop: "20px" }}
              >
                <input
                  type="checkbox"
                  className="round-checkbox"
                  onChange={togglePasswordVisibility}
                />
                Show password
              </div> */}

              <div className="text-input" style={{ marginTop: "20px" }}>
                <div className="title">Numero di telefono</div>
                <div className="input input-control">
                  <input
                    type="number"
                    ref={phoneRef}
                    id="userPhone"
                    placeholder="+39 02 1234567"
                  />
                  {errors.phone && <div className="error">{errors.phone}</div>}
                </div>
              </div>

              <div className="text-input" style={{ marginTop: "20px" }}>
                <div className="title">Tipo di account</div>
                {/* <div className="custom-select-container">
                  <select
                    ref={accountTypeRef}
                    id="accountType"
                    className="custom-select"
                  >
                    <option value="">Select account type</option>
                    <option value="athlete">Athlete</option>
                    <option value="coach">Coach</option>
                  </select>
                  {errors.accountType && (
                    <div className="error">{errors.accountType}</div>
                  )}
                </div> */}
                <div className="custom-select-container">
                  <Dropdown
                    options={options}
                    onSelect={handleSelect}
                    btn="Select account type"
                    bgList="true"
                    className="custom-select-container-drop "
                    content={type}
                  />
                </div>

                <div style={{ width: "100%" }}>
                  <div className="title">Foto del profilo</div>
                  {profileImage ? (
                    <div
                      style={{
                        width: "100%",
                        height: "40px",
                        border: "1px dashed grey",
                        // borderRadius: "100%",
                        background: "#A0FC6E",
                        marginBottom: "20px",
                        textAlign: "center",
                        paddingTop: 5,
                        cursor: "pointer",
                        color: "#000",
                      }}
                    >
                      {imageName}
                    </div>
                  ) : (
                    <div
                      onClick={handleDivClick}
                      style={{
                        width: "100%",
                        height: "40px",
                        border: !profileImage && "1px dashed grey",
                        // borderRadius: "100%",
                        marginBottom: "20px",
                        textAlign: "center",
                        paddingTop: 5,
                        cursor: "pointer",
                      }}
                    >
                      Upload Image
                    </div>
                  )}

                  <input
                    ref={inputRef}
                    onChange={handleFileChange}
                    type="file"
                    accept="image/*"
                    style={{ display: "none" }}
                  />
                </div>
              </div>
            </div>

            <div className="login-register">
              <button
                type="submit"
                id="registerBtn"
                className="button-default-no-icon"
              >
                crea un nuovo account
              </button>
              <Link to="/login" className="button-default-no-icon outline">
                hai già un account? login
              </Link>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default RegisterForm;
