import React, { useState, useEffect } from "react";
import "./onboardingCss.css";
import "../../components/Users/Measurements.css";
import { useNavigate } from "react-router-dom";
import { notify } from "../../components/util/notify";
import axios from "axios";
import { BASE_URL } from "../../assets/constants";

function FourStep({}) {
  const navigate = useNavigate();
  const adminData = JSON.parse(localStorage.getItem("adminData"));
  const token = adminData?.token;
  const [countdown, setCountdown] = useState(3);
  const [showVideo, setShowVideo] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [atheleteData, setAtheleteData] = useState({
    objective: "",
    previous_diets_and_nutrition: "",
    breakfast_choices: "",
    lunch_choices: "",
    dinner_choices: "",
    snack_choices: "",
    breakfast_importance: "",
    hunger_level: "",
    meal_planning: "",
    dont_eat_i_eat_everything: false,
    dont_eat_dairy_products_and_derivatives: false,
    dont_eat_meat: false,
    dont_eat_fish_and_shellfish: false,
    dont_eat_legumes: false,
    dont_eat_cereals: false,
    dont_eat_dried_fruit: false,
    dont_eat_other: false,
    supplements_nobody: false,
    supplements_protein_powder: false,
    supplements_creatine: false,
    supplements_bcaa_eaa: false,
    supplements_vitamins: false,
    supplements_omega_3: false,
    supplements_wing: false,
    supplements_electrolytes: false,
    supplements_glutamine: false,
    supplements_other: false,
  });

  const handleBackClick = async () => {
    setIsLoading(true);
    const errors = [];

    // Validate text fields
    if (!atheleteData?.objective.trim()) {
      errors.push("Objective is required.");
    }
    if (!atheleteData?.previous_diets_and_nutrition.trim()) {
      errors.push("Previous diets and nutrition field is required.");
    }
    if (!atheleteData?.breakfast_choices.trim()) {
      errors.push("Breakfast choices field is required.");
    }
    if (!atheleteData?.lunch_choices.trim()) {
      errors.push("Lunch choices field is required.");
    }
    if (!atheleteData?.dinner_choices.trim()) {
      errors.push("Dinner choices field is required.");
    }
    if (!atheleteData?.snack_choices.trim()) {
      errors.push("Snack choices field is required.");
    }
    if (!atheleteData?.breakfast_importance) {
      errors.push("Breakfast importance is required.");
    }
    if (!atheleteData?.hunger_level) {
      errors.push("Hunger level is required.");
    }
    if (!atheleteData?.meal_planning) {
      errors.push("Meal planning is required.");
    }

    const dontEatCheckboxes = [
      atheleteData.dont_eat_i_eat_everything,
      atheleteData.dont_eat_dairy_products_and_derivatives,
      atheleteData.dont_eat_meat,
      atheleteData.dont_eat_fish_and_shellfish,
      atheleteData.dont_eat_legumes,
      atheleteData.dont_eat_cereals,
      atheleteData.dont_eat_dried_fruit,
      atheleteData.dont_eat_other,
    ];

    if (!dontEatCheckboxes.some(Boolean)) {
      errors.push("Please select at least one option in 'Cosa non mangi'.");
    }

    const supplementsCheckboxes = [
      atheleteData.supplements_nobody,
      atheleteData.supplements_protein_powder,
      atheleteData.supplements_creatine,
      atheleteData.supplements_bcaa_eaa,
      atheleteData.supplements_vitamins,
      atheleteData.supplements_omega_3,
      atheleteData.supplements_wing,
      atheleteData.supplements_electrolytes,
      atheleteData.supplements_glutamine,
      atheleteData.supplements_other,
    ];

    if (!supplementsCheckboxes.some(Boolean)) {
      errors.push(
        "Please select at least one option in 'Fai uso di integratori?'."
      );
    }

    if (errors.length > 0) {
      notify(true, errors.join(" "));
      setIsLoading(false);
      return;
    }
    try {
      let response = await axios.post(
        `${BASE_URL}athlete/step-3`,
        atheleteData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response?.data?.status) {
        let step = await axios.get(`${BASE_URL}athlete/step-form`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        let steps = step?.data?.forms_completed;
        localStorage.setItem("onboardingProgress", JSON.stringify(steps));
        notify(false, "Passaggio 3 completato");
        navigate("/onboardingstep5");
      }
    } catch (error) {
      const errorMessages = error?.response?.data?.message;

      if (errorMessages) {
        const combinedErrors = Object.values(errorMessages).flat().join("\n");

        notify(true, combinedErrors);
      } else {
        notify(true, "Something went wrong!");
      }
    }
    setIsLoading(false);
  };
  const handleChange = (event) => {
    const { value, name, checked, type } = event?.target;
    setAtheleteData({
      ...atheleteData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const spinnerStyle = {
    border: "3px solid #f3f3f3",
    borderTop: "3px solid #a0fc6e",
    borderRadius: "50%",
    width: "30px",
    height: "30px",
    animation: "spin 1s linear infinite",
    margin: "0 auto",
  };

  const containerStyle = {
    display: "flex",
    justifyContent: "center",
    marginTop: "10px",
  };

  return (
    <div className="mainContainer">
      <div>
        <div
          className="hunger-level-container1"
          style={{
            backgroundColor: "#080808",
            marginTop: 20,
            padding: 20,
            width: "25em",
          }}
        >
          <div className="title" style={{ fontSize: "2em" }}>
            nutrizione
          </div>

          <div className="sprateColorsStep">
            <div className="colorBar" style={{ height: 8 }}></div>
            <div
              className="colorBar"
              style={{ height: 6, marginLeft: 10 }}
            ></div>
            <div
              className="colorBar"
              style={{ height: 6, marginLeft: 10 }}
            ></div>
            <div
              className="colorBar"
              style={{ height: 6, marginLeft: 10, backgroundColor: "#fff" }}
            ></div>
            <div
              className="colorBar"
              style={{ height: 6, marginLeft: 10, backgroundColor: "#fff" }}
            ></div>
          </div>

          <div className="range-labels" style={{ marginTop: -15 }}>
            <span className="label-left" style={{ color: "#7A7A7A" }}>
              Step 3 di 5
            </span>
          </div>

          <div className="sleep-hours-container">
            <h2 className="sleep-title">Obiettivo</h2>
            <div className="sleep-option">
              <input
                type="radio"
                id="option1"
                name="objective"
                value={1}
                onChange={handleChange}
              />
              <label for="option1">
                <span className="custom-radio"></span>
                Dimagrire/benessere
              </label>
            </div>
            <div className="sleep-option">
              <input
                type="radio"
                id="option2"
                name="objective"
                value={2}
                onChange={handleChange}
              />
              <label for="option2">
                <span className="custom-radio"></span>
                Massa muscolare/Performance
              </label>
            </div>
          </div>

          {[
            {
              name: "Diete già seguite e alimentazione",
              value: "previous_diets_and_nutrition",
            },
            { name: "Cosa mangi a colazione", value: "breakfast_choices" },
            { name: "Cosa mangi a  pranzo", value: "lunch_choices" },
            { name: "Cosa mangi a  cena", value: "dinner_choices" },
            {
              name: "Cosa mangi come spuntino",
              value: "snack_choices",
            },
          ].map((i, ind) => {
            return (
              <div>
                <div className="text-input">
                  <div className="title" style={{ marginTop: 15 }}>
                    {i.name}
                  </div>
                  <div className="input input-control">
                    <input
                      type="text"
                      onChange={handleChange}
                      name={i.value}
                      id={i.value}
                      placeholder={`Entra ${i?.name} ...`}
                    />
                  </div>
                </div>
              </div>
            );
          })}

          <div className="sleep-hours-container" style={{ marginTop: 20 }}>
            <h2 className="sleep-title">
              Quanto è importante per te la colazione?
            </h2>

            <div className="sleep-option">
              <input
                type="radio"
                onChange={handleChange}
                id="option5"
                name="breakfast_importance"
                value={1}
              />
              <label for="option5">
                <span className="custom-radio"></span>
                Non posso farne a meno
              </label>
            </div>

            <div className="sleep-option">
              <input
                type="radio"
                onChange={handleChange}
                id="option6"
                value={2}
                name="breakfast_importance"
              />
              <label for="option6">
                <span className="custom-radio"></span>
                Vorrei saltarla ma non riesco
              </label>
            </div>
            <div className="sleep-option">
              <input
                type="radio"
                onChange={handleChange}
                id="option3"
                value={3}
                name="breakfast_importance"
              />
              <label for="option3">
                <span className="custom-radio"></span>
                Mi piace ma posso rinunciare
              </label>
            </div>
            <div className="sleep-option">
              <input
                type="radio"
                onChange={handleChange}
                value={4}
                id="option4"
                name="breakfast_importance"
              />
              <label for="option4">
                <span className="custom-radio"></span>
                Non mi piace
              </label>
            </div>
          </div>

          {/* ................................................. */}

          <div className="sleep-hours-container" style={{ marginTop: 20 }}>
            <h2 className="sleep-title" style={{ fontSize: "2em" }}>
              Livello di fame
            </h2>
            <div className="sleep-option">
              <input
                type="radio"
                id="option7"
                name="hunger_level"
                onChange={handleChange}
                value={1}
              />
              <label for="option7">
                <span className="custom-radio"></span>
                Cronica, non riesco a smettere
              </label>
            </div>
            <div className="sleep-option">
              <input
                type="radio"
                id="option8"
                name="hunger_level"
                onChange={handleChange}
                value={2}
              />
              <label for="option8">
                <span className="custom-radio"></span>
                <div>Molti giorni ho fame, altri per nulla</div>
              </label>
            </div>
            <div className="sleep-option">
              <input
                type="radio"
                id="option9"
                name="hunger_level"
                onChange={handleChange}
                value={3}
              />
              <label for="option9">
                <span className="custom-radio"></span>
                Ho fame solo prima dei pasti
              </label>
            </div>
            <div className="sleep-option">
              <input
                type="radio"
                id="option10"
                name="hunger_level"
                onChange={handleChange}
                value={4}
              />
              <label for="option10">
                <span className="custom-radio"></span>
                <div>Pochi giorni ho fame, altri per nulla</div>
              </label>
            </div>

            <div className="sleep-option">
              <input
                type="radio"
                id="option11"
                name="hunger_level"
                onChange={handleChange}
                value={5}
              />
              <label for="option11">
                <span className="custom-radio"></span>
                Non ho mai fame
              </label>
            </div>
          </div>

          {/* ..................................................................... */}

          <div className="sleep-hours-container" style={{ marginTop: 20 }}>
            <h2 className="sleep-title" style={{ fontSize: "2em" }}>
              Organizzazione dei pasti
            </h2>
            <div className="sleep-option">
              <input
                type="radio"
                id="option12"
                name="meal_planning"
                value={1}
                onChange={handleChange}
              />
              <label for="option12">
                <span className="custom-radio"></span>
                Preparo tutti i pasti
              </label>
            </div>
            <div className="sleep-option">
              <input
                type="radio"
                id="option13"
                name="meal_planning"
                value={2}
                onChange={handleChange}
              />
              <label for="option13">
                <span className="custom-radio"></span>
                Preparo solo pasti veloci e semplici
              </label>
            </div>
            <div className="sleep-option">
              <input
                type="radio"
                id="option14"
                name="meal_planning"
                value={3}
                onChange={handleChange}
              />
              <label for="option14">
                <span className="custom-radio"></span>
                Preparo solo la cena
              </label>
            </div>
            <div className="sleep-option">
              <input
                type="radio"
                id="option15"
                name="meal_planning"
                value={4}
                onChange={handleChange}
              />
              <label for="option15">
                <span className="custom-radio"></span>
                Qualcuno prepara i pasti per me
              </label>
            </div>

            <div className="sleep-option">
              <input
                type="radio"
                id="option16"
                name="meal_planning"
                value={5}
                onChange={handleChange}
              />
              <label for="option16">
                <span className="custom-radio"></span>
                Altro
              </label>
            </div>
          </div>

          {/* .................................................................................. */}

          <div className="sleep-hours-container" style={{ marginTop: 20 }}>
            <h2 className="sleep-title" style={{ fontSize: "2em" }}>
              Cosa non mangi
            </h2>
            {[
              { name: "Mangio tutto", value: "dont_eat_i_eat_everything" },
              {
                name: "Latticini e derivati",
                value: "dont_eat_dairy_products_and_derivatives",
              },
              { name: "Carne", value: "dont_eat_meat" },
              {
                name: "Pesce e molluschi",
                value: "dont_eat_fish_and_shellfish",
              },
              { name: "Legumi", value: "dont_eat_legumes" },
              { name: "Cereali", value: "dont_eat_cereals" },
              { name: "Frutta secca", value: "dont_eat_dried_fruit" },
              { name: "Altro", value: "dont_eat_other" },
            ].map((i, ind) => {
              return (
                <div className="sleep-option" key={ind}>
                  <input
                    type="checkbox"
                    id={`eatoption${ind}`}
                    name={i.value}
                    // checked
                    checked={!!atheleteData[i.value]} // Control the checked state based on the selected options
                    onChange={handleChange} // Handle change event
                    style={{
                      borderRadius: 5,
                      border: "1px solid lightgray",
                    }}
                  />
                  <label htmlFor={`eatoption${ind}`}>
                    <span className="custom-checkbox"></span>
                    {i.name}
                  </label>
                </div>
              );
            })}
          </div>

          {/* ........................................................................... */}

          {/* .............................................. */}

          <div className="sleep-hours-container" style={{ marginTop: 20 }}>
            <h2 className="sleep-title" style={{ fontSize: "2em" }}>
              Fai uso di integratori?
            </h2>
            {[
              { name: "Nessuno", value: "supplements_nobody" },
              {
                name: "Proteine in polvere",
                value: "supplements_protein_powder",
              },
              { name: "Creatina", value: "supplements_creatine" },
              { name: "BCAA / EAA", value: "supplements_bcaa_eaa" },
              { name: "Vitamine", value: "supplements_vitamins" },
              { name: "Omega 3", value: "supplements_omega_3" },
              { name: "ALA", value: "supplements_wing" },
              { name: "Elettroliti", value: "supplements_electrolytes" },
              { name: "Glutammina", value: "supplements_glutamine" },
              { name: "ALtro", value: "supplements_other" },
            ].map((i, ind) => {
              return (
                <div className="sleep-option" key={ind}>
                  <input
                    type="checkbox"
                    id={`eatoption1${ind}`}
                    name={i.value}
                    // checked
                    checked={!!atheleteData[i.value]} // Control the checked state based on the selected options
                    onChange={handleChange} // Handle change event
                    style={{
                      borderRadius: 5,
                      border: "1px solid lightgray",
                    }}
                  />
                  <label htmlFor={`eatoption1${ind}`}>
                    <span className="custom-checkbox"></span>
                    {i.name}
                  </label>
                </div>
              );
            })}
          </div>

          {/* ............................. */}
          {isLoading ? (
            <div style={containerStyle}>
              <div style={spinnerStyle}></div>
              <style>
                {`
              @keyframes spin {
                0% { transform: rotate(0deg); }
                100% { transform: rotate(360deg); }
              }
            `}
              </style>
            </div>
          ) : (
            <button
              type="button"
              className="button-default-no-icon"
              onClick={() => {
                handleBackClick();
              }}
              style={{ width: "100%", marginTop: 20 }}
            >
              continua
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default FourStep;
