import React, { useState } from "react";
import "../css/index.css";
import atletiIcon from "../assets/images/home.png";
import calendarioIcon from "../assets/img/icons/calendar.svg";
import noteIcon from "../assets/img/icons/clipboard.svg";
import profiloIcon from "../assets/images/profile.png";
import { NavLink, useNavigate } from "react-router-dom";
import Loader from "./Users/globalLoader";

export default function CoachSidebar({ activeOption }) {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleNavigation = (option) => {
    setLoading(true);
    setTimeout(() => {
      switch (option) {
        case "Atleti":
          navigate("/coach/home");
          break;
        case "Calendario":
          navigate("/coach/calendario");
          break;
        case "Note":
          navigate("/coach/note");
          break;
        case "Profilo":
          navigate("/coach/profilo");
          break;
        default:
          navigate("/coach/home");
      }
      setLoading(false);
    }, 500);
  };

  return (
    <div className="sidebar">
      {loading && <Loader />}
      <div
        className={`sidebar-item ${activeOption === "Atleti" ? "active" : ""}`}
        onClick={() => handleNavigation("Atleti")}
      >
        <img className="sidebarIcons" src={atletiIcon} alt="Atleti" />
        <span>Atleti</span>
      </div>
      <div
        className={`sidebar-item ${
          activeOption === "Calendario" ? "active" : ""
        }`}
        onClick={() => handleNavigation("Calendario")}
      >
        <img className="sidebarIcons" src={calendarioIcon} alt="Calendario" />
        <span>Calendario</span>
      </div>
      <div
        className={`sidebar-item ${activeOption === "Note" ? "active" : ""}`}
        onClick={() => handleNavigation("Note")}
      >
        <img className="sidebarIcons" src={noteIcon} alt="Note" />
        <span>Note</span>
      </div>
      <div
        className={`sidebar-item ${activeOption === "Profilo" ? "active" : ""}`}
        onClick={() => handleNavigation("Profilo")}
      >
        <img className="sidebarIcons" src={profiloIcon} alt="Profilo" />
        <span>Profilo</span>
      </div>
    </div>
  );
}
