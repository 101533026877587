import React, { useEffect, useState } from "react";
import "../Admin/css/Admin.css";
import DashboardCard from "./DashboardCard";
import Accordion from "../Reuseable/Accordian";
import BarChart from "../Reuseable/Chart";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "../../assets/constants";

const AdminCoachStepTwo = ({ onClick }) => {
  const [activeBtn, setActiveBtn] = useState(null);
  const [coachData, setCoachData] = useState(null);

  const adminData = JSON.parse(localStorage.getItem("adminData"));
  const token = adminData?.data?.token;
  const { state: coach } = useLocation();

  const handleButtonClick = (btnId) => {
    setActiveBtn(activeBtn === btnId ? null : btnId);
  };
  const getCoachDetails = async () => {
    try {
      const response = await axios.post(
        `${BASE_URL}admin/coach/${coach?.id}/detail`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setCoachData(response?.data);
    } catch (error) {
      console.error("Error fetching athlete notes:", error);
    } finally {
    }
  };

  useEffect(() => {
    getCoachDetails();
  }, []);

  return (
    <div>
      <div className="admin-coach-step-two-container">
        <Link to="/admin/coach">
          <div>
            <svg
              width="25"
              height="24"
              viewBox="0 0 25 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.3064 2.46888C17.3762 2.53854 17.4316 2.62131 17.4695 2.71243C17.5073 2.80354 17.5267 2.90122 17.5267 2.99988C17.5267 3.09853 17.5073 3.19621 17.4695 3.28733C17.4316 3.37844 17.3762 3.46121 17.3064 3.53088L8.83589 11.9999L17.3064 20.4689C17.4472 20.6097 17.5263 20.8007 17.5263 20.9999C17.5263 21.199 17.4472 21.39 17.3064 21.5309C17.1656 21.6717 16.9746 21.7508 16.7754 21.7508C16.5762 21.7508 16.3852 21.6717 16.2444 21.5309L7.24439 12.5309C7.17454 12.4612 7.11913 12.3784 7.08132 12.2873C7.04351 12.1962 7.02405 12.0985 7.02405 11.9999C7.02405 11.9012 7.04351 11.8035 7.08132 11.7124C7.11913 11.6213 7.17454 11.5385 7.24439 11.4689L16.2444 2.46888C16.3141 2.39903 16.3968 2.34362 16.4879 2.30581C16.5791 2.268 16.6767 2.24854 16.7754 2.24854C16.874 2.24854 16.9717 2.268 17.0628 2.30581C17.154 2.34362 17.2367 2.39903 17.3064 2.46888Z"
                fill="#A0FC6E"
              />
            </svg>
          </div>
        </Link>

        <div className="avatar-container">
          <img
            src="https://png.pngtree.com/png-vector/20190710/ourmid/pngtree-user-vector-avatar-png-image_1541962.jpg"
            alt="image description"
          />
        </div>
        <h3 className="name">{coach?.name}</h3>
      </div>
      <div className="admin-coach-step-two-container-flex">
        <div className="step-1">
          <div className="admin-coach-step-card-flex">
            <div className="admin-coach-step-card">
              <div className="admin-coach-step-card-flex">
                <div>
                  {" "}
                  <h1 className="admin-coach-step-card-title">
                    {coachData?.coach_performance?.task_complete || "--"}
                  </h1>
                </div>
                <div>{/* <span>+1,3%</span> */}</div>
              </div>

              <div className="step-card-sub-heading">Completamneto Task</div>
            </div>
            <div className="admin-coach-step-card">
              <div className="admin-coach-step-card-flex">
                <div>
                  {" "}
                  <h1 className="admin-coach-step-card-title">
                    {coachData?.coach_performance?.average_result || "--"}
                  </h1>
                </div>
                <div>{/* <span>+1,3%</span> */}</div>
              </div>

              <div className="step-card-sub-heading">Resulati medi</div>
            </div>
          </div>

          <div className="dashboard-container">
            <DashboardCard
              value={coachData?.coach?.athlete_followers || "0"}
              label="clienti totali"
            />
            <DashboardCard
              value={coachData?.coach?.athlete_losts || "0"}
              label="rinnovi"
            />
            <DashboardCard
              value={coachData?.coach?.renewal_rate || "0"}
              label="tasso di rinnovo"
              // ratio="+1.3%"
            />
          </div>
          <div className="rating">
            <div className="rating-flex">
              <div className="rating-card">
                <div className="rating-card-flex">
                  <div className="green-color"></div>
                  <div className="rating-card-flex-para">Nuovi clienti</div>
                </div>
                <div className="rating-card-flex">
                  <div className="red-color"></div>
                  <div className="rating-card-flex-para">clienti persi</div>
                </div>
              </div>
              <div className="rating-card">
                <button
                  type="button"
                  className={`custom-button ${
                    activeBtn === "btn1" ? "activeBtn" : ""
                  }`}
                  onClick={() => handleButtonClick("btn1")}
                >
                  <svg
                    width="20"
                    height="14"
                    viewBox="0 0 22 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M21.7754 6.99997C21.7754 6.80106 21.6964 6.61029 21.5557 6.46964C21.4151 6.32899 21.2243 6.24997 21.0254 6.24997H3.33589L8.05639 1.53097C8.12612 1.46124 8.18143 1.37846 8.21917 1.28735C8.25691 1.19624 8.27634 1.09859 8.27634 0.999971C8.27634 0.901355 8.25691 0.803705 8.21917 0.712596C8.18143 0.621487 8.12612 0.538703 8.05639 0.468971C7.98666 0.399239 7.90387 0.343925 7.81276 0.306186C7.72165 0.268447 7.624 0.249023 7.52539 0.249023C7.42677 0.249023 7.32912 0.268447 7.23801 0.306186C7.1469 0.343925 7.06412 0.399239 6.99439 0.468971L0.994389 6.46897C0.924544 6.53864 0.869129 6.6214 0.83132 6.71252C0.79351 6.80364 0.774048 6.90132 0.774048 6.99997C0.774048 7.09862 0.79351 7.1963 0.83132 7.28742C0.869129 7.37854 0.924544 7.4613 0.994389 7.53097L6.99439 13.531C7.06412 13.6007 7.1469 13.656 7.23801 13.6938C7.32912 13.7315 7.42677 13.7509 7.52539 13.7509C7.624 13.7509 7.72165 13.7315 7.81276 13.6938C7.90387 13.656 7.98666 13.6007 8.05639 13.531C8.12612 13.4612 8.18143 13.3785 8.21917 13.2873C8.25691 13.1962 8.27634 13.0986 8.27634 13C8.27634 12.9014 8.25691 12.8037 8.21917 12.7126C8.18143 12.6215 8.12612 12.5387 8.05639 12.469L3.33589 7.74997H21.0254C21.2243 7.74997 21.4151 7.67095 21.5557 7.5303C21.6964 7.38965 21.7754 7.19888 21.7754 6.99997Z"
                      fill="#F6F6F6"
                    />
                  </svg>
                </button>
                <span>2024</span>
                <button
                  type="button"
                  className={`custom-button ${
                    activeBtn === "btn2" ? "activeBtn" : ""
                  }`}
                  onClick={() => handleButtonClick("btn2")}
                >
                  <svg
                    width="22"
                    height="14"
                    viewBox="0 0 22 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M0.773928 7.00003C0.773928 7.19894 0.852945 7.38971 0.993597 7.53036C1.13425 7.67101 1.32502 7.75003 1.52393 7.75003L19.2134 7.75003L14.4929 12.469C14.4232 12.5388 14.3679 12.6215 14.3301 12.7127C14.2924 12.8038 14.273 12.9014 14.273 13C14.273 13.0986 14.2924 13.1963 14.3301 13.2874C14.3679 13.3785 14.4232 13.4613 14.4929 13.531C14.5627 13.6008 14.6454 13.6561 14.7366 13.6938C14.8277 13.7316 14.9253 13.751 15.0239 13.751C15.1225 13.751 15.2202 13.7316 15.3113 13.6938C15.4024 13.6561 15.4852 13.6008 15.5549 13.531L21.5549 7.53103C21.6248 7.46136 21.6802 7.3786 21.718 7.28748C21.7558 7.19636 21.7753 7.09868 21.7753 7.00003C21.7753 6.90138 21.7558 6.8037 21.718 6.71258C21.6802 6.62146 21.6248 6.5387 21.5549 6.46903L15.5549 0.469029C15.4852 0.399298 15.4024 0.343983 15.3113 0.306244C15.2202 0.268506 15.1225 0.249083 15.0239 0.249083C14.9253 0.249083 14.8277 0.268506 14.7366 0.306244C14.6454 0.343983 14.5627 0.399298 14.4929 0.469029C14.4232 0.538761 14.3679 0.621545 14.3301 0.712654C14.2924 0.803763 14.273 0.901414 14.273 1.00003C14.273 1.09865 14.2924 1.1963 14.3301 1.28741C14.3679 1.37851 14.4232 1.4613 14.4929 1.53103L19.2134 6.25003L1.52393 6.25003C1.32502 6.25003 1.13425 6.32905 0.993597 6.4697C0.852945 6.61035 0.773928 6.80112 0.773928 7.00003Z"
                      fill="#F6F6F6"
                    />
                  </svg>
                </button>
              </div>
            </div>
            <BarChart chartData={coachData?.coach_chart} />
          </div>
        </div>

        <div className="step-2">
          <div className="hours">
            <div className="hours-card ">
              <div className="admin-coach-step-card-flex ">
                <div>
                  {" "}
                  <h1 className="admin-coach-step-card-title">
                    {" "}
                    {coachData?.working_time || "00:00 H"}
                  </h1>
                </div>
                <div>{/* <span>+1,3%</span> */}</div>
              </div>
              <p className="step-card-sub-heading">tempo di lavoro</p>
            </div>
            <div className="line-horizontal"></div>
            <div className="hours-card">
              <div className="admin-coach-step-card-flex">
                <div>
                  <h1 className="admin-coach-step-card-title">
                    {coachData?.time_with_client || "00:00 H"}
                  </h1>
                </div>
                <div>{/* <span>+1,3%</span> */}</div>
              </div>

              <p className="step-card-sub-heading">tempo di lavoro</p>
            </div>
          </div>
          <div className="">
            <Accordion
              icon={false}
              data={coachData?.coach_athlete?.map((value) => ({
                title: value,
                content: value,
              }))}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminCoachStepTwo;
