export const FETCH_DATA_REQUEST = "FETCH_DATA_REQUEST";
export const FETCH_FEEDBACK = "FETCH_FEEDBACK";
export const GET_MEAL_PLAN = "GET_MEAL_PLAN";
export const GET_FOOD_LIST = "GET_FOOD_LIST";
export const GET_DAILY_TRAINING = "GET_DAILY_TRAINING";
export const GET_TRAINING_LISTING = "GET_TRAINING_LISTING";
export const GET_FOOD_LIST_SUCCESS = "GET_FOOD_LIST_SUCCESS";
export const SUCCESS = "SUCCESS";
export const FAILURE = "FAILURE";
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const SET_FEEDBACK = "SET_FEEDBACK";
export const REGISTRATION = "REGISTRATION";
export const SET_TOTAL_VALUES = "SET_TOTAL_VALUES";
export const SUCCESS_RECIPE = "SUCCESS_RECIPE";
export const CONCLUDE_EXERCISE = "CONCLUDE_EXERCISE";
export const SET_TRAINING_STATUS = "SET_TRAINING_STATUS";
