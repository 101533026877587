import React from "react";

// Components
import Dashboard from "../../components/Users/Dashboard";

const Users = () => {
  return (
    <>
      <Dashboard />
    </>
  );
};

export default Users;
