import React, { useEffect, useState } from "react";
import "../css/modal.css";

const TimerModal = ({ isOpen, initialTime, setIsOpen }) => {
  const timeStringToSeconds = (timeString) => {
    const [hours, minutes, seconds] = timeString.split(":").map(Number);
    return hours * 3600 + minutes * 60 + seconds;
  };

  const totalSeconds = timeStringToSeconds(initialTime);
  const [time, setTime] = useState(totalSeconds);
  const [isCounting, setIsCounting] = useState(false);
  useEffect(() => {
    if (isOpen) {
      setTime(totalSeconds);
      setIsCounting(false);
    }
  }, [isOpen, totalSeconds]);

  useEffect(() => {
    if (isOpen && !isCounting && time === totalSeconds) {
      setIsCounting(true);
    }
  }, [time, isOpen, isCounting, totalSeconds]);

  useEffect(() => {
    if (isCounting && time > 0) {
      const timerId = setInterval(() => {
        setTime((prevTime) => prevTime - 1);
      }, 1000);

      return () => clearInterval(timerId);
    } else if (time === 0 && isCounting) {
      setTimeout(() => {
        setIsOpen(false);
        setIsCounting(false);
      }, 1000);
    }
  }, [isCounting, time, setIsOpen]);

  const formatTime = (seconds) => {
    const minutes = String(Math.floor(seconds / 60)).padStart(2, "0");
    const secs = String(seconds % 60).padStart(2, "0");
    return `${minutes}:${secs}`;
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="timer" id="scheda-allenamento">
        <div className="title">
          sei pronto?
          <br />
          iniziamo tra
        </div>
        <div className="clock">{formatTime(time)}</div>
      </div>
    </div>
  );
};

export default TimerModal;
