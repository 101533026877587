import React from "react";
import AdminAtheletStepFour from "../../components/Admin/AdminAtheletStepFour";

const AdminActivity = () => {
  return (
    <div>
      <AdminAtheletStepFour />
    </div>
  );
};

export default AdminActivity;
