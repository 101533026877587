import React from "react";
import "../css/modal.css";

const CalorieEditModal = ({
  isOpen,
  onClose,
  editableItem,
  calories,
  handleIncrement,
  handleDecrement,
  handleSaveCalories,
}) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h5>Modifica Quantity of: {editableItem.name}</h5>
        <div
          className="calorie-controls"
          style={{ display: "flex", flexDirection: "row", alignSelf: "center" }}
        >
          <button className="modal-btn" onClick={handleDecrement}>
            -
          </button>
          <div className="form-div">
            <input
              type="number"
              value={calories}
              className="form-input"
              readOnly
              style={{
                textAlign: "center",
              }}
            />
          </div>
          <button
            className="modal-btn"
            onClick={handleIncrement}
            style={{ marginLeft: "20px" }}
          >
            +
          </button>
        </div>
        <button
          type="submit"
          className="modal-btn"
          onClick={handleSaveCalories}
          style={{ width: "100%", marginTop: "20px" }}
        >
          Salva
        </button>
        <div onClick={onClose} className="modal-close">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="15"
            height="15"
            viewBox="0 0 16 16"
          >
            <path
              fillRule="evenodd"
              fill="#fff"
              d="M0 14.545L1.455 16 8 9.455 14.545 16 16 14.545 9.455 8 16 1.455 14.545 0 8 6.545 1.455 0 0 1.455 6.545 8z"
            ></path>
          </svg>
        </div>
      </div>
    </div>
  );
};

export default CalorieEditModal;
