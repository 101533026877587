import React, { useState, useEffect } from "react";
import "./Recipes.css";
import { useDispatch, useSelector } from "react-redux";
import { setTotalValues } from "../../redux/actions";
import axios from "axios";
import { BASE_URL } from "../../assets/constants";
import { useLocation } from "react-router-dom";

const Recipes = ({
  nextStep,

  setIsTabDisabled,
  setSelectedItems,
  selectedItems,
  setTabSelection,
  activeTab,
  tabSelection,
}) => {
  const [foodData, setFoodData] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const dispatch = useDispatch();
  const adminData = JSON.parse(localStorage.getItem("adminData"));
  const token = adminData?.token;
  const { state: dietData } = useLocation();
  const selectedAthlete = dietData?.selectedAthlete;
  let selectedItem = dietData?.selectedItem || {};

  const handleCheckboxChange = (label, item) => {
    setSelectedItems((prevSelectedItems) => {
      const itemsInCategory = prevSelectedItems[label] || [];
      const itemExists = itemsInCategory.some(
        (selectedItem) => selectedItem.id === item.id
      );

      const updatedItemsInCategory = itemExists
        ? itemsInCategory.filter((selectedItem) => selectedItem.id !== item.id)
        : [...itemsInCategory, item];

      const updatedSelectedItems = {
        ...prevSelectedItems,
        [label]: updatedItemsInCategory,
      };

      const isAnySelected = Object.values(updatedSelectedItems).some(
        (categoryItems) => categoryItems.length > 0
      );

      setIsTabDisabled(isAnySelected);

      const allItemsSelected = foodData.every((foodItem) =>
        updatedSelectedItems[foodItem.label]?.some(
          (selectedItem) => selectedItem.id === foodItem.id
        )
      );
      setSelectAll(allItemsSelected);

      return updatedSelectedItems;
    });

    setTabSelection((prevSelection) => {
      const currentTab = ["Breakfast", "Lunch", "Dinner", "Snacks"][activeTab];
      const itemsInMeal = prevSelection[currentTab] || [];
      const itemExistsInMeal = itemsInMeal.some(
        (selectedItem) => selectedItem.id === item.id
      );

      const updatedMealItems = itemExistsInMeal
        ? itemsInMeal.filter((selectedItem) => selectedItem.id !== item.id)
        : [...itemsInMeal, item];

      return {
        ...prevSelection,
        [currentTab]: updatedMealItems,
      };
    });
  };

  const handleCheckboxChangeAll = () => {
    setSelectAll(!selectAll);

    const currentTab = ["Breakfast", "Lunch", "Dinner", "Snacks"][activeTab];

    if (!selectAll) {
      const allSelected = foodData.reduce((acc, item) => {
        const itemsInCategory = acc[item.label] || [];
        acc[item.label] = [...itemsInCategory, item];
        return acc;
      }, {});
      setSelectedItems(allSelected);
      setIsTabDisabled(true);

      setTabSelection((prevSelection) => {
        return {
          ...prevSelection,
          [currentTab]: foodData,
        };
      });
    } else {
      setSelectedItems({});
      setIsTabDisabled(false);

      setTabSelection((prevSelection) => ({
        ...prevSelection,
        [currentTab]: [],
      }));
    }
  };

  const getDietType = async () => {
    try {
      const response = await axios.get(`${BASE_URL}athlete/diet-type`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      let data = response?.data;
      const transformedData = [
        ...data.carbohydrate.map((item) => ({
          ...item,
          label: "Carbohydrate",
        })),
        ...data.protein.map((item) => ({ ...item, label: "Protein" })),
        ...data.fat.map((item) => ({ ...item, label: "Fat" })),
        ...data.fiber.map((item) => ({ ...item, label: "Fiber" })),
      ];
      setFoodData(transformedData);
    } catch (error) {}
  };

  function isEmpty(obj) {
    return Object.keys(obj).length === 0;
  }

  useEffect(() => {
    getDietType();
  }, []);

  useEffect(() => {
    if (!isEmpty(selectedItem)) {
      const currentTab = ["Breakfast", "Lunch", "Dinner", "Snacks"];
      let Carbohydrate = selectedItem[currentTab[activeTab]]?.filter(
        (item) => item?.type == "carbohydrate"
      );
      let Fat = selectedItem[currentTab[activeTab]]?.filter(
        (item) => item?.type == "fat"
      );
      let Fiber = selectedItem[currentTab[activeTab]]?.filter(
        (item) => item?.type == "fiber"
      );
      let Protein = selectedItem[currentTab[activeTab]]?.filter(
        (item) => item?.type == "protein"
      );

      setSelectedItems({ Carbohydrate, Fat, Fiber, Protein });
      setTabSelection(selectedItem);
      let checkingArr = [...Protein, ...Carbohydrate, ...Fat, ...Fiber];
      if (foodData?.length === checkingArr?.length) {
        setSelectAll(true);
      }
    }
  }, [foodData]);

  return (
    <div className="nutrition-sources-gap">
      <div className="title-inner">Scegli le fonti nutrizionali</div>
      <div>
        <label
          className="input-container"
          style={{ width: "fit-content" }}
          htmlFor={`square-checkbox-all`}
        >
          <input
            type="checkbox"
            className="square-checkbox"
            id={`square-checkbox-all`}
            checked={selectAll}
            onChange={handleCheckboxChangeAll}
          />
          <div className="font-key">All</div>
        </label>
      </div>
      <div className="inner">
        {foodData.length > 0 &&
          Object.entries(
            foodData.reduce((acc, item) => {
              if (!acc[item.label]) acc[item.label] = [];
              acc[item.label].push(item);
              return acc;
            }, {})
          ).map(([label, items]) => {
            return (
              <div className="fonte" key={label}>
                <div className="title">{label}</div>
                {items.map((item) => (
                  <label
                    className="input-container"
                    htmlFor={`square-checkbox-${item.id}`}
                    key={item.id}
                    style={{ width: "fit-content" }}
                  >
                    <input
                      type="checkbox"
                      className="square-checkbox"
                      id={`square-checkbox-${item.id}`}
                      checked={
                        selectedItems[label]?.some(
                          (selectedItem) => selectedItem.id === item.id
                        ) || false
                      }
                      onChange={() => handleCheckboxChange(label, item)}
                    />
                    <div className="font-key">{item.name}</div>
                  </label>
                ))}
              </div>
            );
          })}
      </div>
      <br />
      <button
        className={`button-default-no-icon ${
          Object.keys(selectedItems).length === 0 ? "deactive" : ""
        }`}
        onClick={nextStep}
      >
        continua
      </button>
    </div>
  );
};

export default Recipes;
