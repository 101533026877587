import React from "react";

// Components
import HomePage from "../components/Home/HomePage";
import Header from "../components/Layout/Header";

const Home = () => {
  return (
    <div>
      <Header />
      <HomePage />
    </div>
  );
};

export default Home;
