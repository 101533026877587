import React, { useState } from "react";
import { LeftArrowIcon } from "./Icons";
import { Link } from "react-router-dom";

const ResponsiveHeader = () => {
  const tabs = [
    {
      content: "content 1",
      title: "Attività",
    },
    {
      content: "content 2",
      title: "Misurazioni",
    },
  ];

  const [activeTab, setActiveTab] = useState(0);
  const month = localStorage.getItem("month");

  return (
    <div className="ResponsiveHeader">
      <div className="top-bar">
        <Link to="/admin/atleti">
          <button className="icon">
            <LeftArrowIcon />
          </button>
        </Link>
        <div className="title">atleta 1</div>
      </div>
      <div className="ResponsiveHeader-flex">
        <div className="avatar-container">
          <img
            src="https://png.pngtree.com/png-vector/20190710/ourmid/pngtree-user-vector-avatar-png-image_1541962.jpg"
            alt="image description"
          />
        </div>
      </div>
      <div className="custom-tabs">
        {tabs.map((tab, i) => (
          <div onClick={() => setActiveTab(i)} className="w-full-tab">
            <div className={`custom-tab ${activeTab === i ? "active" : ""}`}>
              {tab.title}
            </div>
          </div>
        ))}
      </div>
      <p className="curr-month">{month}</p>
      {/* <div>{tabs[activeTab].content}</div> */}
    </div>
  );
};

export default ResponsiveHeader;
