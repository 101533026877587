import React, { useEffect, useState } from "react";
import "../css/modal.css";
import DatePicker from "react-datepicker";
import axios from "axios";
import { BASE_URL } from "../assets/constants";
import { useNavigate, useParams } from "react-router-dom";
import { notify } from "./util/notify";

const ActivityFeedbackModals = ({
  isOpen,
  id,
  totalActivity,
  setIsOpenModal,
  excerciseData,
  excerciseDataComplete,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [feedbackData, setFeedbackData] = useState({
    round: "",
    exercises:
      excerciseData &&
      excerciseData?.map((exercise) => ({
        excercise_id: exercise.id,
        raps: "",
        time: "",
      })),
  });
  useEffect(() => {
    setFeedbackData({
      round: "",
      exercises:
        excerciseData &&
        excerciseData?.map((exercise) => ({
          excercise_id: exercise.id,
          raps: "",
        })),
    });
  }, [excerciseData]);

  const adminData = JSON.parse(localStorage.getItem("adminData"));
  const token = adminData?.token;
  const navigate = useNavigate();
  const { excerciseId } = useParams();

  const handleRoundChange = (e) => {
    const value = e.target.value ? Number(e.target.value) : "";
    setFeedbackData((prevData) => ({
      ...prevData,
      round: value >= 0 ? value : prevData.round,
    }));
  };

  const handleRapsChange = (exerciseId, raps, value) => {
    setFeedbackData((prevData) => ({
      ...prevData,
      exercises: prevData.exercises?.map((exercise) =>
        exercise?.excercise_id === exerciseId
          ? { ...exercise, raps: raps }
          : exercise
      ),
    }));
  };
  const validateFeedbackData = () => {
    if (feedbackData.round === "" || feedbackData.round < 0) {
      notify(true, "Il campo 'round' non può essere vuoto o minore di 0.");
      return false;
    }

    const timeRegex = /^([0-1]?[0-9]|2[0-3]):([0-5][0-9]):([0-5][0-9])$/;

    for (let exercise of feedbackData.exercises) {
      const exerciseDetails = excerciseData.find(
        (ex) => ex.id === exercise.excercise_id
      );

      // Check if raps value is empty or less than 0
      if (exercise.raps === "" || exercise.raps < 0) {
        notify(
          true,
          `Il campo 'raps' per l'esercizio ${exerciseDetails?.name} non può essere vuoto o minore di 0.`
        );
        return false;
      }

      // Validate time format if time is not "00:00:00"
      if (
        exerciseDetails?.time !== "00:00:00" &&
        !timeRegex.test(exercise.raps)
      ) {
        notify(
          true,
          `Il campo 'raps' per l'esercizio ${exerciseDetails?.name} deve essere nel formato HH:MM:SS.`
        );
        return false;
      }
    }

    return true;
  };

  const onConfirm = async () => {
    if (!validateFeedbackData()) return;

    setIsLoading(true);
    try {
      let response = await axios.post(
        `${BASE_URL}athlete/activity/${id}/excersise/feedback`,
        {
          round: feedbackData?.round,
          excercise: feedbackData?.exercises,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (isOpen === "skip") {
        navigate(
          `/user/training/step-five/${excerciseDataComplete?.session?.id}`
        );
        notify("", "Feedback archiviato con successo...!");
        return;
      }
      notify("", "Attività completata...!");
    } catch (error) {}
    if (totalActivity.slice(-1)[0] == id) {
      if (
        excerciseDataComplete?.activityTimer?.activity?.repetitions ==
        excerciseDataComplete?.activityTimer?.activity?.count
      ) {
        navigate(
          `/user/training/step-five/${excerciseDataComplete?.session?.id}`
        );
        return;
      }
    }
    setIsLoading(false);
    setIsOpenModal(false);
    navigate(`/user/training/step-three/${excerciseId}`, { state: "false" });
  };
  const spinnerStyle = {
    border: "3px solid #f3f3f3",
    borderTop: "3px solid #a0fc6e",
    borderRadius: "50%",
    width: "30px",
    height: "30px",
    animation: "spin 1s linear infinite",
  };

  const containerStyle = {
    display: "flex",
    alignContent: "center",
    backgroundColor: "transparent",
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <div>
          <div
            className="form-div"
            style={{
              // border: "1px solid red",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <label className="d-block fs-6">Quanti round hai completato?</label>
            <input
              type={"number"}
              placeholder="Enter Rounds"
              className="form-input"
              value={feedbackData.round}
              onChange={handleRoundChange}
            />
          </div>
          <label className="d-block fs-6">Quanti rap hai completato?</label>
          <section>
            {excerciseData?.map((value) => {
              return (
                <div
                  key={value?.id}
                  className="form-div"
                  style={{
                    // border: "1px solid red",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "baseline",
                    justifyContent: "space-between",
                  }}
                >
                  <label className="d-block fs-6">{value?.name}</label>
                  <input
                    type={value?.time === "00:00:00" ? "number" : "text"}
                    placeholder={` ${
                      value?.time === "00:00:00" ? "Reps" : "HH:MM:SS"
                    }`}
                    className="form-input"
                    style={{ width: "40%" }}
                    value={
                      feedbackData.exercises?.find(
                        (exercise) => exercise?.excercise_id === value?.id
                      )?.raps || ""
                    }
                    onChange={(e) =>
                      handleRapsChange(value.id, e.target.value, value)
                    }
                  />
                </div>
              );
            })}
          </section>
        </div>
        <div
          className="button-group"
          style={{
            display: "flex",
            flexDirection: "row",
            alignSelf: "center",
          }}
        >
          {isLoading ? (
            <div style={containerStyle}>
              <div style={spinnerStyle}></div>
              <style>
                {`
              @keyframes spin {
                0% { transform: rotate(0deg); }
                100% { transform: rotate(360deg); }
              }
            `}
              </style>
            </div>
          ) : (
            <button
              className="modal-btn confirm-btn"
              onClick={onConfirm}
              style={{ marginRight: "10px" }}
            >
              SÌ
            </button>
          )}
          {isOpen == "skip" && (
            <button
              className="modal-btn confirm-btn"
              onClick={() => setIsOpenModal(false)}
              style={{ marginRight: "10px" }}
            >
              Cancellare
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default ActivityFeedbackModals;
