import { useState, useRef } from "react";
// CSS
import "./LoginForm.css";

// Components
import Header from "../Layout/Header";
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "../../redux/actions";
import { useNavigate } from "react-router-dom";
import Loader from "../Users/globalLoader";
import { notify } from "../util/notify";
import axios from "axios";
import { BASE_URL } from "../../assets/constants";

const ForgotPass = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const passwordRef = useRef();
  const emailRef = useRef();
  const loginData = useSelector((state) => state?.userReducer?.loginData);
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isShowOtp, setIsShowOtp] = useState(false);
  const [errors, setErrors] = useState({});

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const validateForm = () => {
    const newErrors = {};

    if (!emailRef.current.value) {
      newErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(emailRef.current.value)) {
      newErrors.email = "Email address is invalid";
    }

    // if (!passwordRef.current.value) {
    //   newErrors.password = "Password is required";
    // } else if (passwordRef.current.value.length < 6) {
    //   newErrors.password = "Password must be at least 6 characters";
    // }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const onLoginSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    setLoading(true);

    const formData = {
      email: emailRef.current.value,
      //   password: passwordRef.current.value,
    };

    // dispatch(loginUser(formData));

    try {
      const res = await axios.post(`${BASE_URL}forgot-password`, formData);
      if (res?.status === 200) {
        notify(false, res?.data?.message);
        localStorage.setItem("uEmail", emailRef.current.value);
        navigate("/resetPassword", { state: emailRef.current.value });
      }
    } catch (error) {
      if (error.response) {
        notify(true, `Error: ${error.response.data.message}`, 3000);
      } else if (error.request) {
        notify("No response received from server", 3000);
      } else {
        console.log("Error message:", error.message);
        notify(true, error.message, 3000);
      }
    }

    setLoading(false);

    emailRef.current.value = "";
  };

  return (
    <>
      <Header />
      {loading && <Loader />}
      <div className="main-container centered">
        <div className="login-content">
          <div className="headline">Password dimenticata?</div>

          <form name="login" id="userLogin" onSubmit={onLoginSubmit}>
            <div className="user-info">
              <div className="text-input">
                <div className="title">Indirizzo email</div>
                <div className="input input-control">
                  <input
                    type="text"
                    name="email"
                    id="userEmail"
                    placeholder="something@domain.com"
                    ref={emailRef}
                  />
                  {errors.email && <div className="error">{errors.email}</div>}
                </div>
              </div>
              {/* <div className="text-input margin-top">
                <div className="title">Password</div>
                <div className="input input-control">
                  <input
                    type={showPassword ? "text" : "password"}
                    name="password"
                    id="userPassword"
                    placeholder="MyP@assword"
                    ref={passwordRef}
                  />
                  {errors.password && (
                    <div className="error">{errors.password}</div>
                  )}
                </div>
              </div> */}
              {/* <div
                className="input-control show-password"
                style={{ marginBottom: "1em" }}
              >
                <input
                  type="checkbox"
                  className="round-checkbox"
                  checked={showPassword}
                  onChange={toggleShowPassword}
                />
                Show password
              </div> */}
            </div>
            <div className="login-register" style={{ marginTop: 50 }}>
              <button
                type="submit"
                id="submitLogin"
                className="button-default-no-icon"
                disabled={loading}
              >
                {loading ? "Loading..." : "Forgot password"}
              </button>
              <a href="/login" className="button-default-no-icon outline">
                login?
              </a>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default ForgotPass;
