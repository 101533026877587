// Thumbnail.js
import React from "react";
import Arrow from "../../assets/img/icons/chevron-right-active.svg";
import { Link } from "react-router-dom";

const Thumbnail = ({ imageUrl, heading, title, subtitle, status }) => (
  <div className="card-with-thumbnail">
    <Link to="#">
      {/* <div className="thumbnail">
        <img src={imageUrl} alt="" />
      </div> */}
      <div className="text" style={{ padding: 10 }}>
        {heading && <h2 style={{ textTransform: "capitalize" }}>{heading}</h2>}
        <div className="title my-1">{title}</div>
        <div className="subtitle">{subtitle}</div>
      </div>
      <div className="arrow">
        {status ? (
          <>
            {status == "pending" ? (
              <a href="progressi">
                <img src={Arrow} alt="right arrow active" />
              </a>
            ) : (
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="25"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="#a0fc6e"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 12.611L8.923 17.5 20 6.5"
                  ></path>
                </svg>
              </div>
            )}
          </>
        ) : (
          <a href="progressi">
            <img src={Arrow} alt="right arrow active" />
          </a>
        )}
      </div>
    </Link>
  </div>
);

export default Thumbnail;
