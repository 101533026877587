import React from "react";
import AdminAtheletStepFive from "../../components/Admin/AdminAtheletStepFive";

const AdminAllenamento = () => {
  return (
    <div>
      <AdminAtheletStepFive />
    </div>
  );
};

export default AdminAllenamento;
