import React, { useState, useEffect, useRef } from "react";
import "./onboardingCss.css";
import "../../components/Users/Measurements.css";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../../assets/constants";
import axios from "axios";
import { notify } from "../../components/util/notify";
import bannerImage from "../../assets/img/image@2x.png";

function ThirdStep({}) {
  const navigate = useNavigate();
  const fileInputRef = useRef(null);
  const fileInputRef1 = useRef(null);
  const adminData = JSON.parse(localStorage.getItem("adminData"));
  const token = adminData?.token;
  const [countdown, setCountdown] = useState(3);
  const [showVideo, setShowVideo] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [imagesName, setImagesName] = useState({
    front_photo: "",
    back_photo: "",
  });

  const [atheleteData, setAtheleteData] = useState({
    chest: "",
    abdomen: "",
    waist: "",
    glutes: "",
    left_arm: "",
    right_arm: "",
    left_thigh: "",
    right_thigh: "",
    front_photo: "",
  });

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };
  const handleButtonClick1 = () => {
    fileInputRef1.current.click();
  };

  const handleBackClick = async () => {
    setIsLoading(true);
    const errors = [];

    if (!atheleteData.chest || atheleteData.chest <= 0) {
      errors.push("Chest");
    }
    if (!atheleteData.abdomen || atheleteData.abdomen <= 0) {
      errors.push("Abdomen");
    }
    if (!atheleteData.waist || atheleteData.waist <= 0) {
      errors.push("Waist");
    }
    if (!atheleteData.glutes || atheleteData.glutes <= 0) {
      errors.push("Glutes");
    }
    if (!atheleteData.left_arm || atheleteData.left_arm <= 0) {
      errors.push("Left Arm");
    }
    if (!atheleteData.right_arm || atheleteData.right_arm <= 0) {
      errors.push("Right Arm");
    }
    if (!atheleteData.left_thigh || atheleteData.left_thigh <= 0) {
      errors.push("Left Thigh");
    }
    if (!atheleteData.right_thigh || atheleteData.right_thigh <= 0) {
      errors.push("Right Thigh");
    }
    if (!atheleteData.front_photo) {
      errors.push("Front Photo");
    }
    if (!atheleteData.back_photo) {
      errors.push("Back Photo");
    }

    if (errors.length > 0) {
      notify(
        true,
        `The following fields are required or have invalid values: ${errors.join(
          ", "
        )}!`
      );
      setIsLoading(false);
      return;
    }

    try {
      let response = await axios.post(
        `${BASE_URL}athlete/step-2`,
        atheleteData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response?.data?.status) {
        let step = await axios.get(`${BASE_URL}athlete/step-form`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        let steps = step?.data?.forms_completed;
        localStorage.setItem("onboardingProgress", JSON.stringify(steps));
        notify(false, "Passaggio 2 completato");

        navigate("/onboardingstep4");
      }
    } catch (error) {
      const errorMessages = error?.response?.data?.message;

      if (errorMessages) {
        const combinedErrors = Object.values(errorMessages).flat().join("\n");

        notify(true, combinedErrors);
      } else {
        notify(true, "Something went wrong!");
      }
    }
    setIsLoading(false);
  };

  const handleFileChange = (event, value) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => {
        setImagesName({
          ...imagesName,
          [value]: file?.name,
        });
        setAtheleteData({
          ...atheleteData,
          [value]: reader.result,
        });
      };

      reader.onerror = (error) => {
        console.error("Error reading file:", error);
      };
    }
  };

  const handleChange = (event) => {
    let { value, name } = event.target;
    setAtheleteData({
      ...atheleteData,
      [name]: value,
    });
  };

  const spinnerStyle = {
    border: "3px solid #f3f3f3",
    borderTop: "3px solid #a0fc6e",
    borderRadius: "50%",
    width: "30px",
    height: "30px",
    animation: "spin 1s linear infinite",
    margin: "0 auto",
  };

  const containerStyle = {
    display: "flex",
    justifyContent: "center",
    marginTop: "10px",
  };

  return (
    <div className="mainContainer">
      <div>
        <div
          className="hunger-level-container1"
          style={{
            backgroundColor: "#080808",
            marginTop: 20,
            padding: 20,
            width: "25em",
          }}
        >
          <div className="title" style={{ fontSize: "2em" }}>
            misurazioni
          </div>

          <div className="sprateColorsStep">
            <div className="colorBar" style={{ height: 8 }}></div>
            <div
              className="colorBar"
              style={{ height: 6, marginLeft: 10 }}
            ></div>
            <div
              className="colorBar"
              style={{ height: 6, marginLeft: 10, backgroundColor: "#fff" }}
            ></div>
            <div
              className="colorBar"
              style={{ height: 6, marginLeft: 10, backgroundColor: "#fff" }}
            ></div>
            <div
              className="colorBar"
              style={{ height: 6, marginLeft: 10, backgroundColor: "#fff" }}
            ></div>
          </div>

          <div className="range-labels" style={{ marginTop: -15 }}>
            <span className="label-left" style={{ color: "#7A7A7A" }}>
              Step 2 di 5
            </span>
          </div>

          <div className="showBanner">
            <img src={bannerImage} width={"100%"} height={200} />
          </div>

          {[
            { name: "Petto", value: "chest" },
            { name: "Addome", value: "abdomen" },
            { name: "Girovita", value: "waist" },
            { name: "Glutei", value: "glutes" },
          ].map((i, ind) => {
            return (
              <div className="ind" key={ind}>
                <div className="text-input">
                  <div className="title" style={{ marginTop: 15 }}>
                    {i.name}
                  </div>
                  <div className="input input-control">
                    <input
                      type="number"
                      onChange={handleChange}
                      name={i.value}
                      id={i.value}
                      placeholder={`Entra ${i?.name} ...`}
                    />
                  </div>
                </div>
              </div>
            );
          })}

          {[1, 2].map((i, ind) => {
            return (
              <div
                key={ind}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: 20,
                }}
              >
                <div className="text-input">
                  <div className="title">
                    {ind == 0 ? "BRACCIO SX" : "COSCIA SX"}
                  </div>
                  <div className="input input-control">
                    <input
                      name={ind == 0 ? "left_arm" : "left_thigh"}
                      onChange={handleChange}
                      type="number"
                      id={"i"}
                      placeholder={`Entra ${
                        ind == 0 ? "BRACCIO SX" : "COSCIA SX"
                      } ...`}
                    />
                  </div>
                  <p className="help-text">
                    Non sai come fare? Tappa qui e vedi la foto
                  </p>
                </div>
                <div className="text-input" style={{ marginLeft: 5 }}>
                  <div className="title">
                    {ind == 0 ? "BRACCIO DX" : "COSCIA DX"}
                  </div>
                  <div className="input input-control">
                    <input
                      type="number"
                      name={ind == 0 ? "right_arm" : "right_thigh"}
                      onChange={handleChange}
                      id={"i"}
                      placeholder={`Entra ${
                        ind == 0 ? "BRACCIO DX" : "COSCIA DX"
                      } ...`}
                    />
                  </div>
                </div>
              </div>
            );
          })}

          <div className="showBanner">
            <img src={bannerImage} width={"100%"} height={200} />
          </div>

          <div>
            <div className="text-input">
              {/* <div
                className="upload-button"
                style={{
                  backgroundColor: "#7fff00",
                  border: 0,
                  color: "#000",
                }}
              >
                <span className="upload-icon">&#10003;</span>
                <span className="upload-text">NOME FOTO JPEG</span>
                <span className="upload-text"></span>
              </div> */}

              <div style={{ width: "100%" }}>
                <div className="title">FOTO FRONTALE</div>

                {atheleteData?.front_photo ? (
                  <div
                    style={{
                      width: "100%",
                      height: "40px",
                      border: "1px dashed grey",
                      // borderRadius: "100%",
                      background: "#A0FC6E",
                      marginBottom: "20px",
                      textAlign: "center",
                      paddingTop: 5,
                      cursor: "pointer",
                      color: "#000",
                    }}
                  >
                    {imagesName?.front_photo}
                  </div>
                ) : (
                  <div
                    onClick={handleButtonClick}
                    style={{
                      width: "100%",
                      height: "40px",
                      border: !imagesName?.front_photo && "1px dashed grey",
                      // borderRadius: "100%",
                      marginBottom: "20px",
                      textAlign: "center",
                      paddingTop: 5,
                      cursor: "pointer",
                    }}
                  >
                    Upload Image
                  </div>
                )}

                <input
                  ref={fileInputRef}
                  onChange={(event) => handleFileChange(event, "front_photo")}
                  type="file"
                  accept="image/*"
                  name
                  style={{ display: "none" }}
                />
              </div>
              <div style={{ width: "100%" }}>
                <div className="title">Foto posteriore</div>

                {atheleteData?.back_photo ? (
                  <div
                    style={{
                      width: "100%",
                      height: "40px",
                      border: "1px dashed grey",
                      // borderRadius: "100%",
                      background: "#A0FC6E",
                      marginBottom: "20px",
                      textAlign: "center",
                      paddingTop: 5,
                      cursor: "pointer",
                      color: "#000",
                    }}
                  >
                    {imagesName?.back_photo}
                  </div>
                ) : (
                  <div
                    onClick={handleButtonClick1}
                    style={{
                      width: "100%",
                      height: "40px",
                      border: !imagesName?.back_photo && "1px dashed grey",
                      // borderRadius: "100%",
                      marginBottom: "20px",
                      textAlign: "center",
                      paddingTop: 5,
                      cursor: "pointer",
                    }}
                  >
                    Upload Image
                  </div>
                )}

                <input
                  ref={fileInputRef1}
                  onChange={(event) => handleFileChange(event, "back_photo")}
                  type="file"
                  accept="image/*"
                  name
                  style={{ display: "none" }}
                />
              </div>
            </div>
          </div>
          {isLoading ? (
            <div style={containerStyle}>
              <div style={spinnerStyle}></div>
              <style>
                {`
              @keyframes spin {
                0% { transform: rotate(0deg); }
                100% { transform: rotate(360deg); }
              }
            `}
              </style>
            </div>
          ) : (
            <button
              type="button"
              className="button-default-no-icon"
              onClick={() => {
                handleBackClick();
              }}
              style={{ width: "100%", marginTop: 20 }}
            >
              continua
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default ThirdStep;
