import React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { AttivataIcon, MeasurementIcon, Contact } from "./Icons";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
const CoachHeader = () => {
  const [activeItem, setActiveItem] = useState("");
  const { state } = useLocation();
  const navigate = useNavigate();

  const { atheleteId } = useParams();

  const location = useLocation();
  useEffect(() => {
    switch (location.pathname) {
      case "/coach/atleti/misurazioni":
        setActiveItem("misurazioni");
        break;
      case "/coach/atleti/attivita":
        setActiveItem("attivita");
        break;
      case "/coach/atleti/nutrizione":
        setActiveItem("attivita");
        break;
      case "/coach/atleti/allenamento":
        setActiveItem("attivita");
        break;

      default:
        setActiveItem("");
    }
  }, [location.pathname]);

  return (
    <>
      <div
        // className="admin-coach-step-two-container-atleti"
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "40px",
          backgroundColor: "#000",
          marginLeft: "-14px",
          alignItems: "center",
          paddingLeft: "20px",
          /* padding: 10px; */
          paddingTop: "10px",
          paddingBottom: "10px",
        }}
      >
        <Link to="/coach/home">
          <div>
            <svg
              width="25"
              height="24"
              viewBox="0 0 25 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.3064 2.46888C17.3762 2.53854 17.4316 2.62131 17.4695 2.71243C17.5073 2.80354 17.5267 2.90122 17.5267 2.99988C17.5267 3.09853 17.5073 3.19621 17.4695 3.28733C17.4316 3.37844 17.3762 3.46121 17.3064 3.53088L8.83589 11.9999L17.3064 20.4689C17.4472 20.6097 17.5263 20.8007 17.5263 20.9999C17.5263 21.199 17.4472 21.39 17.3064 21.5309C17.1656 21.6717 16.9746 21.7508 16.7754 21.7508C16.5762 21.7508 16.3852 21.6717 16.2444 21.5309L7.24439 12.5309C7.17454 12.4612 7.11913 12.3784 7.08132 12.2873C7.04351 12.1962 7.02405 12.0985 7.02405 11.9999C7.02405 11.9012 7.04351 11.8035 7.08132 11.7124C7.11913 11.6213 7.17454 11.5385 7.24439 11.4689L16.2444 2.46888C16.3141 2.39903 16.3968 2.34362 16.4879 2.30581C16.5791 2.268 16.6767 2.24854 16.7754 2.24854C16.874 2.24854 16.9717 2.268 17.0628 2.30581C17.154 2.34362 17.2367 2.39903 17.3064 2.46888Z"
                fill="#A0FC6E"
              />
            </svg>
          </div>
        </Link>

        <div className="avatar-container">
          <img
            src="https://png.pngtree.com/png-vector/20190710/ourmid/pngtree-user-vector-avatar-png-image_1541962.jpg"
            alt="image description"
          />
        </div>
        <h3 className="name">{state?.name}</h3>
        {/* <Link to="/coach/atleti/attivita"> */}
        <h5
          className="top-nav attivata"
          onClick={() => {
            setActiveItem("attivita");
            navigate(`/coach/atleti/attivita`, { state });
          }}
        >
          <AttivataIcon
            color={activeItem === "attivita" ? "green" : "#F6F6F6"}
          />
          <span className={`${activeItem === "attivita" ? "active" : ""}`}>
            Attività
          </span>
        </h5>
        {/* </Link> */}
        {/* <Link to={`/coach/atleti/misurazioni/${atheleteId}`}> */}
        <h5
          className="top-nav"
          onClick={() => {
            setActiveItem("misurazioni");
            navigate(`/coach/atleti/misurazioni/${atheleteId}`, { state });
          }}
        >
          <MeasurementIcon
            color={activeItem === "misurazioni" ? "green" : "#F6F6F6"}
          />

          <span className={`${activeItem === "misurazioni" ? "active" : ""}`}>
            MISURAZIONI
          </span>
        </h5>
        {/* </Link> */}
        {/* <h5 className="top-nav" onClick={() => setActiveItem("contact")}>
          <Contact color="#F6F6F6" />
          <span>CONTATTA</span>
        </h5> */}
      </div>
    </>
  );
};

export default CoachHeader;
