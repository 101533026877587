import React, { useState } from "react";
import axios from "axios";
import { BASE_URL } from "../assets/constants";
import { notify } from "./util/notify";

const AddTagModal = ({
  isOpen,
  onClose,
  coachId,
  athleteTag,
  athleteId,
  coachAthelete,
}) => {
  const [tag, setTag] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleSave = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (!tag) {
      setIsLoading(false);
      notify(true, "Inserisci il tag qui...!");
      return;
    }
    const adminData = JSON.parse(localStorage.getItem("adminData"));
    const token = adminData?.data?.token;
    const apiUrl = coachAthelete
      ? `${BASE_URL}coach/create/athlete/${athleteId}/tag`
      : athleteTag
      ? `${BASE_URL}admin/create/athlete/${athleteId}/tag`
      : `${BASE_URL}admin/create/coach/${coachId}/tag`;

    try {
      const response = await axios.post(
        apiUrl,
        { tag_name: tag },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        notify(false, "Tag saved successfully!");
        onClose();
        setIsLoading(false);
      }
    } catch (error) {
      const tagError = error?.response?.data?.errors?.tag_name;

      const errorMessage = Array.isArray(tagError)
        ? tagError.join(" ")
        : tagError || "Failed to save the tag. Please try again.";

      console.log("Error saving tag:", errorMessage);
      notify(true, errorMessage);
      setIsLoading(false);
    }
  };
  const spinnerStyle = {
    border: "3px solid #f3f3f3",
    borderTop: "3px solid #a0fc6e",
    borderRadius: "50%",
    width: "30px",
    height: "30px",
    animation: "spin 1s linear infinite",
  };

  const containerStyle = {
    display: "flex",
    justifyContent: "center",
    marging: "0 auto",
    backgroundColor: "transparent",
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <form onSubmit={handleSave}>
          <div className="form-div">
            <label>nome dell'etichetta</label>
            <input
              type="text"
              placeholder="Enter tag here"
              className="form-input"
              value={tag}
              onChange={(e) => setTag(e.target.value)}
            />
          </div>
          <div className="button-group" style={{ marginTop: "10px" }}>
            {isLoading ? (
              <div style={containerStyle}>
                <div style={spinnerStyle}></div>
                <style>
                  {`
              @keyframes spin {
                0% { transform: rotate(0deg); }
                100% { transform: rotate(360deg); }
              }
            `}
                </style>
              </div>
            ) : (
              <button
                type="submit"
                className="modal-btn"
                style={{ width: "100%" }}
              >
                Salva
              </button>
            )}
          </div>
        </form>
        <div onClick={onClose} className="modal-close">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="15"
            height="15"
            viewBox="0 0 16 16"
          >
            <path
              fillRule="evenodd"
              fill="#fff"
              d="M0 14.545L1.455 16 8 9.455 14.545 16 16 14.545 9.455 8 16 1.455 14.545 0 8 6.545 1.455 0 0 1.455 6.545 8z"
            ></path>
          </svg>
        </div>
      </div>
    </div>
  );
};

export default AddTagModal;
