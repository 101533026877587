import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./nutrition.css";
// Reuseable
import WeekCalendar from "../Reuseable/WeekCalender";
import { AiOutlineCheck } from "react-icons/ai";
import SearchIcons from "../../assets/img/icons/search-active.svg";

// Icons
import PlusIcon from "../../assets/img/icons/plus-active.svg";
import CheckIcon from "../../assets/img/icons/check-active.svg";
import CloseIcon from "../../assets/img/icons/close-active.svg";
// Components
import { LeftArrowIcon } from "../Reuseable/Icons";
import { nutritionData } from "../../dumyData";
import Recipes from "./Recipes";
import { notify } from "../util/notify";
import axios from "axios";
import { BASE_URL } from "../../assets/constants";

const NutritionNewStep4 = ({
  // onButtonClick,
  yourRecipe,
  nextStep,
  suggested,
}) => {
  const adminData = JSON.parse(localStorage.getItem("adminData"));
  const token = adminData?.token;

  const [currentIndex, setCuurentIndex] = useState({
    name: "breakfast",
    index: 0,
  });
  const [nData, setNData] = useState({});
  const [nDataIndex, setNDataindex] = useState(0);
  const [searchInput, setSearchInput] = useState("");
  const [iconCheck, setIconCheck] = useState(true);
  const [showAll, setShowAll] = useState(false);
  const [caloriesData, setCaloriesData] = useState(null);
  const [filterCaloriesData, setFilterCaloriesData] = useState(null);
  const [isTabDisabled, setIsTabDisabled] = useState(false);
  const [selectedItems, setSelectedItems] = useState({
    Carbohydrate: [],
    Protein: [],
    Fat: [],
    Fiber: [],
  });
  const [tabSelection, setTabSelection] = useState({
    Breakfast: [],
    Lunch: [],
    Dinner: [],
    Snacks: [],
  });

  const navigate = useNavigate();
  const handleBackClick = () => {
    navigate(-1);
  };
  let dietTypes = ["Breakfast", "Lunch", "Dinner", "Snack"];

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
  };
  const monthNames = [
    "Gennaio",
    "Febbraio",
    "Marzo",
    "Aprile",
    "Maggio",
    "Giugno",
    "Luglio",
    "Agosto",
    "Settembre",
    "Ottobre",
    "Novembre",
    "Dicembre",
  ];

  const initialItemCount = 5;

  const handleShowAllClick = () => {
    setShowAll(true);
  };

  const filteredItems = suggested?.filter((item) =>
    item?.Descrizione?.toLowerCase().includes(searchInput.toLowerCase())
  );

  const itemsToDisplay = showAll
    ? filteredItems
    : filteredItems?.slice(0, initialItemCount);

  const [currentDate, setCurrentDate] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(new Date());

  const generateCalendarDates = (date) => {
    const dates = [];
    const month = date.getMonth();
    const year = date.getFullYear();
    const firstDay = new Date(year, month, 1).getDay();
    const lastDate = new Date(year, month + 1, 0).getDate();

    // Fill in the dates of the previous month
    const prevMonthLastDate = new Date(year, month, 0).getDate();
    for (let i = firstDay === 0 ? 6 : firstDay - 1; i > 0; i--) {
      dates.push({
        day: prevMonthLastDate - i + 1,
        currentMonth: false,
      });
    }

    // Fill in the current month's dates
    for (let i = 1; i <= lastDate; i++) {
      dates.push({
        day: i,
        currentMonth: true,
        isToday:
          i === date.getDate() &&
          month === new Date().getMonth() &&
          year === new Date().getFullYear(),
        isSelected:
          i === selectedDate.getDate() &&
          month === selectedDate.getMonth() &&
          year === selectedDate.getFullYear(),
      });
    }

    return dates;
  };

  const prevMonth = () => {
    setCurrentDate(new Date(currentDate.setMonth(currentDate.getMonth() - 1)));
  };

  const nextMonth = () => {
    setCurrentDate(new Date(currentDate.setMonth(currentDate.getMonth() + 1)));
  };

  const handleDateClick = (day) => {
    setSelectedDate(
      new Date(currentDate.getFullYear(), currentDate.getMonth(), day)
    );
  };

  const calendarDates = generateCalendarDates(currentDate);

  // useEffect(() => {
  //   alert(2);
  // }, []);

  const validateTabSelection = () => {
    const { Breakfast, Lunch, Dinner, Snacks } = tabSelection;

    if (
      Breakfast.length === 0 &&
      Lunch.length === 0 &&
      Dinner.length === 0 &&
      Snacks.length === 0
    ) {
      return false;
    }

    return true;
  };

  const onButtonClick = () => {
    if (!validateTabSelection()) {
      notify(true, "Seleziona le risorse nutrizionali..!");
      return;
    }
    let obj = {
      selectedDate: formatDate(selectedDate),
      tabSelection,
      dietTypes: dietTypes[currentIndex?.index],
    };
    navigate("/user/nutrizione/step-five", { state: obj });
  };

  const getCaloires = async () => {
    let date = new Date(selectedDate);
    const formattedDate =
      date.getFullYear() +
      "-" +
      String(date.getMonth() + 1).padStart(2, "0") +
      "-" +
      String(date.getDate()).padStart(2, "0");
    try {
      let response = await axios.post(
        `${BASE_URL}athlete/plan-meal`,
        {
          date: formattedDate,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setCaloriesData(response?.data?.data);
    } catch (error) {
      console.log({ error });

      notify(
        false,
        error?.response?.data?.message?.date
          ? error?.response?.data?.message?.date[0]
          : error?.response?.data?.message
      );
    }
  };

  useEffect(() => {
    if (selectedDate) {
      getCaloires();
    }
  }, [selectedDate]);

  useEffect(() => {
    let caories =
      caloriesData &&
      caloriesData?.filter((meal) => meal?.type == currentIndex?.name);
    if (caories) setFilterCaloriesData(caories);
  }, [currentIndex, caloriesData]);

  return (
    <div className="container">
      <div className="row">
        <div className=" nutrition-flex">
          <div className="top-bar">
            <button onClick={handleBackClick} className="icon">
              <LeftArrowIcon />
            </button>
            <div className="title">nutrizione</div>
          </div>
        </div>
        <div className="col-md-8">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
            }}
          >
            {[
              { name: "Colazione", value: "breakfast" },
              { name: "Pranzo", value: "lunch" },
              { name: "Cena", value: "dinner" },
              { name: "Spuntino", value: "snack" },
            ].map((item, index) => {
              return (
                <div key={index}>
                  <div
                    className="description"
                    style={{
                      cursor: "pointer",
                      borderBottom:
                        currentIndex?.index === index
                          ? "1px solid #7fff00"
                          : null,
                      width: "10em",
                      alignItems: "center",
                      textAlign: "center",
                      padding: 10,
                    }}
                    onClick={() => {
                      setCuurentIndex({ name: item?.value, index });
                    }}
                  >
                    {item?.name}
                  </div>
                </div>
              );
            })}
          </div>

          <div>
            <div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-evenly",
                  paddingLeft: "10em",
                  paddingRight: "10em",
                  marginTop: 50,
                  marginBottom: "5em",
                  cursor: "pointer",
                }}
              >
                {[
                  {
                    title: `${
                      (filterCaloriesData && filterCaloriesData[0]?.calories) ||
                      0
                    } kcal`,
                    tag: "Calorie",
                    person: 20,
                  },
                  {
                    title: `${
                      (filterCaloriesData &&
                        filterCaloriesData[0]?.carbohydrates) ||
                      0
                    } G`,
                    tag: "Carboidrati",
                    person: 20,
                  },
                  {
                    title: `${
                      (filterCaloriesData && filterCaloriesData[0]?.proteins) ||
                      0
                    } G`,
                    tag: "Proteine",
                    person: 20,
                  },
                  {
                    title: `${
                      (filterCaloriesData && filterCaloriesData[0]?.fats) || 0
                    } G`,
                    tag: "Grassi",
                    person: 20,
                  },
                  {
                    title: `${
                      (filterCaloriesData && filterCaloriesData[0]?.fiber) || 0
                    } G`,
                    tag: "Fibre",
                    person: 20,
                  },
                ].map((i, index) => {
                  return (
                    <div key={index}>
                      <div
                        className="title"
                        style={{ fontSize: 20, color: "#7fff00" }}
                      >
                        {i?.title}
                      </div>
                      <div
                        className="title"
                        style={{
                          fontSize: 13,
                          color: "#7fff00",
                          marginLeft: 10,
                        }}
                      >
                        {i?.tag}
                      </div>
                      <div
                        style={{
                          backgroundColor: "#666",
                          // width: 70,
                          height: 5,
                          borderRadius: 100,
                        }}
                      >
                        <div
                          style={{
                            backgroundColor: "#7fff00",
                            width: 30,
                            height: 5,
                            borderRadius: 100,
                          }}
                        ></div>
                      </div>
                    </div>
                  );
                })}
              </div>

              {/* ....................... */}
              {/* <div>
                <div className=" nutrition-title">
                  scegli le fonti nutrizionali
                </div>
              </div> */}

              <Recipes
                nextStep={onButtonClick}
                foodData={[]}
                setIsTabDisabled={setIsTabDisabled}
                setSelectedItems={setSelectedItems}
                selectedItems={selectedItems}
                setTabSelection={setTabSelection}
                activeTab={currentIndex?.index}
                tabSelection={tabSelection}
              />

              {/* ................................... */}
            </div>
          </div>
        </div>

        {/* ............................Calender..................... */}
        <div className="col-md-4">
          <div className="calendar-container">
            <div className="calendar-header">
              <button className="nav-arrow" onClick={prevMonth}>
                &#8592;
              </button>
              <span className="month-name">
                {monthNames[currentDate.getMonth()]} {currentDate.getFullYear()}
              </span>
              <button className="nav-arrow" onClick={nextMonth}>
                &#8594;
              </button>
            </div>
            <div className="calendar-weekdays">
              <span>L</span>
              <span>M</span>
              <span>M</span>
              <span>G</span>
              <span>V</span>
              <span>S</span>
              <span>D</span>
            </div>
            <div className="calendar-dates">
              {calendarDates.map((date, index) => (
                <span
                  key={index}
                  className={`${!date.currentMonth ? "prev-month" : ""} ${
                    date.isToday ? "current-day" : ""
                  } ${date.isSelected ? "selected" : ""}`}
                  onClick={() => date.currentMonth && handleDateClick(date.day)}
                >
                  {date.day}
                </span>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* .................................END............................. */}
    </div>
  );
};

export default NutritionNewStep4;
