import React, { useEffect, useState } from "react";
import TrainingFirstStep from "./TrainingStepOne";
import TrainingSecondStep from "./TrainingStepTwo";
import TrainingThirdStep from "./TrainingStepThree";
import TrainingFourthStep from "./TrainingStepFour";
import { useDispatch, useSelector } from "react-redux";
import { getTrainingListing } from "../../redux/actions";
import { useNavigate } from "react-router-dom";
import Persontage from "./Persontage";

function TrainingComponent({ retrunFuncValue }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userData = useSelector((state) => state?.userReducer?.data);
  const getTrainingListData = userData?.data;
  const result = userData?.result;
  const token = localStorage.getItem("token");
  const userId = localStorage.getItem("userID");

  const [step, setStep] = useState(1);
  const [selectedData, setSelectedData] = useState([]);

  // useEffect(() => {
  //   if (!token) {
  //     navigate("/login");
  //   }
  // }, [token, navigate]);

  useEffect(() => {
    const data = {
      token: token,
      idutente: userId,
    };
    dispatch(getTrainingListing(data));
  }, [token, userId]);

  const handleSelectedDescriptionChange = (date) => {
    const items = getTrainingListData?.filter((item) => item.data === date);
    setSelectedData(items);
  };

  const goToNextStep = async () => {
    setStep((step) => step + 1);
  };

  const goToNextStep1 = async () => {
    retrunFuncValue(true);
    setStep((step) => step + 1);
  };

  const goToNextStep4 = async () => {
    retrunFuncValue(false);
    setStep((step) => step + 1);
  };

  const goToNextStep5 = async () => {
    retrunFuncValue(false);
    setStep((step) => step + 1);
  };

  const goToPreviousStep = () => {
    setStep((step) => step - 1);
  };

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <TrainingFirstStep
            onButtonClick={goToNextStep}
            completed={result}
            getTrainingListData={getTrainingListData}
            onSelectedDescriptionChange={handleSelectedDescriptionChange}
          />
        );
      case 2:
        return (
          <TrainingSecondStep
            onButtonClick={goToNextStep1}
            result={result}
            onClick={goToPreviousStep}
            selectedData={selectedData}
          />
        );
      case 3:
        return (
          <TrainingThirdStep
            onButtonClick={goToNextStep4}
            getTrainingListData={getTrainingListData}
          />
        );
      case 4:
        return (
          <TrainingFourthStep
            getTrainingListData={getTrainingListData}
            selectedData={selectedData}
            moreIncrement={goToNextStep5}
          />
        );
      case 5:
        return <Persontage />;
      default:
        return <TrainingFirstStep onButtonClick={goToNextStep} />;
    }
  };

  return <div>{renderStep()}</div>;
}

export default TrainingComponent;
