import React from "react";
import AdminAtheletaStepSix from "../../components/Admin/AdminAtheletaStepSix";

const AdminNutrizione = () => {
  return (
    <div>
      <AdminAtheletaStepSix />
    </div>
  );
};

export default AdminNutrizione;
