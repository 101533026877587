import React, { useEffect, useState } from "react";
import plusIcon from "../../assets/img/icons/plus-black.svg";
import AtletiHeader from "../Reuseable/AtletiHeader";
import AdminFoodPlan from "../Admin/AdminFoodPlan";
import NoteCard from "../Admin/NotesCard";
import CoachHeader from "../Reuseable/CoachHeader";
import axios from "axios";
import { BASE_URL } from "../../assets/constants";
import editIcon from "../../assets/img/icons/edit.svg";
import deleteIcon from "../../assets/img/icons/delete.svg";
import calendarIcon from "../../assets/img/icons/calendar.svg";
import { notify } from "../util/notify";
import CreateNoteModal from "../CreateNoteModal";
import { useLocation } from "react-router-dom";
import DeleteModal from "../DeleteModal";

const TaskEditor = ({
  title,
  date,
  description,
  handleEditClick,
  value,
  handleDelete,
}) => {
  const formatDate = (dateString) => {
    // Create a new Date object
    const date = new Date(dateString);

    // Format the date to "Saturday, 05 Oct"
    const options = { weekday: "long", day: "2-digit", month: "short" };
    return date.toLocaleDateString("en-US", options);
  };

  return (
    <div className="task-editor">
      <div className="title">
        {title}
        <div>
          <img
            src={editIcon}
            alt="edit icon"
            onClick={() => handleEditClick(value)}
          />
          <img
            src={deleteIcon}
            alt="delete icon"
            onClick={() => handleDelete(value)}
            style={{
              marginLeft: "10px",
              cursor: "pointer",
              width: "20px",
              height: "20px",
              filter: "invert(100%) brightness(100%)",
            }}
          />
        </div>
      </div>
      <div className="task-body">
        <img
          src={calendarIcon}
          alt="calendar icon"
          style={{ marginRight: "10px" }}
        />{" "}
        <label>{formatDate(date)}</label>
      </div>
      <div className="description">{description}</div>
    </div>
  );
};

const CoachAtheletaStepSix = ({ onClick, onButtonClick }) => {
  const adminData = JSON.parse(localStorage.getItem("adminData"));
  const token = adminData?.data?.token;

  const [confirmationModal, setConfirmationModal] = useState(false);
  const [notes, setNotes] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [selectedNote, setSelectedNote] = useState(null);
  const { state } = useLocation();
  const [isLoading, setIsLoading] = useState(false);

  const handleEditClick = (note) => {
    setOpenModal(true);
    setSelectedNote(note);
  };

  const handleDelete = async (value) => {
    setIsLoading(true);
    try {
      await axios.delete(`${BASE_URL}coach/note-delete/${confirmationModal}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      fetchNotes();
      notify("", "Deleted Successfully..!");
    } catch (error) {}
    setConfirmationModal(false);
    setIsLoading(false);
  };

  const handleNoteSave = () => {
    fetchNotes();
    setOpenModal(false);
  };

  const fetchNotes = async () => {
    try {
      const response = await axios.get(`${BASE_URL}coach/note-all`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setNotes(response?.data?.data || []);
    } catch (error) {
      console.error("Error fetching athlete notes:", error);
    } finally {
    }
  };

  useEffect(() => {
    fetchNotes();
  }, []);

  return (
    <div>
      {/* <div className="admin-coach-step-two-container">
        <div onClick={() => onClick()}>
          <svg
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M17.3064 2.46888C17.3762 2.53854 17.4316 2.62131 17.4695 2.71243C17.5073 2.80354 17.5267 2.90122 17.5267 2.99988C17.5267 3.09853 17.5073 3.19621 17.4695 3.28733C17.4316 3.37844 17.3762 3.46121 17.3064 3.53088L8.83589 11.9999L17.3064 20.4689C17.4472 20.6097 17.5263 20.8007 17.5263 20.9999C17.5263 21.199 17.4472 21.39 17.3064 21.5309C17.1656 21.6717 16.9746 21.7508 16.7754 21.7508C16.5762 21.7508 16.3852 21.6717 16.2444 21.5309L7.24439 12.5309C7.17454 12.4612 7.11913 12.3784 7.08132 12.2873C7.04351 12.1962 7.02405 12.0985 7.02405 11.9999C7.02405 11.9012 7.04351 11.8035 7.08132 11.7124C7.11913 11.6213 7.17454 11.5385 7.24439 11.4689L16.2444 2.46888C16.3141 2.39903 16.3968 2.34362 16.4879 2.30581C16.5791 2.268 16.6767 2.24854 16.7754 2.24854C16.874 2.24854 16.9717 2.268 17.0628 2.30581C17.154 2.34362 17.2367 2.39903 17.3064 2.46888Z"
              fill="#A0FC6E"
            />
          </svg>
        </div>

        <div className="avatar-container">
          <img
            src="https://png.pngtree.com/png-vector/20190710/ourmid/pngtree-user-vector-avatar-png-image_1541962.jpg"
            alt="image description"
          />
        </div>
        <h3 className="name">Nome Atleta</h3>
        <h5 className="top-nav attivata" onClick={() => onButtonClick()}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 1920 1920"
            fill="#a0fc6e"
          >
            <g fillRule="evenodd">
              <path d="M106.667 267H320v213.333h853.33V267h213.34v160h106.66V160.333h-329.1C1142.26 98.19 1083 53.667 1013.33 53.667H480c-69.665 0-128.931 44.523-150.896 106.666H0V1867h1493.33v-320h-106.66v213.33H106.667V267zm320 106.667v-160c0-29.456 23.878-53.334 53.333-53.334h533.33c29.46 0 53.34 23.878 53.34 53.334v160H426.667z"></path>
              <path d="M1677.57 528.309l225.88 225.883c22.02 22.023 22.02 57.713 0 79.849L1225.8 1511.69c-10.62 10.5-24.96 16.49-39.98 16.49H959.937c-31.171 0-56.47-25.3-56.47-56.47v-225.89c0-15.02 5.986-29.36 16.489-39.86L1597.6 528.309c22.14-22.136 57.83-22.136 79.97 0zm-155.41 235.144l146.03 146.033 115.43-115.426-146.04-146.033-115.42 115.426zm-505.75 651.787h146.03l425.9-425.9-146.03-146.038-425.9 425.898v146.04z"></path>
            </g>
          </svg>{" "}
          <span className="span-green">Attività</span>
        </h5>
        <h5 className="top-nav">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              stroke="#fff"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M3 12h6l3-7 2 13.5 3.5-6.5h4"
            ></path>
          </svg>
          <span>MISURAZIONI</span>
        </h5>
        <h5 className="top-nav">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              fill="#fff"
              fillRule="evenodd"
              d="M3.5 12a8.5 8.5 0 118.5 8.5 8.455 8.455 0 01-4.542-1.314 1 1 0 00-.821-.113l-2.88.859 1.085-2.537a1 1 0 00-.07-.92A8.456 8.456 0 013.5 12zM12 1.5C6.201 1.5 1.5 6.201 1.5 12c0 1.838.473 3.568 1.305 5.073L1.08 21.107a1 1 0 001.206 1.351l4.5-1.342A10.456 10.456 0 0012 22.5c5.799 0 10.5-4.701 10.5-10.5S17.799 1.5 12 1.5zm2.293 12.682l-1.315.926A9.338 9.338 0 0111 13.585a10.202 10.202 0 01-1.624-2.114l.835-.709a1 1 0 00.236-1.232l-1.064-2a1 1 0 00-1.54-.283l-.316.275c-.759.662-1.208 1.75-.836 2.852A12.049 12.049 0 009.586 15c1.813 1.813 3.655 2.527 4.733 2.805.868.223 1.689-.076 2.268-.548l.591-.482a1 1 0 00-.12-1.634l-1.678-1a1 1 0 00-1.088.041z"
              clipRule="evenodd"
            ></path>
          </svg>
          <span>CONTATTA</span>
        </h5>
      </div> */}
      <CoachHeader />
      <div className="admin-atletet-step-five-container">
        <div className="admin-atletet-step-five-card-one">
          <AdminFoodPlan type="coachNutrition" />
        </div>
        <CreateNoteModal
          isOpen={openModal}
          onClose={() => setOpenModal(false)}
          token={token}
          selectedNote={selectedNote}
          onSave={handleNoteSave}
          // tableArr={athelets}
          coachAthelete
          userId={state?.id}
        />
        <div className="admin-atletet-step-five-card-two">
          <div className="admin-notes-width">
            <div className="notes-group">
              {/* <div className="notes width">
                <div className=" title-para">
                  <p className=" ">NOTE</p>
                </div>
                {notes.map((note, index) => (
                  <NoteCard
                    key={index}
                    title={note.title}
                    description={note.description}
                    tags={note.tags}
                  />
                ))}
                <a href="#" className="button-default-no-icon noteButton">
                  <img
                    src={plusIcon}
                    alt="plus icon"
                    style={{ width: "25px", height: "25px" }}
                  />
                  <p>aggiungi nuova</p>
                </a>
              </div> */}
              <DeleteModal
                isOpen={confirmationModal}
                onCancel={() => setConfirmationModal(false)}
                onConfirm={handleDelete}
                message="Sei sicuro di voler eliminare questa nota?"
                isLoading={isLoading}
              />
              <div className="right-sidebar">
                <div className="sub---title">Note</div>
                {notes?.length > 0
                  ? notes?.map((activity, index) => (
                      <TaskEditor
                        key={index}
                        title={activity.title}
                        date={activity.date}
                        description={activity.description}
                        handleEditClick={handleEditClick}
                        value={activity}
                        handleDelete={() =>
                          setConfirmationModal(activity?.uuid)
                        }
                      />
                    ))
                  : "essuna nota trovat"}
                <a
                  // href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    setOpenModal(true);
                    setSelectedNote(null);
                  }}
                  className="button-default-no-icon"
                >
                  <img src={plusIcon} alt="plus icon" />
                  <p>aggiungi nuova</p>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CoachAtheletaStepSix;
