import React, { useEffect, useState } from "react";
import AdminTranining from "./AdminTranining";
import plusIcon from "../../assets/img/icons/plus-black.svg";
import NoteCard from "./NotesCard";
import AtletiHeader from "../Reuseable/AtletiHeader";
import axios from "axios";
import { BASE_URL } from "../../assets/constants";
import editIcon from "../../assets/img/icons/edit.svg";
import deleteIcon from "../../assets/img/icons/delete.svg";
import calendarIcon from "../../assets/img/icons/calendar.svg";
import CalendarioModal from "../CalendarioModal";
import CreateNoteModal from "../CreateNoteModal";
import { useLocation } from "react-router-dom";
import DeleteModal from "../DeleteModal";

const TaskEditorTwo = ({
  title,
  description,
  date,
  handleEditClick,
  handleDeleteClick,
  loader,
}) => {
  const formatDate = (dateString) => {
    if (!dateString) {
      return "Invalid Date";
    }

    const [day, month, year] = dateString.split("/");

    const formattedDate = `${year}-${month}-${day}`;
    const parsedDate = new Date(formattedDate);

    if (isNaN(parsedDate)) {
      console.error("Invalid Date:", dateString);
      return "Invalid Date";
    }

    const weekday = parsedDate.toLocaleDateString("en-US", { weekday: "long" });
    const monthShort = parsedDate.toLocaleDateString("en-US", {
      month: "short",
    });

    return `${weekday}, ${day} ${monthShort}`;
  };

  return (
    <>
      {loader ? (
        <p>Loading</p>
      ) : (
        <div className="task-editor">
          <div className="title">
            {title}
            <div>
              <img src={editIcon} alt="edit icon" onClick={handleEditClick} />
              <img
                src={deleteIcon}
                alt="delete icon"
                onClick={handleDeleteClick}
                style={{
                  marginLeft: "10px",
                  cursor: "pointer",
                  width: "20px",
                  height: "20px",
                  filter: "invert(100%) brightness(100%)",
                }}
              />
            </div>
          </div>
          <div className="task-body">
            <img
              src={calendarIcon}
              alt="calendar icon"
              style={{ marginRight: "10px" }}
            />
            <label>{formatDate(date)}</label>
          </div>
          <div className="description">{description}</div>
        </div>
      )}
    </>
  );
};

const AdminAtheletStepFive = ({ onClick, onButtonClick }) => {
  const { state } = useLocation();
  const [loading, setLoading] = useState(true);
  const [notes, setNotes] = useState([]);
  const [selectedNote, setSelectedNote] = useState(null);
  const [isAddModal, setIsAddModal] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const adminData = JSON.parse(localStorage.getItem("adminData"));

  const token = adminData?.data?.token;

  const fetchNotes = async () => {
    try {
      const response = await axios.get(`${BASE_URL}admin/note/athlete/all`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setNotes(response?.data?.data || []);
    } catch (error) {
      console.error("Error fetching athlete notes:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchNotes();
  }, []);
  const handleEditClick = (note) => {
    setModalOpen(true);
    setSelectedNote(note);
  };

  const handleDeleteClick = async (noteId) => {
    setIsLoading(true);

    try {
      const token = adminData?.data?.token;

      await axios.delete(
        `${BASE_URL}admin/note/athlete/delete/${confirmationModal}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      fetchNotes();
      setConfirmationModal(false);
    } catch (error) {
      console.error("Error deleting note:", error);
    }
    setIsLoading(false);
  };
  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleModalSave = () => {
    fetchNotes();
    setModalOpen(false);
  };

  const handleCloseNoteModal = () => {
    setIsAddModal(false);
  };

  const handleNoteSave = () => {
    fetchNotes();
    setIsAddModal(false);
  };
  return (
    <div>
      <AtletiHeader />
      <div className="admin-atletet-step-five-container">
        <div className="admin-atletet-step-five-card-one">
          <AdminTranining />
        </div>
        <DeleteModal
          isOpen={confirmationModal}
          onCancel={() => setConfirmationModal(false)}
          onConfirm={handleDeleteClick}
          message="Sei sicuro di voler eliminare questa nota?"
          isLoading={isLoading}
        />
        <div className="admin-atletet-step-five-card-two">
          <div className="admin-notes-width">
            <div className="notes-group">
              <div className="notes width">
                <div className=" title-para">
                  <p className=" ">NOTE</p>
                </div>
                {notes.length > 0 ? (
                  notes?.map((note) => (
                    <TaskEditorTwo
                      key={note.id}
                      title={note.title}
                      date={new Date(note.date).toLocaleDateString()}
                      description={note.description}
                      loader={loading}
                      handleEditClick={() => handleEditClick(note)}
                      handleDeleteClick={() => setConfirmationModal(note.uuid)}
                    />
                  ))
                ) : (
                  <div style={{ alignSelf: "center" }}>
                    Nessuna nota trovata
                  </div>
                )}
                <CalendarioModal
                  isOpen={isModalOpen}
                  onClose={handleCloseModal}
                  token={token}
                  onSave={handleModalSave}
                  selectedNote={selectedNote}
                />
                <a
                  href=""
                  className="button-default-no-icon noteButton"
                  onClick={(e) => {
                    e.preventDefault();
                    setIsAddModal(true);
                  }}
                >
                  <img
                    src={plusIcon}
                    alt="plus icon"
                    style={{ width: "25px", height: "25px" }}
                  />
                  <p>aggiungi nuova</p>
                </a>
                <CreateNoteModal
                  isOpen={isAddModal}
                  onClose={handleCloseNoteModal}
                  token={token}
                  onSave={handleNoteSave}
                  userId={state?.id}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminAtheletStepFive;
