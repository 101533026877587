import React, { useEffect, useState } from "react";
import editIcon from "../assets/img/icons/edit.svg";
import NoteCard from "../components/Admin/NotesCard";

import backArrow from "../assets/img/icons/arrow-left-active.svg";
import plusIcon from "../assets/img/icons/plus-black.svg";
import "../css/note.css";
// import Sidebar from "../components/sidebar";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
// import { LeftArrowIcon } from "../../src/components/Reuseable/Icons";
import { LeftArrowIcon } from "../components/Reuseable/Icons";
import CreateNoteModal from "../components/CreateNoteModal";
import { BASE_URL } from "../assets/constants";
import axios from "axios";

// const NoteCard = ({ title, description, tags }) => {
//   const [isEditing, setIsEditing] = useState(false);
//   const [originalDescription, setOriginalDescription] = useState(description);
//   const [editedDescription, setEditedDescription] = useState(description);
//   const navigate = useNavigate();
//   const token = localStorage.getItem("token");

//   const handleEditClick = () => {
//     setIsEditing(true);
//     setOriginalDescription(editedDescription);
//   };

//   const handleCancelClick = () => {
//     setEditedDescription(originalDescription);
//     setIsEditing(false);
//   };

//   const handleSaveClick = () => {
//     setIsEditing(false);
//   };

//   return (
//     <div className="card" style={{ marginTop: "20px" }}>
//       <div className="title">
//         {title} <img src={editIcon} alt="edit icon" onClick={handleEditClick} />
//       </div>
//       <div className="description">
//         {isEditing ? (
//           <>
//             <input
//               className="inputText"
//               type="text"
//               value={editedDescription}
//               onChange={(e) => setEditedDescription(e.target.value)}
//             />
//             <button onClick={handleSaveClick}>Save</button>
//             <button onClick={handleCancelClick}>Cancel</button>
//           </>
//         ) : (
//           <p>{editedDescription}</p>
//         )}
//       </div>
//       <div className="tags">
//         {tags.map((tag, index) => (
//           <div key={index} className={`tag ${tag.className}`}>
//             {tag.content}
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

const NoteContent = () => {
  const navigate = useNavigate();
  const adminData = JSON.parse(localStorage.getItem("adminData"));
  const token = adminData?.data?.token;

  const [isAddModal, setIsAddModal] = useState(false);
  const [selectedNote, setSelectedNote] = useState(null);
  const [notes, setNotes] = useState([]);

  const handleBackClick = () => {
    navigate(-1);
  };

  const handleCloseNoteModal = () => {
    fetchNotes();
    setIsAddModal(false);
  };

  const fetchNotes = async () => {
    try {
      let response = await axios.get(`${BASE_URL}admincoach/admin-coach/note`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setNotes(response?.data?.data);
    } catch (error) {}
  };

  const handleNoteSave = () => {
    fetchNotes();
    setIsAddModal(false);
  };

  useEffect(() => {
    fetchNotes();
  }, []);
  return (
    <div>
      <div
        className="topBar"
        style={{ borderBottom: "2px solid #a0fc6e", paddingBottom: "10px" }}
      >
        <span>Note</span>
      </div>
      <div className="admin-notes-width note-margin">
        <div className="notes-group">
          <div className="notes width">
            {notes?.map((note, index) => (
              <NoteCard
                key={index}
                title={note.title}
                id={note?.id}
                description={note.description}
                date={note.date}
                setSelectedNote={setSelectedNote}
                setIsAddModal={setIsAddModal}
                adminCoach
                // tags={note.tags}
              />
            ))}
            <a
              onClick={(e) => {
                e.preventDefault();
                setIsAddModal(true);
                setSelectedNote(null);
              }}
              className="button-default-no-icon noteButton"
            >
              <img
                src={plusIcon}
                alt="plus icon"
                style={{ width: "25px", height: "25px" }}
              />
              <p>aggiungi nuova</p>
            </a>
            <CreateNoteModal
              isOpen={isAddModal}
              onClose={handleCloseNoteModal}
              token={token}
              onSave={handleNoteSave}
              selectedNote={selectedNote}
              adminCoachNote
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NoteContent;
