import { useState } from "react";
// CSS
import "./OTPScreen.css";

// Components
import Header from "../Layout/Header";
import Loader from "../Users/globalLoader";
import axios from "axios";
import { notify } from "../util/notify";
import { useLocation, useNavigate } from "react-router-dom";
import { BASE_URL } from "../../assets/constants";
import { IoMdEye } from "react-icons/io";
import { IoMdEyeOff } from "react-icons/io";

const OTPScreen = () => {
  const [otp, setOtp] = useState(["", "", "", ""]);
  const [loading, setLoading] = useState(false);
  const [showReset, setShowReset] = useState(false);
  const [error, setError] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showCPassword, setShowCPassword] = useState(false);
  const navigate = useNavigate();
  const { state: email } = useLocation();

  const handleChange = (element, index) => {
    if (isNaN(element.value)) return false;
    setOtp([...otp.map((d, idx) => (idx === index ? element.value : d))]);

    if (element.nextSibling) {
      element.nextSibling.focus();
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const email = localStorage.getItem("uEmail");

    if (showReset) {
      // Handle password reset
      if (!password || !confirmPassword) {
        notify(true, "È richiesta la password e la conferma della password");
        return;
      } else if (password !== confirmPassword) {
        notify(true, "La password e la password di conferma non corrispondono");
        return;
      } else if (password?.length < 8 || confirmPassword?.length < 8) {
        notify(true, "La password deve contenere almeno 8 caratteri");
        return;
      }

      setError("");
      setLoading(true);

      try {
        const response = await axios.post(
          `${BASE_URL}${email}/${otp.join("")}/password-update`,
          {
            password,
            password_confirmation: confirmPassword,
          }
        );

        if (response.status === 200) {
          notify(false, response?.data?.message);
          navigate("/login");
        }
      } catch (error) {
        console.log(error);
        setError(error.response?.data?.message || "Server error, try again.");
      } finally {
        setLoading(false);
      }
    } else {
      setLoading(true);
      try {
        const response = await axios.post(`${BASE_URL}${email}/verify-otp`, {
          otp: otp.join(""),
        });

        if (response.status === 200) {
          setShowReset(true);
          notify(false, "OTP verified, please reset your password.");
        }
      } catch (error) {
        console.log(error);
        notify(true, error.response?.data?.message);
        setError(error.response?.data?.message || "Server error, try again.");
      } finally {
        setLoading(false);
      }
    }
  };
  const handleResend = async () => {
    setIsLoading(true);
    try {
      const res = await axios.post(`${BASE_URL}forgot-password`, {
        email: email,
      });
      if (res?.status === 200) {
        notify(false, res?.data?.message);
      }
    } catch (error) {
      if (error.response) {
        notify(true, `Error: ${error.response.data.message}`, 3000);
      } else if (error.request) {
        notify("No response received from server", 3000);
      } else {
        console.log("Error message:", error.message);
        notify(true, error.message, 3000);
      }
    }
    setIsLoading(false);
  };

  const spinnerStyle = {
    border: "3px solid #f3f3f3",
    borderTop: "3px solid #a0fc6e",
    borderRadius: "50%",
    width: "30px",
    height: "30px",
    animation: "spin 1s linear infinite",
  };

  return (
    <>
      <Header />
      {loading && <Loader />}
      <div
        className="main-container centered"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div className="otp-content">
          <div className="headline">
            {showReset ? "Reset Your Password" : "Inserisci il codice OTP"}
          </div>
          <form onSubmit={handleSubmit}>
            {!showReset ? (
              <>
                <div className="otp-inputs">
                  {otp.map((data, index) => (
                    <input
                      className="otp-field"
                      type="text"
                      name="otp"
                      maxLength="1"
                      key={index}
                      value={data}
                      onChange={(e) => handleChange(e.target, index)}
                      onFocus={(e) => e.target.select()}
                    />
                  ))}
                </div>
              </>
            ) : (
              <>
                <div className="form-div" style={{ position: "relative" }}>
                  <div className="title">New Password</div>
                  <input
                    type={showPassword ? "text" : "password"}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Enter new password"
                    className="input input-control"
                    style={{ width: "20em" }}
                  />

                  <div style={{ position: "absolute", top: 30, right: 10 }}>
                    {showPassword ? (
                      <IoMdEyeOff
                        style={{ cursor: "pointer" }}
                        onClick={() => setShowPassword(false)}
                      />
                    ) : (
                      <IoMdEye
                        style={{ cursor: "pointer" }}
                        onClick={() => setShowPassword(true)}
                      />
                    )}
                  </div>
                </div>
                <div className="form-div" style={{ position: "relative" }}>
                  <div className="title">Confirm Password</div>
                  <input
                    type={showCPassword ? "text" : "password"}
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    placeholder="Confirm new password"
                    className="input input-control"
                  />
                  <div style={{ position: "absolute", top: 30, right: 10 }}>
                    {showCPassword ? (
                      <IoMdEyeOff
                        style={{ cursor: "pointer" }}
                        onClick={() => setShowCPassword(false)}
                      />
                    ) : (
                      <IoMdEye
                        style={{ cursor: "pointer" }}
                        onClick={() => setShowCPassword(true)}
                      />
                    )}
                  </div>
                </div>
              </>
            )}
            {!showReset && (
              <p>
                If you have not received the OTP code, click Resend to get a new
                one
              </p>
            )}
            <div className="d-flex text-right">
              {!showReset &&
                (isLoading ? (
                  <div style={{ marginLeft: "auto" }}>
                    <div style={spinnerStyle}></div>
                    <style>
                      {`
              @keyframes spin {
                0% { transform: rotate(0deg); }
                100% { transform: rotate(360deg); }
              }
            `}
                    </style>
                  </div>
                ) : (
                  <button
                    className="button-default-no-icon "
                    disabled={loading}
                    style={{
                      marginTop: 20,
                      width: "150px",
                      marginLeft: "auto",
                    }}
                    type="button"
                    onClick={handleResend}
                  >
                    Invia nuovamente
                  </button>
                ))}
            </div>
            {/* {error && (
              <div
                style={{
                  color: "red",
                  fontSize: "15px",
                  marginTop: "10px",
                }}
              >
                {error}
              </div>
            )} */}
            <button
              type="submit"
              className="button-default-no-icon"
              disabled={loading}
              style={{ marginTop: 20 }}
            >
              {loading
                ? showReset
                  ? "Resetting..."
                  : "Verifica in corso..."
                : showReset
                ? "Reset Password"
                : "Verifica"}
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default OTPScreen;
