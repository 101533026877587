import React, { useState } from "react";

const DropDownForFoodPlan = ({
  content,
  className,
  options,
  onSelect,
  adminFood,
  value,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState(
    adminFood ? [] : [content]
  );

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option) => {
    if (adminFood) {
      setSelectedOptions((prevSelected) => {
        if (prevSelected.includes(option)) {
          return prevSelected.filter((item) => item !== option);
        } else {
          return [...prevSelected, option];
        }
      });
    } else {
      setSelectedOptions([option]);
    }
    onSelect(option);
    setIsOpen(false);
  };

  const displayValue = adminFood
    ? selectedOptions.join(", ") || "Select days"
    : selectedOptions[0] || "Select";

  return (
    <div className="small-dropdown-container">
      <button
        type="button"
        className={`small-dropdown-btn ${className}`}
        style={{ width: "100%" }}
        onClick={toggleDropdown}
      >
        {value ? value : displayValue}
        <span
          className={`small-dropdown-icon ${isOpen ? "open" : ""}`}
          style={{ marginLeft: "10px" }}
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M2.469 6.969C2.53867 6.89915 2.62143 6.84374 2.71255 6.80593C2.80367 6.76812 2.90135 6.74866 3 6.74866C3.09865 6.74866 3.19633 6.76812 3.28745 6.80593C3.37857 6.84374 3.46133 6.89915 3.531 6.969L12 15.4395L20.469 6.969C20.5387 6.89927 20.6215 6.84395 20.7126 6.80621C20.8037 6.76847 20.9014 6.74905 21 6.74905C21.0986 6.74905 21.1963 6.76847 21.2874 6.80621C21.3785 6.84395 21.4613 6.89927 21.531 6.969C21.6007 7.03873 21.656 7.12151 21.6938 7.21262C21.7315 7.30373 21.7509 7.40138 21.7509 7.5C21.7509 7.59861 21.7315 7.69626 21.6938 7.78737C21.656 7.87848 21.6007 7.96127 21.531 8.031L12.531 17.031C12.4613 17.1008 12.3786 17.1563 12.2874 17.1941C12.1963 17.2319 12.0986 17.2513 12 17.2513C11.9013 17.2513 11.8037 17.2319 11.7125 17.1941C11.6214 17.1563 11.5387 17.1008 11.469 17.031L2.469 8.031C2.39915 7.96133 2.34374 7.87857 2.30593 7.78745C2.26812 7.69633 2.24866 7.59865 2.24866 7.5C2.24866 7.40135 2.26812 7.30366 2.30593 7.21255C2.34374 7.12143 2.39915 7.03867 2.469 6.969Z"
              fill="#F6F6F6"
            />
          </svg>
        </span>
      </button>
      {isOpen && (
        <div className="small-dropdown-menu-ul-food">
          <ul>
            {options.map((option, index) => (
              <li key={index} onClick={() => handleOptionClick(option)}>
                {option}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default DropDownForFoodPlan;
