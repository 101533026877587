import React, { useEffect, useState } from "react";
import editIcon from "../../assets/img/icons/edit.svg";
import Sidebar from "../sidebar";
import AppCalendarioAtleta from "../../components/Coach/AppCalendarioAtleta";
import "../../css/coachCalendar.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import LineChart from "../Reuseable/LineChart";
import ResponsiveHeader from "../Reuseable/ResponsiveHeader";
import Dropdown from "../Reuseable/Dropdown";
import { BASE_URL } from "../../assets/constants";
import axios from "axios";

export default function CoachAthleteStepOne({
  onButtonClick,
  onClick,
  onButtonClickSix,
  onButtonClickSeven,
}) {
  const [activeOption, setActiveOption] = useState("Atleti");
  const [calenderRightData, setCalenderRightData] = useState(null);
  const [selectedOption, setSelectedOption] = useState("Massa grassa");
  const [monthlySelectedOption, setMonthlySelectedOption] = useState("mensile");

  const adminData = JSON.parse(localStorage.getItem("adminData"));
  const token = adminData?.data?.token;

  const options = ["nuova settimana", "duplica", "elimina"];
  const { state } = useLocation();
  const navigate = useNavigate();

  const handleSelect = (option) => {
    setSelectedOption(option);
  };

  const handleSelectMonthly = (option) => {
    setSelectedOption(option);
  };

  const handleSidebarClick = (option) => {
    setActiveOption(option);
  };
  const dropDownClick = (event) => {
    // console.log(event);
  };

  const months = [
    "Gennaio",
    "Febbraio",
    "Marzo",
    "Aprile",
    "Maggio",
    "Giugno",
    "Luglio",
    "Agosto",
    "Settembre",
    "Ottobre",
    "Novembre",
    "Dicembre",
  ];
  const monthActive = new Date().getMonth();
  const yearActive = new Date().getFullYear();
  const dateToday = new Date().toISOString().split("T")[0];

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = String(date.getFullYear()).slice(-2);
    return `${day}-${month}-${year}`;
  };

  const handleNavigate = () => {
    navigate("/coach/atleti/nutrizione", { state });
  };
  const naviagteSession = () => {
    navigate("/coach/atleti/allenamento", { state });
  };
  const fetchCalenderRight = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}coach/calender/${state?.id}/right`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setCalenderRightData(response?.data);
    } catch (error) {
      console.error("Error fetching athlete notes:", error);
    } finally {
    }
  };

  useEffect(() => {
    fetchCalenderRight();
  }, [state]);

  return (
    <div>
      <div className="admin-coach-step-header-container">
        <div className="admin-coach-step-two-container-atleti">
          <Link to="/coach/home">
            <div>
              <svg
                width="25"
                height="24"
                viewBox="0 0 25 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M17.3064 2.46888C17.3762 2.53854 17.4316 2.62131 17.4695 2.71243C17.5073 2.80354 17.5267 2.90122 17.5267 2.99988C17.5267 3.09853 17.5073 3.19621 17.4695 3.28733C17.4316 3.37844 17.3762 3.46121 17.3064 3.53088L8.83589 11.9999L17.3064 20.4689C17.4472 20.6097 17.5263 20.8007 17.5263 20.9999C17.5263 21.199 17.4472 21.39 17.3064 21.5309C17.1656 21.6717 16.9746 21.7508 16.7754 21.7508C16.5762 21.7508 16.3852 21.6717 16.2444 21.5309L7.24439 12.5309C7.17454 12.4612 7.11913 12.3784 7.08132 12.2873C7.04351 12.1962 7.02405 12.0985 7.02405 11.9999C7.02405 11.9012 7.04351 11.8035 7.08132 11.7124C7.11913 11.6213 7.17454 11.5385 7.24439 11.4689L16.2444 2.46888C16.3141 2.39903 16.3968 2.34362 16.4879 2.30581C16.5791 2.268 16.6767 2.24854 16.7754 2.24854C16.874 2.24854 16.9717 2.268 17.0628 2.30581C17.154 2.34362 17.2367 2.39903 17.3064 2.46888Z"
                  fill="#A0FC6E"
                />
              </svg>
            </div>
          </Link>

          <div className="avatar-container">
            <img
              src="https://png.pngtree.com/png-vector/20190710/ourmid/pngtree-user-vector-avatar-png-image_1541962.jpg"
              alt="image description"
            />
          </div>
          <h3 className="name">{state?.name}</h3>
          <h5 className="top-nav attivata">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 1920 1920"
              fill="#a0fc6e"
            >
              <g fillRule="evenodd">
                <path d="M106.667 267H320v213.333h853.33V267h213.34v160h106.66V160.333h-329.1C1142.26 98.19 1083 53.667 1013.33 53.667H480c-69.665 0-128.931 44.523-150.896 106.666H0V1867h1493.33v-320h-106.66v213.33H106.667V267zm320 106.667v-160c0-29.456 23.878-53.334 53.333-53.334h533.33c29.46 0 53.34 23.878 53.34 53.334v160H426.667z"></path>
                <path d="M1677.57 528.309l225.88 225.883c22.02 22.023 22.02 57.713 0 79.849L1225.8 1511.69c-10.62 10.5-24.96 16.49-39.98 16.49H959.937c-31.171 0-56.47-25.3-56.47-56.47v-225.89c0-15.02 5.986-29.36 16.489-39.86L1597.6 528.309c22.14-22.136 57.83-22.136 79.97 0zm-155.41 235.144l146.03 146.033 115.43-115.426-146.04-146.033-115.42 115.426zm-505.75 651.787h146.03l425.9-425.9-146.03-146.038-425.9 425.898v146.04z"></path>
              </g>
            </svg>{" "}
            <span className="span-green">Attività</span>
          </h5>
          {/* <Link to={}> */}
          <h5
            className="top-nav"
            onClick={() => {
              navigate(`/coach/atleti/misurazioni/${state?.id}`, { state });
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="#fff"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M3 12h6l3-7 2 13.5 3.5-6.5h4"
              ></path>
            </svg>
            <span>MISURAZIONI</span>
          </h5>
          {/* </Link> */}
          {/* <h5 className="top-nav">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                fill="#fff"
                fillRule="evenodd"
                d="M3.5 12a8.5 8.5 0 118.5 8.5 8.455 8.455 0 01-4.542-1.314 1 1 0 00-.821-.113l-2.88.859 1.085-2.537a1 1 0 00-.07-.92A8.456 8.456 0 013.5 12zM12 1.5C6.201 1.5 1.5 6.201 1.5 12c0 1.838.473 3.568 1.305 5.073L1.08 21.107a1 1 0 001.206 1.351l4.5-1.342A10.456 10.456 0 0012 22.5c5.799 0 10.5-4.701 10.5-10.5S17.799 1.5 12 1.5zm2.293 12.682l-1.315.926A9.338 9.338 0 0111 13.585a10.202 10.202 0 01-1.624-2.114l.835-.709a1 1 0 00.236-1.232l-1.064-2a1 1 0 00-1.54-.283l-.316.275c-.759.662-1.208 1.75-.836 2.852A12.049 12.049 0 009.586 15c1.813 1.813 3.655 2.527 4.733 2.805.868.223 1.689-.076 2.268-.548l.591-.482a1 1 0 00-.12-1.634l-1.678-1a1 1 0 00-1.088.041z"
                clipRule="evenodd"
              ></path>
            </svg>
            <span>CONTATTA</span>
          </h5> */}
        </div>
      </div>
      <ResponsiveHeader />
      <div className="content-columns">
        <div className="content-center">
          <AppCalendarioAtleta
            months={months}
            monthActive={monthActive}
            yearActive={yearActive}
            dateToday={dateToday}
            onClick={onClick}
            onButtonClick={onButtonClick}
            onButtonClickSeven={onButtonClickSeven}
          />
        </div>
        <div className="content-right-sidebar mt-side-bar">
          {/* <div className="card">
            <div className="title">
              <img src={editIcon} alt="edit" />
              abbonamento
            </div>
            <div className="description">
              <div className="nome">Piano trimestrale</div>
              <div className="ddmm">01/01/2023 - 31/03/2023</div>
            </div>
          </div> */}
          {/* <Link to="/coach/atleti/nutrizione"> */}
          <div className="card" onClick={handleNavigate}>
            <div className="top">
              <div className="title">
                <img src={editIcon} alt="edit" />
                piano alimentare
              </div>
              <div className="description">
                <div className="nome">Definizione</div>
                <div className="ddmm">
                  {calenderRightData?.start_date
                    ? formatDate(calenderRightData?.start_date)
                    : "--"}{" "}
                  -{" "}
                  {calenderRightData?.end_date
                    ? formatDate(calenderRightData?.end_date)
                    : "--"}
                </div>
              </div>
            </div>
            <div className="bottom-flex">
              <div className="left">
                <div className="title">giorni on</div>
                <div className="description">
                  {calenderRightData?.on_calories || "--"} kcal
                </div>
              </div>
              <div className="right">
                <div className="title">giorni off</div>
                <div className="description">
                  {calenderRightData?.off_calories || "--"} kcal
                </div>
              </div>
            </div>
          </div>
          {/* </Link> */}
          {/* <Link to="/coach/atleti/allenamento"> */}
          <div className="card" onClick={naviagteSession}>
            <div className="top">
              <div className="title">
                <img src={editIcon} alt="edit" />
                programma di allenamento
              </div>
              <div className="description">
                <div className="nome">Definizione massa muscolare</div>
                <div className="ddmm">
                  {calenderRightData?.duration || "--"} settimane
                </div>
              </div>
            </div>
            <div className="bottom column">
              <div className="title">sessioni</div>
              <div className="tags tag-group">
                {calenderRightData?.training_session &&
                  calenderRightData?.training_session.map((value, index) => {
                    return (
                      <div className="tag outline" key={index}>
                        {value}
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
          {/* </Link> */}
          <div className="total-group">
            <div className="total-box state-good">
              <div className="title">ADERENZA</div>
              <div className="values">
                <div className="score">
                  {calenderRightData?.Adhesion || "--"}
                </div>
                <div className="percent"></div>
              </div>
            </div>
            <div className="total-box state-good">
              <div className="title">COSTANZA</div>
              <div className="values">
                <div className="score">
                  {calenderRightData?.costanza || "--"}
                </div>
                <div className="percent"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
