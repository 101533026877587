import React from "react";
import AdminAthelet from "../../components/Admin/AdminAthelet";

const AdminAthleti = () => {
  return (
    <div>
      <AdminAthelet />
    </div>
  );
};

export default AdminAthleti;
