import React, { useRef, useState } from "react";
import calendarIcon from "../assets/img/icons/calendar.svg";

import "../css/modal.css";
import axios from "axios";
import { BASE_URL } from "../assets/constants";
import { notify } from "./util/notify";
import { IoMdEye } from "react-icons/io";
import { IoMdEyeOff } from "react-icons/io";

const AdminCoachModal = ({
  closedHandler,
  type,
  calender,
  setFeedbackValues,
  feedbackValues,
  storeFeedback,
}) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [errors, setErrors] = useState({});
  const [phone, setPhone] = useState("");
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [profileImage, setProfileImage] = useState();
  const [imageName, setImageName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [imageError, setImageError] = useState();

  const inputRef = useRef(null);

  const handleDivClick = () => {
    inputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setImageName(file?.name);
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => {
        setProfileImage(reader?.result);
        setImageError(false);
      };

      reader.onerror = (error) => {
        console.error("Error reading file:", error);
      };
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!profileImage) {
      setImageError("L'immagine è obbligatoria..!");
      return;
    }
    setLoading(true);

    const coachData = {
      profile: profileImage,
      name,
      email,
      password,
      phone,
      password_confirmation: passwordConfirmation,
    };

    const adminData = JSON.parse(localStorage.getItem("adminData"));
    const token = adminData?.data?.token;

    try {
      const response = await axios.post(
        `${BASE_URL}admin/create/coach`,
        coachData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        notify(false, "Coach Created Successfully");

        setLoading(false);
        // alert("Coach Created Successfully");
        closedHandler();
      }
    } catch (error) {
      if (error?.response?.data?.error) {
        setErrors(error.response.data.error);
      } else {
        console.error("Unexpected error:", error);
        notify(true, "Failed to create coach");
        // alert("Failed to create coach");
      }
      setLoading(false);
    }
    setLoading(false);
  };

  const handleAthleteSubmit = async (e) => {
    e.preventDefault();
    if (!profileImage) {
      setImageError("L'immagine è obbligatoria..!");
      return;
    }
    const athleteData = {
      phone,
      profile: profileImage,
      name,
      email,
      password,
      password_confirmation: passwordConfirmation,
    };

    const adminData = JSON.parse(localStorage.getItem("adminData"));
    const token = adminData?.data?.token;
    setLoading(true);

    try {
      const response = await axios.post(
        `${BASE_URL}admin/create/athlete`,
        athleteData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        notify(false, "Athlete Created Successfully");

        closedHandler();
      }
    } catch (error) {
      console.log(error, "ERROR");
      if (error?.response?.data?.error) {
        setErrors(error.response.data.error);
      } else {
        console.error("Unexpected error:", error);
        notify(true, "Failed to create athlete");
        // alert("Failed to create athlete");
      }
    }
    setLoading(false);
  };
  const spinnerStyle = {
    border: "3px solid #f3f3f3",
    borderTop: "3px solid #a0fc6e",
    borderRadius: "50%",
    width: "30px",
    height: "30px",
    animation: "spin 1s linear infinite",
  };

  const containerStyle = {
    display: "flex",
    justifyContent: "center",
    marging: "0 auto",
    backgroundColor: "transparent",
  };

  return (
    <div className="modal-overlayy">
      {type === "admin-coach" ? (
        <div className="modal-content">
          <p className="modal-title">Nuovo Coach</p>
          <form onSubmit={handleSubmit}>
            <div className="form-div">
              <label>Nome</label>
              <input
                type="text"
                placeholder="John Doe"
                className="form-input"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              {errors.name && (
                <div
                  style={{
                    color: "red",
                    fontSize: "15px",
                    marginTop: "10px",
                  }}
                >
                  {errors.name[0]}
                </div>
              )}
            </div>
            <div className="form-div">
              <label>Email</label>
              <input
                type="email"
                placeholder="JohnDoe@gmail.com"
                className="form-input"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              {errors.email && (
                <div
                  style={{
                    color: "red",
                    fontSize: "15px",
                    marginTop: "10px",
                  }}
                >
                  {errors.email[0]}
                </div>
              )}
            </div>
            <div className="form-div">
              <label>Phone</label>
              <input
                type="number"
                placeholder="000-000-000"
                className="form-input"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
              {errors.phone && (
                <div
                  style={{
                    color: "red",
                    fontSize: "15px",
                    marginTop: "10px",
                  }}
                >
                  {errors.phone[0]}
                </div>
              )}
            </div>
            <div className="form-div" style={{ position: "relative" }}>
              <label>Password</label>
              <input
                type={showPassword ? "text" : "password"}
                placeholder="***********"
                className="form-input"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <div style={{ position: "absolute", bottom: 15, right: 10 }}>
                {showPassword ? (
                  <IoMdEyeOff
                    style={{ cursor: "pointer" }}
                    onClick={() => setShowPassword(false)}
                  />
                ) : (
                  <IoMdEye
                    style={{ cursor: "pointer" }}
                    onClick={() => setShowPassword(true)}
                  />
                )}
              </div>
              {errors.password && (
                <div
                  style={{
                    color: "red",
                    fontSize: "15px",
                    marginTop: "10px",
                  }}
                >
                  {errors.password[0]}
                </div>
              )}
            </div>
            <div className="form-div" style={{ position: "relative" }}>
              <label>Confirm Password</label>
              <input
                type={showConfirmPassword ? "text" : "password"}
                placeholder="***********"
                className="form-input"
                value={passwordConfirmation}
                onChange={(e) => setPasswordConfirmation(e.target.value)}
              />
              <div style={{ position: "absolute", bottom: 15, right: 10 }}>
                {showConfirmPassword ? (
                  <IoMdEyeOff
                    style={{ cursor: "pointer" }}
                    onClick={() => setShowConfirmPassword(false)}
                  />
                ) : (
                  <IoMdEye
                    style={{ cursor: "pointer" }}
                    onClick={() => setShowConfirmPassword(true)}
                  />
                )}
              </div>
            </div>
            <div style={{ width: "100%", marginTop: 10 }}>
              {profileImage ? (
                <div
                  style={{
                    width: "100%",
                    height: "40px",
                    border: "1px dashed grey",
                    // borderRadius: "100%",
                    background: "#A0FC6E",
                    marginBottom: "20px",
                    textAlign: "center",
                    paddingTop: 5,
                    cursor: "pointer",
                    color: "#000",
                  }}
                >
                  {imageName}
                </div>
              ) : (
                <>
                  <div
                    onClick={handleDivClick}
                    style={{
                      width: "100%",
                      height: "40px",
                      border: !profileImage && "1px dashed grey",
                      // borderRadius: "100%",
                      marginBottom: "20px",
                      textAlign: "center",
                      paddingTop: 5,
                      cursor: "pointer",
                    }}
                  >
                    Upload Image
                  </div>
                  {imageError && (
                    <div
                      style={{
                        color: "red",
                        fontSize: "15px",
                        marginTop: "10px",
                      }}
                    >
                      {imageError}
                    </div>
                  )}
                </>
              )}

              <input
                ref={inputRef}
                onChange={handleFileChange}
                type="file"
                style={{ display: "none" }}
                accept="image/*"
              />
            </div>
            {loading ? (
              <div style={containerStyle}>
                <div style={spinnerStyle}></div>
                <style>
                  {`
             @keyframes spin {
               0% { transform: rotate(0deg); }
               100% { transform: rotate(360deg); }
             }
           `}
                </style>
              </div>
            ) : (
              <button type="submit" className="modal-btn">
                Salva
              </button>
            )}
          </form>
          <div onClick={closedHandler} className="modal-close">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="15"
              height="15"
              viewBox="0 0 16 16"
            >
              <path
                fillRule="evenodd"
                fill="#fff"
                d="M0 14.545L1.455 16 8 9.455 14.545 16 16 14.545 9.455 8 16 1.455 14.545 0 8 6.545 1.455 0 0 1.455 6.545 8z"
              ></path>
            </svg>
          </div>
        </div>
      ) : type === "admin-atleta" ? (
        <div className="modal-content">
          <p>Nuovo Atleta</p>
          <form onSubmit={handleAthleteSubmit}>
            <div className="form-div">
              <label>Nome</label>
              <input
                type="text"
                placeholder="John Doe"
                className="form-input"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              {errors.name && (
                <div
                  style={{
                    color: "red",
                    fontSize: "15px",
                    marginTop: "10px",
                  }}
                >
                  {errors.name[0]}
                </div>
              )}
            </div>
            <div className="form-div">
              <label>Email</label>
              <input
                type="email"
                placeholder="JohnDoe@gmail.com"
                className="form-input"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              {errors.email && (
                <div
                  style={{
                    color: "red",
                    fontSize: "15px",
                    marginTop: "10px",
                  }}
                >
                  {errors.email[0]}
                </div>
              )}
            </div>
            <div className="form-div">
              <label>Phone</label>
              <input
                type="number"
                placeholder="000-000"
                className="form-input"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
              {errors.phone && (
                <div
                  style={{
                    color: "red",
                    fontSize: "15px",
                    marginTop: "10px",
                  }}
                >
                  {errors.phone[0]}
                </div>
              )}
            </div>
            <div className="form-div" style={{ position: "relative" }}>
              <label>Password</label>
              <input
                type={showPassword ? "text" : "password"}
                placeholder="***********"
                className="form-input"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <div style={{ position: "absolute", bottom: 15, right: 10 }}>
                {showPassword ? (
                  <IoMdEyeOff
                    style={{ cursor: "pointer" }}
                    onClick={() => setShowPassword(false)}
                  />
                ) : (
                  <IoMdEye
                    style={{ cursor: "pointer" }}
                    onClick={() => setShowPassword(true)}
                  />
                )}
              </div>
              {errors.password && (
                <div
                  style={{
                    color: "red",
                    fontSize: "15px",
                    marginTop: "10px",
                  }}
                >
                  {errors.password[0]}
                </div>
              )}
            </div>
            <div className="form-div" style={{ position: "relative" }}>
              <label>Confirm Password</label>
              <input
                type={showConfirmPassword ? "text" : "password"}
                placeholder="***********"
                className="form-input"
                value={passwordConfirmation}
                onChange={(e) => setPasswordConfirmation(e.target.value)}
              />
              <div style={{ position: "absolute", bottom: 15, right: 10 }}>
                {showConfirmPassword ? (
                  <IoMdEyeOff
                    style={{ cursor: "pointer" }}
                    onClick={() => setShowConfirmPassword(false)}
                  />
                ) : (
                  <IoMdEye
                    style={{ cursor: "pointer" }}
                    onClick={() => setShowConfirmPassword(true)}
                  />
                )}
              </div>
            </div>
            <div style={{ width: "100%", marginTop: 10 }}>
              {profileImage ? (
                <div
                  style={{
                    width: "100%",
                    height: "40px",
                    border: "1px dashed grey",
                    // borderRadius: "100%",
                    background: "#A0FC6E",
                    marginBottom: "20px",
                    textAlign: "center",
                    paddingTop: 5,
                    cursor: "pointer",
                    color: "#000",
                  }}
                >
                  {imageName}
                </div>
              ) : (
                <>
                  <div
                    onClick={handleDivClick}
                    style={{
                      width: "100%",
                      height: "40px",
                      border: !profileImage && "1px dashed grey",
                      // borderRadius: "100%",
                      marginBottom: "20px",
                      textAlign: "center",
                      paddingTop: 5,
                      cursor: "pointer",
                    }}
                  >
                    Upload Image
                  </div>
                  {imageError && (
                    <div
                      style={{
                        color: "red",
                        fontSize: "15px",
                        marginTop: "10px",
                      }}
                    >
                      {imageError}
                    </div>
                  )}
                </>
              )}

              <input
                ref={inputRef}
                onChange={handleFileChange}
                type="file"
                style={{ display: "none" }}
                accept="image/*"
              />
            </div>
            {loading ? (
              <div style={containerStyle}>
                <div style={spinnerStyle}></div>
                <style>
                  {`
              @keyframes spin {
                0% { transform: rotate(0deg); }
                100% { transform: rotate(360deg); }
              }
            `}
                </style>
              </div>
            ) : (
              <button type="submit" className="modal-btn">
                Salva
              </button>
            )}
          </form>
          <div onClick={closedHandler} className="modal-close">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="15"
              height="15"
              viewBox="0 0 16 16"
            >
              <path
                fillRule="evenodd"
                fill="#fff"
                d="M0 14.545L1.455 16 8 9.455 14.545 16 16 14.545 9.455 8 16 1.455 14.545 0 8 6.545 1.455 0 0 1.455 6.545 8z"
              ></path>
            </svg>
          </div>
        </div>
      ) : type === "admin-calendrio" ? (
        <div className="modal-content">
          <p>lunedì, 3 luglio</p>
          <div className="button-group">
            <a
              className="button-square strongblue btn-mar-bottom"
              style={{ cursor: "pointer" }}
            >
              allenamento Giorno on
            </a>

            <a
              className="button-square btn-mar-bottom softblue"
              style={{ cursor: "pointer" }}
            >
              nutrizione 1280kcal
            </a>

            <a
              className="button-square btn-mar-bottom"
              style={{ cursor: "pointer" }}
            >
              Affaticato
            </a>
            <div className="note-input">
              Note
              <input
                onChange={(e) =>
                  setFeedbackValues({
                    ...feedbackValues,
                    description: e.target.value,
                  })
                }
                value={feedbackValues?.description}
                type="text"
              />
            </div>
            <a href="#" onClick={storeFeedback} className="modal-btn">
              Salva
            </a>
          </div>
          <div onClick={closedHandler} className="modal-close">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="15"
              height="15"
              viewBox="0 0 16 16"
            >
              <path
                fillRule="evenodd"
                fill="#fff"
                d="M0 14.545L1.455 16 8 9.455 14.545 16 16 14.545 9.455 8 16 1.455 14.545 0 8 6.545 1.455 0 0 1.455 6.545 8z"
              ></path>
            </svg>
          </div>
        </div>
      ) : type === "admin-event" ? (
        // <div className="modal-content">
        //   <p>New Event</p>
        //   <form>
        //     <div className="form-div">
        //       <label> Name</label>
        //       <input type="text" placeholder=" Name" className="form-input" />
        //     </div>
        //     <div className="form-div">
        //       <label>Description</label>
        //       <input
        //         type="text"
        //         className="form-input"
        //         placeholder="description"
        //       />
        //     </div>
        //     <div className="form-div">
        //       <label>Image</label>
        //       <input type="file" className="form-input" accept="image/*" />
        //     </div>
        //     <a href="#" className="modal-btn">
        //       Save Event
        //     </a>
        //   </form>
        //   <div onClick={closedHandler} className="modal-close">
        //     <svg
        //       xmlns="http://www.w3.org/2000/svg"
        //       width="15"
        //       height="15"
        //       viewBox="0 0 16 16"
        //     >
        //       <path
        //         fillRule="evenodd"
        //         fill="#fff"
        //         d="M0 14.545L1.455 16 8 9.455 14.545 16 16 14.545 9.455 8 16 1.455 14.545 0 8 6.545 1.455 0 0 1.455 6.545 8z"
        //       ></path>
        //     </svg>
        //   </div>
        // </div>
        <div className="modal-content">
          <p>Event</p>
          <form>
            <div className="form-div">
              <label>Name</label>
              <input type="text" placeholder="Name" className="form-input" />
            </div>
            <div className="form-div">
              <label>Description</label>
              <input
                type="text"
                className="form-input"
                placeholder="description"
              />
            </div>
            <div className="form-div">
              <label>Image</label>
              <input type="file" className="form-input" accept="image/*" />
            </div>
            <button type="submit" className="modal-btn">
              Save Event
            </button>
          </form>
          <div onClick={closedHandler} className="modal-close">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="15"
              height="15"
              viewBox="0 0 16 16"
            >
              <path
                fillRule="evenodd"
                fill="#fff"
                d="M0 14.545L1.455 16 8 9.455 14.545 16 16 14.545 9.455 8 16 1.455 14.545 0 8 6.545 1.455 0 0 1.455 6.545 8z"
              ></path>
            </svg>
          </div>
        </div>
      ) : (
        <div className="modal-content">
          <p>Allenamento</p>
          <div className="modal-content-task-body">
            <img src={calendarIcon} alt="calendar icon" />
            <label>lunedì, 3 luglio</label>
          </div>
          <div className="modal-content-task-body">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              version="1.1"
              viewBox="0 0 24 24"
              xmlSpace="preserve"
              fill="#fff"
            >
              <path d="M20 13c-.6 0-1-.4-1-1 0-3.9-3.1-7-7-7-1.9 0-3.6.7-5 2-.3.4-1 .4-1.4 0-.4-.4-.4-1 0-1.4C7.3 3.9 9.6 3 12 3c5 0 9 4 9 9 0 .6-.4 1-1 1zM12 21c-5 0-9-4-9-9 0-.6.4-1 1-1s1 .4 1 1c0 3.9 3.1 7 7 7 1.9 0 3.6-.7 5-2 .4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4-1.7 1.7-4 2.6-6.4 2.6z"></path>
              <path
                d="M20 13c-.2 0-.4-.1-.6-.2L16 10.3c-.4-.3-.5-1-.2-1.4.3-.4 1-.5 1.4-.2l3.4 
"
              />
            </svg>
          </div>
          <div onClick={closedHandler} className="modal-close">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="15"
              height="15"
              viewBox="0 0 16 16"
            >
              <path
                fillRule="evenodd"
                fill="#fff"
                d="M0 14.545L1.455 16 8 9.455 14.545 16 16 14.545 9.455 8 16 1.455 14.545 0 8 6.545 1.455 0 0 1.455 6.545 8z"
              ></path>
            </svg>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminCoachModal;
