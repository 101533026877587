export const exceciseDropDownObj = [
  {
    name: "Adduttori pancake",
    link: "https://youtu.be/VGrRX23gslM",
  },
  {
    name: "HSPU pancia muro",
    link: "https://youtu.be/Zk-_I6E1sc4",
  },
  {
    name: "HSPU schiena muro",
    link: "https://youtu.be/ZirA1XOeHJc",
  },
  {
    name: "Ice Cream Maker",
    link: "https://youtu.be/fC0-MXrSK5E",
  },
  {
    name: "ICM tuck",
    link: "https://youtu.be/-187WNzsDt0",
  },
  {
    name: "Back Squat 3-5x8-5",
    link: "https://www.youtube.com/watch?v=05xORpEY5Ng&ab_channel=CristianMoletto",
  },
  {
    name: "Jefferson curl",
    link: "https://youtu.be/J4-v05hdVC4",
  },
  {
    name: "Jumpin' jacks",
    link: "https://youtu.be/OhNs2p89eqQ",
  },
  {
    name: "Kip extension",
    link: "https://youtu.be/NxmuxhVytsc",
  },
  {
    name: "L-sit tuck",
    link: "https://youtu.be/Jd9IWqI0xj0",
  },
  {
    name: "Lat Machine Mono Braccio",
    link: "https://youtu.be/JQ_PQUdVNtE",
  },
  {
    name: "Lat Machine Presa Neutra",
    link: "https://youtu.be/bdyIkk47L98",
  },
  {
    name: "Lat Machine Presa Prona",
    link: "https://youtu.be/WfID5CxqvCE",
  },
  {
    name: "Lat Machine Presa Supina",
    link: "https://youtu.be/z3yO9BE_GU4",
  },
  {
    name: "Lean Pushup",
    link: "https://youtu.be/hLdov7TqBnk",
  },
  {
    name: "Leg curl",
    link: "https://youtu.be/GHzFmWc1u4o",
  },
  {
    name: "Leg extension",
    link: "https://youtu.be/FKWmfmCrKQs",
  },
  {
    name: "Leg Press",
    link: "https://youtu.be/VbKW0PE9vaY",
  },
  {
    name: "Leg press monopodalica",
    link: "https://youtu.be/RxlnzX8dhyI",
  },
  {
    name: "Leg Raise a terra",
    link: "https://youtu.be/ORNLqQMAt0s",
  },
  {
    name: "Leg Raise alla sbarra",
    link: "https://youtu.be/U6Z7-G67x5c",
  },
  {
    name: "Leg raise parallele",
    link: "https://youtu.be/5reLiBcirPQ",
  },
  {
    name: "Mettere la zavorra",
    link: "https://youtu.be/36GQB_yqeu0",
  },
  {
    name: "Military press",
    link: "https://youtu.be/cmuvA9nKUWE",
  },
  {
    name: "Military press loop band",
    link: "https://youtu.be/ARIClFmUcag",
  },
  {
    name: "MU con loopband",
    link: "https://youtu.be/ACd72kk9c6E",
  },
  {
    name: "MU senza dip",
    link: "https://youtu.be/VLDSpBTKWuM",
  },
  {
    name: "Muscle Up",
    link: "https://youtu.be/yWeYR6PpR_8",
  },
  {
    name: "One Arm Body Row",
    link: "https://youtu.be/yJP3k3QLZrw",
  },
  {
    name: "Panca piana",
    link: "https://youtu.be/JDnvBPMctL8",
  },
  {
    name: "Panca stretta",
    link: "https://youtu.be/TR0d3E3gexo",
  },
  {
    name: "Pike pushups",
    link: "https://youtu.be/hh-4aZs0tv8",
  },
  {
    name: "Pike stand",
    link: "https://youtu.be/PZriDmoZ0Js",
  },
  {
    name: "Pistol assistiti",
    link: "https://youtu.be/ElHGfWsaA5w",
  },
  {
    name: "Pistol su cubo",
    link: "https://youtu.be/RiiTToVs6ME",
  },
  {
    name: "Pistols",
    link: "https://youtu.be/itK4gGpCeVk",
  },
  {
    name: "Planche lean",
    link: "https://youtu.be/FmDxDq58d5k",
  },
  {
    name: "Planche tuck",
    link: "https://youtu.be/lDjtXYXTj4M",
  },
  {
    name: "Planche tuck to L-sit",
    link: "https://youtu.be/tX_n--TB9IA",
  },
  {
    name: "Plank su gomiti",
    link: "https://youtu.be/_foqH4a2W_o",
  },
  {
    name: "Plank su mani",
    link: "https://youtu.be/7c4Wjv54-4I",
  },
  {
    name: "Plio pushup",
    link: "https://youtu.be/37T2lxBVwQg",
  },
  {
    name: "Ponte allungato",
    link: "https://youtu.be/IB1c8UFh0hs",
  },
  {
    name: "Pull down elastico",
    link: "https://youtu.be/B2lav-qRmKM",
  },
  {
    name: "Pulley cavo basso presa neutra",
    link: "https://youtu.be/RHT7vgZeNo8",
  },
  {
    name: "Pulley cavo basso presa prona",
    link: "https://youtu.be/whX44ohNi7k",
  },
  {
    name: "Pulley cavo basso presa supina",
    link: "https://youtu.be/t-b5yv3NcI4",
  },
  {
    name: "Pulley loopband",
    link: "https://youtu.be/bg0o1ficC5A",
  },
  {
    name: "Pulley mono braccio",
    link: "https://youtu.be/NTjkrHXWcA8",
  },
  {
    name: "Pullup",
    link: "https://youtu.be/H6DRdTPBtPs",
  },
  {
    name: "Pullup con bloccaggi",
    link: "https://youtu.be/6vXfDGVVF6s",
  },
  {
    name: "Pullup esplosivi",
    link: "https://youtu.be/s9bFhKRDt2M",
  },
  {
    name: "Pullup fronte sbarra",
    link: "https://youtu.be/Aqk_9NSbdHA",
  },
  {
    name: "Pullup iso",
    link: "https://youtu.be/oIkL-DtNgMg",
  },
  {
    name: "Pullup iso tuck",
    link: "https://youtu.be/a_r2jJpxqX8",
  },
  {
    name: "Pullup negativo",
    link: "https://youtu.be/3HZj3o1Q6kQ",
  },
  {
    name: "Pullup presa mista",
    link: "https://youtu.be/7_Jpzgyo5XE",
  },
  {
    name: "Pushdown cavo alto",
    link: "https://youtu.be/4mrSVk1CyOU",
  },
  {
    name: "Pushup",
    link: "https://youtu.be/Qcl1NVTmZNA",
  },
  {
    name: "Pushup Bar",
    link: "https://youtu.be/FYNfPCBDQ1A",
  },
  {
    name: "Pushup iso",
    link: "https://youtu.be/ppcQQzHXioc",
  },
  {
    name: "Pushup negativi",
    link: "https://youtu.be/2YtpwsDaZIU",
  },
  {
    name: "Pushup slow",
    link: "https://youtu.be/5zooEBNTv9U",
  },
  {
    name: "Pushup su ginocchia",
    link: "https://youtu.be/oove5B-vkXc",
  },
  {
    name: "Pushup su rialzo",
    link: "https://youtu.be/vlDBZrrlqDQ",
  },
  {
    name: "Pushup supino alla sbarra",
    link: "https://youtu.be/u-cBfXVlTYg",
  },
  {
    name: "Rematore bilanciere",
    link: "https://youtu.be/Qj8Zis-EfVg",
  },
  {
    name: "Rematore T-Bar",
    link: "https://youtu.be/gycw3nXw9NE",
  },
  {
    name: "Reverse plank",
    link: "https://youtu.be/ebK1D0O4twM",
  },
  {
    name: "Russian Twist",
    link: "https://youtu.be/k1yv25nJC3A",
  },
  {
    name: "Seal row presa prona",
    link: "https://youtu.be/frF_Ptm3CjY",
  },
  {
    name: "Seal row presa supina",
    link: "https://youtu.be/tgwSwRIWWgc",
  },
  {
    name: "Sedia a muro",
    link: "https://youtu.be/wLQLblHcmbM",
  },
  {
    name: "Shoulder press",
    link: "https://youtu.be/M7LvqvWSyCo",
  },
  {
    name: "Situp",
    link: "https://youtu.be/IEaoxpqMjlw",
  },
  {
    name: "Squat jump",
    link: "https://youtu.be/0IK-LUmI094",
  },
  {
    name: "Squat libero",
    link: "https://youtu.be/RQp7qvEwWmM",
  },
  {
    name: "Stacco kettlebell o manubri",
    link: "https://youtu.be/4IZeuejVeUQ",
  },
  {
    name: "Stacco regolare bilanciere",
    link: "https://youtu.be/2lRx153wJdE",
  },
  {
    name: "Stacco rumeno bilanciere",
    link: "https://youtu.be/VKaAD2tKor0",
  },
  {
    name: "Stacco sumo bilanciere",
    link: "https://youtu.be/tNBNuf__PBY",
  },
  {
    name: "Trazioni loop band",
    link: "https://youtu.be/G_ZsYN8KXX4",
  },
  {
    name: "Trazioni spezzate",
    link: "https://youtu.be/UZy-nflDHuw",
  },
  {
    name: "V-pushup",
    link: "https://youtu.be/z2vULNDxWPM",
  },
  {
    name: "V-pushup negativi o con loopband",
    link: "https://youtu.be/GjogL2Tf_NU",
  },
  {
    name: "V-pushups facilitati",
    link: "https://youtu.be/3LkzFX7jtfg",
  },
  {
    name: "V-stand",
    link: "https://youtu.be/ysjo1FRA_Js",
  },
  {
    name: "Verticale pancia muro",
    link: "https://youtu.be/w-4h844k-lE",
  },
  {
    name: "Verticale schiena muro",
    link: "https://youtu.be/riB7qgrHN2A",
  },
  {
    name: "Voltabraccia bastone",
    link: "https://youtu.be/CvAXw3_IjSk",
  },
];
