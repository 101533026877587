import axios from "axios";
import {
  FETCH_DATA_REQUEST,
  SUCCESS,
  FAILURE,
  LOGIN_REQUEST,
  GET_MEAL_PLAN,
  GET_FOOD_LIST,
  GET_FOOD_LIST_SUCCESS,
  GET_DAILY_TRAINING,
  LOGIN_SUCCESS,
  FETCH_FEEDBACK,
  SET_FEEDBACK,
  REGISTRATION,
  SET_TOTAL_VALUES,
  SUCCESS_RECIPE,
  GET_TRAINING_LISTING,
  CONCLUDE_EXERCISE,
  SET_TRAINING_STATUS,
  RESET_TRAINING_STATUS,
} from "./actionTypes";
import { BASEURL } from "../../assets/constants";
import { toast } from "react-toastify";

export const fetchUserData = (payload) => async (dispatch) => {
  dispatch({ type: FETCH_DATA_REQUEST });

  try {
    const response = await axios.post(
      `${BASEURL}/services/coaches/getAllUsers.php`,
      payload
    );

    if (response.status === 200) {
      dispatch({ type: SUCCESS, payload: response.data });
    } else {
      dispatch({ type: FAILURE, payload: response.data.message });
    }
  } catch (error) {
    dispatch({ type: FAILURE, payload: error.message });
  }
};

export const loginUser = (credentials, callback) => async (dispatch) => {
  dispatch({ type: LOGIN_REQUEST });

  try {
    const response = await axios.post(
      `${BASEURL}/services/loginUser.php`,
      credentials
    );

    if (response.status === 200) {
      dispatch({ type: LOGIN_SUCCESS, payload: response?.data?.data });
      localStorage.setItem("coachID", response?.data?.data?.idcoach);
      localStorage.setItem("token", response?.data?.data?.token);
      localStorage.setItem("userID", response?.data?.data?.idutente);
      localStorage.setItem("coachName", response?.data?.data?.nome_coach);
      if (callback) callback(response?.data?.data?.ruolo);
    } else {
      dispatch({ type: FAILURE, payload: response?.data?.message });
    }
  } catch (error) {
    dispatch({ type: FAILURE, payload: error.message });
  }
};

export const mealData = (data) => async (dispatch) => {
  dispatch({ type: GET_MEAL_PLAN });

  try {
    const response = await axios.post(
      `${BASEURL}/services/users/meals/getMealPlan.php`,
      data
    );

    if (response.status === 200) {
      dispatch({ type: SUCCESS, payload: response?.data });
    } else {
      dispatch({ type: FAILURE, payload: response?.data?.message });
    }
  } catch (error) {
    dispatch({ type: FAILURE, payload: error.message });
  }
};

export const foodList = (foodData) => async (dispatch) => {
  dispatch({ type: GET_FOOD_LIST });

  try {
    const response = await axios.post(
      `${BASEURL}/services/users/meals/getMacroFoodList.php`,
      foodData
    );


    if (response.status === 200) {
      dispatch({ type: SUCCESS, payload: response?.data });
    } else {
      dispatch({ type: FAILURE, payload: response?.data?.message });
    }
  } catch (error) {
    dispatch({ type: FAILURE, payload: error.message });
  }
};

export const getDailyTraining = (payload) => async (dispatch) => {
  dispatch({ type: GET_DAILY_TRAINING });

  try {
    const response = await axios.post(
      `${BASEURL}/services/users/training/getDailyTraining.php`,
      payload
    );


    if (response.status === 200) {
      dispatch({ type: SUCCESS, payload: response?.data });
    } else {
      dispatch({ type: FAILURE, payload: response?.data?.message });
    }
  } catch (error) {
    dispatch({ type: FAILURE, payload: error.message });
  }
};

export const getTrainingListing = (payload) => async (dispatch) => {
  dispatch({ type: GET_TRAINING_LISTING });

  try {
    const response = await axios.post(
      `${BASEURL}/services/users/training/getTrainingListing.php`,
      payload
    );

    if (response.status === 200) {
      dispatch({ type: SUCCESS, payload: response?.data });
    } else {
      dispatch({ type: FAILURE, payload: response?.data?.message });
    }
  } catch (error) {
    dispatch({ type: FAILURE, payload: error.message });
  }
};

export const fetchFeedback = (payload) => async (dispatch) => {
  dispatch({ type: FETCH_FEEDBACK });

  try {
    const response = await axios.post(
      `${BASEURL}/services/users/training/setUserFeedback.php`,
      payload
    );


    if (response.status === 200) {
      dispatch({ type: SUCCESS, payload: response.data });
    } else {
      dispatch({ type: FAILURE, payload: response.data.message });
    }
  } catch (error) {
    dispatch({ type: FAILURE, payload: error.message });
  }
};

export const setRegistration = (payload) => async (dispatch) => {
  dispatch({ type: REGISTRATION });

  try {
    const response = await axios.post(
      `${BASEURL}/services/users/registerUser.php`,
      payload
    );

    if (response.status === 200) {
      dispatch({ type: SUCCESS, payload: response.data });
    } else {
      dispatch({ type: FAILURE, payload: response.data.message });
    }
  } catch (error) {
    dispatch({ type: FAILURE, payload: error.message });
  }
};

export const setFeedback = (date, feedbackText) => {
  return {
    type: SET_FEEDBACK,
    payload: { date, feedbackText },
  };
};

export const setTotalValues = (totalC, totalP, totalF, totalG) => ({
  type: SET_TOTAL_VALUES,
  payload: { totalC, totalP, totalF, totalG },
});

export const recipeSuccess = (data) => async (dispatch) => {
  dispatch({ type: SUCCESS_RECIPE });

  try {
    const response = await axios.post(
      `${BASEURL}/services/users/meals/setUserRecipe.php`,
      data
    );

    if (response.status === 200) {
      dispatch({ type: SUCCESS, payload: response?.data });
      // toast.success("Ricetta Salvata");
    } else {
      dispatch({ type: FAILURE, payload: response?.data?.message });
    }
  } catch (error) {
    dispatch({ type: FAILURE, payload: error.message });
  }
};

export const concludeExercise = (exerciseId) => ({
  type: CONCLUDE_EXERCISE,
  payload: exerciseId,
});

export const setTrainingStatus = (status, date) => {
  return {
    type: SET_TRAINING_STATUS,
    payload: { status, date },
  };
};

export const setReset = () => {
  return {
    type: "RESET",
    payload: {},
  };
};
