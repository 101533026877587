import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";

const CoachRoute = () => {
  const userData = JSON.parse(localStorage.getItem("adminData"));
  const role = userData?.data?.role;
  const lastScreen = localStorage.getItem("lastScreen");
  const location = useLocation();
  console.log("last", lastScreen);

  if (role !== "coach") {
    return <Navigate to={lastScreen ? lastScreen : "/login"} />;
  }
  localStorage.setItem("lastScreen", location.pathname);

  return <Outlet />;
};

export default CoachRoute;
