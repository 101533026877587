import { combineReducers } from "redux";
import userReducer from "./reducer";
import trainingReducer from "./trainingReducer";

const reducers = combineReducers({
  userReducer,
  trainingReducer,
});

export default reducers;
