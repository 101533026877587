import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import "moment/locale/it";
import thumbnailImage from "../../assets/img/thumbnail.jpg";
import Thumbnail from "../Reuseable/Thumbnail";
import chartImg from "../../assets/images/chart.png";
import "./Home.css";
import { useSelector } from "react-redux";
import { BASE_URL } from "../../assets/constants";
import axios from "axios";

const Home = ({ giornoAttivo }) => {
  const navigate = useNavigate();
  const adminData = JSON.parse(localStorage.getItem("adminData"));
  const token = adminData?.token;
  moment.locale("it");
  const formatDate = (date) => moment(date).format("LL");

  const [dashboardData, setDashboardData] = useState(null);

  const handleClick = () => {
    navigate("/user/pre-allenamento-giorno-on");
  };
  const handleClick1 = () => {
    navigate("/user/aggiorn-le-misurazioni");
  };

  const getStatus = useSelector(
    (state) => state?.trainingReducer?.trainingCompleted
  );

  const getDashboardData = async () => {
    try {
      const response = await axios.get(`${BASE_URL}athlete/dashboard`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setDashboardData(response?.data);
    } catch (error) {}
  };

  useEffect(() => {
    getDashboardData();
  }, []);

  return (
    <div className="content">
      <section className="welcome">
        <div className="date">{formatDate(giornoAttivo)}</div>
        <div className="name">{adminData?.data?.name || "---"}</div>

        <div className="description">cosa facciamo oggi?</div>
      </section>

      <section className="allenamento">
        <div className="title">Allenamento</div>
        {/* <a onClick={handleClick}> */}
        <div
          onClick={() =>
            dashboardData?.training?.status === "pending" &&
            navigate(`/user/training/step-two/${dashboardData?.training?.id}`)
          }
        >
          <Thumbnail
            // imageUrl={thumbnailImage}
            status={dashboardData?.training?.status}
            title={dashboardData?.training?.content || "----"}
            // subtitle="350 kcal"
          />
        </div>
        {/* </a> */}
      </section>

      <section className="nutrizione">
        <div className="title">Nutrizione</div>
        {dashboardData?.nutrition?.length > 0 &&
        typeof dashboardData?.nutrition == "object"
          ? dashboardData?.nutrition?.map((value, index) => {
              return (
                <Link to="#" key={index}>
                  <Thumbnail
                    // imageUrl={thumbnailImage}
                    heading={value?.type || "---"}
                    title={value?.meal_name[0] || "---"}
                    subtitle={value?.total_calories || "---"}
                  />
                </Link>
              );
            })
          : dashboardData?.nutrition}
      </section>
      <section className="allenamento">
        <div className="title">AGGIORNA LE MISURAZIONI</div>
        <a onClick={handleClick1}>
          <Thumbnail
            // imageUrl={thumbnailImage}
            title="AGGIORNA LE MISURAZIONI"
            subtitle=""
          />
        </a>
      </section>

      <section className="progressi">
        <div className="title">Progressi</div>
        <div className="box-group">
          <div className="total-box state-good">
            <div className="title">ADERENZA</div>
            <div className="values">
              <div className="score">
                {dashboardData?.progress?.Adhesion || 0}
              </div>
              {/* <div className="percent">+2.51%</div> */}
            </div>
          </div>
          <div className="total-box state-bad">
            <div className="title">costanza</div>
            <div className="values">
              <div className="score">
                {dashboardData?.progress?.costanza || 0}
              </div>
              {/* <div className="percent">-13.6%</div> */}
            </div>
          </div>
        </div>
        {/* <div className="diagram">
          <div className="title">ANDAMENTO</div>
          <div className="drop-downs">
            <details className="dropdown-menu">
              <summary>MASSA GRASSA</summary>
              <ul>
                <li>
                  <a href="#">UNO</a>
                </li>
                <li>
                  <a href="#">DUE</a>
                </li>
                <li>
                  <a href="#">TRE</a>
                </li>
              </ul>
            </details>
            <details className="dropdown-menu">
              <summary>MENSILE</summary>
              <ul>
                <li>
                  <a href="#">UNO</a>
                </li>
                <li>
                  <a href="#">DUE</a>
                </li>
                <li>
                  <a href="#">TRE</a>
                </li>
              </ul>
            </details>
          </div>
          <div className="chart"></div>
        </div> */}
        {/* <Link to="#">
          <Thumbnail
            imageUrl={thumbnailImage}
            title="Aggiorna le tue misurazioni"
            subtitle="ultimo aggiornamento: 27 maggio"
          />
        </Link> */}
      </section>
    </div>
  );
};

export default Home;
