import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./nutrition.css";
// Reuseable
import WeekCalendar from "../Reuseable/WeekCalender";
import SearchIcons from "../../assets/img/icons/search-active.svg";

// Icons
import PlusIcon from "../../assets/img/icons/plus-active.svg";
import CheckIcon from "../../assets/img/icons/check-active.svg";
import CloseIcon from "../../assets/img/icons/close-active.svg";

// Components
import { LeftArrowIcon } from "../Reuseable/Icons";

const NutritionStepOne = ({ onButtonClick, meal, suggested }) => {
  const [currentIndex, setCuurentIndex] = useState(0);
  const [searchInput, setSearchInput] = useState("");
  const [iconCheck, setIconCheck] = useState(true);
  const [showAll, setShowAll] = useState(false);

  const navigate = useNavigate();
  const handleBackClick = () => {
    navigate(-1);
  };
  const monthNames = [
    "Gennaio",
    "Febbraio",
    "Marzo",
    "Aprile",
    "Maggio",
    "Giugno",
    "Luglio",
    "Agosto",
    "Settembre",
    "Ottobre",
    "Novembre",
    "Dicembre",
  ];

  const initialItemCount = 5;

  const handleShowAllClick = () => {
    setShowAll(true);
  };

  const filteredItems = suggested?.filter((item) =>
    item?.Descrizione?.toLowerCase().includes(searchInput.toLowerCase())
  );

  const itemsToDisplay = showAll
    ? filteredItems
    : filteredItems?.slice(0, initialItemCount);

  const [currentDate, setCurrentDate] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(new Date());

  const generateCalendarDates = (date) => {
    const dates = [];
    const month = date.getMonth();
    const year = date.getFullYear();
    const firstDay = new Date(year, month, 1).getDay();
    const lastDate = new Date(year, month + 1, 0).getDate();

    // Fill in the dates of the previous month
    const prevMonthLastDate = new Date(year, month, 0).getDate();
    for (let i = firstDay === 0 ? 6 : firstDay - 1; i > 0; i--) {
      dates.push({
        day: prevMonthLastDate - i + 1,
        currentMonth: false,
      });
    }

    // Fill in the current month's dates
    for (let i = 1; i <= lastDate; i++) {
      dates.push({
        day: i,
        currentMonth: true,
        isToday:
          i === date.getDate() &&
          month === new Date().getMonth() &&
          year === new Date().getFullYear(),
        isSelected:
          i === selectedDate.getDate() &&
          month === selectedDate.getMonth() &&
          year === selectedDate.getFullYear(),
      });
    }

    return dates;
  };

  const prevMonth = () => {
    setCurrentDate(new Date(currentDate.setMonth(currentDate.getMonth() - 1)));
  };

  const nextMonth = () => {
    setCurrentDate(new Date(currentDate.setMonth(currentDate.getMonth() + 1)));
  };

  const handleDateClick = (day) => {
    setSelectedDate(
      new Date(currentDate.getFullYear(), currentDate.getMonth(), day)
    );
  };

  const calendarDates = generateCalendarDates(currentDate);

  return (
    <div className="container">
      <div className="row">
        <div className=" nutrition-flex">
          <div className="top-bar">
            <button onClick={handleBackClick} className="icon">
              <LeftArrowIcon />
            </button>
            <div className="title">nutrizione</div>
          </div>
        </div>
        <div className="col-md-8">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
            }}
          >
            {["Calendario", "Preferiti", "Lista spesa"].map((item, index) => {
              return (
                <div>
                  <div
                    className="description"
                    style={{
                      cursor: "pointer",
                      borderBottom:
                        currentIndex === index ? "1px solid #7fff00" : null,
                      width: "15em",
                      alignItems: "center",
                      textAlign: "center",
                      padding: 10,
                    }}
                    onClick={() => {
                      setCuurentIndex(index);
                    }}
                  >
                    {item}
                  </div>
                </div>
              );
            })}
          </div>

          <div>
            {currentIndex === 0 ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: 50,
                  marginBottom: "20em",
                  cursor: "pointer",
                }}
              >
                <div
                  className="title"
                  style={{ fontSize: 20, color: "#7fff00" }}
                  onClick={() => {
                    onButtonClick();
                    // navigate("/NutritionStepTwo");
                  }}
                >
                  genera automaticamente
                </div>
                <div
                  className="title"
                  style={{ fontSize: 20, color: "#7fff00" }}
                >
                  Importa dai preferiti
                </div>
                <div
                  className="title"
                  style={{ fontSize: 20, color: "#7fff00" }}
                >
                  pianifica manualmente
                </div>
              </div>
            ) : currentIndex == 1 ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  // marginTop: 50,
                  // marginBottom: "20em",
                }}
              >
                <div
                  className="search-bar"
                  style={{ width: "40em", marginTop: 50 }}
                >
                  <div className="icon left">
                    <img src={SearchIcons} alt="search-icon" />
                  </div>
                  <div className="input-textarea">
                    <input
                      type="search"
                      name="searchInput"
                      placeholder="Search input text"
                      value={searchInput}
                      onChange={(e) => setSearchInput(e.target.value)}
                    />
                  </div>
                  <div className="icon right">
                    {searchInput && (
                      <img
                        onClick={() => setSearchInput("")}
                        src={CloseIcon}
                        alt="close-icon"
                      />
                    )}
                  </div>
                </div>
                <div style={{ width: "45em", marginTop: 50 }}>
                  <div className="card-with-actions">
                    <div className="title">Preferiti 1</div>
                    <div onClick={() => setIconCheck(!iconCheck)}>
                      {iconCheck ? (
                        <img src={PlusIcon} alt="" />
                      ) : (
                        <img src={CheckIcon} alt="" />
                      )}
                    </div>
                  </div>
                </div>
                <div style={{ width: "45em", marginTop: 10 }}>
                  <div className="card-with-actions">
                    <div className="title">Preferiti 1</div>
                    <div onClick={() => setIconCheck(!iconCheck)}>
                      {iconCheck ? (
                        <img src={PlusIcon} alt="" />
                      ) : (
                        <img src={CheckIcon} alt="" />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    // marginTop: 50,
                    // marginBottom: "20em",
                  }}
                >
                  <div
                    className="search-bar"
                    style={{ width: "40em", marginTop: 50 }}
                  >
                    <div className="icon left">
                      <img src={SearchIcons} alt="search-icon" />
                    </div>
                    <div className="input-textarea">
                      <input
                        type="search"
                        name="searchInput"
                        placeholder="Search input text"
                        value={searchInput}
                        onChange={(e) => setSearchInput(e.target.value)}
                      />
                    </div>
                    <div className="icon right">
                      {searchInput && (
                        <img
                          onClick={() => setSearchInput("")}
                          src={CloseIcon}
                          alt="close-icon"
                        />
                      )}
                    </div>
                  </div>
                  <div style={{ width: "45em", marginTop: 50 }}>
                    <div className="card-with-actions">
                      <div className="title">
                        Lista spesa 1<br />
                        300G
                      </div>

                      <div onClick={() => setIconCheck(!iconCheck)}>
                        <img
                          onClick={() => setSearchInput("")}
                          src={CloseIcon}
                          alt="close-icon"
                        />
                      </div>
                    </div>
                  </div>
                  <div style={{ width: "45em", marginTop: 10 }}>
                    <div className="card-with-actions">
                      <div className="title">
                        Lista spesa 2<br />
                        125G
                      </div>

                      <div onClick={() => setIconCheck(!iconCheck)}>
                        <img
                          onClick={() => setSearchInput("")}
                          src={CloseIcon}
                          alt="close-icon"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="col-md-4">
          <div className="calendar-container">
            <div className="calendar-header">
              <button className="nav-arrow" onClick={prevMonth}>
                &#8592;
              </button>
              <span className="month-name">
                {monthNames[currentDate.getMonth()]} {currentDate.getFullYear()}
              </span>
              <button className="nav-arrow" onClick={nextMonth}>
                &#8594;
              </button>
            </div>
            <div className="calendar-weekdays">
              <span>L</span>
              <span>M</span>
              <span>M</span>
              <span>G</span>
              <span>V</span>
              <span>S</span>
              <span>D</span>
            </div>
            <div className="calendar-dates">
              {calendarDates.map((date, index) => (
                <span
                  key={index}
                  className={`${!date.currentMonth ? "prev-month" : ""} ${
                    date.isToday ? "current-day" : ""
                  } ${date.isSelected ? "selected" : ""}`}
                  onClick={() => date.currentMonth && handleDateClick(date.day)}
                >
                  {date.day}
                </span>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* .................................END............................. */}

      {/* Calender */}
      {/* <WeekCalendar /> */}

      {/* Nutrition Facts */}
      {/* <div>
        <div className="stepOne-nutrition-fact">
          <div className="card-text-with-progress-bar">
            <div className="info">{meal?.calorie} kcal</div>
            <div className="description">Calorie</div>
            <progress max="100" value="0"></progress>
          </div>
          <div className="card-text-with-progress-bar">
            <div className="info">{meal?.carboidrati}</div>
            <div className="description">Carboidrati</div>
            <progress max="100" value="0"></progress>
          </div>
          <div className="card-text-with-progress-bar">
            <div className="info">{meal?.proteine} G</div>
            <div className="description">Proteine</div>
            <progress max="100" value="0"></progress>
          </div>
          <div className="card-text-with-progress-bar">
            <div className="info">{meal?.fibre} G</div>
            <div className="description">Fibre</div>
            <progress max="100" value="0"></progress>
          </div>
        </div>

        <div className="link-list">
          <a href="#" onClick={onButtonClick}>
            pianifica
          </a>
        </div>
      </div> */}
    </div>
  );
};

export default NutritionStepOne;
